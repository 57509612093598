import React, { useState, useEffect } from 'react';
import {
  BottomButtonContainer,
  ButtonEnableDisable,
  ContentsContainer,
  ModalOverlay,
  PaymentMethodChoiceContainer,
  PaymentMethodChoiceItem,
  PaymentMethodModalContainer,
  TitleSpan,
} from './styles';

interface PaymentIncomeDeductionBottomModalProps {
  isVisible: boolean;
  onClose: () => void;
  incomeDeduction?: string
  onIncomeDeduction: (method: string) => void;
}

const PaymentIncomeDeductionBottomModal:
  React.FC<PaymentIncomeDeductionBottomModalProps> =
    ({ isVisible, onClose, incomeDeduction, onIncomeDeduction }) => {

  const [selectedIncomeDeduction, setSelectedIncomeDeduction] = useState<string | null>(incomeDeduction || null);

  const handleOverlayClick = () => {
    onClose();
  };

  const paymentMethod = ['휴대폰번호', '현금영수증카드'];

  // 모달이 열릴 때 선택된 방법 초기화
  useEffect(() => {
    if (isVisible) {
      setSelectedIncomeDeduction(incomeDeduction || '휴대폰번호');
    }
  }, [isVisible, incomeDeduction]);

  useEffect(() => {
    if (selectedIncomeDeduction) {
      onIncomeDeduction(selectedIncomeDeduction);
    }
  }, [selectedIncomeDeduction, onIncomeDeduction]);

  if (!isVisible) return null;

  return (
    <>
      <ModalOverlay onClick={handleOverlayClick} />
      <PaymentMethodModalContainer isVisible={isVisible}>
        <TitleSpan>유형선택</TitleSpan>
        <ContentsContainer>
          <PaymentMethodChoiceContainer>
            {paymentMethod.map((method, index) => (
              <PaymentMethodChoiceItem
                key={index}
                isSelected={selectedIncomeDeduction === method}
                onClick={() => setSelectedIncomeDeduction(method)}
              >
                {method}
              </PaymentMethodChoiceItem>
            ))}
          </PaymentMethodChoiceContainer>
        </ContentsContainer>

        <BottomButtonContainer>
          <ButtonEnableDisable
            onClick={() => {
              if (selectedIncomeDeduction) {
                onIncomeDeduction(selectedIncomeDeduction)
                onClose();
              }
            }}
            disabled={!selectedIncomeDeduction}
          >
            확인
          </ButtonEnableDisable>
        </BottomButtonContainer>
      </PaymentMethodModalContainer>
    </>
  );
};

export default PaymentIncomeDeductionBottomModal;
