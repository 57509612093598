import { styled } from '@stitches/react';

export const ParkingPhotoCompleteContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  marginTop: '84px'
})

export const ParkingPhotoCompleteSpan = styled('span', {
  marginTop: '20px',
  textAlign: 'center',
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  color: '#8B63BB'
})