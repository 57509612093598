import icProfileUser from "../../../assets/ic/ic_profile_user.png";
import React from "react";
import {
  ButtonModify, ImgMyPage,
  ImgUserProfile,
  MyPageGreetingPhoneNumberContainer,
  MyPagePhoneNumberContainer, MyPageSettingListContainer,
  MyPageUserInfoContainer, MyPageUserInfoPhotoContainer, MyPageUserInfoPhotoEllipseContainer,
  TextGreeting,
  TextPhoneNumber, TextSir
} from "./styles";
import icProfile from "../../../assets/ic/ic_profile.svg"
import icLocation from "../../../assets/ic/ic_location.svg"
import icWallet from "../../../assets/ic/ic_wallet.svg"
import icCar from "../../../assets/ic/ic_car.svg"
import icDotPurple from "../../../assets/ic/ic_dot_purple.svg"
import {useNavigate} from "react-router-dom";
import {FixedSizeList as List} from "react-window";


const MyPageList = () => {
  const navigate = useNavigate();

  const userInfoItems = [
    {textSetting: '휴대폰 번호', textUserInfo: '010-1234-5678'},
    {textSetting: '이메일', textUserInfo: 'zoomansa@gmail.com'},
    {textSetting: '성별 / 생년월일', textUserInfo: '확인불가/확인불가'}
  ];

  const UserInfoRow = ({index, style}: {index: number, style: React.CSSProperties}) => {
    const {textSetting, textUserInfo} = userInfoItems[index];

    return (
        <div style={{display: 'flex', alignItems: 'center', ...style}}>
          <img src={icDotPurple} alt={'점 아이콘'} style={{ marginTop: '8px'}}/>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '8px',
            marginLeft: '7px',
            width: '100%',
          }}>
            <span>{textSetting}</span>
            <span style={{marginLeft: 'auto'}}>{textUserInfo}</span>
          </div>
        </div>
    );
  };

  return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100%',
      }}>
        <div style={{flex: 1, overflowY: 'auto'}}>
          <MyPageUserInfoContainer>
            <MyPageGreetingPhoneNumberContainer>
              <TextGreeting>안녕하세요!</TextGreeting>
              <MyPagePhoneNumberContainer>
                <TextPhoneNumber>01012345678</TextPhoneNumber>
                <TextSir>님</TextSir>
              </MyPagePhoneNumberContainer>
            </MyPageGreetingPhoneNumberContainer>
            <MyPageUserInfoPhotoContainer>
              <MyPageUserInfoPhotoEllipseContainer>
                <ImgUserProfile src={icProfileUser} alt={'프로필 타원 아이콘'}/>
              </MyPageUserInfoPhotoEllipseContainer>
            </MyPageUserInfoPhotoContainer>
          </MyPageUserInfoContainer>
          <div>
            <MyPageSettingListContainer>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <ImgMyPage src={icProfile} alt="프로필 아이콘"/>
                <span>프로필</span>
              </div>
              <ButtonModify>수정</ButtonModify>
            </MyPageSettingListContainer>
            <div style={{flexGrow: 1, overflowY: 'auto'}}>
              <List
                  height={120}
                  itemCount={userInfoItems.length}
                  itemSize={30}
                  width={'100%'}
              >
                {UserInfoRow}
              </List>
            </div>
            <div>
              <MyPageSettingListContainer>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <ImgMyPage src={icLocation} alt="로케이션 아이콘"/>
                  <span>월 주차 희망지역</span>
                </div>
                <ButtonModify>수정</ButtonModify>
              </MyPageSettingListContainer>
              <div style={{marginTop: '8px', textAlign: 'right'}}>
                강남구 테헤란로 77길 23
              </div>
            </div>
            <div>
              <MyPageSettingListContainer>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <ImgMyPage src={icWallet} alt="지갑 아이콘"/>
                  <span>정기결제 출금 계좌</span>
                </div>
                <ButtonModify>수정</ButtonModify>
              </MyPageSettingListContainer>
              <div style={{marginTop: '8px', textAlign: 'right'}}>
                정기결제 신청 내역이 없습니다.
              </div>
            </div>
            <div>
              <MyPageSettingListContainer>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <ImgMyPage src={icCar} alt="자동차 아이콘"/>
                  <span>차량관리</span>
                </div>
                <ButtonModify onClick={() => {
                  {/* 등록된 차량이 있다면*/}
                  // navigate('/carRegistration')

                  {/* 등록된 차량이 없다면*/}
                  navigate('/carInfo')
                }}>등록</ButtonModify>
              </MyPageSettingListContainer>
              <div style={{marginTop: '8px', textAlign: 'right'}}>
                등록된 차량이 없습니다.
              </div>
            </div>
          </div>
        </div>
        <span style={{
          textAlign: 'center',
          padding: '39px',
          cursor: 'pointer',
          fontFamily: 'Pretendard',
          fontSize: '13px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: 'normal',
          textDecoration: 'underline',
          color: '#868686',
        }}
        onClick={() => {
          {/* 로그아웃 처리 되면서 홈으로 이동*/}
          navigate(-1)
        }}
        >
        로그아웃
      </span>
      </div>
  );
};

export default MyPageList;
