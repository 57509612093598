import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Title from '../../../../components/atoms/Title/PageTitle';
import WithdrawBottomModal from '../../../../components/atoms/Modal/WithdrawBottomModal';

import icArrowDelete from '../../../../assets/ic/ic_arrow_delete.svg';
import PurpleOneButtonCheckModal from '../../../../components/atoms/Modal/PurpleOneButtonCheckModal';
import PurpleTwoButtonCheckModal from '../../../../components/atoms/Modal/PurpleTwoButtonCheckModal';
import {
  AllDeleteContainer,
  BottomButtonKeyboardContainer,
  BottomGridContainer,
  ButtonEnableDisable,
  CautionContainer,
  InputBoxContainer,
  InputButton,
  InputGridContainer,
  OneDeleteContainer,
  OneDeleteImg,
  PasswordInput,
  PasswordTitleInputContainer,
  SearchPasswordContainer, SearchPasswordForget,
  WithdrawalMainContainer,
} from '../styles';
import { encTextWord, getRandomString } from '../../../../utils/cryptoJS';
import { pointPasswordGenerateService } from '../../../../service/Point/pointPasswordGenerateService';
import { observer } from 'mobx-react';
import { LINK_UID } from '../../../../constants/userInfoConstants';
import { pointPasswordGenerateStore } from '../../../../stores/store/Point/pointPasswordGenerateStore';

const PointWithdrawalPassword: React.FC = observer(() => {
  const navigate = useNavigate();
  const [isWithdrawBottomModalVisible, setIsWithdrawBottomModalVisible] = React.useState(false);
  const [inputValues, setInputValues] = useState<string[]>(Array(6).fill(''));
  const [isSearchPasswordModal, setIsSearchPasswordModal] = useState(false);
  const [isFivePasswordMissModal, setIsFivePasswordMissModal] = useState(false);
  const [errorCount, setErrorCount] = useState(0);
  const [isPasswordIncorrect, setIsPasswordIncorrect] = useState(false);

  const handleNumberClick = (index: number, number: string) => {
    setInputValues((prevValues) => {
      const newValues = [...prevValues];
      newValues[index] = number;
      return newValues;
    });
  };

  const handleClearAll = () => {
    setInputValues(Array(6).fill(''));
  };

  const handleBackspace = () => {
    setInputValues((prevValues) => {
      const lastNonEmptyIndex = prevValues
        .map((value, index) => (value !== '' ? index : -1))
        .filter((index) => index !== -1)
        .pop();

      if (lastNonEmptyIndex !== undefined) {
        const newValues = [...prevValues];
        newValues[lastNonEmptyIndex] = '';
        return newValues;
      }
      return prevValues;
    });
  };

  const getDisplayValue = (value: string, index: number) => {
    const filledCount = inputValues.filter((v) => v !== '').length;
    return index < filledCount - 1 ? '*' : value;
  };

  const isAllFieldsFilled = inputValues.every((value) => value !== '');

  const handleSubmit = async () => {
    const enteredPassword = inputValues.join('');
    const { iv, salt } = getRandomString();
    const memberPW = encTextWord(enteredPassword, iv, salt);
    const linkUID = parseInt(sessionStorage.getItem(LINK_UID) || '0', 10);

    await pointPasswordGenerateStore.fetchPwValidate(linkUID, memberPW, iv, salt);

    if (pointPasswordGenerateStore.success) {
      sessionStorage.setItem('token', pointPasswordGenerateStore.token);
      console.log(pointPasswordGenerateStore.token);
      navigate('/pointWithdrawalFinalCheck');
    } else {
      setIsPasswordIncorrect(true);
      const tryCount = pointPasswordGenerateStore.tryCount || 0;
      setErrorCount(tryCount);

      console.log(tryCount);

      if (tryCount >= 5) {
        setIsFivePasswordMissModal(true);

        await pointPasswordGenerateService.postPointPasswordDelete(
          {
            memberUID: linkUID,
            wdPassword: memberPW,
          },
          iv,
          salt,
        );
      } else {
        handleClearAll();
      }
    }
  };

  useEffect(() => {
    window.onBtnBackClicked = function () {
      navigate(-1);
    };
    return () => {
      delete window.onBtnBackClicked;
    };
  }, []);

  return (
    <>
      <WithdrawalMainContainer>
        <Title
          title="출금"
          leftArrowButtonVisible={true}
          leftOnClick={() => {
            navigate(-1);
          }}
          rightTextButtonVisible={false}
        />

        <PasswordTitleInputContainer>
          <span>송금을 위해</span>
          <br />
          <span>비밀번호를 입력해 주세요.</span>
        </PasswordTitleInputContainer>

        <InputBoxContainer>
          {inputValues.map((value, index) => (
            <PasswordInput key={index} value={getDisplayValue(value, index)} readOnly />
          ))}
        </InputBoxContainer>

        {isPasswordIncorrect && (
          <CautionContainer>
            <span>비밀번호가 일치하지 않습니다.</span>
            <span>{errorCount} / 5</span>
          </CautionContainer>
        )}

        <SearchPasswordForget onClick={() => setIsSearchPasswordModal(true)}>
          비밀번호를 잊었습니다
        </SearchPasswordForget>

        <BottomButtonKeyboardContainer>
          <ButtonEnableDisable onClick={handleSubmit} disabled={!isAllFieldsFilled}>
            확인
          </ButtonEnableDisable>

          <BottomGridContainer>
            <InputGridContainer>
              {[1, 2, 3].map((number: number) => (
                <InputButton
                  key={number}
                  onClick={() => {
                    const emptyIndex = inputValues.indexOf('');
                    if (emptyIndex !== -1) {
                      handleNumberClick(emptyIndex, number.toString());
                    }
                  }}
                >
                  {number}
                </InputButton>
              ))}
            </InputGridContainer>
            <InputGridContainer>
              {[4, 5, 6].map((number: number) => (
                <InputButton
                  key={number}
                  onClick={() => {
                    const emptyIndex = inputValues.indexOf('');
                    if (emptyIndex !== -1) {
                      handleNumberClick(emptyIndex, number.toString());
                    }
                  }}
                >
                  {number}
                </InputButton>
              ))}
            </InputGridContainer>
            <InputGridContainer>
              {[7, 8, 9].map((number: number) => (
                <InputButton
                  key={number}
                  onClick={() => {
                    const emptyIndex = inputValues.indexOf('');
                    if (emptyIndex !== -1) {
                      handleNumberClick(emptyIndex, number.toString());
                    }
                  }}
                >
                  {number}
                </InputButton>
              ))}
            </InputGridContainer>
            <InputGridContainer>
              <AllDeleteContainer onClick={handleClearAll}>전체삭제</AllDeleteContainer>
              <InputButton
                onClick={() => {
                  const emptyIndex = inputValues.indexOf('');
                  if (emptyIndex !== -1) {
                    handleNumberClick(emptyIndex, '0');
                  }
                }}
              >
                0
              </InputButton>
              <OneDeleteContainer>
                <OneDeleteImg
                  onClick={handleBackspace}
                  src={icArrowDelete}
                  alt={'입력 필드 지우는 아이콘'}
                />
              </OneDeleteContainer>
            </InputGridContainer>
          </BottomGridContainer>
        </BottomButtonKeyboardContainer>

        <WithdrawBottomModal
          isVisible={isWithdrawBottomModalVisible}
          onClose={() => setIsWithdrawBottomModalVisible(false)}
        />

        <PurpleOneButtonCheckModal
          isVisible={isFivePasswordMissModal}
          onClose={() => {
            setIsFivePasswordMissModal(false);
            navigate('/pointWithdrawalIdentityCert');
          }}
          title={'비밀번호 오류 횟수가 초과되었어요.\n비밀번호 설정 화면으로 이동합니다.'}
          content={''}
          buttonText={'확인'}
        />

        <PurpleTwoButtonCheckModal
          isVisible={isSearchPasswordModal}
          onClose={() => {
            setIsSearchPasswordModal(false);
          }}
          title={''}
          content={
            <div>
              <span>비밀번호를 잊으셨나요?</span>
              <br />
              <span>[확인]을 누르면 재설정하러 갑니다.</span>
            </div>
          }
          leftButtonText={'취소'}
          rightButtonText={'확인'}
          rightClick={() => {
            sessionStorage.setItem('isPwUpdateUser', 'true');
            navigate('/pointWithdrawalIdentityCert');
          }}
        />
      </WithdrawalMainContainer>
    </>
  );
});

export default PointWithdrawalPassword;