import React, { useState, useEffect, MouseEvent } from 'react';
import { YearSelectModalContainer, Overlay, ListItem, ConfirmButton } from './styles'; // styled components를 import 합니다.

interface YearSelectBottomModalProps {
  isVisible: boolean;
  onClose: () => void;
  onSelectYear: (year: number) => void;
}

const startYear = 2018;

const YearSelectBottomModal: React.FC<YearSelectBottomModalProps> = ({ isVisible, onClose, onSelectYear }) => {
  const currentYear = new Date().getFullYear();
  const year = Array.from({ length: currentYear - startYear + 1 }, (_, i) => startYear + i);

  const [selectedYear, setSelectedYear] = useState<number | null>(null);

  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isVisible]);

  useEffect(() => {
    if (!isVisible) {
      setSelectedYear(null);
    }
  }, [isVisible])

  if (!isVisible) return null;

  const handleListItemClick = (event: MouseEvent<HTMLDivElement>, year: number) => {
    event.stopPropagation();
    setSelectedYear(year);
    onSelectYear(year);
  }

  const handleClose = () => {
    onClose();
  }

  return (
    <>
      <Overlay onClick={handleClose} />
      <YearSelectModalContainer isVisible={isVisible}>
        <div style={{ display: 'flex', flexDirection: 'column', height: '80%' }}>
          <div style={{ flex: 1, overflowY: 'auto', paddingBottom: '16px' }}>
            {year.map((year) => (
              <ListItem
                key={year}
                isSelected={selectedYear === year}
                onClick={(event) => handleListItemClick(event, year)}
              >
                {year}년
              </ListItem>
            ))}
          </div>
          <ConfirmButton
            onClick={selectedYear ? handleClose : undefined}
            disabled={!selectedYear}
          >
            확인
          </ConfirmButton>
        </div>
      </YearSelectModalContainer>
    </>
  );
};

export default YearSelectBottomModal;
