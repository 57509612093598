import {MainContainer} from "../../../components/styles";
import Title from "../../../components/atoms/Title/PageTitle";
import React from "react";
import {useNavigate} from "react-router-dom";
import {ParkingPhotoCompleteContainer, ParkingPhotoCompleteSpan} from "./styles";
import icCheckRoundBorderWhite from '../../../assets/ic/ic_check_round_border_white.svg'

const ParkingPhotoUploadComplete = () => {
  const navigate = useNavigate()

  return(
      <MainContainer>
        <Title
            title="주차장 사진 업로드"
            leftArrowButtonVisible
            leftOnClick={() => {
              navigate('/home')
            }}
            rightTextButtonVisible={false}
        />
        <ParkingPhotoCompleteContainer>
          <img src={icCheckRoundBorderWhite} alt="느낌표 둥근 원 라인 아이콘" style={{width: '28px', height: '28px'}}/>
            <ParkingPhotoCompleteSpan>제출이 완료 되었습니다.</ParkingPhotoCompleteSpan>
          <div style={{
            color: '#868686',
            textAlign: 'center',
            fontFamily: 'Pretendard',
            fontSize: '13px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '18px',
            marginTop: '15px'
          }}>
              <span>업로드하신 이미지는 심사가 완료된 후</span><br/>계시됩니다.
          </div>

        </ParkingPhotoCompleteContainer>
      </MainContainer>
  )

}

export default ParkingPhotoUploadComplete
