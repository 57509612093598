import React, { useState } from 'react';
import Modal from 'react-modal';
import { ImgClose, Content, Overlay, NavigationButton, NavigationButtonContainer } from './styles';
import icCloseWhite from '../../../../assets/ic/ic_close_white.svg';

interface ParkingPhotoExpansionModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  images: string[];
  startIndex?: number;
}

const ParkingPhotoExpansionModal: React.FC<ParkingPhotoExpansionModalProps> = ({ isOpen, onRequestClose, images, startIndex = 0 }) => {
  const [currentIndex, setCurrentIndex] = useState(startIndex);

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
      <Modal
          isOpen={isOpen}
          onRequestClose={onRequestClose}
          ariaHideApp={false}
          style={{
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
            content: {
              inset: '0',
              padding: '0',
              backgroundColor: 'transparent',
              border: 'none',
            },
          }}
      >
        <Overlay>
          <Content>
            <ImgClose onClick={onRequestClose} src={icCloseWhite} />
            <img src={images[currentIndex]} alt="Full view" style={{ maxWidth: '100%', maxHeight: '100%' }} />

            {images.length > 1 && (
                <NavigationButtonContainer>
                  <NavigationButton
                      onClick={prevImage}
                      style={{
                        left: 0,
                        backgroundColor: currentIndex === 0 ? '#fff' : 'transparent',
                        color: currentIndex === 0 ? '#000' : '#000',
                      }}
                  >
                    {currentIndex === 0 ? 1 : 1}
                  </NavigationButton>
                  <NavigationButton
                      onClick={nextImage}
                      style={{
                        right: 0,
                        backgroundColor: currentIndex === images.length - 1 ? '#fff' : 'transparent',
                        color: currentIndex === images.length - 1 ? '#000' : '#000',
                      }}
                  >
                    {/* 현재 인덱스에 따라 다음 인덱스 표시 */}
                    {currentIndex === images.length - 1 ? images.length : currentIndex + 2}
                  </NavigationButton>
                </NavigationButtonContainer>
            )}
          </Content>
        </Overlay>
      </Modal>
  );
};

export default ParkingPhotoExpansionModal;
