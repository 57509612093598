import PointWithdrawalMoney from '../../../pages/Point/PointWithdrawal/PointWithdrawalMoney'
import BrandListService from '../../../service/Brand/brandService'
import BaseError from '../../../models/baseError'
import PointWithdrawalMoneyService from '../../../service/Point/pointWithdrawalMoneyService'
import { makeAutoObservable } from 'mobx'

class PointWithdrawalMoneyStore {
  withdrawalMoneyLoading: boolean = false
  withdrawalLoading: boolean = false
  error: string | null = null
  withdrawalMoney: string = '0'
  isShowWithdrawalExceededModal: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setShowWithdrawalExceededModal(value: boolean) {
    this.isShowWithdrawalExceededModal = value;
  }

  async fetchPointWithdrawalMoney(mLinkUID: number) {
    this.withdrawalMoneyLoading = true
    this.error = null

    try {
      const response = await PointWithdrawalMoneyService.postWithdrawalMoney({ mLinkUID: mLinkUID })
      if (response instanceof BaseError) {
        if (response.status === 204) {
          this.setShowWithdrawalExceededModal(true)
        } else {
          this.error = response.errorMessage
        }
      } else {
        this.withdrawalMoney = response.data
      }
    } catch (err) {
      this.error = '출금금액을 가져오는 중 오류가 발생했습니다.'
    } finally {
      this.withdrawalMoneyLoading = false
    }
  }

  async fetchPointWithdrawal(mLinkUID: number, token: string, withdrawAmt: number) {
    this.withdrawalLoading = true
    this.error = null

    try {
      const response =
        await PointWithdrawalMoneyService.postWithdrawal({ mLinkUID: mLinkUID, token: token, withdrawAmt: withdrawAmt })
      if (response instanceof BaseError) {
        this.error = response.errorMessage
      } else {
        console.log('출금 성공')
      }
    } catch (err) {
      this.error = '출금 신청 중 오류가 발생했습니다.';
    } finally {
      this.withdrawalLoading = false;
    }
  }
}

export const pointWithdrawalMoneyStore = new PointWithdrawalMoneyStore()