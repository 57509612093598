import BaseService from '../baseService';
import { IUserInfo, IUserInfoResponse } from '../../models/UserInfo/userInfoModel';
import BaseError from '../../models/baseError';
import AxiosClient from '../../utils/axiosClient';
import ErrorHandler from '../../utils/error/errorHandler';

interface UserInfoApiEndpoint {
  USER_INFO_SEARCH: string;
}

class UserInfoService extends BaseService<UserInfoApiEndpoint> {
  apiEndpoints: UserInfoApiEndpoint = {
    USER_INFO_SEARCH: 'getUserInfo',
  }

  constructor() {
    super()
  }

  userInfoSearch = async (
    requestBody: IUserInfo,
    iv: string,
    salt: string,
  ): Promise<IUserInfoResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .headers({
          'Content-Type': 'application/json',
          iv: iv,
          salt: salt,
        })
        .data({ ...requestBody })
        .url(`${this.apiEndpoints.USER_INFO_SEARCH}`)
        .build<IUserInfoResponse>();

      console.log('포스트 쏠때pw:', requestBody.memberPW);
      console.log('포스트 쏠때iv:', iv)
      console.log('포스트 쏠때salt:', salt)

      if (response.data.res_code === 200) {
        return response.data
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError()
      }
    } catch (error) {
      console.log(salt)
      console.error(error)
      return ErrorHandler.create(error).getError()
    }
  }
}

export default new UserInfoService();
