import React from "react";
import Title from '../../components/atoms/Title/PageTitle';
import {useNavigate} from "react-router-dom";
import ToggleButton from "../../components/atoms/Toggle";
import {MainContainer} from "../../components/styles";

const ParkingUserList: React.FC = () => {
  const navigate = useNavigate();

  const handleLeftOnClick = () => {
    navigate(-1);
  };

  const handleToggle = (isToggled: boolean) => {
    console.log(`현재 상태: ${isToggled ? 'Option B' : 'Option A'}`);
  };

  return (
      <MainContainer>
        <Title
            title="주차장 이용내역"
            leftArrowButtonVisible
            leftOnClick={handleLeftOnClick}
            rightTextButtonVisible={false}
        />

          {/*<ToggleButton*/}
          {/*    options={['이용 중', '지난 내역']}*/}
          {/*    defaultToggled={false}*/}
          {/*    onToggle={handleToggle}*/}
          {/*/>*/}
      </MainContainer>
  )
}

export default ParkingUserList;
