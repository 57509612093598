import { styled } from '@stitches/react';
import icCheckWhite from 'assets/ic/ic_check_white.svg';
import icCheckGray from 'assets/ic/ic_check_gray.svg';

export const TerraceTowerBottomContentsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  margin: '28px 20px 30px',
});

export const RequiredText = styled('span', {
  color: '#FF0000',
  marginRight: '4px',
});

export const BottomButtonContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: '10px',
  margin: '0 20px 40px',
  width: 'calc(100% - 40px)',
  textAlign: 'center',
});

export const AgreeButton = styled('button', {
  flex: 1,
  width: '100%',
  height: '46px',
  fontSize: '14px',
  fontFamily: 'Pretendard',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  color: '#FFFFFF',
  border: 'none',
  backgroundColor: '#868686',
  borderRadius: '4px',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  cursor: 'none',

  variants: {
    state: {
      agreeCheck: {
        backgroundColor: '#8B63BB', // 선택된 상태일 때 배경색
      },
      notCheck: {
        backgroundColor: '#868686', // 비활성화된 상태일 때 배경색
      },
    },
  },
})

export const StyledCheckbox = styled('input', {
  appearance: 'none',
  width: '20px',
  height: '20px',
  border: '2px solid #E6E6E6',
  borderRadius: '4px',
  outline: 'none',
  backgroundImage: `url(${icCheckGray})`,
  backgroundSize: '12px 12px',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  marginRight: '8px',

  '&:checked': {
    backgroundColor: '#514263',
    borderColor: '#514263',
    backgroundImage: `url(${icCheckWhite})`,
    backgroundSize: '12px 12px',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
})
