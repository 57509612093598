import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { MainContainer } from '../../../components/styles';
import Title from '../../../components/atoms/Title/PageTitle';
import {
  AccountInfoContainer,
  BankNameSpan,
  InfoContentsContainer,
  InfoContentsMargin,
  InfoInput,
  LoadingText,
  NameInputCloseContainer,
} from './styles';
import icCloseCircleGray from '../../../assets/ic/ic_close_circle_gray.svg';
import icArrowBottomPurple from '../../../assets/ic/ic_arrow_bottom_purple.svg';
import BankSelectBottomModal from '../../../components/atoms/Modal/BankSelectBottomModal';
import {
  BottomButtonContainer,
  ButtonEnableDisable,
} from '../../../components/atoms/Button/styles';
import PurpleOneButtonCheckModal from '../../../components/atoms/Modal/PurpleOneButtonCheckModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { pointAccountRegiStore } from '../../../stores/store/Point/pointAccountRegiStore';
import { observer } from 'mobx-react';
import { LINK_UID } from '../../../constants/userInfoConstants';

const PointAccountRegi = observer(() => {
  const navigate = useNavigate();
  const [focusedField, setFocusedField] = useState<string | null>(null);
  const [isBankSelectModalVisible, setIsBankSelectModalVisible] = useState(false);
  const [selectedBankName, setSelectedBankName] = useState<string>('');
  const [selectedBankCode, setSelectedBankCode] = useState<string>('');
  const [accountNumber, setAccountNumber] = useState<string>('');
  const [errorNameMessage, setErrorNameMessage] = useState<string>('');
  const [errorAccountMessage, setErrorAccountMessage] = useState<string>('');
  const location = useLocation();
  const withdrawCustomer = location.state?.withdrawCustomer;
  const accountChangeUser = location.state?.isAccountChangeUser;

  const { control, handleSubmit, watch, setValue } = useForm({
    mode: 'onChange',
  });

  const nameFieldValue = watch('name', '')
  const accountNumberFieldValue = watch('account', '');

  const nameInputRef = useRef<HTMLInputElement | null>(null);
  const accountInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (selectedBankName && accountInputRef.current) {
      accountInputRef.current.focus();
    }
  }, [selectedBankName]);

  useEffect(() => {
    pointAccountRegiStore.setIsAccountSuccessModal(false);
  }, []);

  const isFormValid =
    nameFieldValue !== '' && nameFieldValue.length >= 2 && selectedBankName !== '' && accountNumberFieldValue !== '' && accountNumberFieldValue.length >= 7;


  const handleBankSelect = (bankCode: string, bankName: string) => {
    setSelectedBankCode(bankCode);
    setSelectedBankName(bankName);
    setIsBankSelectModalVisible(false);
  };

  const handleBankContainerClick = () => {
    if (nameFieldValue.length >= 2 && !errorNameMessage) {
      setIsBankSelectModalVisible(true);
      setFocusedField('bank');
    } else {
      setErrorNameMessage('올바른 이름 형식으로 입력해 주세요');
      if (nameInputRef.current) {
        nameInputRef.current.focus();
        return;
      }
    }
  };

  const handleAccountClickAndFocus = () => {
    if (nameFieldValue.length < 2 || errorNameMessage) {
      setErrorNameMessage('올바른 이름 형식으로 입력해 주세요');
      if (nameInputRef.current) {
        nameInputRef.current.focus();
      }
      return;
    }

    if (!selectedBankName) {
      accountInputRef.current?.blur();
      setIsBankSelectModalVisible(true);
      return;
    }
    setIsBankSelectModalVisible(false);

    if (accountInputRef.current) {
      setTimeout(() => {
        accountInputRef.current?.focus();
      }, 0);
    }
    return;
  };

  const handleModalClose = () => {
    setIsBankSelectModalVisible(false);
  };

  const handleAccountSuccess = async () => {
    try {
      await onSubmit();
    } catch (error) {
      console.error('계좌 등록 처리 중 오류 발생:', error);
    }
  };

  const handleFocusToEnd = (e: React.FocusEvent<HTMLInputElement>) => {
    const input = e.target;
    const length = input.value.length;

    input.setSelectionRange(length, length);
  };

  const onSubmit = async () => {
    pointAccountRegiStore.setLoading(true);
    const linkUID = parseInt(sessionStorage.getItem(LINK_UID) || '0', 10);

    try {
      const isSuccess = await pointAccountRegiStore.fetchPointAccountRegi(
        linkUID,
        selectedBankCode,
        selectedBankName,
        accountNumber,
        nameFieldValue,
      );

      if (!isSuccess) {
        console.error('계좌 등록 실패');
        pointAccountRegiStore.setIsAccountFailModal(true);
      } else {
        pointAccountRegiStore.setIsAccountSuccessModal(true);
      }
    } catch (error) {
      console.error('계좌 등록 처리 중 오류 발생:', error);
      pointAccountRegiStore.setIsAccountFailModal(true);
    }
  };

  const handleModalCloseAndNavigate = () => {
    pointAccountRegiStore.setIsAccountSuccessModal(false);
    if (withdrawCustomer === 'withdrawCustomer') {
      navigate('/PointWithdrawalIdentityCert');
    } else {
      navigate('/pointHistory');
    }
  };

  useEffect(() => {
    if (nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, []);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, fieldName: string) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      if (fieldName === 'name') {
        if (nameFieldValue.length >= 2 && !errorNameMessage) {
          setIsBankSelectModalVisible(true);
          Promise.resolve().then(() => {
            if (nameInputRef.current) {
              nameInputRef.current.blur();
            }
          });
        } else {
          setErrorNameMessage('올바른 이름 형식으로 입력해 주세요');
          nameInputRef.current?.focus();
          setIsBankSelectModalVisible(false);
        }
      }
    }
  };

  const handleBlur = (fieldName: string) => {
    if (fieldName === 'name') {
      if (nameFieldValue.length >= 2 && !errorNameMessage) {
        if (!selectedBankName) {
          if (nameInputRef.current) {
            setIsBankSelectModalVisible(true);
          }
          if (accountInputRef.current) {
            accountInputRef.current.blur();
          }
        } else {
          if (accountInputRef.current) {
            accountInputRef.current.blur();
          }
        }
      } else {
        setIsBankSelectModalVisible(false);
        setErrorNameMessage('올바른 이름 형식으로 입력해 주세요');
        if (nameInputRef.current) {
          nameInputRef.current.focus();
        }
        if (accountInputRef.current) {
          accountInputRef.current.blur();
        }
      }
    }
  };

  const handleInputChange = (value: string) => {
    let errorMessage = ''

    if (value.length < 2) {
      errorMessage = '2글자 이상 입력해주세요.'
    }

    setErrorNameMessage(errorMessage);
    return value.slice(0, 20)
  };

  const handleAccountInputChange = (value: string) => {
    const sanitizedValue = value.replace(/[^0-9\-]/g, '')
    let errorMessage = ''

    if (sanitizedValue.length <= 6) {
      errorMessage = '계좌번호는 7자리 이상 입력해 주세요.'
    }

    setErrorAccountMessage(errorMessage)

    return sanitizedValue.slice(0, 16)
  };

  const handleClearInput = (field: 'name' | 'account') => {
    setValue(field, '', { shouldValidate: true })
    setErrorNameMessage('')
    setErrorAccountMessage('')
    setFocusedField(null)
  };

  useEffect(() => {
    const preventZoom = (e: TouchEvent) => {
      if (e.touches.length > 1) {
        e.preventDefault();
      }
    };
    document.addEventListener('touchstart', preventZoom, { passive: false });
    document.addEventListener('touchmove', preventZoom, { passive: false });

    return () => {
      document.removeEventListener('touchstart', preventZoom);
      document.removeEventListener('touchmove', preventZoom);
    };
  }, []);

  const handleLeftOnClick = () => {
    navigate('/pointHistory')
  }

  useEffect(() => {
    window.onBtnBackClicked = function () {
      handleLeftOnClick()
    }
    return () => {
      delete window.onBtnBackClicked;
    }
  }, [])

  return (
    <MainContainer>
      <Title
        title="계좌 등록"
        leftArrowButtonVisible={true}
        leftOnClick={handleLeftOnClick}
        rightTextButtonVisible={false}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <form onSubmit={handleSubmit(handleAccountSuccess)}>
          <AccountInfoContainer
            selected={focusedField === 'name'}
            css={{ marginTop: '13px' }}
            onClick={() => {
              if (!pointAccountRegiStore.isLoading) {  // 로딩 중이 아닐 때만 실행
                setFocusedField('name');
              }
            }}
          >
            <InfoContentsMargin>
              <InfoContentsContainer>이름</InfoContentsContainer>
              <NameInputCloseContainer>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <InfoInput
                      placeholder={'예금주명을 입력해 주세요'}
                      value={field.value}
                      onChange={(e) => {
                        const newValue = handleInputChange(e.target.value)
                        field.onChange(newValue);
                      }}
                      onFocus={(e) => {
                        setFocusedField('name')
                        handleFocusToEnd(e)
                      }}
                      ref={nameInputRef}
                      onKeyDown={(e) => handleKeyDown(e, 'name')}
                      onBlur={() => handleBlur('name')}
                      style={{
                        backgroundColor: focusedField === 'name' ? '#fff' : '#F8F8FA',
                      }}
                      disabled={pointAccountRegiStore.isLoading}
                    />
                  )}
                />
                {focusedField === 'name' && (
                  <img
                    src={icCloseCircleGray}
                    alt={'동그라미 닫기 버튼'}
                    onClick={() => handleClearInput('name')}
                  />
                )}
              </NameInputCloseContainer>
            </InfoContentsMargin>
          </AccountInfoContainer>
          <div style={{ color: '#ED1659', marginTop: '8px' }}>{errorNameMessage}</div>

          <AccountInfoContainer
            selected={focusedField === 'bank'}
            onClick={() => {
              if (!pointAccountRegiStore.isLoading) {  // 로딩 중이 아닐 때만 실행
                handleBankContainerClick()
              }
            }}
            css={{ marginTop: '13px' }}
          >
            <InfoContentsMargin>
              <InfoContentsContainer>은행</InfoContentsContainer>
              <NameInputCloseContainer>
                <div>
                  <BankNameSpan hasSelectedBankName={!!selectedBankName}>
                    {selectedBankName || '은행명을 선택해 주세요'}
                  </BankNameSpan>
                </div>
                <img src={icArrowBottomPurple} alt={'애로우 보라색 하단 아이콘'} />
              </NameInputCloseContainer>
            </InfoContentsMargin>
          </AccountInfoContainer>

          <AccountInfoContainer
            selected={focusedField === 'account'}
            css={{ marginTop: '13px' }}
            onClick={() => {
              if (!pointAccountRegiStore.isLoading) {  // 로딩 중이 아닐 때만 실행
                handleAccountClickAndFocus()
              }
            }}
          >
            <InfoContentsMargin>
              <InfoContentsContainer>계좌</InfoContentsContainer>
              <NameInputCloseContainer>
                <Controller
                  name="account"
                  control={control}
                  rules={{
                    required: '계좌번호를 입력해주세요.',
                    minLength: { value: 7, message: '올바른 계좌번호를 입력해 주세요.' },
                  }}
                  render={({ field }) => (
                    <InfoInput
                      name="account"
                      type="text"
                      inputMode="numeric"
                      placeholder={'-없이 계좌번호만 입력해 주세요.'}
                      value={field.value}
                      onChange={(e) => {
                        const newValue = handleAccountInputChange(e.target.value);
                        field.onChange(newValue);
                        setAccountNumber(newValue);
                      }}
                      ref={accountInputRef}
                      onFocus={(e) => {
                        setFocusedField('account');
                        handleFocusToEnd(e);
                        handleAccountClickAndFocus();
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          (e.target as HTMLInputElement).blur();
                        }
                      }}
                      style={{ backgroundColor: focusedField === 'account' ? '#fff' : '#F8F8FA' }}
                      disabled={pointAccountRegiStore.isLoading}
                    />
                  )}
                />
                {focusedField === 'account' && (
                  <img
                    src={icCloseCircleGray}
                    alt={'동그라미 닫기 버튼'}
                    onClick={() => handleClearInput('account')}
                  />
                )}
              </NameInputCloseContainer>
            </InfoContentsMargin>
          </AccountInfoContainer>
          <div style={{ color: '#ED1659', marginTop: '8px' }}>{errorAccountMessage}</div>
          <BottomButtonContainer>
            <ButtonEnableDisable
              disabled={pointAccountRegiStore.isLoading || !isFormValid} type="submit">
              {pointAccountRegiStore.isLoading ? (
                <LoadingText>계좌 정보 확인 중</LoadingText>
              ) : (
                '계좌 등록'
              )}
            </ButtonEnableDisable>
          </BottomButtonContainer>
        </form>
      </div>

      { isBankSelectModalVisible &&  (
        <BankSelectBottomModal
          isVisible={isBankSelectModalVisible}
          onClose={handleModalClose}
          onSelectBank={handleBankSelect}
        />
      )}

      {pointAccountRegiStore.isAccountSuccessModal && (
        <PurpleOneButtonCheckModal
          isVisible={pointAccountRegiStore.isAccountSuccessModal}
          onClose={() => {
            pointAccountRegiStore.setIsAccountSuccessModal(false)

            if (withdrawCustomer === 'withdrawCustomer') {
              navigate('/PointWithdrawalIdentityCert')
              return
            }

            if (withdrawCustomer === 'notAccountAndChangeCustomer') {
              navigate('/pointHistory');
              return
            }

            // else {
            //   navigate('/pointWithdrawalMoney');
            // }
          }}
          title="계좌 등록이 완료되었습니다."
          buttonText="확인"
          content={''}
        />
      )}
      {pointAccountRegiStore.isAccountFailModal && (
        <PurpleOneButtonCheckModal
          isVisible={pointAccountRegiStore.isAccountFailModal}
          onClose={() => {
            pointAccountRegiStore.setIsAccountFailModal(false);
            console.log('계좌 정보 없음');
          }}
          title={'입력하신 계좌정보를 확인할 수 없어요.\n다시 확인해 주세요.'}
          buttonText="확인"
          content={''}
        />
      )}
    </MainContainer>
  );
});

export default PointAccountRegi;