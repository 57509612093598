import React, { useState, useEffect } from 'react';
import {
  BottomButtonContainer,
  ButtonEnableDisable,
  ContentsContainer,
  ModalOverlay,
  PaymentMethodChoiceContainer,
  PaymentMethodChoiceItem,
  PaymentMethodModalContainer,
  TitleSpan,
} from './styles';

interface PaymentMethodBottomModalProps {
  isVisible: boolean;
  onClose: () => void;
  initialMethod?: string;
  onSelectMethod: (method: string) => void;
}

const PaymentMethodBottomModal: React.FC<PaymentMethodBottomModalProps> = ({ isVisible, onClose, initialMethod, onSelectMethod }) => {
  const [selectedMethod, setSelectedMethod] = useState<string | null>(initialMethod || null);

  const handleOverlayClick = () => {
    onClose();
  };

  const paymentMethod = ['가상계좌', '카드결제'];

  // 모달이 열릴 때 선택된 방법 초기화
  useEffect(() => {
    if (isVisible) {
      setSelectedMethod(initialMethod || '가상계좌');
    }
  }, [isVisible, initialMethod]);

  useEffect(() => {
    if (selectedMethod) {
      onSelectMethod(selectedMethod);
    }
  }, [selectedMethod, onSelectMethod]);

  if (!isVisible) return null;

  return (
    <>
      <ModalOverlay onClick={handleOverlayClick} />
      <PaymentMethodModalContainer isVisible={isVisible}>
        <TitleSpan>결제수단 선택</TitleSpan>
        <ContentsContainer>
          <PaymentMethodChoiceContainer>
            {paymentMethod.map((method, index) => (
              <PaymentMethodChoiceItem
                key={index}
                isSelected={selectedMethod === method}
                onClick={() => setSelectedMethod(method)}
              >
                {method}
              </PaymentMethodChoiceItem>
            ))}
          </PaymentMethodChoiceContainer>
        </ContentsContainer>

        <BottomButtonContainer>
          <ButtonEnableDisable
            onClick={() => {
              if (selectedMethod) {
                onSelectMethod(selectedMethod)
                onClose();
              }
            }}
            disabled={!selectedMethod}
          >
            확인
          </ButtonEnableDisable>
        </BottomButtonContainer>
      </PaymentMethodModalContainer>
    </>
  );
};

export default PaymentMethodBottomModal;
