import { styled } from '@stitches/react';

export const Dialog = styled('div', {
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  height: '100%',
  background: '#fff',
  boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.2)',
  transition: 'transform 0.3s ease-in-out',
  display: 'flex',
  flexDirection: 'column',
  padding: '16px',
  boxSizing: 'border-box',
  zIndex: 10000,
  variants: {
    isVisible: {
      true: { transform: 'translateY(0)' },
      false: { transform: 'translateY(100%)' },
    },
  },
});

export const ContentsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  width: '100%',
});

export const TitleSpan = styled('span', {
  color: '#8B63BB',
  fontFamily: 'Pretendard',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: 'normal',
});

export const SubTitleSpan = styled('span', {
  color: '#868686',
  fontSize: '13px',
  fontWeight: 400,
  lineHeight: 'normal',
  marginTop: '6px',
});

export const ChoiceDateContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '28px',
  padding: '25px',
});

export const ChoiceDateItem = styled('div', {
  backgroundColor: '#F8F8FA',
  borderRadius: '5px',
  padding: '10px',
  margin: '8px 0',
  fontSize: '16px',
  width: '100%',
  textAlign: 'center',
  fontFamily: 'Pretendard',
  fontWeight: 700,
  lineHeight: 'normal',
  variants: {
    isSelected: {
      true: {
        color: '#1A1A1A',
        border: '2px solid #8B63BB',
      },
      false: {
        color: '#868686',
        border: '2px solid #F8F8FA',
      },
    },
  },
});
