import { styled } from '@stitches/react';

export const MoneyCouponContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: '#F3F4F6',
  borderRadius: '8px',
  padding: '20px',
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
})

export const MoneyContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  color: '#555',
  lineHeight: 'normal'
})

export const MoneySpan = styled('span', {
  fontSize: '18px',
  fontWeight: 700
})

export const CouponContainer = styled('div', {
  backgroundColor: '#fff',
  padding: '8px',
  borderRadius: '8px',
  fontSize: '15px',
  color: '#8B63BB'
})
