import { pointCouponService } from '../../../service/Point/pointCouponService';
import BaseError from '../../../models/baseError';

class PointCouponSendStore {
  isLoading = false;
  error: string | null = null;
  state: string = '';

  async sendCouponCert(mLinkUID: number, storageUID: number, couponCode: string) {
    try {
      const response = await pointCouponService.postPointCertSend({
        mLinkUID: mLinkUID,
        storageUID: storageUID,
        couponCode: couponCode,
      });

      if (response instanceof BaseError) {
        this.error = response.errorMessage
      } else if (response.data && Array.isArray(response.data)) {
        // 문자 재발송 상태를 가진 쿠폰 찾기
        const sentCoupon = response.data.find((coupon) => coupon.state === '문자 재발송');
        if (sentCoupon) {
          this.state = sentCoupon.state;
        } else {
          console.error('문자 재발송 쿠폰이 없습니다.');
          this.error = '문자 재발송 쿠폰이 없습니다.';
        }
      } else {
        console.error('Invalid data format or empty response:', response.data);
        this.error = '잘못된 데이터 형식 또는 빈 응답';
      }
    } catch (error) {
      console.error('Error sending coupon cert:', error);
      this.error = '쿠폰 인증 전송 중 오류가 발생했습니다.'
      throw error
    }
  }
}

export const pointCouponSendStore = new PointCouponSendStore();
