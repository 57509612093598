import { makeAutoObservable } from 'mobx';
import BaseError from '../../../models/baseError';
import terraceTowerService from '../../../service/TerraceTower/terraceTowerService';
import { ITerraceTowerCompleteProductOrderData} from '../../../models/TerraceTower/terraceTowerCompleteProductOrder';

class TerraceTowerCompleteProductOrderStore {
  isLoading = false
  error: string | null = null
  getCompleteProductOrder: ITerraceTowerCompleteProductOrderData | null = null

  constructor() {
    makeAutoObservable(this);
  }

  setLoading(value: boolean) {
    this.isLoading = value;
  }

  setGetCompleteProductOrder(data: ITerraceTowerCompleteProductOrderData) {
    this.getCompleteProductOrder = data
  }

  async fetchTerraceTowerCompleteProductOrder(saleHistoryUID: number, paymentMethod: string, paymentID: string) {
    const requestBody = {
      saleHistoryUID,
      paymentMethod,
      paymentID
    }

    this.setLoading(true)

    const response = await terraceTowerService.postTerraceTowerCompleteProductOrder(requestBody)

    if (response instanceof BaseError) {
      this.error = response.errorMessage
      this.setLoading(false)
      return false
    } else {
      const { data } = response
      if (data) {
        this.setGetCompleteProductOrder(data)
      }
      this.setLoading(false)
      return true
    }
  }
}

export const terraceTowerCompleteProductOrderStore = new TerraceTowerCompleteProductOrderStore()