import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MainContainer } from '../../../components/styles';
import Title from '../../../components/atoms/Title/PageTitle';
import icLocation from '../../../assets/ic/ic_location.svg';
import icPhoto from '../../../assets/ic/ic_photo.svg';
import icCall from '../../../assets/ic/ic_call.svg';
import imgParkingForeground from '../../../assets/images/img_parking_foreground.png';
import imgParkingGate from '../../../assets/images/img_parking_gate.png';
import imgParkingInside1 from '../../../assets/images/img_parking_inside1.png';
import imgParkingInside2 from '../../../assets/images/img_parking_inside2.png';
import {
  AddressContainer,
  AddressNameResultSpan,
  AddressNameSpan,
  BottomContainer,
  CallIconTextContainer,
  CautionContentContainer,
  CautionTitleSpan,
  CustomerCenterCallContainer,
  ExampleTextContainer,
  GalleryIconContainer,
  IconImage,
  IconTextContainer,
  LocationIconContainer,
  ParkingInfoContainer,
  ParkingNameResultSpan,
  ParkingNameSpan,
  ParkingPhotoCautionContainer,
  ParkingPhotoDetailTitleContainer,
  ParkingPhotoUploadDetailMainContainer,
  PhotoDescriptionSpan,
  PhotoParkingInfoContainer,
  PhotoPictureContainer,
  SubmitButtonContainer,
  UploadedImage,
  UploadImageSection,
} from './styles';
import { CameraFileUpload } from '../../../components/atoms/CameraFileUpload';
import ParkingPhotoExpansionModal from '../../../components/atoms/Modal/ParkingPhotoExpansionModal';

const ParkingPhotoUploadDetail: React.FC = () => {
  const navigate = useNavigate();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentImages, setCurrentImages] = useState<string[]>([]);
  const [startIndex, setStartIndex] = useState<number>(0);

  const handleLeftOnClick = () => {
    if (modalIsOpen) {
      closeModal();
    } else {
      navigate(-1);
    }
  };

  const openModal = (images: string[], index: number) => {
    setCurrentImages(images);
    setStartIndex(index);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentImages([]);
  };

  return (
      <div>
        <ParkingPhotoUploadDetailMainContainer>
          <Title
              title="주차장 사진 업로드"
              leftArrowButtonVisible
              leftOnClick={handleLeftOnClick}
              rightTextButtonVisible={false}
          />
          <ParkingPhotoDetailTitleContainer>
            공유하고 계신 주차장 사진을 올려주세요.
          </ParkingPhotoDetailTitleContainer>

          {/* Parking Info */}
          <div>
            <LocationIconContainer>
              <IconTextContainer>
                <IconImage src={icLocation} alt="로케이션 아이콘" />
                <span>주차장 정보</span>
              </IconTextContainer>
            </LocationIconContainer>
            <ParkingInfoContainer>
              <ParkingNameSpan>주차장명</ParkingNameSpan>
              <ParkingNameResultSpan>1012번 주차장</ParkingNameResultSpan>
            </ParkingInfoContainer>
            <AddressContainer>
              <AddressNameSpan>주소</AddressNameSpan>
              <AddressNameResultSpan>
                서울시 영등포구 경인로 775, 에이스하이테크시티 지하 1층
              </AddressNameResultSpan>
            </AddressContainer>
          </div>

          {/* Caution Section */}
          <ParkingPhotoCautionContainer>
            <CautionTitleSpan>유의사항</CautionTitleSpan>
            <CautionContentContainer>
              <div>
                - <span style={{ color: '#ED1659' }}>개인정보(차량번호 등)</span>
                <span>가 드러나지 않도록 촬영해주<br />세요.</span>
              </div>
              <br />
              <div>
                - <span style={{ color: '#ED1659' }}>사진이 선명</span>
                <span>할수록 운전자분들 결정에 많은 도움이<br /> 됩니다.</span>
              </div>
              <br />
              - <span style={{ color: '#ED1659' }}>주차장 주소와 일치</span>
              <span>하는 사진을 업로드 해주세요.</span>
            </CautionContentContainer>
          </ParkingPhotoCautionContainer>

          {/* Photo Upload Section */}
          <GalleryIconContainer>
            <IconTextContainer>
              <IconImage src={icPhoto} alt="갤러리 아이콘" />
              <span>주차장 사진</span>
            </IconTextContainer>
          </GalleryIconContainer>

          {/* Example Photos */}
          <PhotoParkingInfoContainer>
            <IconTextContainer>
              <span>주차장 전경</span>
              <PhotoPictureContainer>1장</PhotoPictureContainer>
            </IconTextContainer>
          </PhotoParkingInfoContainer>
          <PhotoDescriptionSpan>
            주차장 또는 건물 전체가 보일 수 있도록 촬영 해주세요.
          </PhotoDescriptionSpan>
          <UploadImageSection style={{ marginTop: '8px' }}>
            <UploadedImage
                src={imgParkingForeground}
                alt={'주차장 전경 이미지'}
                onClick={() => openModal([imgParkingForeground], 0)}
            />
            {!modalIsOpen && <ExampleTextContainer>예시</ExampleTextContainer>}
            <CameraFileUpload multiple />
          </UploadImageSection>

          <PhotoParkingInfoContainer>
            <IconTextContainer>
              <span>주차장 입구</span>
              <PhotoPictureContainer>1장</PhotoPictureContainer>
            </IconTextContainer>
          </PhotoParkingInfoContainer>
          <PhotoDescriptionSpan>
            주차장의 입구가 보일 수 있도록 촬영 해주세요.
          </PhotoDescriptionSpan>
          <UploadImageSection style={{ marginTop: '8px' }}>
            <UploadedImage
                src={imgParkingGate}
                alt={'주차장 입구 이미지'}
                onClick={() => openModal([imgParkingGate], 0)}
            />
            {!modalIsOpen && <ExampleTextContainer>예시</ExampleTextContainer>}
            <CameraFileUpload multiple />
          </UploadImageSection>

          <PhotoParkingInfoContainer>
            <IconTextContainer>
              <span>주차장 내부</span>
              <PhotoPictureContainer>3장</PhotoPictureContainer>
            </IconTextContainer>
          </PhotoParkingInfoContainer>
          <PhotoDescriptionSpan>
            주차장의 내부가 보일 수 있도록 촬영 해주세요.
          </PhotoDescriptionSpan>
          <UploadImageSection style={{ marginTop: '8px' }}>
            <UploadedImage
                src={imgParkingInside1}
                alt={'주차장 내부 이미지1'}
                onClick={() => openModal([imgParkingInside1, imgParkingInside2], 0)}
            />
            {!modalIsOpen && <ExampleTextContainer>예시</ExampleTextContainer>}
            <CameraFileUpload multiple />
            <CameraFileUpload multiple />
            <CameraFileUpload multiple />
          </UploadImageSection>

          <SubmitButtonContainer onClick={() => navigate('/parkingPhotoUploadComplete')}>
            제출하기
          </SubmitButtonContainer>

          <ParkingPhotoExpansionModal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              images={currentImages}
              startIndex={startIndex}
          />
        </ParkingPhotoUploadDetailMainContainer>

        <BottomContainer>
          <span style={{
            marginTop: '20px'
          }}>고객센터</span>
          <CustomerCenterCallContainer onClick={() => console.log('전화로 연결됨')}>
            <CallIconTextContainer>
              <img src={icCall} alt={'전화기 아이콘'} style={{ marginRight: '4px', height: '13px' }} />
              <span>1666-4369</span>
            </CallIconTextContainer>
          </CustomerCenterCallContainer>
        </BottomContainer>
      </div>
  );
};

export default ParkingPhotoUploadDetail;
