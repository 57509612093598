import React from 'react';
import {useNavigate} from "react-router-dom";
import Title from "../../components/atoms/Title/PageTitle";
import MyPageList from "./MyPageList";
import {MainContainer} from "../../components/styles";

const MyPage: React.FC = () => {
  const navigate = useNavigate();

  const handleLeftOnClick = () => {
    navigate(-1);
  };

  return(
      <MainContainer>

        <Title
          title="내 정보"
          leftArrowButtonVisible={true}
          leftOnClick={handleLeftOnClick}
          rightTextButtonVisible={false}
        />

          <MyPageList/>


      </MainContainer>
  )
}
export default MyPage
