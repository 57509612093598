import { makeAutoObservable } from 'mobx';
import BaseError from '../../../models/baseError';
import { IPointPasswordGenerate } from '../../../models/Point/pointPasswordGenerateModel';
import { pointPasswordGenerateService } from '../../../service/Point/pointPasswordGenerateService';
import { IPointPasswordValidate } from '../../../models/Point/pointPasswordValidate';
import { IPointPasswordDelete } from '../../../models/Point/pointPasswordDeleteModel';
import { response } from 'express';

class PointPasswordGenerateStore {
  // 로딩은 각 스토어별로 나눠야함
  isLoading = false
  error: string | null = null;
  successMessage: string = '';



  token: string = ''
  success: boolean = false
  tryCount: number = 0

  constructor() {
    makeAutoObservable(this);
  }

  // 출금 비밀번호 생성
  async generatePassword(requestBody: IPointPasswordGenerate, iv: string, salt: string) {
    this.isLoading = true
    this.error = null;
    this.successMessage = '';

    const result = await pointPasswordGenerateService.postPointPasswordGenerate(requestBody, iv, salt);

    this.isLoading = true

    if (result instanceof BaseError) {
      this.error = result.errorMessage
    } else {
      this.successMessage = '비밀번호가 성공적으로 생성되었습니다.';
    }
  }

  // 출금 비밀번호 검증
  // async validatePassword(requestBody: IPointPasswordValidate, iv: string, salt: string) {
  //   this.loading = true;
  //   this.error = null;
  //   this.successMessage = '';
  //
  //   const result = await pointPasswordGenerateService.postPointPasswordValidate(requestBody, iv, salt);
  //
  //   this.loading = false;
  //
  //   if (result instanceof BaseError) {
  //     this.error = result.errorMessage
  //   } else {
  //     this.successMessage = '비밀번호 검증 성공.';
  //   }
  // }


  // 출금 비밀번호 검증
  async fetchPwValidate(memberUID: number, wdPassword: string, iv: string, salt: string) {
    this.isLoading = true
    this.error = null
    this.success = false

    const requestBody: IPointPasswordValidate = {
      memberUID,
      wdPassword,
    }

    try {
      const response = await pointPasswordGenerateService.postPointPasswordValidate(requestBody, iv, salt);

      if (response instanceof BaseError) {
        this.error = response.errorMessage
        this.tryCount += 1
      } else {
        this.success = response.data.success
        this.tryCount = response.data.tryCnt || this.tryCount
        this.token = response.data.token
      }
    } catch (e) {
      this.error = 'Unexpected error occurred.'
      console.error(e);
    } finally {
      this.isLoading = false
    }
  }

  // 출금 비밀번호 수정
  async updatePassword(requestBody: IPointPasswordGenerate, iv: string, salt: string) {
    this.isLoading = true
    this.error = null;
    this.successMessage = '';

    const result = await pointPasswordGenerateService.postPointPasswordUpdate(requestBody, iv, salt);

    if (result instanceof BaseError) {
      this.error = result.errorMessage
    } else {
      this.successMessage = '비밀번호가 성공적으로 수정되었습니다.';
    }
  }

  // 출금 비밀번호 삭제
  async deletePassword(requestBody: IPointPasswordDelete, iv: string, salt: string) {
    this.isLoading = true
    this.error = null;
    this.successMessage = '';

    const result = await pointPasswordGenerateService.postPointPasswordDelete(requestBody, iv, salt);

    if (result instanceof BaseError) {
      this.error = result.errorMessage
    } else {
      this.successMessage = '비밀번호가 성공적으로 삭제되었습니다.';
    }
  }
}

export const pointPasswordGenerateStore = new PointPasswordGenerateStore();
