import React, { useState } from "react";
import {useNavigate} from "react-router-dom";
import Title from "../../components/atoms/Title/PageTitle";
import icExclamation from '../../assets/ic/ic_exclamation.svg'
import {MainContainer} from "../../components/styles";
import {NotSharedParkingPhotoContainer, NotSharedParkingPhotoText} from "./styles";
import ParkingPhotoList from "./ParkingPhotoList";

const ParkingPhotoUpload: React.FC = () => {
  const navigate = useNavigate()

  const handleLeftOnClick = () => {
    navigate(-1);
  };

  return(
      <MainContainer>
        <Title
            title="주차장 사진 업로드"
            leftArrowButtonVisible
            leftOnClick={handleLeftOnClick}
            rightTextButtonVisible={false}
        />
        {/*<NotSharedParkingPhotoContainer>*/}
        {/*  <img src={icExclamation} alt="느낌표 아이콘" style={{width: '28px', height: '28px'}}/>*/}
        {/*  <NotSharedParkingPhotoText>공유 중인 주차장이 없습니다.</NotSharedParkingPhotoText>*/}
        {/*</NotSharedParkingPhotoContainer>*/}

        <ParkingPhotoList/>

      </MainContainer>
  )
}

export default ParkingPhotoUpload;