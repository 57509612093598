import { makeAutoObservable } from "mobx";

class ToggleStore {
  selectedIndex: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  setSelectedIndex(index: number) {
    this.selectedIndex = index;
  }
}

export const toggleStore = new ToggleStore();