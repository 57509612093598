import { styled } from '@stitches/react';

export const TitleContainer = styled('div', {
  zIndex: 2,
  width: '100%',
  maxWidth: '480px',
  height: '66px',
  display: 'flex',
  justifyContent: 'space-between',
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: '50%',
  transform: 'translateX(-50%)',
  padding: '0 16px',
  boxSizing: 'border-box',
  variants: {
    background: {
      default: {
        background: '#FFFFFF',
      },
      purpleGradient: {
        background: 'linear-gradient(to right, #BB8AF4, #7749AE)',
      },
      gray: {
        background: '#F0F0F0',
      },
    },
  },

  titleColor: {
    default: {
      color: '#514263',
    },
    white: {
      color: '#FFF',
    },
  },

  imgBackColor : {
    default: {
      color: '#514263',
    },
    white: {
      color: '#FFF',
    },
  },

  defaultVariants: {
    background: 'default',
  },

  // iOS specific styles
  // '@media (max-width: 812px) and (-webkit-device-pixel-ratio: 3)': {
  //   height: 'calc(100px + env(safe-area-inset-top))',
  //   paddingTop: '30px',
  // },
  // '@media (max-width: 896px) and (-webkit-device-pixel-ratio: 3)': {
  //   height: 'calc(100px + env(safe-area-inset-top))',
  //   paddingTop: '30px'
  // },
  // '@media (max-width: 2796px) and (-webkit-device-pixel-ratio: 3)': {
  //   height: 'calc(100px + env(safe-area-inset-top))',
  //   paddingTop: '30px'
  // },
});
