// pages/NotificationCenter/NotificationCenterMain.tsx
import React, { useEffect } from 'react';
import { observer } from 'mobx-react'; // MobX observer를 import합니다
import { useNavigate, useLocation } from 'react-router-dom'; // useLocation을 import합니다
import Title from "../../../components/atoms/Title/PageTitle";
import ToggleButton from "../../../components/atoms/Toggle";
import { MainContainer } from "../../../components/styles";
import NotificationCenterCenterImportantMessageList from "../NotificationCenterImportantMessageList";
import NotificationCenterGeneralMessageList from "../NotificationCenterGeneralMessageList";
import { toggleStore } from '../../../stores/toggle/toggleStore';

const NotificationCenter: React.FC = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const store = toggleStore; // Directly access toggleStore

  const [updateMessageDeleteImage, setUpdateMessageDeleteImage] = React.useState(false);
  const [rightTitle, setRightTitle] = React.useState('삭제');

  const handleRightOnClick = () => {
    setUpdateMessageDeleteImage(prevState => !prevState);
    setRightTitle(prevTitle => prevTitle === '삭제' ? '취소' : '삭제');
  };

  const handleLeftOnClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    const path = location.pathname;
    // 현재 경로에 따라서 상태를 설정합니다.
    if (path.includes('notificationCenterImportantMessageDetail')) {
      store.setSelectedIndex(0); // Important Message Detail
    } else if (path.includes('notificationCenterGeneralMessageDetail')) {
      store.setSelectedIndex(1); // General Message Detail
    }
  }, [location.pathname, store]);

  return (
      <MainContainer>
        <Title
            title="알림"
            leftArrowButtonVisible
            leftOnClick={handleLeftOnClick}
            rightTextButtonVisible={true}
            rightTitle={rightTitle}
            rightOnClick={handleRightOnClick}
        />
        <div>
          <ToggleButton
              options={['중요', '일반']}
              selectedIndex={store.selectedIndex}
              content={[
                <div key="중요">
                  <NotificationCenterCenterImportantMessageList updateMessageDeleteImage={updateMessageDeleteImage} />
                </div>,
                <div key="일반">
                  <NotificationCenterGeneralMessageList updateMessageDeleteImage={updateMessageDeleteImage} />
                </div>
              ]}
              onToggle={(index) => store.setSelectedIndex(index)}
          />
        </div>
      </MainContainer>
  );
});

export default NotificationCenter;
