import { styled } from '@stitches/react';

export const PointMallBrandContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  touchAction: 'pan-x pan-y pinch-zoom',
  backgroundColor: '#F3F4F6',
  minHeight: '100vh', // 컨텐츠가 적어도 화면 전체를 차지하도록 최소 높이를 설정
});
export const GradientContainer = styled('div', {
  background: 'linear-gradient(to right, #BB8AF4, #7749AE)',
  justifyContent: 'center',
  paddingTop: '86px',
  color: '#FFF',
  fontFamily: 'Pretendard',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
  display: 'flex',
  flexDirection: 'column'
})

export const MoneyPossibleContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  marginLeft: '20px',
  gap: '4px',
  '@media (max-width: 812px) and (min-width: 375px) and (-webkit-device-pixel-ratio: 3)': {
    marginTop: '28px',
  },
  '@media (max-width: 896px) and (min-width: 414px) and (-webkit-device-pixel-ratio: 3)': {
    marginTop: '28px',
  },
  '@media (max-width: 2796px) and (min-width: 414px) and (-webkit-device-pixel-ratio: 3)': {
    marginTop: '28px',
  }
})

export const MoneyCouponContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginTop: '4px',
  paddingBottom: '40px',
  alignItems: 'center'
})

export const ImpossibleMoneyContentContainer = styled('div', {
  color: '#6B6B6B',
  fontFamily: 'Pretendard',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  marginTop: '8px'
})

export const MoneySpan = styled('span', {
  marginLeft: '20px',
  fontWeight: 800,
  fontSize: '28px',
  fontStyle: 'normal',
  color: '#FFF',
  fontFamily: 'Pretendard',
  lineHeight: 'normal',
  wordBreak: 'break-all',
})

export const CouponContainer = styled('div', {
  marginRight: '20px',
  backgroundColor: '#FFF',
  display: 'flex',
  justifyContent: 'center',
  borderRadius: '8px'
})

export const CouponSpan = styled('span', {
  padding: '8px 16px',
  color: '#8B63BB',
  fontFamily: 'Pretendard',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal'
})

export const BrandListContainer = styled('div', {
  padding: '28px 20px',
  boxSizing: 'border-box',
});

export const BranTitleSpan = styled('span', {
  color: '#555',
  fontFamily: 'Pretendard',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
  display: 'block', // Make sure the span behaves as a block element
  marginBottom: '20px', // Add margin-bottom to create space below the title
});
