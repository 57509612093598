import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import Title from '../../components/atoms/Title/PageTitle';
import { MainContainer, LogoContainer } from "../../components/styles";
import imgZoomansaLarge from "../../assets/images/img_zoomansa_large.svg";
import { UserInfoInputContainer } from "./styles";
import { useForm, SubmitHandler } from "react-hook-form";

// Define the form values type
interface IFormInputs {
  userId: string;
  userPassword: string;
}

const ZMSLogin: React.FC = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, watch } = useForm<IFormInputs>();

  const userId = watch('userId', '');
  const userPassword = watch('userPassword', '');

  const onSubmit: SubmitHandler<IFormInputs> = (data) => {
    console.log(data);
    console.log('로그인 화면 이동')
  };

  const searchUserIdAndPwClick = () => {
    navigate('/searchMyInfo')
  }

  const handleLeftOnClick = () => {
    navigate(-1);
  };

  const buttonStyle = {
    padding: '0px',
    border: '0px',
    width: '100%',
    marginRight: '16px',
    height: '46px',
    color: userId && userPassword ? 'white' : '#8B63BB',
    backgroundColor: userId && userPassword ? '#8B63BB' : '#F2F2F2',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: 'normal',
    borderRadius: '5px',
    cursor: userId && userPassword ? 'pointer' : 'not-allowed',
  };

  return (
      <MainContainer>
        <Title
            title="아이디 로그인"
            leftArrowButtonVisible={true}
            leftOnClick={handleLeftOnClick}
            rightTextButtonVisible={false}
        />
        <LogoContainer>
          <img src={imgZoomansaLarge} alt="Zoomansa Logo" />
        </LogoContainer>

        <UserInfoInputContainer style={{ marginTop: '57px' }}>
          <input
              type="text"
              id="userId"
              placeholder="아이디"
              {...register('userId', { required: '필수 입력 항목입니다.' })}
              style={{
                padding: '5px',
                border: '0px',
                width: '100%',
                marginRight: '16px',
                outline: 'none',
                borderBottom: '1px solid #dbdbdb',
                fontWeight: 700,
                color: '#868686',
                fontSize: '16px'
              }}
          />
        </UserInfoInputContainer>

        <UserInfoInputContainer style={{ marginTop: '41px' }}>
          <input
              type="password"
              id="userPassword"
              placeholder="비밀번호"
              {...register('userPassword', { required: '필수 입력 항목입니다.' })}
              style={{
                padding: '5px',
                border: '0px',
                width: '100%',
                marginRight: '16px',
                outline: 'none',
                borderBottom: '1px solid #dbdbdb',
                fontWeight: 700,
                color: '#868686',
                fontSize: '16px'
              }}
          />
        </UserInfoInputContainer>

        <UserInfoInputContainer style={{ marginTop: '38px' }}>
          <button
              style={buttonStyle}
              onClick={handleSubmit(onSubmit)}
              disabled={!userId || !userPassword}
          >
            로그인
          </button>
        </UserInfoInputContainer>

        <div style={{
          color: '#666666',
          fontSize: '14px',
          marginTop: '15px', textAlign: 'center', alignItems: 'center'}} onClick={() => {
          searchUserIdAndPwClick()
        }}>
          아이디 · 비밀번호 찾기
        </div>
      </MainContainer>
  );
};

export default ZMSLogin;
