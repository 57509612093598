import { styled } from '@stitches/react';

export const BrandListGridContainer = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))', // Adjust as needed
  gap: '10px', // Space between items
})

export const BrandItemBackGroundContainer = styled('div', {
  backgroundColor: '#FFF',
  borderRadius: '8px',
  textAlign: 'center',
  alignItems: 'center',
  display: 'flex',
  // marginTop: '20px',
  justifyContent: 'space-between',
  flexDirection: 'column',
  aspectRatio: '1 / 1'
})

export const BrandItemIcon = styled('img', {
  width: '48px', // 아이콘이 부모 컨테이너 내에서 조정
  height: '48px', // 아이콘의 세로 크기 조정
  margin: 'auto',
  objectFit: 'contain'
})

export const BrandItemSpan = styled('span', {
  fontFamily: 'Pretendard',
  color: '#6B6B6B',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
  marginBottom: '12px'
})
