import { styled } from '@stitches/react';

export const AllMessageReadContainer = styled('div', {
  color: '#868686',
  fontFamily: 'Pretendard',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  marginTop: '13px',
  justifyContent: 'right',
  display: 'flex',
  textDecoration: 'underline'
})

export const MessageBackGroundContainer = styled('div', {
  height: 'auto',
  display: 'flex',
  backgroundColor: '#FFF',
  borderBottom: '1px solid #E4E4E4',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: '15px',
  marginTop: '15px'
});


export const MessageTitleContentsDateContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
})

export const MessageTitleContentsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
})

export const MessageTitleContainer = styled('div', {
  display: 'flex', alignItems: 'center', textAlign: 'center'
})

export const IconNotification = styled('img', {
  marginRight: '10px auto'
})

export const MessageTitleSpan = styled('span', {
  fontFamily: 'Pretendard',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '22px',
  color: '#333',
  marginLeft: '7px'
})


export const NewTextSpan = styled('span', {
  color: '#ED1659', margin: '8px auto', fontFamily: 'Pretendard', fontSize: '11px', fontStyle: 'normal',
  fontWeight: 700, lineHeight: '13px', marginLeft: '7px'
})

export const MessageContentContainer = styled('div', {
  color: '#868686',
  fontFamily: 'Pretendard',
  fontSize: '11px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '15px',
  width: '228px',
  marginTop: '8px',
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  textOverflow: 'ellipsis',
})

export const DateContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  color: '#868686',
  fontFamily: 'Pretendard',
  fontSize: '11px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '15px'
})

export const NotNotifyContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  marginTop: '84px'
})

export const NotNotifyText = styled('span', {
  marginTop: '20px',
  textAlign: 'center',
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  color: '#8B63BB'
})