import React from 'react';

interface SpacerProps {
  spacer: number;
}

const Spacer: React.FC<SpacerProps> = ({ spacer }) => {
  return <div style={{ height: `${spacer}px` }} />;
};

export default Spacer;