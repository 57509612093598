import React, { useState, useEffect } from 'react';
import icArrowRightBlack from '../../../assets/ic/ic_arrow_right_black.svg';

interface CustomCalendarProps {
  onClose: () => void;
  onDateSelect: (date: Date) => void;
}

// 날짜 포맷팅 유틸리티 함수
const formatDate = (date: Date) => date.toISOString().split('T')[0];

// 특정 월의 첫 날과 마지막 날을 찾는 함수
const getMonthDays = (year: number, month: number) => {
  const startDate = new Date(year, month, 1);
  const endDate = new Date(year, month + 1, 0);

  const days = [];
  for (let day = 1; day <= endDate.getDate(); day++) {
    days.push(new Date(year, month, day));
  }

  return days;
};

// 특정 월의 첫 날의 요일을 가져오는 함수
const getStartDayOfWeek = (year: number, month: number) => {
  const startDate = new Date(year, month, 1);
  return startDate.getDay();
};

const weekdays = ['일', '월', '화', '수', '목', '금', '토'];

const CustomCalendar: React.FC<CustomCalendarProps> = ({ onClose, onDateSelect }) => {
  const handleDateChange = (date: Date) => {
    onDateSelect(date); // 선택한 날짜를 상위 컴포넌트로 전달
    onClose(); // 달력 닫기
  };

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [daysInMonth, setDaysInMonth] = useState<Date[]>(getMonthDays(currentDate.getFullYear(), currentDate.getMonth()));

  // 오늘 날짜를 한국 시간으로 정확하게 설정
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  useEffect(() => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    setDaysInMonth(getMonthDays(year, month));
  }, [currentDate]);

  const handleDayClick = (date: Date) => {
    // 오늘 날짜보다 이전 날짜는 클릭할 수 없도록 설정
    if (date >= today) {
      setSelectedDate(date);
      handleDateChange(date); // 날짜 선택 시 호출
    }
  };

  const handleMonthChange = (offset: number) => {
    setCurrentDate(prevDate => {
      const newMonth = prevDate.getMonth() + offset;
      const newYear = prevDate.getFullYear() + Math.floor(newMonth / 12);
      const finalMonth = newMonth % 12;
      return new Date(newYear, finalMonth, 1);
    });
  };

  // 빈칸을 추가하여 월의 첫 날이 요일에 맞게 조정된 배열을 생성합니다.
  const daysArray = () => {
    const days = getMonthDays(currentDate.getFullYear(), currentDate.getMonth());
    const startDayOfWeek = getStartDayOfWeek(currentDate.getFullYear(), currentDate.getMonth());
    const paddedDays = Array(startDayOfWeek).fill(null).concat(days);
    return paddedDays;
  };

  return (
    <div style={{ height: '100%', overflow: 'auto' }}>
      <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
        <span style={{
          fontFamily: 'Pretendard',
          fontSize: '13px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: '20px',
          color: '#000',
          flexGrow: 1, textAlign: 'center' }}>
          {currentDate.getFullYear()}년 {String(currentDate.getMonth() + 1).padStart(2, '0')}월
        </span>
        <img
          style={{
            width: '13px',
            height: '13px'
          }}
          onClick={() => handleMonthChange(1)}
          src={icArrowRightBlack} alt={'오른쪽 검정 아이콘'}
        />
      </div>
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(7, 1fr)', textAlign: 'center', height: 'calc(100% - 40px)' }}>
        {weekdays.map(day => (
          <div key={day}>{day}</div>
        ))}
        {daysArray().map((day, index) => (
          <div
            key={index}
            style={{
              padding: '10px',
              cursor: day && day >= today ? 'pointer' : 'not-allowed',
              backgroundColor: selectedDate && day && formatDate(selectedDate) === formatDate(day) ? '#d3d3d3' : '#fff',
              border: day && formatDate(today) === formatDate(day) ? '2px solid #000' : 'none',
              fontWeight: day && formatDate(today) === formatDate(day) ? 'bold' : 'normal',
              textDecoration: day && day >= today ? 'pointer' : 'line-through',
              height: '5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: 'auto'
            }}
            onClick={() => day && handleDayClick(day)}
          >
            {day ? day.getDate() : ''}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomCalendar;
