import { styled } from '@stitches/react';

export const ReductionChoiceContainer = styled('div', {
  width: '100%',
  height: '76px',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#F8F8FA',
  borderRadius: '10px',
  marginTop: '20px',
  color: '#6B6B6B',
  border: '1px solid transparent', // Default border
}, {
  variants: {
    selected: {
      true: {
        border: '1px solid #8B63BB', // Border color for selected state
      },
      false: {
        border: '1px solid transparent', // Default border
      }
    }
  },
  defaultVariants: {
    selected: false
  }
});

export const NextButton = styled('button', {
  padding: '0px',
  border: '0px',
  width: '100%',
  height: '46px',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: 'normal',
  borderRadius: '5px',
  marginTop: '26px',
  cursor: 'pointer',
  variants: {
    disabled: {
      true: {
        backgroundColor: '#D1D1D1',
        color: '#A0A0A0',
        cursor: 'not-allowed',
      },
      false: {
        backgroundColor: '#8B63BB',
        color: '#FFFFFF',
      },
    },
  },
  defaultVariants: {
    disabled: false,
  },
});

export const ReductionNotifyFirstListContainer = styled('ul', {
  paddingLeft: '20px',
  marginTop: '0px',
  lineHeight: '1.5',
  marginLeft: '22px'
})

export const ReductionNotifyFirstContentsText = styled('li', {
  marginBottom: '2px',
  fontFamily: 'Pretendard',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '16px',
  color: '#868686'
})