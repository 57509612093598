import React, { useState, useRef } from 'react';
import { MainContainer } from "../../../components/styles";
import Title from "../../../components/atoms/Title/PageTitle";
import { useNavigate } from "react-router-dom";
import CarInfoList from "./CarInfoList";

interface Car {
  carNumber: string;
  status: string;
  isToggled: boolean;
}

const CarInfo = () => {
  const navigate = useNavigate();
  const scrollContainerRef = useRef<HTMLDivElement>(null); // 스크롤 컨테이너 참조
  const [carList, setCarList] = useState<Car[]>([
    { carNumber: '123가4567', status: '정상', isToggled: false },
    { carNumber: '234나5678', status: '감면신청 진행 중', isToggled: false },
    { carNumber: '345다6789', status: '정상', isToggled: false },
    { carNumber: '449라6789', status: '정상', isToggled: false },
    { carNumber: '549라6789', status: '감면신청 진행 중', isToggled: false },
    { carNumber: '649라6789', status: '감면신청 진행 중', isToggled: false },
  ]);

  // 토글 핸들러
  const handleToggle = (index: number) => {
    // 모든 차량의 isToggled를 false로 설정
    const resetToggledList = carList.map(car => ({ ...car, isToggled: false }));

    // 선택된 차량만 isToggled를 true로 설정
    const updatedCarList = resetToggledList.map((car, i) => {
      if (i === index) {
        return { ...car, isToggled: !car.isToggled };
      }
      return car;
    });

    // 선택된 차량을 리스트의 상단으로 이동
    const toggledCar = updatedCarList.find(car => car.isToggled);
    const remainingCars = updatedCarList.filter(car => !car.isToggled);

    const sortedCarList = toggledCar ? [toggledCar, ...remainingCars] : remainingCars;

    setCarList(sortedCarList);

    // 스크롤 위치를 상단으로 조정
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = 0;
    }
  };

  const carCount = carList.length;

  return (
    <MainContainer>
      <Title
        title="차량 관리"
        leftArrowButtonVisible={true}
        leftOnClick={() => navigate(-1)}
        rightTextButtonVisible={true}
        rightOnClick={() => {
        }}
        rightTitle={`${carCount}/20`}
      />
      <div>
        <div
          ref={scrollContainerRef}
          style={{ flex: 1, overflowY: 'auto', paddingBottom: '40px' }}
        >
          {carList.map((car, index) => (
            <CarInfoList
              key={index}
              carNumber={car.carNumber}
              status={car.status}
              isLast={index === carList.length - 1}
              isToggled={car.isToggled}
              onToggle={() => handleToggle(index)}
            />
          ))}
          <div style={{ height: '40px' }}></div>
        </div>

      </div>
      <button
        style={{
          position: 'fixed',
          bottom: '20px',
          left: '50%',
          transform: 'translateX(-50%)',
          width: 'calc(100% - 50px)',
          height: '46px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          border: 'none',
          backgroundColor: '#8B63BB',
          color: '#fff',
          borderRadius: '4px',
          padding: '10px 20px',
          cursor: 'pointer',
          fontSize: '16px',
          zIndex: 1000,
        }}
        onClick={() => {
          navigate('/carRegistration');
        }}
      >
        차량 등록
      </button>
    </MainContainer>
  );
};

export default CarInfo;
