import { styled } from '@stitches/react';

export const Dialog = styled('div', {
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  height: '100%',
  background: '#fff',
  boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.2)',
  transition: 'transform 0.3s ease-in-out',
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  zIndex: 10000,
  variants: {
    isVisible: {
      true: { transform: 'translateY(0)' },
      false: { transform: 'translateY(100%)' },
    },
  },
})

export const ScrollableContent = styled('div', {
  flex: 1,
  overflowY: 'auto',
});

export const ParkingNumberSpan = styled('div', {
  color: '#1A1A1A',
  fontFamily: 'Pretendard',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
});
//
// export const ImageTextContainer = styled('div', {
//   display: 'flex',
//   alignItems: 'center',
//   color: '#6B6B6B',
//   fontFamily: 'Pretendard',
//   fontSize: '14px',
//   fontStyle: 'normal',
//   fontWeight: 400,
//   lineHeight: 'normal',
//   marginTop: '8px',
// });
//
// export const ImageTextContainer2 = styled('div', {
//   display: 'flex',
//   alignItems: 'center',
//   color: '#6B6B6B',
//   fontFamily: 'Pretendard',
//   fontSize: '14px',
//   fontStyle: 'normal',
//   fontWeight: 400,
//   lineHeight: 'normal',
//   margin: '25px',
// });
//
// export const ExclamationIcon = styled('img', {
//   width: '20px',
//   height: '20px',
//   marginLeft: 'auto',
//   cursor: 'pointer',
// });
//
// export const LoadViewImageViewContainer = styled('div', {
//   height: '310px',
//   backgroundColor: '#F8F8FA',
//   margin: '10px 0',
//   display: 'flex',
//   flexDirection: 'column',
//   alignItems: 'center',
//   justifyContent: 'center',
//   textAlign: 'center',
//   borderRadius: '5px',
// });
//
// export const CautionSirenTitleContentsContainer = styled('div', {
//   width: '100%',
//   height: '6px',
//   backgroundColor: '#F8F8FA',
//   marginTop: '40px',
// });
//
// export const CautionSirenTitleContainer = styled('div', {
//   display: 'flex',
//   flexDirection: 'column',
//   textAlign: 'center',
//   alignItems: 'center',
//   marginTop: '30px',
// });
//
// export const SirenIcon = styled('img', {
//   width: '48px',
//   height: '48px',
//   marginTop: '30px',
// });
//
// export const CautionTitleContainer = styled('div', {
//   color: '#333',
//   fontFamily: 'Pretendard',
//   fontSize: '19px',
//   fontStyle: 'normal',
//   fontWeight: 800,
//   lineHeight: 'normal',
//   marginTop: '12px',
// });
//
// export const ParkingInfoSpan = styled('span', {
//   color: '#8B63BB',
//   fontFamily: 'Pretendard',
//   fontSize: '16px',
//   fontStyle: 'normal',
//   fontWeight: 700,
//   lineHeight: 'normal',
// });
//
// export const CautionContainer = styled('div', {
//   height: '70px',
//   backgroundColor: '#F8F8FA',
//   display: 'flex',
//   borderRadius: '5px',
// });
//
// export const CautionIcon = styled('img', {
//   margin: '10px',
//   width: '50px',
//   height: '50px',
// });
//
// export const CautionTextContainer = styled('div', {
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'center',
//   marginLeft: '9px',
// });
//
// export const CautionTopTextContainer = styled('div', {
//   fontFamily: 'Pretendard',
//   fontSize: '14px',
//   fontStyle: 'normal',
//   fontWeight: 400,
//   lineHeight: 'normal',
// });
//
// export const CautionBottomTextContainer = styled('div', {
//   fontFamily: 'Pretendard',
//   fontSize: '14px',
//   fontStyle: 'normal',
//   fontWeight: 700,
//   lineHeight: 'normal',
//   marginTop: '3px',
// });
//
// export const CautionDetailContainer = styled('div', {
//   margin: '15px auto',
//   display: 'flex',
//   flexDirection: 'column',
// });
//
// export const CautionDetailNumberTextContainer = styled('div', {
//   display: 'flex',
//   flexDirection: 'row',
//   alignItems: 'flex-start',
// });
//
// export const CautionDetailTextContainer = styled('div', {
//   marginLeft: '8px',
//   fontFamily: 'Pretendard',
//   fontSize: '13px',
//   fontStyle: 'normal',
//   fontWeight: 400,
//   lineHeight: '18px',
//   color: '#6B6B6B',
// });
//
// export const CautionDetailNumberSpan = styled('div', {
//   color: '#8B63BB',
//   fontFamily: 'Pretendard',
//   fontSize: '13px',
//   fontStyle: 'normal',
//   fontWeight: 700,
//   lineHeight: '18px',
// });
//
// export const CautionDetailTextSpan = styled('div', {
//   color: '',
//   fontFamily: 'Pretendard',
//   fontSize: '13px',
//   fontStyle: 'normal',
//   fontWeight: 400,
//   lineHeight: '18px',
// });
//
// export const CautionDotTextContainer = styled('div', {
//   display: 'flex',
//   alignItems: 'flex-start',
//   marginTop: '8px',
//   gap: '8px',
//   color: '#6B6B6B',
//   fontFamily: 'Pretendard',
//   fontSize: '13px',
//   fontStyle: 'normal',
//   fontWeight: 400,
//   lineHeight: '18px',
// });
//
// export const DotIcon = styled('img', {
//   marginTop: '6px',
// });
//
// export const ParkingInfoListSpan = styled('span', {
//   color: '#6B6B6B',
//   fontFamily: 'Pretendard',
//   fontSize: '15px',
//   fontStyle: 'normal',
//   fontWeight: 400,
//   lineHeight: 'normal',
// });
//
// export const ParkingInfoWeekContainer = styled('div', {
//   backgroundColor: '#8B63BB',
//   color: '#fff',
//   borderRadius: '20px',
//   textAlign: 'center',
//   padding: '1px 5px',
//   fontFamily: 'Pretendard',
//   fontSize: '12px',
//   fontStyle: 'normal',
//   fontWeight: 700,
//   lineHeight: 'normal',
// });
//
// export const TimeOperatingHoursSpan = styled('span', {
//   color: '#1A1A1A',
//   fontFamily: 'Pretendard',
//   fontSize: '15px',
//   fontStyle: 'normal',
//   fontWeight: 400,
//   lineHeight: 'normal',
// });
//
// export const DiscountAmountSpan = styled('span', {
//   color: '#B0B0B0',
//   fontFamily: 'Pretendard',
//   fontSize: '11px',
//   fontStyle: 'normal',
//   fontWeight: 400,
//   lineHeight: '13px',
//   textDecoration: 'line-through',
// });
//
// export const PaymentAccountDepositContainer = styled('div', {
//   display: 'flex',
//   justifyContent: 'space-between',
//   alignItems: 'center',
//   marginTop: '8px',
// });
//
// export const PaymentAccountDepositContentContainer = styled('div', {
//   display: 'flex',
//   alignItems: 'center',
//   gap: '8px',
// });
//
// export const ParkingTitleSpan = styled('span', {
//   color: '#6B6B6B',
//   fontFamily: 'Pretendard',
//   fontSize: '15px',
//   fontStyle: 'normal',
//   fontWeight: 400,
//   lineHeight: 'normal',
// });
//
// export const OperatingHourContainer = styled('div', {
//   display: 'flex',
//   justifyContent: 'space-between',
//   alignItems: 'center',
//   marginTop: '30px',
// });
//
// export const WeekContainer = styled('div', {
//   backgroundColor: '#8B63BB',
//   color: '#fff',
//   borderRadius: '20px',
//   textAlign: 'center',
//   padding: '2px 5px',
//   fontFamily: 'Pretendard',
//   fontSize: '12px',
//   fontStyle: 'normal',
//   fontWeight: 700,
//   lineHeight: 'normal',
// });
//
// export const DepositStatusContainer = styled('div', {
//   backgroundColor: '#EBEBFB',
//   color: '#8B63BB',
//   borderRadius: '5px',
//   textAlign: 'center',
//   padding: '5px 5px',
//   fontFamily: 'Pretendard',
//   fontSize: '12px',
//   fontStyle: 'normal',
//   fontWeight: 700,
//   lineHeight: 'normal',
// });
//
// export const WeekInfoContainer = styled('div', {
//   display: 'flex',
//   justifyContent: 'flex-end',
//   alignItems: 'center',
//   gap: '8px',
//   marginTop: '10px',
// });
//
// export const ParkingInfoStatusContainer = styled('div', {
//   display: 'flex',
//   overflowX: 'auto',
//   padding: '10px',
//   whiteSpace: 'nowrap',
//   scrollBehavior: 'smooth',
//   marginTop: '25px',
//   marginBottom: '25px',
// });
//
// export const ParkingInfoIconStyled = styled('img', {
//   height: '50px',
//   marginRight: '15px',
// });
//
// export const ButtonSeasonTicket = styled('button', {
//   padding: '0px',
//   border: '0px',
//   width: '100%',
//   height: '46px',
//   fontWeight: 700,
//   fontSize: '16px',
//   lineHeight: 'normal',
//   borderRadius: '5px',
//   backgroundColor: '#8B63BB',
//   color: '#fff',
//   marginBottom: '25px',
// });
