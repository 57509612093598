import { styled } from '@stitches/react';

export const PointInfoContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  textAlign: 'left',
  margin: '20px',
})

export const DateSpan = styled('div', {
  color: '#CCC',
  fontFamily: 'Pretendard',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal'
})

export const PointStatusContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  textAlign: 'left',
  width: '100%',
})

export const PointStatusSpan = styled('span', {
  overflowWrap: 'break-word',
  wordWrap: 'break-word',
  whiteSpace: 'normal',
  maxWidth: 'calc(100% - 50px)',
  marginTop: '10px',
  color: '#555',
  fontFamily: 'Pretendard',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal'
})

export const PointMoneySpan = styled('span', {
  color: '#868686',
  fontFamily: 'Pretendard',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal'
})

export const PointDetailContainer = styled('div', {
  backgroundColor: '#F8F8FA',
  height: 'auto',
  borderRadius: '5px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginTop: '10px',
  color: '#868686',
  fontFamily: 'Pretendard',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  gap: '10px',
  padding: '10px 16px'
})

export const ViewDivideContainer = styled('div', {
  width: '100%',
  height: '1px',
  backgroundColor: '#CCC',
  marginTop: '20px',
})

export const EmptyHistoryContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '50vh',
  textAlign: 'center',
  color: '#868686',
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal'
})

export const SpinnerContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '50vh'
})