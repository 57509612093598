import CryptoJS from 'crypto-js';

// 암호화 설정 값들
export const keySize = 256
export const iterationCount = 1000
export const passPhrase = 'ZOOMANSA'

// IV와 Salt 값을 랜덤으로 생성하는 함수
export const getRandomString = () => {
  const iv = CryptoJS.lib.WordArray.random(128 / 8).toString(CryptoJS.enc.Hex)
  const salt = CryptoJS.lib.WordArray.random(128 / 8).toString(CryptoJS.enc.Hex)
  return { iv, salt }
}

export const encTextWord = (textWord: string, iv: string, salt: string) => {
  const key128Bits100Iterations = CryptoJS.PBKDF2(passPhrase, CryptoJS.enc.Hex.parse(salt), {
    keySize: keySize / 32,
    iterations: iterationCount,
  })

  const encrypted = CryptoJS.AES.encrypt(textWord, key128Bits100Iterations, {
    iv: CryptoJS.enc.Hex.parse(iv),
  })
  return encrypted.toString()
}
