import { styled } from '@stitches/react';

export const PointMallStorageBoxContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#F2F2F2',
  flex: 1, // flex-grow를 사용하여 부모 컨테이너가 가득 차도록 설정
  overflowY: 'auto', // 스크롤 가능하게 설정
  minHeight: '100vh', // 최소 높이를 100vh로 설정하여 전체 화면을 차지하게 함
  paddingBottom: '40px', // 하단 패딩 추가 (필요 시)

});

export const Spacer = styled('div', {
  height: '100px', // 기본값

  '@media (max-width: 812px) and (min-width: 375px) and (-webkit-device-pixel-ratio: 3)': {
    height: '128px',
  },
  '@media (max-width: 896px) and (min-width: 414px) and (-webkit-device-pixel-ratio: 3)': {
    height: '128px',
  },
  '@media (max-width: 2796px) and (min-width: 414px) and (-webkit-device-pixel-ratio: 3)': {
    height: '128px',
  }
});