import { styled } from '@stitches/react';


// 스타일 컴포넌트 정의
export const ModalContentContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

export const ItemContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  transition: 'background-color 0.3s, box-shadow 0.3s',
  marginTop: '10px',
  marginLeft: '10px',
  marginRight: '10px',
  padding: '5px',
  borderRadius: '5px',
  variants: {
    isActive: {
      true: {
        backgroundColor: '#b799db40', // 선택된 상태의 배경색

      },
      false: {
        backgroundColor: 'transparent', // 비선택 상태의 배경색

      },
    },
  },
});

export const ItemText = styled('span', {
  marginLeft: '15px',
  fontSize: '13px',
  fontFamily: 'Pretendard',
  fontWeight: 400,
  color: '#868686',
});

export const SkipSelectionText = styled('div', {
  color: '#868686',
  fontFamily: 'Pretendard',
  fontSize: '13px',
  fontWeight: 400,
  textDecoration: 'underline',
  marginTop: '56px',
  cursor: 'pointer',

  display: 'flex',
  flexDirection: 'column',
});
