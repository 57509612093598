import { MainContainer } from '../../../components/styles';
import React, { useState } from 'react';
import Title from '../../../components/atoms/Title/PageTitle';
import { useNavigate } from "react-router-dom";
import {
  AddressDetailIContainer,
  ArrowIcon, BottomButtonContainer, ButtonEnableDisable,
  CarNumberAddressContainer,
  CarTypeContainer,
  CashReceiptChoiceContainer,
  DiscountAmountSpan,
  ExpenditureContainer,
  FlexContainer,
  IncomeDeductionContainer, MarginTopStyled,
  OperatingHourContainer,
  ParkingInfoContainer,
  ParkingInfoDetailContainer,
  ParkingTitleSpan,
  PaymentAccountDepositContainer,
  PaymentAccountDepositContentContainer,
  PaymentAmountSpan,
  PaymentContainer,
  PaymentInfoContainer,
  PaymentInputContainer,
  PaymentMethodChoiceContainer,
  PaymentMethodNumberInput,
  PostContainer,
  SearchContainer,
  SearchIcon,
  SpacingBetweenContainer,
  StyledCheckbox,
  TextCenterSpan,
  TimeOperatingHoursSpan, UserInfoInputContainer,
  ViewDivide,
  ViewDivide2,
  WeekContainer,
  WeekInfoContainer,
} from './styles';
import icSearch from 'assets/ic/ic_setting.svg';
import icArrowBlack from 'assets/ic/ic_arrow_bottom_black.svg'
import {
  DepositStatusContainer,
} from '../../../components/atoms/Modal/MonthParkingInfoDetailBottomModal/MonthParkingInfoDetail/styles';
import PaymentMethodBottomModal from '../../../components/atoms/Modal/PaymentMethodBottomModal';
import { CheckBoxLabel, RequiredText } from '../../AgreeTerms/styles';
import icArrowRightGraySmall from 'assets/ic/ic_arrow_right_gray_small.svg';
import { Button } from 'react-scroll';
import PaymentIncomeDeductionBottomModal from '../../../components/atoms/Modal/PaymentIncomeDeductionBottomModal';
import PaymentExpenditureBottomModal from '../../../components/atoms/Modal/PaymentExpenditureBottomModal';

const PaymentUserInfoCheck = () => {
  const navigate = useNavigate();
  const [isPaymentMethodModal, setIsPaymentMethodModal] = useState(false)

  const [isIncomeDeductionModal, setIsIncomeDeductionModal] = useState(false)
  const [isExpenditureModal, setIsExpenditureModal] = useState(false)

  const [checkboxes, setCheckboxes] = React.useState({
    // 신청
    // application: false,
    // 소득공제
    incomeDeduction: false,
    // 지출
    expenditure: false,
    // 개인정보 수집 이용 동의
    privacyPolicy: false,
  });

  const [checkCashReceiptMethod, setCheckCashReceiptMethod] = useState({
    incomeDeduction: true,  // 처음 소득공제가 선택된 상태로 설정
    expenditure: false,
  });

  const handleChangeCashReceipt = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: keyof typeof checkCashReceiptMethod
  ) => {
    const isChecked = event.target.checked;
    console.log(`Checkbox for ${key} clicked, checked: ${isChecked}`);

    if (key === 'incomeDeduction') {
      setCheckCashReceiptMethod({
        incomeDeduction: isChecked,
        expenditure: !isChecked,
      });
    } else if (key === 'expenditure') {
      setCheckCashReceiptMethod({
        incomeDeduction: !isChecked,
        expenditure: isChecked,
      });
    }
  };

  const [selectedMethod, setSelectedMethod] = useState('가상계좌')
  const [selectIncomeDeduction, setSelectIncomeDeduction] = useState('휴대폰번호')
  const [selectExpenditureDeduction, setSelectExpenditureDeduction] = useState('사업자등록번호')

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, key: keyof typeof checkboxes) => {
    const { checked } = event.target;
    setCheckboxes(prev => {
      const updatedCheckboxes = {
        ...prev,
        [key]: checked
      };

      // const allChecked = Object.values(updatedCheckboxes).every(value => value);
      // setIsCheckedAll(allChecked);
      return updatedCheckboxes;
    });
  };

  return (
    <PaymentContainer>
      <UserInfoInputContainer>
        <Title
          title="결제"
          leftArrowButtonVisible={true}
          leftOnClick={() => {
            navigate('/home');
          }}
          rightTextButtonVisible={false}
        />
        <CarTypeContainer>
          <TextCenterSpan>차종 *</TextCenterSpan>
          <PaymentInputContainer placeholder="차종을 입력해주세요." />
        </CarTypeContainer>
        <CarNumberAddressContainer>
          <TextCenterSpan>차량번호 *</TextCenterSpan>
          <PaymentInputContainer placeholder="차량번호를 입력해주세요." />
        </CarNumberAddressContainer>

        <CarNumberAddressContainer>
          <TextCenterSpan>자택주소 *</TextCenterSpan>
          <PostContainer>
            우편번호 찾기
            <SearchIcon src={icSearch} alt={'검색아이콘'} />
          </PostContainer>
        </CarNumberAddressContainer>
        <AddressDetailIContainer>
          <PaymentInputContainer placeholder="상세주소" />
          <PaymentInputContainer style={{ marginTop: '15px' }} />
        </AddressDetailIContainer>
      </UserInfoInputContainer>
      <ViewDivide />
      <ParkingInfoContainer>
        주차장 정보
        <OperatingHourContainer>
          <ParkingTitleSpan>운영시간</ParkingTitleSpan>
          <PaymentAccountDepositContentContainer>
            <WeekContainer>평일</WeekContainer>
            <TimeOperatingHoursSpan>08:00~17:00</TimeOperatingHoursSpan>
          </PaymentAccountDepositContentContainer>
        </OperatingHourContainer>
        <WeekInfoContainer>
          <WeekContainer>주말·공휴일</WeekContainer>
          <TimeOperatingHoursSpan>운영안함</TimeOperatingHoursSpan>
        </WeekInfoContainer>
        <PaymentAccountDepositContainer>
          <ParkingTitleSpan>결제금액</ParkingTitleSpan>
          <PaymentAccountDepositContentContainer>
            <DiscountAmountSpan>142.600원</DiscountAmountSpan>
            <TimeOperatingHoursSpan>140,000원</TimeOperatingHoursSpan>
          </PaymentAccountDepositContentContainer>
        </PaymentAccountDepositContainer>
      </ParkingInfoContainer>
      <ViewDivide />
      <ParkingInfoContainer>
        주차장 정보
        <div
          style={{
            marginTop: '20px',
          }}
        >
          <ParkingInfoDetailContainer>
            <ParkingTitleSpan>이용시작</ParkingTitleSpan>
            <TimeOperatingHoursSpan>2024. 06. 29(수)</TimeOperatingHoursSpan>
          </ParkingInfoDetailContainer>

          <ParkingInfoDetailContainer>
            <ParkingTitleSpan>이용종료</ParkingTitleSpan>
            <TimeOperatingHoursSpan>2024. 06. 30(목)</TimeOperatingHoursSpan>
          </ParkingInfoDetailContainer>

          <ParkingInfoDetailContainer>
            <ParkingTitleSpan>주차요금</ParkingTitleSpan>
            <TimeOperatingHoursSpan>140,000원</TimeOperatingHoursSpan>
          </ParkingInfoDetailContainer>

          <ParkingInfoDetailContainer>
            <ParkingTitleSpan>할인금액</ParkingTitleSpan>
            <TimeOperatingHoursSpan>-20,000원</TimeOperatingHoursSpan>
          </ParkingInfoDetailContainer>

          <ParkingInfoDetailContainer>
            <ParkingTitleSpan>보증금</ParkingTitleSpan>
            <PaymentAccountDepositContentContainer>
              <DepositStatusContainer>주자 이용 종료 후 반환</DepositStatusContainer>
              <TimeOperatingHoursSpan>50.000원</TimeOperatingHoursSpan>
            </PaymentAccountDepositContentContainer>
          </ParkingInfoDetailContainer>
          <ViewDivide2 />

          <ParkingInfoDetailContainer>
            <ParkingTitleSpan>총결제 금액</ParkingTitleSpan>
            <PaymentAmountSpan>120,000원</PaymentAmountSpan>
          </ParkingInfoDetailContainer>
        </div>
      </ParkingInfoContainer>

      <ViewDivide />

      <ParkingInfoContainer>
        결제 수단
        <MarginTopStyled/>
        <PaymentMethodChoiceContainer
          onClick={() => {
            setIsPaymentMethodModal(true);
          }}
        >
          <span>{selectedMethod}</span>
          <ArrowIcon src={icArrowBlack} alt={'애로우 바텀 아이콘'} />
        </PaymentMethodChoiceContainer>
      </ParkingInfoContainer>

      {selectedMethod === '가상계좌' && (
        <div>
          <CashReceiptChoiceContainer>
            <span>현금 영수증</span>
            <FlexContainer>
              <IncomeDeductionContainer>
                <StyledCheckbox
                  type="checkbox"
                  checked={checkCashReceiptMethod.incomeDeduction}
                  onChange={(e) => handleChangeCashReceipt(e, 'incomeDeduction')}
                />
                <span>소득공제</span>
              </IncomeDeductionContainer>

              <ExpenditureContainer>
                <StyledCheckbox
                  type="checkbox"
                  checked={checkCashReceiptMethod.expenditure}
                  onChange={(e) => handleChangeCashReceipt(e, 'expenditure')}
                />
                <span>지출증빙</span>
              </ExpenditureContainer>
            </FlexContainer>
          </CashReceiptChoiceContainer>
          <PaymentInfoContainer>
            <PaymentMethodChoiceContainer
              onClick={() => {
                if (checkCashReceiptMethod.incomeDeduction) {
                  setIsIncomeDeductionModal(true);
                } else if (checkCashReceiptMethod.expenditure) {
                  setIsExpenditureModal(true);
                }
              }}
            >
              <span>
                {checkCashReceiptMethod.incomeDeduction
                  ? selectIncomeDeduction
                  : checkCashReceiptMethod.expenditure
                    ? selectExpenditureDeduction : ''}
              </span>
              <ArrowIcon src={icArrowBlack} alt={'애로우 바텀 아이콘'} />
            </PaymentMethodChoiceContainer>
            <SpacingBetweenContainer>
              <PaymentMethodNumberInput/>
            </SpacingBetweenContainer>
          </PaymentInfoContainer>
        </div>
      )}
      <div>
        <CheckBoxLabel
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            margin: '20px 20px',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <StyledCheckbox
              type="checkbox"
              checked={checkboxes.privacyPolicy}
              onChange={(e) => handleChange(e, 'privacyPolicy')}
            />
            <RequiredText>(필수)</RequiredText>서비스 이용약관 및 개인정보 수집 동의
          </div>
          <div
            style={{ flexShrink: 0 }}
            onClick={(e) => {
              e.stopPropagation();
              console.log('주만사 서비스 이용약관 상세');
            }}
          >
            <img src={icArrowRightGraySmall} alt="arrow.png" onClick={() => {
              navigate('/policyPersonal')
            }} />
          </div>
        </CheckBoxLabel>
        <BottomButtonContainer>
          <ButtonEnableDisable
            onClick={() => {
              // 가상계좌일경우
              navigate('/paymentVirtualAccount')
              // 아닐경우
              // 외부 업체
            }}>
            결제하기
          </ButtonEnableDisable>
        </BottomButtonContainer>
      </div>

      <PaymentMethodBottomModal
        isVisible={isPaymentMethodModal}
        onClose={() => setIsPaymentMethodModal(false)}
        initialMethod={selectedMethod}
        onSelectMethod={setSelectedMethod}
      />

      <PaymentIncomeDeductionBottomModal
        isVisible={isIncomeDeductionModal}
        onClose={() => setIsIncomeDeductionModal(false)}
        incomeDeduction={selectIncomeDeduction}
        onIncomeDeduction={setSelectIncomeDeduction}
      />

      <PaymentExpenditureBottomModal
        isVisible={isExpenditureModal}
        onClose={() => {setIsExpenditureModal(false)}}
        expenditure={selectExpenditureDeduction}
        onExpenditure={setSelectExpenditureDeduction}/>
    </PaymentContainer>
  );
};
export default PaymentUserInfoCheck;
