import { styled } from '@stitches/react';

export const WithdrawalMainContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  touchAction: 'pan-x pan-y pinch-zoom',
})

export const PasswordTitleInputContainer = styled('div', {
  color: '#666',
  textAlign: 'center',
  fontFamily: 'Pretendard',
  fontSize: '17px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '23px',
  marginTop: '60px'
})

export const WithdrawalPossibleMoneyContainer = styled('div', {
  color: '#868686',
  textAlign: 'center',
  fontFamily: 'Pretendard',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  marginTop: '60px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
})

export const WithdrawalMoneyInput = styled('input', {
  border: 'none',
  outline: 'none',
  fontWeight: 800,
  fontFamily: 'Pretendard',
  fontSize: '32px',
  lineHeight: 'normal',
  color: '#8B63BB',
  height: '38px',
  backgroundColor: 'transparent',
  textAlign: 'center',
  marginTop: '40px',

  '&::placeholder': {
    color: '#E4E4E4',
  },
});

export const InputBoxContainer = styled('div', {
  display: 'flex',
  gap: '10px',
  overflowX: 'auto',
  boxSizing: 'border-box',
  marginBottom: '20px',
  margin: '40px 66px 17px',

  '@media (max-width: 360px)': {
    margin: '40px 56px 17px',
    gap: '10px',
  },
})

export const CautionContainer = styled('div', {
  color: '#ED1659',
  fontFamily: 'Pretendard',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '18px',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column'
})

export const PasswordInput = styled('input', {
  border: 'none',
  outline: 'none',
  borderBottom: '3px solid #8B63BB',
  padding: '5px',
  flex: '1',
  minWidth: '0',
  fontWeight: 500,
  fontFamily: 'Pretendard',
  fontSize: '28px',
  lineHeight: '34px',
  color: '#333',
  height: '35px',
  backgroundColor: 'transparent',
  textAlign: 'center',
  borderRadius: '0',
})

export const InputGridContainer = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: '10px',

  '@media (max-width: 812px) and (min-width: 375px) and (-webkit-device-pixel-ratio: 3)': {
    padding: '10px 0 20px',
  },
  '@media (max-width: 896px) and (min-width: 414px) and (-webkit-device-pixel-ratio: 3)': {
    padding: '10px 0 20px'
  },
  '@media (max-width: 2796px) and (min-width: 414px) and (-webkit-device-pixel-ratio: 3)': {
    padding: '10px 0 20px'
  }
})

export const InputButton = styled('button', {
  padding: '10px',
  border: 'none',
  backgroundColor: '#f9f9f9',
  textAlign: 'center',
  color: '#444446',
  fontFamily: 'Pretendard',
  fontSize: '28px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '34px',
})

export const AllDeleteContainer = styled('button', {
  padding: '10px',
  border: 'none',
  backgroundColor: '#f9f9f9',
  textAlign: 'center',
  color: '#444446',
  fontSize: '16px',
  fontFamily: 'Pretendard',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '20px'
})

export const OneDeleteContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#f9f9f9',
})

export const OneDeleteImg = styled('img', {
  padding: '10px',
  width: '37px',
  height: '37px',
})

export const SearchPasswordContainer = styled('div', {
  backgroundColor: '#FFF',
  color: '#8B63BB',
  border: '1px solid #8B63BB',
  borderRadius: '20px',
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '18px',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '16px auto',
  padding: '8px 10px'
})

export const SearchPasswordForget = styled('div', {
  backgroundColor: '#FFF',
  color: '#8B63BB',
  border: '1px solid #8B63BB',
  borderRadius: '20px',
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '18px',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '4px auto',
  padding: '8px 10px'
})

export const BottomButtonKeyboardContainer = styled('div', {
  height: 'auto',
  position: 'fixed',
  bottom: '0',
  left: '0',
  right: '0',
})

export const BottomGridContainer = styled('div', {
  backgroundColor: '#F8F8F8',
})

export const ButtonEnableDisable = styled('button', {
  border: 'none',
  borderRadius: '5px',
  width: 'calc(100% - 40px)',
  marginLeft: '20px',
  marginRight: '20px',
  height: '46px',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: 'normal',
  backgroundColor: '#8B63BB',
  color: '#fff',
  marginBottom: '20px',
  '&:disabled': {
    backgroundColor: '#868686',
    color: '#fff',
    cursor: 'not-allowed',
  },
});