import Title from '../../../components/atoms/Title/PageTitle';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ExpectedMoneySpan,
  MoneyContainer,
  PointContainer,
  PointEarnExpectedContainer,
  TicketContainer,
  TitleContainer,
  ViewDivideContainer1,
  ViewDivideContainer2,
} from './styles';
import PointEarnExpectedList from '../PointEarnExpectedList';
import { observer } from 'mobx-react';
import { pointPendingStore } from '../../../stores/store/Point/pointPendingStore';
import { LINK_UID } from '../../../constants/userInfoConstants';

const PointEarnExpected = observer(() => {
  const navigate = useNavigate();

  const handleLeftOnClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    window.onBtnBackClicked = function () {
      handleLeftOnClick();
    };
    return () => {
      delete window.onBtnBackClicked;
    };
  }, []);

  useEffect(() => {
    const fetchPointPending = async () => {
      const linkUID = parseInt(sessionStorage.getItem(LINK_UID) || '0', 10);
      await pointPendingStore.fetchPointPending(linkUID);
    };
    fetchPointPending().catch((error) => {
      console.log('유저정보 가져오기 중 오류가 발생했습니다.', error);
    });
  }, []);

  return (
    <>
      <Title
        title="적립 예정 내역"
        leftArrowButtonVisible={true}
        leftOnClick={handleLeftOnClick}
        rightTextButtonVisible={false}
      />
      <PointEarnExpectedContainer>
        <TicketContainer>
          <TitleContainer>
            <span>적립 예정 금액</span>
            <ExpectedMoneySpan>{pointPendingStore.pendingSum}</ExpectedMoneySpan>
          </TitleContainer>
          <ViewDivideContainer1 />
          <MoneyContainer>
            <span>ㄴ 금액</span>
            {pointPendingStore.pendingPays}
          </MoneyContainer>
          <PointContainer>
            <span>ㄴ 포인트</span>
            {pointPendingStore.pendingPoints}
          </PointContainer>
        </TicketContainer>
      </PointEarnExpectedContainer>
      <ViewDivideContainer2 />
      <PointEarnExpectedList />
    </>
  );
});

export default PointEarnExpected;