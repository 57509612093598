import { styled } from '@stitches/react';
import WithdrawBottomModal from './index';

export const WithdrawModalContainer = styled('div', {
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  height: 'auto',
  background: '#fff',
  boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.2)',
  transition: 'transform 0.3s ease-in-out',
  transform: 'translateY(100%)', // 모달을 화면 아래로 숨김
  display: 'flex',
  flexDirection: 'column',
  padding: '28px 20px 40px' ,
  boxSizing: 'border-box',
  borderRadius: '20px 20px 0px 0px',
  zIndex: 10000,
  variants: {
    isVisible: {
      true: { transform: 'translateY(0)' },
      false: { transform: 'translateY(100%)' },
    },
  },
})

export const WithdrawModalOverlay = styled('div', {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.3)',
  zIndex: 9999,
})

export const WithdrawContentsTitleContainer = styled('div', {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  lineHeight: '20px',
  paddingBottom: '28px',
})

export const FirstTitleSpan = styled('span', {
  color: '#1A1A1A',
  fontFamily: 'Pretendard',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
})

export const ContentsContainer = styled('div', {
  color: '#868686',
  fontFamily: 'Pretendard',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  display: 'flex',
  flexDirection: 'column',
})

export const TitleSpan = styled('div', {
  color: '#1A1A1A',
  fontFamily: 'Pretendard',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
  marginTop: '28px',
})

export const ContentsMarginLeftSpan = styled('span', {
  marginLeft: '8px'
})

export const FirstContentContainer = styled('div', {
  display: 'flex',
  alignItems: 'flex-start',
  marginTop: '16px',
  marginBottom: '4px',
})

export const SecondContentContainer = styled('div', {
  display: 'flex',
  alignItems: 'flex-start',
})

export const DotIc = styled('img', {
  alignSelf: 'flex-start', marginTop: '8px'
})

export const MainColorSpan = styled('span', {
  color: '#8B63BB',
  fontWeight: 600,
})

export const FirstLimitContentContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginTop: '16px',
  color: '#666',
  fontFamily: 'Pretendard',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal'

})

export const SecondLimitContentContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginTop: '10px',
  color: '#666',
  fontFamily: 'Pretendard',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal'
})

export const CheckButton = styled('div', {
  height: '46px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  border: 'none',
  backgroundColor: '#8B63BB',
  color: '#fff',
  borderRadius: '4px',
  cursor: 'pointer',
  fontSize: '16px',
  fontFamily: 'Pretendard',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal'
})
