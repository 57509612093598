import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import {
  BrandItemBackGroundContainer,
  BrandItemIcon,
  BrandItemSpan,
  BrandListGridContainer
} from './styles'
import { brandListStore } from '../../../../stores/store/Point/brandListStore'
import LoadingSpinnerScreen from '../../../../components/atoms/LoadingSpinner/LoadingSpinnerScreen'
import { LINK_UID } from '../../../../constants/userInfoConstants'

const PointMallBrandList = () => {
  const navigate = useNavigate()

  useEffect(() => {
    const loadBrands = async () => {
      const linkUID = parseInt(sessionStorage.getItem(LINK_UID) || '0', 10)
      await brandListStore.fetchBrands(linkUID)
    }
    loadBrands().catch((error) => {
      console.log('유저정보 가져오기 중 오류가 발생했습니다.', error)
    })
  }, [])

  if (brandListStore.loading) {
    return <LoadingSpinnerScreen />
  }

  return (
    <BrandListGridContainer>
      {brandListStore.brands.map(brand => (
        <BrandItemBackGroundContainer
          key={brand.brandUID}
          onClick={() => navigate(`/pointMallBrandDetail/${brand.brandName}`, { state: { brandName: brand.brandName } })}
        >
          <BrandItemIcon
            src={brand.brandImage}
            alt={brand.brandName}
          />
          <BrandItemSpan>{brand.brandName}</BrandItemSpan>
        </BrandItemBackGroundContainer>
      ))}
    </BrandListGridContainer>
  );
};

export default observer(PointMallBrandList);
