import { makeAutoObservable } from 'mobx';
import PointService from '../../../service/Point/pointSearchService';
import BaseError from '../../../models/baseError';
import { IPointSearch, IPointSumData } from '../../../models/Point/pointHistoryModel';

export class PointSearchStore {
  private _pointSumData: IPointSumData | null = null
  isLoading = false
  error: string | null = null

  constructor() {
    makeAutoObservable(this);
  }

  get remainingPay() : string {
    return this._pointSumData?.remainingPays || '0';
  }

  get remainingPoints() : string {
    return this._pointSumData?.remainingPoints || '0';
  }

  get pendingSum() : string {
    return this._pointSumData?.pendingSum || '0'
  }

  get expiringSum() : string {
    return this._pointSumData?.expiringSum || '0'
  }

  setPointSumData(data: IPointSumData) {
    this._pointSumData = data;
  }

  setLoading(value: boolean) {
    this.isLoading = value;
  }

  async fetchPointsData(requestBody: IPointSearch) {
    this.setLoading(true);
    try {
      const response = await PointService.postPointSearch(requestBody);

      if (response instanceof BaseError) {
        console.error(response.message)
      } else {
        const { sumData } = response.data
        this.setPointSumData(sumData)
      }
    } catch (error) {
      console.error('포인트, 잔액 정보 불러오기 실패:', error);
      this.error = '포인트, 잔액 정보 불러오기 실패 했습니다';
    } finally {
      this.setLoading(false);
    }
  }
}

export const pointSearchStore = new PointSearchStore();
