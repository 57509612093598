import {
  AllMessageReadContainer, DateContainer, IconNotification,
  MessageBackGroundContainer, MessageContentContainer, MessageTitleContainer,
  MessageTitleContentsContainer,
  MessageTitleContentsDateContainer, MessageTitleSpan, NewTextSpan
} from "../styles";
import icLetterSky from '../../../assets/ic/ic_letter_sky.svg'
import icLetterGray from '../../../assets/ic/ic_letter_gray.svg'
import {useNavigate} from "react-router-dom";
import React, {useState} from "react";
import TwoButtonModal from "../../../components/atoms/Modal/TwoButtonModal";

const initialMessages = [
  {
    title: "월 정액 가상계좌 안내",
    content: "[입금요청] 49,000원 (예금주 : 주차장만드는사람들 (주) 국민은행) 입금바랍니다.",
    date: "오늘",
    icon: icLetterSky,
    new: true
  },
  {
    title: "다음 달 요금 안내",
    content: "[입금요청] 50,000원 (예금주 : 예금주(주) 국민은행) 입금바랍니다.",
    date: "어제",
    icon: icLetterGray,
    new: false
  },
];

const NotificationCenterGeneralMessageList: React.FC<{ updateMessageDeleteImage: boolean }> = ({ updateMessageDeleteImage }) => {
  const navigate = useNavigate()

  const [isAllMessageReadModal, setIsAllMessageReadModal] = useState(false);
  const [messages, setMessages] = useState(initialMessages);
  const [allMessagesRead, setAllMessagesRead] = useState(false);

  const allMessageDeleteModel = () => {
    setIsAllMessageReadModal(true);
  };

  const handleAllReadModal = () => {
    const updatedMessages = messages.map(message => ({
      ...message,
      icon: icLetterGray,
      new: false // Remove 'New' tag from all messages
    }));
    setMessages(updatedMessages);
    setAllMessagesRead(true);
    setIsAllMessageReadModal(false);
  };

  return (
      <>
        <AllMessageReadContainer onClick={allMessageDeleteModel}>
          모두읽기
        </AllMessageReadContainer>
        {messages.map((message, index) => (
            <MessageBackGroundContainer key={index}>
              <MessageTitleContentsDateContainer>
                <MessageTitleContentsContainer onClick={() => {
                  navigate('/notificationCenterGeneralMessageDetail')
                }}>
                  <MessageTitleContainer>
                    <IconNotification src={message.icon} alt="확성기 아이콘"/>
                    <MessageTitleSpan
                        style={{
                          fontWeight: allMessagesRead || !message.new ? 400 : 700,
                          color: allMessagesRead || !message.new ? '#B0B0B0' : '#333', // Change text color based on read status and new status
                        }}
                    >
                      {message.title}
                    </MessageTitleSpan>
                    {!allMessagesRead && message.new && (
                        <NewTextSpan>
                          New
                        </NewTextSpan>
                    )}
                  </MessageTitleContainer>
                  <MessageContentContainer>
                <span>
                  {message.content}
                </span>
                  </MessageContentContainer>
                </MessageTitleContentsContainer>
                <DateContainer>
                  {message.date}
                </DateContainer>
              </MessageTitleContentsDateContainer>
            </MessageBackGroundContainer>
        ))}
        <TwoButtonModal
            isVisible={isAllMessageReadModal}
            onClose={() => setIsAllMessageReadModal(false)}
            title={'모든 알림을 읽음\n처리 하시겠습니까?'}
            content={''}
            leftButtonText={'취소'}
            rightButtonText={'확인'}
            rightClick={handleAllReadModal}
        />
      </>
  )
}

export default NotificationCenterGeneralMessageList

