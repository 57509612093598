import { styled } from '@stitches/react';

// 토글 컨테이너 스타일
export const ToggleContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  width: '100%',
  height: '35px',
  backgroundColor: '#fff',
  borderRadius: '5px',
  cursor: 'pointer',
  transition: 'background-color 0.3s ease',
  border: '1px solid #ccc',
});

// 슬라이더 스타일
export const Slider = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',  // 슬라이더 안의 콘텐츠 정중앙
  width: '50%',  // 컨테이너의 절반 너비
  height: '35px',  // 컨테이너 전체 높이
  backgroundColor: '#8B63BB',
  borderRadius: '5px',  // 모서리를 둥글게 조정
  position: 'absolute',
  top: 0,
  left: 0,
  transition: 'transform 0.3s ease',
  transform: 'translateX(0%)',  // 기본 위치
});

// 듀오 토글 옵션 스타일
export const ToggleOption = styled('div', {
  flex: 1,
  textAlign: 'center',
  lineHeight: '35px',  // 컨테이너 높이와 맞춤
  fontSize: '16px',
  fontWeight: 700,
  color: '#fff',
  zIndex: 1,

  variants: {
    active: {
      true: {
        color: '#fff',
      },
      false: {
        color: '#CCC',
      },
    },
  },
});
