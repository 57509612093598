import { makeAutoObservable } from 'mobx';
import UserInfoService from '../../../service/UserInfo/userInfoService';
import BaseError from '../../../models/baseError';
import { getBankIcon } from '../../../utils/bankIcon';
import { IUserInfo } from '../../../models/UserInfo/userInfoModel';

class UserInfoStore {
  memberUID: number = 0
  bankName: string = ''
  accountHolder: string = ''
  accountNumber: string = ''
  bankIconSrc: string = ''
  hasEmptyAccount: string = ''
  wdPassword: string = ''
  phoneNumber: string = ''
  isAccountDeleteModal: boolean = false
  isAccountChangeModal: boolean = false
  isLoading: boolean = false
  isErrorToast: boolean = false

  loginType: string = ''

  constructor() {
    makeAutoObservable(this)
  }

  setMemberUID(memberUID: number) {
    this.memberUID = memberUID
  }

  setBankName(bankName: string) {
    this.bankName = bankName
  }

  setAccountHolder(accountHolder: string) {
    this.accountHolder = accountHolder
  }

  setAccountNumber(accountNumber: string) {
    this.accountNumber = accountNumber
  }

  setBankIconSrc(bankIcon: string) {
    this.bankIconSrc = bankIcon
  }

  setHasEmptyAccount(hasEmptyAccount: string) {
    this.hasEmptyAccount = hasEmptyAccount
  }

  setWdPassword(password: string) {
    this.wdPassword = password
  }

  setPhoneNumber(phoneNumber: string) {
    this.phoneNumber = phoneNumber
  }

  setIsAccountDeleteModal(value: boolean) {
    this.isAccountDeleteModal = value
  }

  setIsAccountChangeModal(value: boolean) {
    this.isAccountChangeModal = value
  }

  setLoading(loading: boolean) {
    this.isLoading = loading
  }

  setIsErrorToast(errorToast: boolean) {
    this.isErrorToast = errorToast
  }

  async fetchUserInfoSearch(requestBody: IUserInfo, iv: string, salt: string) {
    this.setLoading(true)
    this.setIsErrorToast(false);

    try {
      const response = await UserInfoService.userInfoSearch(requestBody, iv, salt)

      if (response instanceof BaseError) {
        console.log(response.errorMessage)
        this.setIsErrorToast(true)
      } else {
        if (response.data.accountData) {
          const bankIcon = getBankIcon(response.data.accountData.bankCode)
          this.setBankName(response.data.accountData.bankName)
          this.setAccountHolder(response.data.accountData.accountHolder)
          this.setAccountNumber(response.data.accountData.accountNumber)
          this.setBankIconSrc(bankIcon)
          this.setHasEmptyAccount(response.data.memberData.accountYN)
          this.setWdPassword(response.data.memberData.wdPassword)
          this.setPhoneNumber(response.data.memberData.memberPhone)
          this.setMemberUID(response.data.memberData.memberUID)
        } else {
          console.error('No account data found');
        }
      }
    } catch (error) {
      console.error('사용자 정보를 가져오는 중 오류가 발생했습니다.', error);
    } finally {
      this.setLoading(false)
    }
  }
}

export const userInfoStore = new UserInfoStore();

