import { styled } from '@stitches/react';

export const DeleteAccountReasonContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  color: '#868686',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 700,
  textAlign: 'left',
  marginTop: '10px',
  lineHeight: 'normal',
})

export const DeleteAccountReasonText = styled('textarea', {
  borderRadius: '5px',
  border: '2px solid #E6E6E6',
  padding: '20px',
  height: '139px',
  marginTop: '10px',
  boxSizing: 'border-box',
  lineHeight: '15px',
  textAlign: 'left',
  resize: 'none',
  overflow: 'auto',
  color: '#868686',
  fontFamily: 'Pretendard',
  fontSize: '11px',
  fontStyle: 'normal',
  fontWeight: 700,
})