// App.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ToastProvider } from 'components/atoms/Toast/ToastProvider';
import { Provider } from 'mobx-react';
import ZMSLogin from './pages/ZMSLogin';
import AgreeTerms from './pages/AgreeTerms';
import Home from './pages/Home';
import SearchMyInfo from './pages/SearchMyInfo';
import NotificationCenter from './pages/NotificationCenter/NotificationCenterMain';
import ParkingUserList from './pages/ParkingUserList';
import ShareRequestMain from './pages/ShareRequestMain';
import ShareRequestMingan from './pages/ShareRequestMingan';
import ParkingPhotoUpload from './pages/ParkingPhotoUpload';
import Setting from './pages/Setting/SettingMainPage';
import ShareRequestGeoju from './pages/ShareRequestGeoju';
import MyPage from './pages/MyPage';
import PolicyServicePage from './pages/Policy/PolicyService';
import PolicyPersonalPage from './pages/Policy/PolicyPersonal';
import SettingLocationPolicy from './pages/Policy/PolicyLocation';
import PolicyMarketingPage from './pages/Policy/PolicyMarketing';
import CarRegistration from './pages/Car/CarRegistration';
import ReductionCategory from './pages/Reduction/ReductionCategory';
import ReductionFileAttach from './pages/Reduction/ReductionFileAttach';
import ReductionApplySuccess from './pages/Reduction/ReductionApplySuccess';
import ReductionRegistrationSuccess from './pages/Reduction/ReductionRegistrationSuccess';
import ReductionRegistrationFail from './pages/Reduction/ReductionRegistrationFail';
import CarInfo from './pages/Car/CarInfo';
import CarInfoEdit from './pages/Car/CareInfoEdit';
import ParkingPhotoUploadDetail from './pages/ParkingPhotoUpload/ParkingPhotoUploadDetail';
import ParkingPhotoUploadComplete from './pages/ParkingPhotoUpload/ParkingPhotoUploadComplete';
import NotificationCenterGeneralMessageDetail from './pages/NotificationCenter/NotificationCenterGeneralMessageDetail';
import NotificationCenterImportantMessageDetail from './pages/NotificationCenter/NotificationCenterImportantMessageDetail';
import DeleteAccount from './pages/DeleteAccount/DeleteAccountMain';
import DeleteAccountNotification from './pages/DeleteAccount/DeleteAccountNotification';
import DeleteAccountReason from './pages/DeleteAccount/DeleteAccountReason';
import SignUpCert from './pages/SignUp/SignUpCert';
import SignUpCreateUserInfo from './pages/SignUp/SignUpCreateUserInfo';
import PaymentUserInfoCheck from './pages/Payment/PaymentUserInfoCheck';
import PointHistory from './pages/Point/PointHistory';
import PointAccountRegi from './pages/Point/PointAccountRegi';
import PointWithdrawalPassword from './pages/Point/PointWithdrawal/PointWithdrawalPassword';
import PointEarnExpected from './pages/Point/PointEarnExpected';
import PointLoseExpected from './pages/Point/PointLoseExpected';
import PointWithdrawalIdentityCert from './pages/Point/PointWithdrawal/PointWithdrawalIdentityCert';
import PointWithdrawalMoney from './pages/Point/PointWithdrawal/PointWithdrawalMoney';
import PointWithdrawalFinalCheck from './pages/Point/PointWithdrawalFinal/PointWithdrawalFinalCheck';
import PointWithdrawalFinalSuccess from './pages/Point/PointWithdrawalFinal/PointWithdrawalFinalSuccess';
import PointMallBrand from './pages/Point/PointMall/PointMallBrand';
import PointWithdrawalPasswordSetting from './pages/Point/PointWithdrawal/PointWithdrawalPasswordSetting';
import PointMallBrandDetail from 'pages/Point/PointMall/PointMallBrandDetail';
import PointMallStorageBox from 'pages/Point/PointMall/PointMallStorageBox';
import PointWithdrawalPasswordSettingRe from 'pages/Point/PointWithdrawal/PointWithdrawalPasswordSettingRe';
import { toggleStore } from 'stores/toggle/toggleStore';
import PaymentVirtualAccount from './pages/Payment/PaymentVirtualAccount';
import MonthParking from './pages/MonthParking';
import TerraceTowerDetail from './pages/TerraceTower/TerraceTowerDetail';
import TerraceTowerPaymentCancel from './pages/TerraceTower/TerraceTowerPaymentCancel';
import TerraceTowerPaymentCancelRequest from './pages/TerraceTower/TerraceTowerPaymentCancelRequest';
import TerraceTowerReservationCaution from './pages/TerraceTower/TerraceTowerReservationCaution';
import TerraceTowerPayment from './pages/TerraceTower/TerraceTowerPayment';
import ExternalPayment from './pages/ExternalPayment';
import TerraceTowerPaymentResult from './pages/ExternalPayment';
import TerraceTowerDetailPage from './pages/TerraceTower/TerraceTowerDetail';
import TerraceTowerPaymentCancelRequestPage from './pages/TerraceTower/TerraceTowerPaymentCancelRequest';
import TerraceTowerReservationCautionPage from './pages/TerraceTower/TerraceTowerReservationCaution';
import TerraceTowerPaymentCancelPage from './pages/TerraceTower/TerraceTowerPaymentCancel';
import TerraceTowerPaymentPage from './pages/TerraceTower/TerraceTowerPayment';
import TerraceTowerPaymentResultPage from './pages/ExternalPayment';
import SettingMainPage from './pages/Setting/SettingMainPage';
import PolicyLocationPage from './pages/Policy/PolicyLocation';
import PolicyPersonalSupplyPage from 'pages/Policy/PolicyPersonalSupply';

function App() {
  return (
    <ToastProvider>
      <Provider toggleStore={toggleStore}>
        <Router>
          <Routes>
            // 포인트
            <Route path="/pointHistory" element={<PointHistory />} />
            <Route path="/pointAccountRegi" element={<PointAccountRegi />} />
            <Route path="/pointWithdrawal" element={<PointWithdrawalPassword />} />
            <Route path="/pointEarnExpected" element={<PointEarnExpected />} />
            <Route path="/pointLoseExpected" element={<PointLoseExpected />} />
            <Route path="/pointWithdrawalIdentityCert" element={<PointWithdrawalIdentityCert />} />
            <Route path="/pointWithdrawalPassword" element={<PointWithdrawalPassword />} />
            <Route path="/pointWithdrawalPasswordSetting" element={<PointWithdrawalPasswordSetting />} />
            <Route path="/pointWithdrawalPasswordSettingRe" element={<PointWithdrawalPasswordSettingRe />} />
            <Route path="/pointWithdrawalMoney" element={<PointWithdrawalMoney />} />
            <Route path="/pointWithdrawalFinalCheck" element={<PointWithdrawalFinalCheck />} />
            <Route path="/pointWithdrawalFinalSuccess" element={<PointWithdrawalFinalSuccess />} />
            <Route path="/pointMallBrand" element={<PointMallBrand />} />
            <Route path="/pointMallBrandDetail/:brandName" element={<PointMallBrandDetail />} />
            <Route path="/pointMallStorageBox" element={<PointMallStorageBox />} />
            <Route path="/zmsLogin" element={<ZMSLogin />} />
            <Route path="/agreeTerms" element={<AgreeTerms />} />
            <Route path="/signUpCert" element={<SignUpCert />} />
            <Route path="/signUpCreateUserInfo" element={<SignUpCreateUserInfo />} />
            <Route path="/home" element={<Home />} />
            <Route path="/searchMyInfo" element={<SearchMyInfo />} />
            <Route path="/parkingUserList" element={<ParkingUserList />} />
            <Route path="/shareRequestMain" element={<ShareRequestMain />} />
            <Route path="/shareRequestMingan" element={<ShareRequestMingan />} />
            <Route path="/shareRequestGeoju" element={<ShareRequestGeoju />} />
            <Route path="/notificationCenter" element={<NotificationCenter />} />
            <Route
              path="/notificationCenterGeneralMessageDetail"
              element={<NotificationCenterGeneralMessageDetail />}
            />
            <Route
              path="/notificationCenterImportantMessageDetail"
              element={<NotificationCenterImportantMessageDetail />}
            />
            <Route path="/settingMain" element={<SettingMainPage />} />
            <Route path="/myPage" element={<MyPage />} />
            <Route path="/policyPersonalSupply" element={<PolicyPersonalSupplyPage />} />
            <Route path="/policyService" element={<PolicyServicePage />} />
            <Route path="/policyPersonal" element={<PolicyPersonalPage />} />
            <Route path="/policyLocation" element={<PolicyLocationPage />} />
            <Route path="/policyMarketing" element={<PolicyMarketingPage />} />
            <Route path="/deleteAccount" element={<DeleteAccount />} />
            <Route path="/deleteAccountNotification" element={<DeleteAccountNotification />} />
            <Route path="/deleteAccountReason" element={<DeleteAccountReason />} />
            <Route path="/carRegistration" element={<CarRegistration />} />
            <Route path="/carInfo" element={<CarInfo />} />
            <Route path="/carInfoEdit" element={<CarInfoEdit />} />
            <Route path="/reductionCategory" element={<ReductionCategory />} />
            <Route path="/reductionFileAttach" element={<ReductionFileAttach />} />
            <Route path="/reductionApplySuccess" element={<ReductionApplySuccess />} />
            <Route path="/reductionRegistrationSuccess" element={<ReductionRegistrationSuccess />} />
            <Route path="/reductionRegistrationFail" element={<ReductionRegistrationFail />} />
            <Route path="/parkingPhotoUpload" element={<ParkingPhotoUpload />} />
            <Route path="/parkingPhotoUploadDetail" element={<ParkingPhotoUploadDetail />} />
            <Route path="/parkingPhotoUploadComplete" element={<ParkingPhotoUploadComplete />} />

            // 월주차
            <Route path="/monthParking" element={<MonthParking />} />

            // 결제
            <Route path="/paymentUserInfoCheck" element={<PaymentUserInfoCheck />} />
            <Route path="/paymentVirtualAccount" element={<PaymentVirtualAccount />} />

            // 테라스타워 상세
            <Route path="/terraceTowerDetail" element={<TerraceTowerDetailPage/>} />
            // 테라스타워 결제 취소 요청
            <Route path="/terraceTowerPaymentCancelRequest" element={<TerraceTowerPaymentCancelRequestPage/>} />
            // 테라스타워 결제 취소
            <Route path="/terraceTowerPaymentCancel" element={<TerraceTowerPaymentCancelPage/>} />
            // 테라스타워 예약 전 주의
            <Route path="/terraceTowerReservationCaution" element={<TerraceTowerReservationCautionPage/>} />
            // 테라스타워 결제
            <Route path="/terraceTowerPayment" element={<TerraceTowerPaymentPage/>} />

            // 세종결제
            <Route path="/terraceTowerPaymentResult" element={<TerraceTowerPaymentResultPage/>} />
          </Routes>
        </Router>
      </Provider>
    </ToastProvider>
  );
}

export default App;
