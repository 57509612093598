import { makeAutoObservable } from 'mobx';
import BaseError from '../../../models/baseError';
import terraceTowerService from '../../../service/TerraceTower/terraceTowerService';
import { ITerraceTowerProductData } from 'models/TerraceTower/terraceTowerProduct';

class TerraceTowerProductStore {
  isLoading = false
  error: string | null = null
  terraceTowerOrder: ITerraceTowerProductData | null = null

  constructor() {
    makeAutoObservable(this)
  }

  setLoading(value: boolean) {
    this.isLoading = value
  }

  setGetProduct(data: ITerraceTowerProductData) {
    this.terraceTowerOrder = data
  }

  async fetchTerraceTowerProduct(productUID: string) {
    const requestBody = {
      productUID,
    }

    this.setLoading(true)

    const response = await terraceTowerService.postTerraceTowerProduct(requestBody)

    if (response instanceof BaseError) {
      this.error = response.errorMessage
      this.setLoading(false)
      return false
    } else {
      const { data } = response
      if (data) {
        this.setGetProduct(data)
      }
      this.setLoading(false)
      return true
    }
  }
}
export const terraceTowerProductStore = new TerraceTowerProductStore()