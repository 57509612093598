import React, { useEffect } from 'react';
import { MainContainer } from '../../../../components/styles';
import Title from '../../../../components/atoms/Title/PageTitle';
import { useNavigate } from 'react-router-dom';
import imgShield from 'assets/images/img_shield.svg';
import imgLock from 'assets/images/img_lock.svg';
import {
  AccountNumberInfoContainer,
  AccountNumberSpan,
  BottomButton,
  MainColor,
  PointWithdrawalFinalSuccessContainer,
  PointWithdrawalFinalSuccessContentsContainer,
  WithdrawalCheckSpan,
  WithdrawalImg,
  WithdrawalSuccessContainer,
  WithdrawalUserInfoContainer,
  WithdrawalUserInfoDetailCheckContainer,
  WithdrawalUserInfoDetailCheckSpan,
  WithdrawalUserInfoDetailContainer,
} from './styles';
import { userInfoStore } from '../../../../stores/store/UserInfo/userInfoStore';
import icNotBank from 'assets/ic/ic_not_bank.svg';
import { observer } from 'mobx-react';
import LoadingSpinnerScreen from '../../../../components/atoms/LoadingSpinner/LoadingSpinnerScreen';
import { useFetchUserInfo } from '../../../../hooks/useFetchUserInfo';

const PointWithdrawalFinalSuccess = observer(() => {
  const navigate = useNavigate();
  const storedWithdrawalAmount = sessionStorage.getItem('withdrawalMoney')
  const { fetchUserInfo } = useFetchUserInfo()
  const numericWithdrawalAmount = storedWithdrawalAmount
    ? Number(storedWithdrawalAmount.replace(/[^0-9]/g, ''))
    : 0;

  const handleLeftOnClick = () => {
    navigate('/pointHistory');
  }

  const getWithdrawalImg = () => {
    return numericWithdrawalAmount >= 1000000 ? imgLock : imgShield;
  }

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        await fetchUserInfo()
        console.log('유저 정보 가져오기 성공')
      } catch (error) {
        console.error('유저 정보 가져오기 실패:', error)
      }
    }
    getUserInfo().catch((error) => {
      console.error('유저 정보 가져오기 실패:', error)
    })
  }, [])

  useEffect(() => {
    window.onBtnBackClicked = function () {
      handleLeftOnClick();
    };
    return () => {
      delete window.onBtnBackClicked;
    };
  }, []);

  if (userInfoStore.isLoading) {
    return <LoadingSpinnerScreen />;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <MainContainer style={{ flex: 1 }}>
        <Title
          title="출금"
          leftArrowButtonVisible={true}
          leftOnClick={handleLeftOnClick}
          rightTextButtonVisible={false}
        />
        <PointWithdrawalFinalSuccessContainer>
          <PointWithdrawalFinalSuccessContentsContainer>
            <WithdrawalImg src={getWithdrawalImg()} alt="출금 완료 아이콘" />
            <WithdrawalSuccessContainer>
              <span>{userInfoStore.bankName} 계좌로</span>
              <MainColor>&nbsp;{storedWithdrawalAmount}</MainColor>
            </WithdrawalSuccessContainer>
            {numericWithdrawalAmount >= 1000000 ? (
              <span>출금 신청 완료했어요</span>
            ) : (
              <span>출금 완료했어요</span>
            )}
            <WithdrawalUserInfoContainer>
              <WithdrawalUserInfoDetailContainer>
                <span>계좌번호</span>
                <WithdrawalUserInfoDetailCheckContainer>
                  <span>{userInfoStore.bankName}</span>
                  <AccountNumberInfoContainer>
                    <AccountNumberSpan>{userInfoStore.accountNumber}</AccountNumberSpan>
                  </AccountNumberInfoContainer>
                </WithdrawalUserInfoDetailCheckContainer>
              </WithdrawalUserInfoDetailContainer>
              <WithdrawalUserInfoDetailContainer
                style={{
                  marginTop: '11px',
                }}
              >
                <span>계좌주 명</span>
                <WithdrawalUserInfoDetailCheckSpan>
                  {userInfoStore.accountHolder}
                </WithdrawalUserInfoDetailCheckSpan>
              </WithdrawalUserInfoDetailContainer>
              <WithdrawalUserInfoDetailContainer
                style={{
                  marginTop: '11px',
                }}
              >
                <span>출금 신청</span>
                <WithdrawalUserInfoDetailCheckSpan>
                  <span>2024.09.10 14:43</span>
                </WithdrawalUserInfoDetailCheckSpan>
              </WithdrawalUserInfoDetailContainer>
              <WithdrawalUserInfoDetailContainer
                style={{
                  marginTop: '11px',
                }}
              >
                <span>남은 포인트</span>
                <WithdrawalUserInfoDetailCheckSpan>
                  <span>300</span>
                </WithdrawalUserInfoDetailCheckSpan>
              </WithdrawalUserInfoDetailContainer>
            </WithdrawalUserInfoContainer>
            {numericWithdrawalAmount >= 1000000 && (
              <WithdrawalCheckSpan>담당자 승인 후 출금 될 예정이에요.</WithdrawalCheckSpan>
            )}
          </PointWithdrawalFinalSuccessContentsContainer>
        </PointWithdrawalFinalSuccessContainer>
      </MainContainer>

      <BottomButton
        onClick={() => {
          navigate('/pointHistory');
        }}
      >
        확인
      </BottomButton>
    </div>
  );
});

export default PointWithdrawalFinalSuccess;
