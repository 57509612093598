import React from 'react';
import { ToggleContainer, Slider, ToggleOption } from './styles';

interface ToggleButtonProps {
  options: [string, string];  // 토글 옵션 텍스트
  selectedIndex: number;  // 선택된 인덱스
  content: [React.ReactNode, React.ReactNode];  // 각 옵션에 연결될 div 내용
  onToggle: (index: number) => void;  // 선택된 인덱스 변경 시 호출될 콜백 함수
}

const ToggleButton: React.FC<ToggleButtonProps> = ({
                                                     options,
                                                     selectedIndex,
                                                     onToggle,
                                                     content
                                                   }) => {
  // 인덱스 클릭 시 상태 변경 함수
  const handleToggle = (index: number) => {
    if (index !== selectedIndex) {
      onToggle(index);  // 새로운 인덱스를 전달하여 상태 변경
    }
  };

  // 슬라이더의 위치를 비율로 계산
  const sliderPosition = `${(selectedIndex / (options.length - 1)) * 100}%`;

  return (
      <div>
        <ToggleContainer>
          {options.map((option, index) => (
              <ToggleOption
                  key={index}
                  active={index === selectedIndex}
                  onClick={() => {
                    handleToggle(index);
                  }}
              >
                {option}
              </ToggleOption>
          ))}
          <Slider
              style={{ transform: `translateX(${sliderPosition})` }}  // 선택된 인덱스에 따라 슬라이더 위치 조정
          />
        </ToggleContainer>

        {/* 선택된 인덱스에 따라 각각 다른 div를 렌더링 */}
        <div>
          {content[selectedIndex]}
        </div>
      </div>
  );
};

export default ToggleButton;
