import React, { useState } from 'react';
import { TabMenu, Submenu, Desc } from './styles';

interface TabProps {
  menuArr: {
    name: string;
    content: React.ReactNode;
    ref?: React.RefObject<HTMLDivElement>;
  }[];
  currentTab: number; // 현재 탭 상태 추가
  onTabClick?: (index: number) => void; // onTabClick prop 추가
}

const Tab: React.FC<TabProps> = ({ menuArr, onTabClick }) => {
  const [currentTab, setCurrentTab] = useState(0);

  const selectMenuHandler = (index: number) => {
    setCurrentTab(index);

    // onTabClick 호출
    if (onTabClick) {
      onTabClick(index); // 상위 컴포넌트에 인덱스 전달
    }
  };

  return (
    <>
      <TabMenu>
        {menuArr.map((el, index) => (
          <Submenu
            key={index}
            focused={index === currentTab}
            onClick={() => selectMenuHandler(index)} // 클릭 시 인덱스 전달
          >
            {el.name}
          </Submenu>
        ))}
      </TabMenu>
      {/*<Desc>*/}
      {/*  <div>{menuArr[currentTab].content}</div> /!* 현재 탭의 콘텐츠 표시 *!/*/}
      {/*</Desc>*/}
    </>
  );
};

export default Tab;
