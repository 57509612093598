import React, {useEffect} from 'react';
import { ToastContainer, ToastContentsContainer, ToastImage, ToastMessage } from './styles';

interface ToastProps {
  message: string
  show: boolean
  onClose: () => void
  imageUrl?: string
}

const Toast: React.FC<ToastProps> = ({ message, show, onClose, imageUrl }) => {
  useEffect(() => {
    if (show) {
      const timer = setTimeout(onClose, 3000)
      return () => clearTimeout(timer)
    }
  }, [show, onClose]);

  return (
    <ToastContainer>
      <ToastContentsContainer isVisible={show}>
        {imageUrl && <ToastImage src={imageUrl} alt="Toast Image" />}
        <ToastMessage style={{ marginLeft: imageUrl ? '8px' : '0' }}>{message}</ToastMessage>
      </ToastContentsContainer>
    </ToastContainer>
  );
};

export default Toast;