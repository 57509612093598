import { makeAutoObservable } from 'mobx';
import { IPointExpiresData } from '../../../models/Point/pointExpiresModel';
import BaseError from '../../../models/baseError';
import pointExpiresService from '../../../service/Point/pointExpiresService';

class PointExpiresStore {
  isLoading = false
  error: string | null = null
  expiresSum: string = '0'
  expiresPoints: string = '0'
  expiresPays: string = '0'
  pointExpiresData: IPointExpiresData[] = []
  pageNum: number = 1
  isScrollLoading = false
  isEnd = false
  totalCount: number = 0

  constructor() {
    makeAutoObservable(this);
  }

  setLoading(value: boolean) {
    this.isLoading = value;
  }

  setScrollLoading(value: boolean) {
    this.isScrollLoading = value;
  }

  // 소멸 예정 포인트 데이터 불러오는 스토어
  async fetchPointExpires(mLinkUID: number, isScrollLoad: boolean = false) {
    if (isScrollLoad) {
      this.setScrollLoading(true);
    } else {
      this.setLoading(true);
      this.pageNum = 1
    }

    try {
      const response = await pointExpiresService.postPointExpires({
        mLinkUID: mLinkUID,
        pageNum: this.pageNum,
      })

      if (response instanceof BaseError) {
        this.error = response.errorMessage
        return
      }

      const { pointData, sumData } = response.data

      this.expiresSum = sumData.expiringSum
      this.expiresPoints = sumData.expiringPoints
      this.expiresPays = sumData.expiringPays

      if (pointData.length === 0) {
        console.log('소멸 예정 내역 데이터가 없습니다');
        this.isEnd = true
        return
      }

      if (isScrollLoad) {
        // 스크롤 로딩 시, 기존 데이터에 추가
        this.pageNum += 1 // 페이지 번호 증가
        this.pointExpiresData = [...this.pointExpiresData, ...pointData]
      } else {
        // 초기 로드 시 데이터 덮어쓰기
        this.pointExpiresData = pointData
        this.pageNum = 1
        console.log('데이터 덮어 쓰기')
      }
    } catch (error) {
      console.error('소멸 예정 데이터를 불러오는데 실패했습니다', error)
      this.error = '소멸 예정 데이터를 불러오는 데 실패했습니다.'
      throw error
    } finally {
      this.setLoading(false)
      this.setScrollLoading(false) // 스크롤 로딩 종료
    }
  }
}

export const pointExpiresStore = new PointExpiresStore();
