import React, { useState } from 'react';
import { Dialog } from './styles';
import Title from '../../Title/PageTitle';
import icSearch from '../../../../assets/ic/ic_search.svg';
import testNaverMap from '../../../../assets/images/img_test_map.png';
import CheckModal from '../CheckModal';

interface DesiredAreaBottomModalProps {
  isVisible: boolean
  onClose: () => void
  onSelectArea: (address: string) => void
  onSelectAreaSuccess: () => void
  onNotSelectArea: () => void
}

const searchAreaItems = [
  { placeText: 'ㄱ 카센터', addressText: '1' },
  { placeText: '기억 인테리어 디자인ㄱ', addressText: '1' },
  { placeText: '카페 ㄱ해', addressText: '1' },
  { placeText: '문화도시 청주 시민 아카이브', addressText: '1' },
];

const SearchRow = ({ index, style, onSelectAddress }: { index: number; style: React.CSSProperties; onSelectAddress: (address: string) => void }) => {
  const { placeText, addressText } = searchAreaItems[index];

  return (
    <div
      style={{
        color: '#000000',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        position: 'relative',
        ...style,
        boxSizing: 'border-box',
      }}
      onClick={() => {
        onSelectAddress(addressText);
      }}
    >
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
      }}>
        <span style={{
          fontSize: '13px',
          fontWeight: '700',
          lineHeight: 'normal',
          fontFamily: 'Pretendard',
          fontStyle: 'normal'
        }}>{placeText}</span>
        <span style={{
          fontSize: '11px',
          color: '#868686',
          fontWeight: '400',
          lineHeight: 'normal',
          fontFamily: 'Pretendard',
          fontStyle: 'normal'
        }}>{addressText}</span>
      </div>
    </div>
  );
};

const DesiredAreaBottomModal: React.FC<DesiredAreaBottomModalProps>
  = ({ isVisible, onClose, onSelectArea, onSelectAreaSuccess, onNotSelectArea }) => {
  const [isListVisible, setIsListVisible] = useState(true)
  const [searchText, setSearchText] = useState('')
  const [isDesiredAreaSearchSuccessModal, setIsDesiredAreaSearchSuccessModal] = useState(false)
  const [isNotDesiredAreaSearchModal, setIsNotDesiredAreaSearchModal] = useState(false)

  const handleSelectAddress = (address: string) => {
    onSelectArea(address);
    setIsListVisible(false);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchText(value);
    setIsListVisible(true);
  };

  return (
    <Dialog isVisible={isVisible}>
      <Title
        title="지역설정"
        leftArrowButtonVisible={true}
        leftOnClick={onClose}
        rightTextButtonVisible={true}
        rightOnClick={() => {
          setIsNotDesiredAreaSearchModal(true)
          setIsNotDesiredAreaSearchModal(true)
        }}
        rightTitle={'건너뛰기'}
      />
      <div
        style={{
          color: '#333',
          fontFamily: 'Pretendard',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '22px',
        }}
      >
        <span
          style={{
            color: '#8B63BB',
          }}
        >
          월 주차 희망지역
        </span>
        <span>을 알려주세요</span>
        <br />
        <span>희망 지역 인근 주차장이 있으면 알려드립니다!</span>
      </div>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
      }}>
        <div
          style={{
            height: '45px',
            background: '#F8F8FA',
            fontFamily: 'Pretendard',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: 'normal',
            borderRadius: '5px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingLeft: '10px',
            paddingRight: '10px',
            marginTop: '32px',
            color: '#868686',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <img
              style={{
                width: '20px',
                height: '20px',
              }}
              src={icSearch}
              alt={'검색아이콘'}
            />
            <input
              placeholder={'희망하는 주소를 검색하세요.'}
              value={searchText}
              onChange={handleInputChange}
              style={{
                border: 'none',
                outline: 'none',
                backgroundColor: '#F8F8FA',
                background: 'none',
                height: '100%',
                marginLeft: '18px',
                flexGrow: 1,
                padding: '12px',
                color: '#868686',
                fontFamily: 'Pretendard',
                fontSize: '16px',
                fontWeight: 700,
                lineHeight: 'normal',
              }}
            />
          </div>
        </div>
        {isListVisible ? (
          <div style={{
            marginTop: '20px'
          }}>
            {searchAreaItems.map((item, index) => (
              <SearchRow
                key={index}
                index={index}
                style={{ padding: '10px 0' }}
                onSelectAddress={handleSelectAddress}
              />
            ))}
          </div>
        ) : (
          <div style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            marginTop: '20px'
          }}>
            <img src={testNaverMap} alt={'네이버 테스트 지도'} />

            <button style={{
              width: '310px',
              height: '46px',
              background: '#8B63BB',
              borderRadius: '5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              color: '#FFF',
              marginTop: 'auto',
              marginBottom: '29px',
              fontFamily: 'Pretendard',
              border: 'none',
              fontSize: '14px',
              fontWeight: 'bold',
              position: 'absolute',
            }} onClick={() => {
              setIsDesiredAreaSearchSuccessModal(true);
            }}>
              이 위치로 설정
            </button>

          </div>
        )}
      </div>

      <CheckModal
        isVisible={isDesiredAreaSearchSuccessModal}
        onClose={() => {
          setIsDesiredAreaSearchSuccessModal(false)
          onSelectAreaSuccess()
        }}
        title={'지역 설정 안내'}
        content={'월 주차 희망지역이 설정 되었습니다.'}
        buttonText={'확인'}
      />
      <CheckModal
        isVisible={isNotDesiredAreaSearchModal}
        onClose={() => {
          setIsNotDesiredAreaSearchModal(false);
        }}
        title={'월 주차 희망지역 설정을 건너뛰기 할까요?'}
        content={
          <div style={{
            color: '#868686'
          }}>
            <div>
              메뉴 &gt; 마이페이지에서 재설정 가능합니다.
            </div>
            <div style={{
              marginTop: '16px',
              textDecoration: 'underline',
            }} onClick={() => {
              onNotSelectArea()
            }}>
              건너뛰기
            </div>
          </div>
        }
        buttonText={'계속 설정하기'}
      />
    </Dialog>
  );
}

export default DesiredAreaBottomModal;
