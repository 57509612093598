import React from 'react';
import {
  TwoButtonLeftButton, TwoButtonModalContainer,
  TwoButtonModalTitleSpan,
  TwoButtonRightButton,
} from './styles';

interface CheckModalProps {
  isVisible: boolean;
  onClose: () => void;
  title: string;
  content: React.ReactNode;
  leftButtonText: string;
  rightButtonText: string;
  rightClick: () => void;
}

const TowButtonModal: React.FC<CheckModalProps> = ({
                                                     isVisible,
                                                     onClose,
                                                     title,
                                                     content,
                                                     leftButtonText,
                                                     rightButtonText,
                                                     rightClick
                                                   }) => {
  if (!isVisible) return null;

  return (
      <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 9999,
          }}
          onClick={onClose}
      >
        <TwoButtonModalContainer onClick={(e) => e.stopPropagation()}>
          <div style={{ padding: '30px' }}>
            <TwoButtonModalTitleSpan style={{ whiteSpace: 'pre-line' }}>
              {title}
            </TwoButtonModalTitleSpan>
          </div>
          <div
              style={{
                color: '#868686',
                fontFamily: 'Pretendard',
                fontSize: '13px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
                paddingBottom: content ? '30px' : '0px',
                whiteSpace: 'pre-line',
          }}>
            {content}
          </div>
          <div style={{ display: 'flex', width: '100%' }}>
            <TwoButtonLeftButton onClick={onClose}>
              {leftButtonText}
            </TwoButtonLeftButton>
            <TwoButtonRightButton onClick={() => { rightClick(); onClose();}}> {rightButtonText} </TwoButtonRightButton>
          </div>
        </TwoButtonModalContainer>
      </div>
  );
};

export default TowButtonModal;
