import {
  DateSpan,
  EmptyHistoryContainer,
  PointDetailContainer,
  PointInfoContainer,
  PointStatusContainer,
  PointStatusSpan,
  SpinnerContainer,
  ViewDivideContainer,
} from './styles';
import React, { useEffect, useRef } from 'react';
import { pointPendingStore } from '../../../stores/store/Point/pointPendingStore';
import { CommentKeySpan, CommentValue } from '../PointHistoryBottomList/styles';
import { Spinner } from '../../../components/atoms/LoadingSpinner/LoadingSpinnerImage/styles';
import { observer } from 'mobx-react';
import { LINK_UID } from '../../../constants/userInfoConstants';
import { pointExpiresStore } from '../../../stores/store/Point/pointExpiresStore';
import { ICommentPending } from '../../../models/Point/pointPendingModel';

const PointEarnExpectedList = observer(() => {
  const containerRef = useRef<HTMLDivElement>(null)
  const mLinkUID = parseInt(sessionStorage.getItem(LINK_UID) || '0', 10)

  useEffect(() => {
    const fetchInitPendingList = async ()  => {
      try {
        await pointPendingStore.fetchPointPending(mLinkUID, false)
      } catch (error) {
        console.error('적립 예정 초기화 로드 에러:', error)
      }
    }
    fetchInitPendingList().catch((error) => {
      console.log('소멸 예정 포인트 초기화 로드 에러', error)
    })
  }, [])

  // 스크롤시 다음 데이터 로드
  const handleScroll = async () => {
    const { scrollTop, scrollHeight, clientHeight } = document.documentElement;

    if (scrollTop + clientHeight >= scrollHeight - 5 && !pointPendingStore.isLoading && !pointPendingStore.isEnd) {
      try {
        await pointPendingStore.fetchPointPending(mLinkUID, true)
      } catch (error) {
        console.error('데이터 로드 에러:', error);
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])



  return (
    <div ref={containerRef}>
      {pointPendingStore.isLoading && !pointPendingStore.isScrollLoading ? (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      ) : pointPendingStore.pointPendingData.length === 0 ? (
        <EmptyHistoryContainer>적립 예정 내역이 없어요.</EmptyHistoryContainer>
      ) : (

        pointPendingStore.pointPendingData.map((pointPending, index) => {
          let parsedCommentPending: ICommentPending = {}

          if (typeof pointPending.comment === 'string') {
            try {
              parsedCommentPending = JSON.parse(pointPending.comment)
            } catch (error) {
              console.error('Fail to parse comment:', error)
            }
          } else {
            console.error('포인트 적립 예정 객체 스트링 변환 실패', pointPending.comment)
          }

          const comments = Object.entries(parsedCommentPending).map(([key, value]) => (
            <div key={key}>
              <CommentKeySpan>{key}</CommentKeySpan>
              <CommentValue>{value}</CommentValue>
            </div>
          ))

          return (
            <PointInfoContainer key={index}>
              <DateSpan>{pointPending.confirmed_at}</DateSpan>
              <PointStatusContainer>
                <PointStatusSpan>{pointPending.status}</PointStatusSpan>
              </PointStatusContainer>
              <PointDetailContainer>{comments}</PointDetailContainer>
              <ViewDivideContainer />
            </PointInfoContainer>
          )
        })
      )}
    </div>
  );
});

export default PointEarnExpectedList;