import BaseService from '../baseService';
import BaseError from '../../models/baseError';
import AxiosClient from '../../utils/axiosClient';
import ErrorHandler from '../../utils/error/errorHandler';
import { IPointAccountRegi, IPointAccountRegiResponse } from '../../models/Point/pointAccountRegiModel';

interface PointAccountRegiEndpoints {
  POINT_ACCOUNT_REGI_CHANGE: string
  POINT_ACCOUNT_DELETE: string
}

class PointAccountRegiService extends BaseService<PointAccountRegiEndpoints> {
  apiEndpoints: PointAccountRegiEndpoints = {
    POINT_ACCOUNT_REGI_CHANGE: 'updateAccount',
    POINT_ACCOUNT_DELETE: 'deleteAccount',
  }

  postPointAccountRegiChange = async (requestBody: IPointAccountRegi): Promise<IPointAccountRegiResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .data({...requestBody})
        .url(`${this.apiEndpoints.POINT_ACCOUNT_REGI_CHANGE}`)
        .build<IPointAccountRegiResponse>();

      if (response.data.res_code === 200) {
        return response.data
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError()
      }
    } catch (error) {
      console.error(error)
      return ErrorHandler.create(error).getError()
    }
  }

  postPointAccountDelete = async (requestBody: IPointAccountRegi): Promise<IPointAccountRegiResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .data({...requestBody})
        .url(`${this.apiEndpoints.POINT_ACCOUNT_DELETE}`)
        .build<IPointAccountRegiResponse>();
      if (response.data.res_code === 200) {
        return response.data
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError()
      }
    } catch (error) {
      console.error(error)
      return ErrorHandler.create(error).getError()
    }
  }
}

export default new PointAccountRegiService()
