import { MainContainer } from "components/styles";
import Title from "../../../../components/atoms/Title/PageTitle";
import CouponBottomModal from "../../../../components/atoms/Modal/CouponBottomModal";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import { PointMallStorageBoxContainer, Spacer } from './styles';
import PointMallStorageBoxList from "../PointMallStorageBoxList";

const PointMallStorageBox = () => {
  const navigate = useNavigate()
  const [isCouponNotesModal, setIsCouponNotesModal] = useState(false)

  const handleCouponNotes = () => {
    setIsCouponNotesModal(true)
  }

  useEffect(() => {
    window.scroll(0, 0);

    window.onBtnBackClicked = function() {
      navigate(-1)
    }

    // 스크롤 비활성화
    if (isCouponNotesModal) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }

    return () => {
      delete window.onBtnBackClicked
      document.body.style.overflow = 'auto'
    }
  }, [isCouponNotesModal])

  return (
    <PointMallStorageBoxContainer>
      <Title
        title="쿠폰 보관함"
        leftArrowButtonVisible={true}
        leftOnClick={() => {
          navigate(-1);
        }}
        rightTextButtonVisible={true}
        rightOnClick={() => {
          handleCouponNotes();
        }}
        rightTitle={"유의사항"}
        showSpacer={false}
      />
      <Spacer/>

      <PointMallStorageBoxList/>
      <CouponBottomModal
        isVisible={isCouponNotesModal}
        onClose={() => {
          setIsCouponNotesModal(false);
        }}
      />
    </PointMallStorageBoxContainer>
  );
};

export default PointMallStorageBox
