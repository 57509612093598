import { styled } from '@stitches/react';

export const CameraFileUploadContainer = styled('div', {
  height: '100%',
  borderRadius: '4px',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
  gap: '6px',
  fontWeight: 400,
  lineHeight: '20px',
  letterSpacing: '-0.4px',
  fontSize: '14px',
  fontFamily: 'Pretendard',
  backgroundColor: '#F8F8FA'
});