import React, { useState } from "react";
import {useNavigate} from "react-router-dom";
import Title from "../../../components/atoms/Title/PageTitle";
import {MainContainer} from 'components/styles';
import { useToast } from 'components/atoms/Toast/ToastProvider';
import SettingServiceList from "../SettingServiceList";
import SettingTermsList from "../SettingTermsList";
import SettingAppInfoList from "../SettingAppInfoList";

const SettingMainPage: React.FC = () => {
  const navigate = useNavigate()

  const handleLeftOnClick = () => {
    navigate(-1);
  };

  const { showToast } = useToast();

  return (
      <MainContainer>
        <Title
            title="환경설정"
            leftArrowButtonVisible={true}
            leftOnClick={handleLeftOnClick}
            rightTextButtonVisible={false}
        />
        <SettingServiceList/>
        <SettingTermsList/>
        <SettingAppInfoList/>
    </MainContainer>
  )
}

export default SettingMainPage