import { styled } from '@stitches/react';


export const ParkingPhotoUploadChoiceContainer = styled('span', {
  marginBottom: '46px', textAlign: 'center'
})

export const ParkingPhotoUploadChoiceSpan = styled('span', {
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  color: '#8B63BB',
})

export const ParkingRegistrationItemContainer = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  paddingBottom: '15px',
  boxSizing: 'border-box',
  borderBottom: '1px solid #E0E0E0',
  marginTop: '15px',
})

export const ParkingNameButtonContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
})

export const ParkingNameContainer = styled('span', {
  color: '#1A1A1A',
  fontFamily: 'Pretendard',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '20px',
  marginTop: '5px'
})

export const RegistrationButton = styled('button', {
  width: '60px',
  height: '28px',
  backgroundColor: '#8B63BB',
  color: '#fff',
  borderRadius: '5px',
  border: 'none',
  position: 'relative',
})


export const ParkingAddressSpan = styled('span', {
  color: '#868686',
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontWeight: 700,
  lineHeight: '20px',
  marginTop: '19px',
  textAlign: 'left',
})
