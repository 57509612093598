import { styled } from '@stitches/react';
import PointWithdrawalFinalSuccess from './index';

export const PointWithdrawalFinalSuccessContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  padding: '20px',
  justifyContent: 'center',
})

export const PointWithdrawalFinalSuccessContentsContainer = styled('div', {
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  color: '#555',
  fontFamily: 'Pretendard',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  flexDirection: 'column',
})

export const WithdrawalImg = styled('img', {
  marginTop: '30px'
})

export const WithdrawalSuccessContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  marginTop: '20px',
})

export const MainColor= styled('span', {
  color: '#8B63BB',
})

export const WithdrawalUserInfoContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  marginTop: '40px',
  backgroundColor: '#F8F8FA',
  width: '100%',
  justifyContent: 'space-between',
  padding: '20px',
  borderRadius: '5px',
})

export const WithdrawalUserInfoDetailContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  color: '#868686',
  fontFamily: 'Pretendard',
  alignItems: 'center',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
})

export const WithdrawalUserInfoDetailCheckContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '4px',
  color: '#6B6B6B',
  fontFamily: 'Pretendard',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
})

export const WithdrawalUserInfoDetailCheckSpan = styled('span', {
  color: '#6B6B6B',
  fontFamily: 'Pretendard',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
})

export const AccountNumberInfoContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
})

export const AccountNumberSpan = styled('span', {
  wordBreak: 'break-word',
  maxWidth: '150px',
  textAlign: 'left',
})

export const BankIcon = styled('img', {
  width: '28px',
  height: '28px',
})

export const BottomButton = styled('button', {
  height: '46px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  border: 'none',
  backgroundColor: '#8B63BB',
  color: '#fff',
  borderRadius: '4px',
  padding: '10px 20px',
  fontSize: '16px',
  marginTop: 'auto',
  marginBottom: '40px',
  margin: '40px 20px',
})

export const WithdrawalCheckSpan = styled('span', {
  color: '#8B63BB',
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  marginTop: '20px'
})



