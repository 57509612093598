import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import Toast from '../Toast';

interface ToastContextProps {
  showToast: (message: string, imageUrl?: string) => void;
}

const ToastContext = createContext<ToastContextProps | undefined>(undefined);

export const useToast = (): ToastContextProps => {
  const context = useContext(ToastContext);
  if (context === undefined) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};

interface ToastProviderProps {
  children: ReactNode;
}

export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  const [toast, setToast] = useState<{
    message: string;
    show: boolean;
    imageUrl?: string;
  }>({
    message: '',
    show: false,
    imageUrl: undefined,
  })

  const showToast = (message: string, imageUrl?: string) => {
    setToast({ message, show: true, imageUrl })
  }

  const hideToast = () => {
    setToast((prev) => ({ ...prev, show: false }))
  }

  useEffect(() => {
    if (toast.show) {
      const timer = setTimeout(hideToast, 3000)
      return () => clearTimeout(timer)
    }
  }, [toast.show])

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <Toast
        message={toast.message}
        show={toast.show}
        onClose={hideToast}
        imageUrl={toast.imageUrl}
      />
    </ToastContext.Provider>
  );
};
