import { MainContainer } from '../../../../components/styles';
import Title from '../../../../components/atoms/Title/PageTitle';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import icQuestionWhite from '../../../../assets/ic/ic_question_white.svg';
import {
  BrandListContainer,
  BranTitleSpan,
  CouponContainer,
  CouponSpan,
  GradientContainer,
  ImpossibleMoneyContentContainer,
  MoneyCouponContainer,
  MoneyPossibleContainer,
  MoneySpan,
  PointMallBrandContainer,
} from './styles';
import PointMallBrandList from '../PointMallBrandList';
import NotificationModal from 'components/atoms/Modal/NotificationModal';
import Spacer from '../../../../components/atoms/Spacer/Spacer';
import { observer } from 'mobx-react';
import { brandListStore } from '../../../../stores/store/Point/brandListStore';

const PointMallBrand = observer(() => {
  const [isPossibleMoneyModal, setIsPossibleMoneyModal] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    window.onBtnBackClicked = function () {
      navigate('/pointHistory');
    };
    return () => {
      delete window.onBtnBackClicked;
    };
  }, []);

  return (
    <PointMallBrandContainer>
      <Title
        title="주만사 포인트몰"
        leftArrowButtonVisible={true}
        leftOnClick={() => {
          navigate('/pointHistory');
        }}
        background={'purpleGradient'}
        showSpacer={false}
        rightTextButtonVisible={false}
        titleColor={'white'}
        imgBackPurpleColor={false}
      />

      <GradientContainer>
        <div>
          <MoneyPossibleContainer
            onClick={() => {
              setIsPossibleMoneyModal(true);
            }}
          >
            <span>사용 가능 금액</span>
            <img src={icQuestionWhite} alt={'물음표 아이콘'} />
          </MoneyPossibleContainer>
          {isPossibleMoneyModal && (
            <NotificationModal
              isVisible={isPossibleMoneyModal}
              onClose={() => {
                setIsPossibleMoneyModal(false);
              }}
              title="사용 가능 금액이란?"
              content={
                <ImpossibleMoneyContentContainer>
                  <span>
                    민간 주차 공간 공유하여 생기는 수익금과
                    <br />
                    주만사 포인트가 합쳐진 금액을 의미해요.
                  </span>
                </ImpossibleMoneyContentContainer>
              }
            />
          )}
        </div>

        <MoneyCouponContainer>
          <MoneySpan>{brandListStore.useAvailPoints}</MoneySpan>
          <CouponContainer
            onClick={() => {
              navigate('/pointMallStorageBox')
            }}
          >
            <CouponSpan>보유 쿠폰 {brandListStore.couponCount}</CouponSpan>
          </CouponContainer>
        </MoneyCouponContainer>
      </GradientContainer>
      <BrandListContainer>
        <BranTitleSpan>이런 브랜드가 있어요</BranTitleSpan>
        <PointMallBrandList />
      </BrandListContainer>
    </PointMallBrandContainer>
  )
})

export default PointMallBrand;
