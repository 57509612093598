import { AxiosError } from 'axios';
import BaseError from 'models/baseError';
import ErrorCodeMap from 'constants/errorCodeMap';
import ErrorParser from 'utils/error/errorParser';

type ErrorHandleTask = (errorData: BaseError) => void;

export default class ErrorHandler {
  private _error: BaseError = new BaseError(ErrorCodeMap.UNKNOWN);

  static create(error: unknown): ErrorHandler {
    const errorHandler = new ErrorHandler();

    if (error instanceof AxiosError) {
      // AxiosError일 경우
      errorHandler.error = ErrorParser.parseError(error);
    } else {
      // AxiosError가 아닐 경우, UNKNOWN 에러로 처리
      errorHandler.error = new BaseError(ErrorCodeMap.UNKNOWN);
    }

    return errorHandler;
  }

  getError(): BaseError {
    return this._error;
  }

  private set error(error: BaseError) {
    this._error = error;
  }

  private executeCallback(
    targetError: BaseError,
    commonCallback: ErrorHandleTask,
    customCallback?: ErrorHandleTask,
  ): ErrorHandler {
    const isSameError = this._error?.status === targetError.status;

    if (!isSameError) return this;

    const task = customCallback || commonCallback;
    task(this._error);

    return this;
  }

  handleBadRequest(callback?: ErrorHandleTask): ErrorHandler {
    const commonHandler = (error: BaseError) => {
      // TODO: should notify an error to the user via toast or modal dialog.
      console.log({ message: error.errorMessage });
    };

    return this.executeCallback(new BaseError(ErrorCodeMap.BAD_REQUEST), commonHandler, callback);
  }

  handleUnAuthorization(callback?: ErrorHandleTask): ErrorHandler {
    const commonHandler = () => {
      // 에러 발생시 어디로 보낼지 세팅
      // window.location.href = '/login';
    };

    return this.executeCallback(new BaseError(ErrorCodeMap.UNAUTHORIZED), commonHandler, callback);
  }

  handleForbiddenError(callback?: ErrorHandleTask): ErrorHandler {
    const commonHandler = (error: BaseError) => {
      // TODO: should notify an error to the user via toast or modal dialog.
      console.log({ message: error.errorMessage });
    };

    return this.executeCallback(new BaseError(ErrorCodeMap.FORBIDDEN), commonHandler, callback);
  }

  handleNotFound(callback?: ErrorHandleTask): ErrorHandler {
    const commonHandler = (error: BaseError) => {
      // TODO: should notify an error to the user via toast or modal dialog.
      console.log({ message: error.errorMessage });
    };

    return this.executeCallback(new BaseError(ErrorCodeMap.NOT_FOUND), commonHandler, callback);
  }

  handleInternalServerError(callback?: ErrorHandleTask): ErrorHandler {
    const commonHandler = (error: BaseError) => {
      // TODO: should notify an error to the user via toast or modal dialog.
      console.log({ message: error.errorMessage });
    };

    return this.executeCallback(
      new BaseError(ErrorCodeMap.INTERNAL_SERVER_ERROR),
      commonHandler,
      callback,
    );
  }

  handleGatewayTimeout(callback?: ErrorHandleTask): ErrorHandler {
    const commonHandler = (error: BaseError) => {
      // TODO: should notify an error to the user via toast or modal dialog.
      console.log({ message: error.errorMessage });
    };

    return this.executeCallback(
      new BaseError(ErrorCodeMap.GATEWAY_TIMEOUT),
      commonHandler,
      callback,
    );
  }

  handleNetworkTimeout(callback?: ErrorHandleTask): ErrorHandler {
    const commonHandler = (error: BaseError) => {
      // TODO: should notify an error to the user via toast or modal dialog.
      console.log({ message: error.errorMessage });
    };

    return this.executeCallback(
      new BaseError(ErrorCodeMap.NETWORK_TIMEOUT),
      commonHandler,
      callback,
    );
  }

  handleUnknown(callback?: ErrorHandleTask): ErrorHandler {
    const commonHandler = (error: BaseError) => {
      // TODO: should notify an error to the user via toast or modal dialog.
      console.log({ message: error.errorMessage });
    };

    return this.executeCallback(new BaseError(ErrorCodeMap.UNKNOWN), commonHandler, callback);
  }
}