// styles.ts
import { styled } from '@stitches/react';

export const SettingTermsListContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

export const SectionHeaderContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  textAlign: 'left',
  borderBottom: '1px solid #E6E6E6',
  paddingBottom: '10px',
  marginTop: '12px',
  fontFamily: 'Pretendard',
  fontWeight: 400,
  fontSize: '14px',
  fontStyle: 'normal',
  lineHeight: 'normal',
});

export const ItemListContainer = styled('div', {
  flexGrow: 1,
  overflowY: 'auto',
  maxHeight: '200px',
});

export const ItemContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  position: 'relative',
  padding: '10px 0',
  boxSizing: 'border-box',
});

export const ItemTextContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
});

export const ItemText = styled('span', {
  fontSize: '13px',
  fontWeight: '400',
  lineHeight: 'normal',
  fontFamily: 'Pretendard',
  fontStyle: 'normal',
  color: '#868686',
});

export const ArrowIcon = styled('img', {
  width: '5px',
  height: '8px',
  marginLeft: '10px',
  alignSelf: 'center',
});
