import { useEffect, useState } from 'react';

const useUserInfo = () => {
  const [memberID, setMemberID] = useState('');
  const [memberPW, setMemberPW] = useState('');
  const [loginType, setLoginType] = useState('');

  const requestUserInfo = () => {
    let platform = '';

    const browserInfo = navigator.userAgent

    if (browserInfo.indexOf("arsparking_android") > -1) {
      platform = 'AOS'
      if (window.arsparkingapp) {
        console.log('Android 인터페이스를 사용가능')
        window.arsparkingapp.getUserInfo()
      } else {
        console.error('Android 인터페이스를 사용할 수 없습니다. 웹 혹은 iOS 에서 열었습니다.')
      }
    } else if (browserInfo.indexOf("arsparking_ios") > -1) {
      platform = 'IOS'; // 플랫폼을 iOS로 설정
      if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.arsparkingapp) {
        console.log('iOS 인터페이스를 사용가능');
        window.webkit.messageHandlers.arsparkingapp.postMessage({
          func: 'getUserInfo'
        })
      } else {
        console.error('iOS 인터페이스를 사용할 수 없습니다. 웬 혹은 Android 에서 열었습니다.')
      }
    }

    if (platform === 'AOS') {
      const appleElement = document.getElementById("APPLE");
      if (appleElement) {
        appleElement.style.display = "none"
      }
    }
  }

  window.setRegularInfo = (id: string, pw: string, loginType: string, token: string, appVersion: string) => {
    setMemberID(id)
    setMemberPW(pw)
    setLoginType(loginType)
  }

  useEffect(() => {
    requestUserInfo()
  }, [])

  return { memberID, memberPW, loginType }
}

export default useUserInfo;
