import Title from '../../../components/atoms/Title/PageTitle';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import icReceiptDocument from '../../../assets/ic/ic_receipt_document.svg'
import {
  ParkingInfoDetailContainer,
  ParkingTitleSpan, PaymentAmountSpan,
  TimeOperatingHoursSpan,
  ViewDivide, ViewDivide2,
} from '../PaymentUserInfoCheck/styles';
import {
  OperatingHourContainer, PaymentAccountDepositContentContainer,
  WeekContainer, WeekInfoContainer,
} from '../../../components/atoms/Modal/MonthParkingInfoDetailBottomModal/MonthParkingInfoDetail/styles';

const PaymentVirtualAccount = () => {
  const navigate = useNavigate();

  const handleLeftOnClick = () => {
    navigate('/home');
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          margin: '0 20px',
        }}
      >
        <Title
          title="결제"
          leftArrowButtonVisible={true}
          leftOnClick={handleLeftOnClick}
          rightTextButtonVisible={false}
        />
        <div
          style={{
            display: 'flex',
            textAlign: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            marginTop: '20px',
            height: 'auto',
            backgroundColor: '#F8F8FA',
          }}
        >
          <img src={icReceiptDocument} alt={'출금 계좌 아이콘'} style={{ marginTop: '20px' }} />
          <div style={{
            margin: '14px 14px'
          }}>
            <span>해당 가상계좌는 2023년 12월 10일 11시 59분 이내 입금 시 주문 완료 됩니다</span>
          </div>
        </div>

        <ParkingInfoDetailContainer>
          <ParkingTitleSpan>은행명</ParkingTitleSpan>
          <TimeOperatingHoursSpan>SC제일은행</TimeOperatingHoursSpan>
        </ParkingInfoDetailContainer>

        <OperatingHourContainer>
          <ParkingTitleSpan>운영시간</ParkingTitleSpan>
          <PaymentAccountDepositContentContainer>
            <TimeOperatingHoursSpan>1048801046</TimeOperatingHoursSpan>
            <WeekContainer>복사</WeekContainer>
          </PaymentAccountDepositContentContainer>
        </OperatingHourContainer>

        <ParkingInfoDetailContainer>
          <ParkingTitleSpan>예금주</ParkingTitleSpan>
          <TimeOperatingHoursSpan>주차장만드는사람들</TimeOperatingHoursSpan>
        </ParkingInfoDetailContainer>

        <ViewDivide2 />

        <ParkingInfoDetailContainer>
          <ParkingTitleSpan>최종 결제금액</ParkingTitleSpan>
          <PaymentAmountSpan>120,000원</PaymentAmountSpan>
        </ParkingInfoDetailContainer>
      </div>
    </div>
  );
};

export default PaymentVirtualAccount;