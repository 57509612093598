import AxiosClient from '../../utils/axiosClient'
import { IBank, IBankListResponse } from '../../models/Bank/bankListModel';
import BaseService from '../baseService';
import BaseError from '../../models/baseError';
import ErrorHandler from '../../utils/error/errorHandler';
import { Simulate } from 'react-dom/test-utils';
import error = Simulate.error;

interface BankListEndpoints {
  BANK_LIST: string
}
class BankService extends BaseService<BankListEndpoints>{
  apiEndpoints: BankListEndpoints = {
    BANK_LIST: 'getBanks'
  }

  getBankList = async (): Promise<IBankListResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .get()
        .url(`${this.apiEndpoints.BANK_LIST}`)
        .build<IBankListResponse>();

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  }
}

export default new BankService();
