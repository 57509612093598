import React, { useEffect, useState } from 'react';
import {
  CarNumberBottomButtonContainer,
  CarNumberCheckModalContainer,
  CarNumberCheckModalMainContainer,
  CarNumberMistakeContainer,
  ModalOverlay,
  ModifyLeftButton,
  NumberCheckRightButton,
} from './styles';
import icDotBlack from '../../../../assets/ic/ic_dot_black.svg';
import {
  CarNumberBackGroundContainer,
  CarNumberOutlineContainer,
  CarNumberText,
  LeftDotImg,
  RightDotImg,
} from 'pages/Car/styles';
import { INNOPAY_SCRIPT_URL, JQUERY_SCRIPT_URL } from 'constants/baseConstants';
import { Caption1, Headline } from 'styles/typography';

interface BottomModalProps {
  isVisible: boolean;
  onClose: () => void;
  carNumber: string;
  modifyCarNumber: () => void;
  handlePayment: () => void;
}

const CarNumberCheckModal: React.FC<BottomModalProps> = ({
  isVisible,
  onClose,
  carNumber,
  modifyCarNumber,
  handlePayment,
}) => {
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    const jQueryScript = document.createElement('script');
    jQueryScript.src = JQUERY_SCRIPT_URL;
    jQueryScript.async = true;

    jQueryScript.onload = () => {
      console.log('jQuery Script loaded.');
      setScriptLoaded(true); // 스크립트 로드 성공
    };

    jQueryScript.onerror = () => {
      console.error('Failed to load jQueryScript');
      setScriptLoaded(false); // 스크립트 로드 실패
    };

    // 외부 스크립트 로드
    const script = document.createElement('script');
    script.src = INNOPAY_SCRIPT_URL;
    script.async = true;

    script.onload = () => {
      console.log('Innopay script loaded.');
      setScriptLoaded(true); // 스크립트 로드 성공
    };

    script.onerror = () => {
      console.error('Failed to load Innopay script.');
      setScriptLoaded(false); // 스크립트 로드 실패
    };

    document.body.appendChild(jQueryScript);
    document.body.appendChild(script);

    return () => {
      // 컴포넌트 언마운트 시 스크립트 제거
      document.body.removeChild(jQueryScript);
      document.body.removeChild(script);
    };
  }, []);

  const handleClose = () => {
    onClose();
  };

  if (!isVisible) return null;

  return (
    <>
      <ModalOverlay onClick={handleClose} />
      <CarNumberCheckModalContainer isVisible={isVisible}>
        <CarNumberCheckModalMainContainer>
          <Headline weight={'semibold'} color={'black1'}>
            차량번호를 확인해 주세요!
          </Headline>
          <CarNumberBackGroundContainer>
            <CarNumberOutlineContainer>
              <LeftDotImg src={icDotBlack} alt={'왼쪽 점 아이콘'} />
              <CarNumberText>{carNumber}</CarNumberText>
              <RightDotImg src={icDotBlack} alt={'점 아이콘2'} />
            </CarNumberOutlineContainer>
          </CarNumberBackGroundContainer>

          <CarNumberMistakeContainer>
            <Caption1 weight={'regular'} color={'red'}>
              차량번호 오기입, 합의 되지 않은 타인 명의의 차량번호
            </Caption1>
            <Caption1 weight={'regular'} color={'black3'}>
              등으로 인해 발생하는 민형사상의 법적 책임은 본인에게 있습니다.
            </Caption1>
          </CarNumberMistakeContainer>
          <CarNumberBottomButtonContainer>
            <ModifyLeftButton
              onClick={() => {
                handleClose();
                modifyCarNumber();
              }}
            >
              수정할래요
            </ModifyLeftButton>
            <NumberCheckRightButton onClick={handlePayment} disabled={!scriptLoaded}>
              번호 맞아요
            </NumberCheckRightButton>
          </CarNumberBottomButtonContainer>
        </CarNumberCheckModalMainContainer>
      </CarNumberCheckModalContainer>
    </>
  );
};

export default CarNumberCheckModal;
