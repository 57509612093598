import { styled } from '@stitches/react';

export const CarInfoRepresentMainContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
})

export const CarInfoRepresentContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontFamily: 'Pretendard',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '22px',
  margin: '0 25px'
})

export const CarInfoRepresentSpan = styled('span', {
  fontFamily: 'Pretendard',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '22px',
  color: '#333',
})

export const ToggleButtonContainer = styled('div', {
  width: '40px',
  height: '20px',
  borderRadius: '20px',
  backgroundColor: '#ccc',
  position: 'relative',
  transition: 'background-color 0.3s',
  cursor: 'pointer',

  '&:before': {
    content: '',
    width: '18px',
    height: '18px',
    borderRadius: '50%',
    backgroundColor: 'white',
    position: 'absolute',
    top: '1px',
    left: '1px',
    transition: 'left 0.3s',
  },

  variants: {
    isToggled: {
      true: {
        backgroundColor: '#8B63BB',

        '&:before': {
          left: '20px',
        },
      },
      false: {
        backgroundColor: '#ccc',

        '&:before': {
          left: '1px',
        },
      },
    },
  },
});

export const ViewDivideContainer = styled('div', {
  width: '100%',
  height: '8px',
  backgroundColor: '#CCC',
  marginTop: '26px',
})

export const ParkingReductionContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontFamily: 'Pretendard',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '18px',
  color: '#6B6B6B',
  width: '100%',
})

export const ParkingReductionStatus = styled('span', {
  color: '#333',
  fontFamily: 'Inter',
  fontSize: '17px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '24px'
})

export const ModifyDeleteContainer = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
})

export const LengthDividerSpan = styled('span', {
  margin: '0 10px'
})

export const CarInfoRegistrationSpan = styled('span', {
  color: '#6B6B6B',
  fontStyle: 'normal',
  fontSize: '15px',
  fontWeight: 400,
  lineHeight: '22px'
})

export const CarInfoRegistrationDateSpan = styled('span', {
  color: '#333',
  fontFamily: 'Inter',
  fontSize: '17px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '24px'
})