import React from 'react';
import { MainContainer } from 'components/styles';
import Title from '../../../components/atoms/Title/PageTitle';
import { useNavigate } from 'react-router-dom';
import { Caption2, Caption3, Subheadline, TermsDetail } from 'styles/typography';
import { DetailContainer } from 'pages/Policy/styles';
import { showWebBrowser } from 'utils/deviceUtils';
import { PAST_POLICY_SERVICE } from 'constants/baseConstants';

const PolicyServicePage = () => {
  const navigate = useNavigate();

  const handleLeftOnClick = () => {
    navigate(-1);
  };

  const serviceChapter1 =
    '이 약관은 주차장만드는사람들㈜(이하 “회사”라 함)가 운영하는 “주만사서비스”(이하 “주만사”이라 함)에서 제공하는 모든 서비스(이하 “서비스”라 함)를 이용함에 있어 이용자의 권리 및 의무, 기타 부수 사항에 관하여 규정함을 목적으로 합니다.';

  const serviceChapter2 = [
    '1. “주만사”이라 함은 “회사”가 “주차공간 제공자”가 등록한 “주차공간”을 “주차공간 사용자”에게 제공하기 위해 온라인 웹, 모바일 어플리케이션 등 정보통신 소프트웨어를 이용하여 설정한 가상의 영업장을 말하며, 아울러 이를 운영하는 사업자의 의미로도 사용합니다.',
    '2. “주차공간”이라 함은 자동차를 주차시키기 위한 시설 또는 공간을 말하며, 주차장법에 의거해 노상주차장, 노외주차장, 부설주차장으로 구분됩니다.',
    '3. “주차공간 제공자”라 함은 “주만사”을 통해 주차공간의 정보를 온라인상에 등록하는 자로서, 상기에 구분된 주차장의 유형별로 소유 혹은 관리 및 운영 권한이 있는 주체를 의미합니다.',
    '4. “주차공간 사용자”라 함은 “주만사”을 통해 “주차공간”의 정보를 탐색하는 자로서, 자동차를 이용하여 “주차공간”에 주차를 하고자 하는 운전자를 의미합니다.',
    '5. “주차공간 사용자”는 회사가 정한 소정의 절차를 거쳐 서비스를 이용한 자로서, “주만사”의 정보를 지속적으로 제공받으며, “주만사”이 제공하는 서비스를 계속적으로 이용할 수 있고, “주차공간 제공자” 및 “주차공간 사용자”가 될 수 있는 권한을 가진 자를 말합니다.',
    '6. “정회원”이라 함은 “주차공간 제공자” 중 회사가 정한 소정의 개인 확인 인증 절차를 거친 자로서, “주차공간”과 관련된 상거래 행위를 할 수 있는 자를 말합니다. “정회원”과 “주차공간 사용자”를 “회원”이라 통칭합니다.',
    '7. “컨텐츠”라 함은 “주차공간 제공자” 또는 “주차공간 사용자”가 “주차공간”의 정보공유 및 판매를 위하여 게재한 모든 글, 사진, 동영상 등을 말합니다.',
  ];

  const serviceChapter3 = [
    '1. 회사는 이 약관의 내용을 회사의 상호, 영업소 소재지, 대표자의 성명, 사업자등록번호, 연락처(전화, 팩스, 전자우편주소 등)등과 함께 회원이 확인할 수 있도록 “주만사” 초기 서비스 화면(전면)에 게시합니다.',
    '2. 회사는 약관의 규제에 관한 법률, 전기통신사업법 및 정보통신망이용촉진 및 정보보호 등에 관한 법률 등 관련법을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.',
    '3. 약관은 이용자가 주만사 서비스에 회원 가입(페이스북 계정, 네이버 계정, 카카오 계정 등)하는 경우 본 계약에 명시된 조건에 동의하는 것으로 간주하며, 그 즉시 효력이 발생합니다.',
    '4. 회사는 필요한 사유가 발생했을 때 사전 고지 없이 약관을 변경할 수 있습니다. 변경된 약관은 시행일로부터 7일간 주만사 온라인 페이지에 게시하는 방법에 의해 공지하고, 명시적으로 회원이 반대하지 않는 한 개정된 약관에 동의한 것으로 간주합니다.',
    '5. 제4항의 방법으로 변경 고지된 약관은 기존의 회원에게도 유효하게 적용됩니다.',
  ];

  const serviceChapter4 = [
    '1. 약관의 해석은 한국어로 작성된 내용을 기준으로 하며, 영어를 비롯한 다른 언어로 번역되어 한국어 내용과 상이한 해석이 발생할 경우, 한국어 해석에 따릅니다.',
    '2. 약관에 정하지 아니한 사항과 이 약관의 해석에 관하여는 관계법령 및 상관례에 따릅니다.',
  ];

  const serviceChapter5 = [
    '1. 회사가 정한 양식에 따라 회원이 모든 정보를 정확히 기재해 회원가입을 신청하거나 정보제공을 동의하는 경우 회사는 회원 가입을 승낙합니다. 회사는 회원 가입을 신청을 통해 서비스 이용을 승인합니다.',
    '2. 타인의 정보를 도용하여 회원정보를 기재하는 경우, 서비스 이용이 제한되거나 관련 법령에 의거 처벌받을 수 있습니다.',
    '3. 회사는 다음과 같은 사유가 발생한 경우 회원 가입 신청에 대한 승낙을 거부, 유보하거나 회원자격 박탈 등의 조치를 취할 수 있습니다.',
  ];

  const serviceChapter5ThirdDetail = [
    '가. 다른 사람의 명의를 사용하여 가입 신청한 경우',
    '나. 신청 시 필수 작성 사항을 허위로 기재한 경우',
    '다. 회사에 의하여 이용계약이 해지된 날로부터 3개월 이내에 재이용신청을 하는 경우',
    '라. 회사로부터 회원자격 정지 조치 등을 받은 회원이 그 조치기간 중에 이용계약을 임의 해지하고 재이용신청을 하는 경우',
    '마. 관계법령의 위반을 목적으로 신청하거나 그러한 행위를 하는 경우',
    '바. 사회의 안녕질서 또는 미풍양속을 저해할 목적으로 신청하거나 그러한 행위를 하는 경우',
    '사. 다른 사람의 서비스 이용을 방해하거나 그 정보를 도용하는 등 전자거래질서를 위협하는 경우',
    '아. 제 14조 회원의 의무를 지키지 않는 경우',
    '자. 기타 이 약관에 위배되거나 위법 또는 부당한 이용신청임이 확인된 경우 및 회사가 합리적인 판단에 의하여 필요하다고 인정하는 경우',
  ];

  const serviceChapter6 =
    '1. 회원은 회사에 언제든지 탈퇴를 요청할 수 있으며 회사는 즉시 회원탈퇴를 처리합니다.';

  const serviceChapter7 = [
    '1. 서비스는 회사의 업무상 또는 기술상의 장애, 기타 특별한 사유가 없는 한 연중무휴, 1일 24시간 제공함을 원칙으로 합니다.',
    '2. 회사는 약관이 정한 바에 따라 안정적으로 서비스를 제공하기 위해 노력하며, 부득이한 이유로 서비스가 중단되면 지체 없이 수리 복구합니다. 단 시스템 점검, 천재지변, 비상사태, 기타 부득이한 경우에는 서비스를 일시적으로 중단할 수 있습니다.',
    '3. 회사가 회원에게 제공하는 서비스는 아래와 같습니다.',
  ];

  const serviceChapter7ThirdDetail = [
    '가. 주차공간에 관한 이름, 위치, 이용시간, 요금체계, 사용요금, 연락처 등의 세부정보',
    '나. 주차공간에 관한 이벤트 정보 제공',
    '다. 이용자 푸쉬알림 및 문자메시지, 메일링 서비스',
    '라. 기타 회사가 자체 개발하거나 타 회사와의 협력계약 등을 통해 회원들에게 제공하는 일체의 서비스',
  ];

  const serviceChapter8 = [
    '1. 이용자는 서비스 접속 및 어플리케이션 다운로드 즉시 제7조 3항에서 열거한 서비스를 이용할 수 있습니다.',
    '2. 이용자는 서비스 이용 시 불편사항에 관하여 언제든지 회사에 문제제기를 할 수 있습니다. 회사는 회원의 문제 제기가 정당하다고 인정하는 경우 이를 해소하기 위해 노력합니다.',
    '3. 회원의 아이디와 비밀번호에 대한 모든 관리책임은 회원에게 있고, 회원은 자신의 아이디를 타인에게 양도, 증여할 수 없습니다. 회원이 등록한 아이디 및 비밀번호를 이용하여 발생한 회원, 회사 또는 제3자에 대한 손해 및 손실에 관한 모든 책임은 해당 회원에게 있습니다.',
  ];

  const serviceChapter9 = [
    '1. 회사는 본 약관이 정하는 바에 따라 지속적이고 안정적인 서비스를 제공하는데 최선을 다합니다.',
    '2. 회사는 항상 등록자의 정보를 포함한 개인신상정보에 대하여 관리적, 기술적 안전조치를 강구하여 정보보안에 최선을 다합니다.',
    '3. 회사는 공정하고 건전한 운영을 통하여 전자상거래 질서유지에 최선을 다하고 지속적인 연구개발을 통하여 양질의 서비스를 제공함으로써 고객만족을 극대화하여 인터넷 사업 발전에 기여합니다.',
    '4. 회사는 고객으로부터 제기되는 불편사항 및 문제에 대해 정당하다고 판단될 경우 우선적으로 그 문제를 즉시 처리합니다. 단, 신속한 처리가 곤란할 경우, 고객에게 그 사유와 처리일정을 즉시 통보합니다.',
    '5. 회사는 소비자 보호단체 및 공공기관의 소비자 보호업무의 추진에 필요한 자료 등의 요구에 적극 협력합니다.',
  ];

  const serviceChapter10 = [
    '1. 회원은 자신이 게시한 정보에 대해 무한 책임을 집니다.',
    '2. 아이디와 비밀번호에 관한 모든 관리의 책임은 회원에게 있습니다.',
    '3. 회원은 아이디와 비밀번호를 제 3 자가 알 수 있도록 해서는 안 됩니다.',
    '4. 회원은 다음 행위를 하여서는 안 됩니다.',
  ];

  const serviceChapter10FourthDetail = [
    '가. 회원가입 신청 또는 변경 시 허위 내용의 등록',
    '나. 타인의 정보 도용',
    '다. 회사가 게시한 정보의 변경',
    '라. 회사가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시',
    '마. 회사 와 기타 제 3자의 저작권 등 지적재산권에 대한 침해',
    '바. 회사 및 기타 제 3자의 명예를 손상 시키거나 업무를 방해하는 행위',
    '사. 외설 또는 폭력적인 메시지, 화상, 음성, 기타 미풍양속에 반하는 정보를 서비스에 공개 또는 게시하는 행위',
    '아. 기타 불법적이거나 부당한 행위',
  ];

  const serviceChapter10Remain = [
    '5. 회원은 본 약관 및 관계법령에서 규정한 사항을 준수합니다.',
    '6. 회원은 서비스를 이용하여 얻은 정보를 회사의 사전 승낙 없이 복사, 복제, 변경, 번역, 출판 및 기타의 방법으로 사용하거나 이를 타인에게 제공할 수 없습니다.',
    '7. 회원은 회사 서비스를 해킹하거나 불법 프로그램을 업로드 해서는 안 됩니다.',
  ];

  const serviceChapter11 =
    '1. 회원이 다음에 해당하는 행위를 했을 경우 회사는 사전 통지 없이 이용 계약을 해지하거나 서비스 이용을 제한할 수 있습니다.';

  const serviceChapter11FirstDetail = [
    '가. 제 4조 회원의 의무를 지키지 않은 경우',
    '나. 불량게시물 규정에 위배되는 행위를 한 경우',
    '다. 회사에서 제공하는 서비스에 위해를 가하는 등 회사의 업무를 방해하거나 타인의 회사 서비스 이용을 저해하는 경우',
    '라. 기타 회사에서 더 이상 서비스 제공이 어렵다고 판단할만한 상당한 이유가 있는 경우',
  ];

  const serviceChapter12 = [
    '1. 회사는 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을 지지 않습니다.',
    '2. 회원이 서비스를 이용함에 있어 행한 불법행위로 인하여 회사가 당해 회원 이외의 제3자로부터 손해배상청구, 소송을 비롯한 각종의 이의제기를 받는 경우 당해 회원은 회사의 면책을 위하여 노력하여야 하며, 회사가 면책되지 못한 경우는 당해 회원은 그로 인하여 회사에게 발생한 모든 손해를 배상하여야 합니다.',
  ];

  const serviceChapter13 = [
    '1. 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.',
    '2. 회사는 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여는 책임을 지지 않습니다.',
    '3. 회사 이외의 타 사업자가 제공하는 정보에 의해 발생하는 피해나 서비스의 장애로 인한 경우에 대하여는 회사는 책임을 지지 않습니다.',
    '4. 회사는 서비스와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여 법률적인 책임을 지지 않습니다.',
    '5. 회사가 제공한 서비스를 통해 취득한 정보나 서비스에 게시된 다른 회원의 게시물을 통해 법률적 또는 의학적, 기타 재정적 사항에 관하여 필요한 결정을 하고자 할 때에는 반드시 사전에 전문가와 상의할 것을 권고합니다.',
    '6. 본 약관의 적용은 이용계약을 체결한 회원에 한하며 제3자에게 어떠한 배상, 소송 등에 대하여 회사는 책임을 면합니다.',
  ];

  const serviceChapter14 =
    '회사는 전화, 서면, 전자우편 또는 인터넷 홈페이지 등을 통하여 개인정보와 관련한 회원의 의견을 수렴하고 불만사항을 접수하여 이를 처리합니다.';

  const serviceChapter15 = [
    '1. 회사 및 회원은 개인정보에 관한 분쟁이 있는 경우 신속하고 효과적인 분쟁해결을 위하여 개인정보분쟁조정위원회에 분쟁의 조정을 신청할 수 있습니다.',
    '2. 서비스 이용으로 발생한 분쟁에 대한 소송은 회사의 본사 소재지를 관할하는 법원을 관할법원으로 합니다.',
  ];

  const serviceChapter16 =
    '회원은 서비스를 이용함에 있어 국내법이나 규정 정책 및 서비스를 이용함에 있어서 필요한 국제법 및 관련 당사자국의 법을 준수할 책임이 있습니다. 본 약관은 2018년 02월 01일부터 시행됩니다.';

  return (
    <MainContainer>
      <Title
        title="주만사 서비스 이용약관"
        leftArrowButtonVisible={true}
        leftOnClick={handleLeftOnClick}
        rightTextButtonVisible={false}
        titleColor={'darkPurple'}
        showSpacer={true}
      />
      <div style={{ marginBottom: '40px' }}>
        <Subheadline weight={'semibold'}>제 1조 (목적)</Subheadline>
        <TermsDetail
          color={'gray09'}
          weight={'regular'}
          style={{
            marginTop: '10px',
          }}
        >
          {serviceChapter1}
        </TermsDetail>

        <Subheadline
          weight={'semibold'}
          style={{
            marginTop: '40px',
          }}
        >
          제 2조 (정의)
        </Subheadline>
        <div
          style={{
            marginTop: '10px',
          }}
        >
          {serviceChapter2.map((chapter2, index) => (
            <TermsDetail color={'gray09'} weight={'regular'} key={index}>
              {chapter2}
            </TermsDetail>
          ))}
        </div>

        <Subheadline
          weight={'semibold'}
          style={{
            marginTop: '40px',
          }}
        >
          제 3조 (약관의 명시, 효력과 개정)
        </Subheadline>
        <div
          style={{
            marginTop: '10px',
          }}
        >
          {serviceChapter3.map((chapter3, index) => (
            <TermsDetail color={'gray09'} weight={'regular'} key={index}>
              {chapter3}
            </TermsDetail>
          ))}
        </div>

        <Subheadline
          weight={'semibold'}
          style={{
            marginTop: '40px',
          }}
        >
          제 4조 (약관의 해석)
        </Subheadline>
        <div
          style={{
            marginTop: '10px',
          }}
        >
          {serviceChapter4.map((chapter4, index) => (
            <TermsDetail color={'gray09'} weight={'regular'} key={index}>
              {chapter4}
            </TermsDetail>
          ))}
        </div>

        <Subheadline
          weight={'semibold'}
          style={{
            marginTop: '40px',
          }}
        >
          제 5조 (회원 가입 및 자격)
        </Subheadline>
        <div
          style={{
            marginTop: '10px',
          }}
        >
          {serviceChapter5.map((chapter5, index) => (
            <TermsDetail color={'gray09'} weight={'regular'} key={index}>
              {chapter5}
            </TermsDetail>
          ))}

          <DetailContainer>
            {serviceChapter5ThirdDetail.map((thirdDetail, index) => (
              <TermsDetail color={'gray09'} weight={'regular'} key={index}>
                {thirdDetail}
              </TermsDetail>
            ))}
          </DetailContainer>
          <TermsDetail color={'gray09'} weight={'regular'}>
            4. 회사가 회원 자격을 박탈하는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 사전
            통지하여 소명할 기회를 부여합니다. 단, 회원의 귀책사유로 통지할 수 없는 경우 통지를
            생략할 수 있습니다.
          </TermsDetail>
        </div>

        <Subheadline
          weight={'semibold'}
          style={{
            marginTop: '40px',
          }}
        >
          제 6조 (회원 탈퇴)
        </Subheadline>
        <TermsDetail color={'gray09'} weight={'regular'} style={{ marginTop: '10px' }}>
          {serviceChapter6}
        </TermsDetail>

        <Subheadline
          weight={'semibold'}
          style={{
            marginTop: '40px',
          }}
        >
          제 7조 (서비스의 제공)
        </Subheadline>
        <div
          style={{
            marginTop: '10px',
          }}
        >
          {serviceChapter7.map((chapter7, index) => (
            <TermsDetail color={'gray09'} weight={'regular'} key={index}>
              {chapter7}
            </TermsDetail>
          ))}

          <DetailContainer>
            {serviceChapter7ThirdDetail.map((thirdDetail, index) => (
              <TermsDetail color={'gray09'} weight={'regular'} key={index}>
                {thirdDetail}
              </TermsDetail>
            ))}
          </DetailContainer>
        </div>

        <Subheadline
          weight={'semibold'}
          style={{
            marginTop: '40px',
          }}
        >
          제 8조 (서비스 이용)
        </Subheadline>
        <div
          style={{
            marginTop: '10px',
          }}
        >
          {serviceChapter8.map((chapter8, index) => (
            <TermsDetail color={'gray09'} weight={'regular'} key={index}>
              {chapter8}
            </TermsDetail>
          ))}
        </div>

        <Subheadline
          weight={'semibold'}
          style={{
            marginTop: '40px',
          }}
        >
          제 9조 (회사의 의무)
        </Subheadline>
        <div
          style={{
            marginTop: '10px',
          }}
        >
          {serviceChapter9.map((chapter9, index) => (
            <TermsDetail color={'gray09'} weight={'regular'} key={index}>
              {chapter9}
            </TermsDetail>
          ))}
        </div>

        <Subheadline
          weight={'semibold'}
          style={{
            marginTop: '40px',
          }}
        >
          제 10조 (회원의 의무)
        </Subheadline>
        <div
          style={{
            marginTop: '10px',
          }}
        >
          {serviceChapter10.map((chapter10, index) => (
            <TermsDetail color={'gray09'} weight={'regular'} key={index}>
              {chapter10}
            </TermsDetail>
          ))}

          <DetailContainer>
            {serviceChapter10FourthDetail.map((fourthDetail, index) => (
              <TermsDetail color={'gray09'} weight={'regular'} key={index}>
                {fourthDetail}
              </TermsDetail>
            ))}
          </DetailContainer>
        </div>

        {serviceChapter10Remain.map((remain, index) => (
          <TermsDetail color={'gray09'} weight={'regular'} key={index}>
            {remain}
          </TermsDetail>
        ))}

        <Subheadline
          weight={'semibold'}
          style={{
            marginTop: '40px',
          }}
        >
          제 11조 (서비스 이용제한)
        </Subheadline>
        <TermsDetail color={'gray09'} weight={'regular'} style={{ marginTop: '10px' }}>
          {serviceChapter11}
        </TermsDetail>

        <DetailContainer>
          {serviceChapter11FirstDetail.map((firstDetail, index) => (
            <TermsDetail color={'gray09'} weight={'regular'} key={index}>
              {firstDetail}
            </TermsDetail>
          ))}
        </DetailContainer>

        <Subheadline
          weight={'semibold'}
          style={{
            marginTop: '40px',
          }}
        >
          제 12조 (손해배상)
        </Subheadline>
        <div
          style={{
            marginTop: '10px',
          }}
        >
          {serviceChapter12.map((chapter12, index) => (
            <TermsDetail color={'gray09'} weight={'regular'} key={index}>
              {chapter12}
            </TermsDetail>
          ))}
        </div>

        <Subheadline
          weight={'semibold'}
          style={{
            marginTop: '40px',
          }}
        >
          제 13조 (면책 범위)
        </Subheadline>
        <div
          style={{
            marginTop: '10px',
          }}
        >
          {serviceChapter13.map((chapter13, index) => (
            <TermsDetail color={'gray09'} weight={'regular'} key={index}>
              {chapter13}
            </TermsDetail>
          ))}
        </div>

        <Subheadline
          weight={'semibold'}
          style={{
            marginTop: '40px',
          }}
        >
          제 14조 (고충처리)
        </Subheadline>
        <TermsDetail weight={'regular'} color={'gray09'} style={{ marginTop: '10px' }}>
          {serviceChapter14}
        </TermsDetail>

        <Subheadline
          weight={'semibold'}
          style={{
            marginTop: '40px',
          }}
        >
          제 15조 (분쟁해결 및 관할법원)
        </Subheadline>
        <div
          style={{
            marginTop: '10px',
          }}
        >
          {serviceChapter15.map((chapter15, index) => (
            <TermsDetail weight={'regular'} color={'gray09'} key={index}>
              {chapter15}
            </TermsDetail>
          ))}
        </div>

        <Subheadline
          weight={'semibold'}
          style={{
            marginTop: '40px',
          }}
        >
          제 16조 (법 준수)
        </Subheadline>
        <TermsDetail weight={'regular'} color={'gray09'} style={{ marginTop: '10px' }}>
          {serviceChapter16}
        </TermsDetail>
        <Caption2 style={{
          marginTop: '28px'
        }}
        onClick={() => {
          showWebBrowser(PAST_POLICY_SERVICE)
        }}
        weight={'regular'} color={'blue'}>이전 버전 보러가기 &gt;</Caption2>
      </div>
    </MainContainer>
  );
};
export default PolicyServicePage;
