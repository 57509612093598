import { makeAutoObservable, runInAction } from 'mobx';
import { IPointCouponStorageData } from '../../../models/Point/pointCouponStorageModel';
import { pointCouponService } from '../../../service/Point/pointCouponService';
import BaseError from '../../../models/baseError';

class PointCouponStorageStore {
  couponStorageData: IPointCouponStorageData[] = [];
  isStorageBoxAllClicked = true;
  isStorageBoxChangePossibleClicked = false;
  isStorageBoxChangeImpossibleClicked = false;
  isLoading = false;
  error: string | null = null;
  isScrollLoading = false;
  isEnd = false;
  pageNum: number = 1;
  totalCount: number = 0

  constructor() {
    makeAutoObservable(this);
  }

  setCouponStorageData(data: IPointCouponStorageData[]) {
    this.couponStorageData = data;
  }

  getSelectedStatus() {
    if (this.isStorageBoxAllClicked) return 'ALL';
    if (this.isStorageBoxChangePossibleClicked) return 'EXC';
    if (this.isStorageBoxChangeImpossibleClicked) return 'EXP';
    return 'ALL';
  }

  setStorageBoxAllClicked(value: boolean) {
    this.isStorageBoxAllClicked = value;
    this.isStorageBoxChangePossibleClicked = !value;
    this.isStorageBoxChangeImpossibleClicked = !value;
  }

  updateCouponState(index: number, newState: string) {
    if (index >= 0 && index < this.couponStorageData.length) {
      runInAction(() => {
        this.couponStorageData[index].state = newState;
      });
    }
  }

  resetPageNum() {
    this.pageNum = 1;
    this.couponStorageData = [];
  }

  toggleStorageBoxAll() {
    this.isStorageBoxAllClicked = true;
    this.isStorageBoxChangePossibleClicked = false;
    this.isStorageBoxChangeImpossibleClicked = false;
    this.resetPageNum();
  }

  toggleStorageBoxChangePossible() {
    this.isStorageBoxAllClicked = false;
    this.isStorageBoxChangePossibleClicked = true;
    this.isStorageBoxChangeImpossibleClicked = false;
    this.resetPageNum();
  }

  toggleStorageBoxChangeImpossible() {
    this.isStorageBoxAllClicked = false;
    this.isStorageBoxChangePossibleClicked = false;
    this.isStorageBoxChangeImpossibleClicked = true;
    this.resetPageNum();
  }

  setLoading(value: boolean) {
    this.isLoading = value;
  }

  setScrollLoading(value: boolean) {
    this.isScrollLoading = value;
  }

  setTotalCount(value: number) {
    this.totalCount = value
  }

  async fetchCouponStorage(mLinkUID: number, isScrollLoad: boolean = false) {
    if (!isScrollLoad) {
      this.resetPageNum(); // 첫 페이지 로드 시, 페이지 번호 초기화
    }

    if (isScrollLoad) {
      this.setScrollLoading(true);
    } else {
      this.setLoading(true);
    }

    try {
      const response = await pointCouponService.postPointCouponStorage({
        mLinkUID: mLinkUID,
        type: this.getSelectedStatus(),
        pageNum: this.pageNum,
      })
      
      if (response instanceof BaseError) {
        this.error = response.message
      } else {
        const couponStorage = response.data
        
        this.setTotalCount(response.totalCount)
        
        if (isScrollLoad) {
          // 데이터 중복 방지를 위해 현재 데이터 개수와 총 개수를 비교
          if (this.couponStorageData.length + couponStorage.length < this.totalCount) {
            this.pageNum += 1; // 불러올 데이터가 있을 경우에만 페이지 번호 증가
            this.couponStorageData = [...this.couponStorageData, ...couponStorage]; // 기존 데이터에 추가
          } else {
            console.log('모든 데이터를 불러왔습니다.');
          }
        }
        // 처음 로드하거나 필터를 변경한 경우 (isScrollLoad 가 false)
        else {
          this.couponStorageData = couponStorage;
        }
      }
    } catch (error) {
      console.error('Error fetching points:', error)
    } finally {
      if (isScrollLoad) {
        this.setScrollLoading(false)
      }
      this.setLoading(false)
    }
  }
}

export const pointCouponStorageStore = new PointCouponStorageStore();
