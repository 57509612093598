import React from 'react';
import {
  ModalOverlay,
  PurpleTwoButtonCheckModalContainer,
  PurpleTwoButtonCheckModalTitleSpan, PurpleTwoButtonLeftButton,
  PurpleTwoButtonRightButton,
} from './styles';

interface PurpleOneButtonCheckModal {
  isVisible: boolean;
  onClose: () => void;
  title: string;
  content: React.ReactNode;
  leftButtonText: string;
  rightButtonText: string;
  rightClick: () => void;
}

const PurpleOneButtonCheckModal: React.FC<PurpleOneButtonCheckModal> = ({ isVisible,
                                                                          onClose,
                                                                          title,
                                                                          content,
                                                                          leftButtonText,
                                                                          rightButtonText,
                                                                          rightClick  }) => {

  if (!isVisible) return null

  return (
    <>
      <ModalOverlay onClick={onClose}>
        <PurpleTwoButtonCheckModalContainer onClick={(e) => e.stopPropagation()}>
          <div style={{
            padding: title ? '20px' : '10px',
            paddingLeft: '20px',
            paddingRight : '20px' }}>
            <PurpleTwoButtonCheckModalTitleSpan>
              {title}
            </PurpleTwoButtonCheckModalTitleSpan>
          </div>
          <div style={{
            paddingBottom: content ? '30px' : '0px',
            paddingLeft: '20px',
            paddingRight : '20px'
          }}>{content}</div>
          <div style={{ display: 'flex', width: '100%' }}>
            <PurpleTwoButtonLeftButton onClick={onClose}>
              {leftButtonText}
            </PurpleTwoButtonLeftButton>
            <PurpleTwoButtonRightButton
              onClick={() => {
                rightClick();
                onClose();
              }}
            >
              {rightButtonText}
            </PurpleTwoButtonRightButton>
          </div>
        </PurpleTwoButtonCheckModalContainer>

      </ModalOverlay>
    </>
  )
}

export default PurpleOneButtonCheckModal