import {styled} from "@stitches/react";

export const TitleContainer = styled('div', {
  color: '#444446',
  fontFamily: 'Pretendard',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '20px'
})

export const ContentContainer = styled('div',{
  color: '#555',
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '20px',
})

export const AllIndexTable = styled('table', {
  width: '100%',
  borderCollapse: 'collapse',
  marginTop: '28px',
  fontFamily: 'Pretendard',
  color: '#424B5A',
  '& th, & td': {
    border: '1px solid #ddd',
    padding: '8px',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: '14px',
    lineHeight: '20px',
    width: '50%'
  },
  '& th': {
    backgroundColor: '#f2f2f2',
    fontWeight: 600,
  },
});

export const ContentTable = styled('table', {
  width: '100%',
  borderCollapse: 'collapse',
  marginTop: '30px',
  fontFamily: 'Pretendard',
  color: '#424B5A',
  '& th, & td': {
    border: '1px solid #ddd',
    padding: '8px',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: '14px',
    lineHeight: '20px',
    width: '20%'
  },
  '& th': {
    backgroundColor: '#f2f2f2',
    fontWeight: 600,
  },
});

export const TableTitleText = styled('th', {
  color: '#555',
  fontFamily: 'Pretendard',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal'
})

export const TableContentText = styled('td', {
  color: '#555',
  fontFamily: 'Pretendard',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '16px',
  verticalAlign: 'top',  // 수직 정렬을 상단으로 설정
})
