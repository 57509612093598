import React from "react";
import { MainContainer } from 'components/styles';
import { StyledCheckbox, CheckBoxContainer, CheckBoxLabel, RequiredText, ViewDivide } from "./styles";
import Title from '../../components/atoms/Title/PageTitle';
import { useNavigate } from "react-router-dom";
import icArrowRightGraySmall from "../../assets/ic/ic_arrow_right_gray_small.svg";

const AgreeTerms: React.FC = () => {
  const navigate = useNavigate();
  const [isCheckedAll, setIsCheckedAll] = React.useState(false);
  const [checkboxes, setCheckboxes] = React.useState({
    serviceTerms: false,
    privacyPolicy: false,
    locationBased: false,
    marketing: false
  });

  const handleAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setIsCheckedAll(isChecked);
    setCheckboxes({
      serviceTerms: isChecked,
      privacyPolicy: isChecked,
      locationBased: isChecked,
      marketing: isChecked
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, key: keyof typeof checkboxes) => {
    const { checked } = event.target;
    setCheckboxes(prev => {
      const updatedCheckboxes = {
        ...prev,
        [key]: checked
      };

      const allChecked = Object.values(updatedCheckboxes).every(value => value);
      setIsCheckedAll(allChecked);
      return updatedCheckboxes;
    });
  };

  const handleLeftOnClick = () => {
    navigate(-1);
  };

  const navigateToDetails = (path: string) => {
    navigate(path);
  };

  const isButtonEnabled =
      isCheckedAll ||
      [checkboxes.serviceTerms, checkboxes.privacyPolicy, checkboxes.locationBased].filter(Boolean).length >= 3;

  // Button style
  const buttonStyle = {
    padding: '0px',
    border: '0px',
    width: '100%',
    marginRight: '16px',
    height: '46px',
    color: isButtonEnabled ? '#FFF' : '#8B63BB',
    backgroundColor: isButtonEnabled ? '#8B63BB' : '#F2F2F2',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: 'normal',
    borderRadius: '5px',
    cursor: isButtonEnabled ? 'pointer' : 'not-allowed',
  };

  // Button click handler
  const handleButtonClick = () => {
    if (isButtonEnabled) {
      navigate('/signUpCert')
    }
  };

  return (
    <MainContainer>
      <Title
        title="약관 동의"
        leftArrowButtonVisible={true}
        leftOnClick={handleLeftOnClick}
        rightTextButtonVisible={false}
      />
      <CheckBoxContainer>
        <CheckBoxLabel>
          <StyledCheckbox
            type="checkbox"
            checked={isCheckedAll}
            onChange={handleAllChange}
          />
          전체동의
        </CheckBoxLabel>
        <ViewDivide/>
          <CheckBoxLabel style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <StyledCheckbox
                type="checkbox"
                checked={checkboxes.serviceTerms}
                onChange={(e) => handleChange(e, 'serviceTerms')}
              />
            <RequiredText>(필수)</RequiredText>주만사 서비스 이용약관
            </div>
            <div
              style={{ flexShrink: 0 }}
              onClick={(e) => {
                e.stopPropagation(); // Prevent the click event from propagating to the checkbox
                console.log('주만사 서비스 이용약관 상세');
              }}
            >
            <img src={icArrowRightGraySmall} alt="arrow.png" />
            </div>
          </CheckBoxLabel>

          <CheckBoxLabel style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <StyledCheckbox
                type="checkbox"
                checked={checkboxes.privacyPolicy}
                onChange={(e) => handleChange(e, 'privacyPolicy')}
              />
              <RequiredText>(필수)</RequiredText>개인정보 수집 및 이용동의
            </div>
            <div
              style={{ flexShrink: 0 }}
              onClick={(e) => {
                e.stopPropagation();
                console.log('개인정보 수집 및 이용동의 상세');
              }}
            >
            <img src={icArrowRightGraySmall} alt="arrow.png" />
            </div>
          </CheckBoxLabel>

          <CheckBoxLabel style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <StyledCheckbox
                type="checkbox"
                checked={checkboxes.locationBased}
                onChange={(e) => handleChange(e, 'locationBased')}
              />
            <RequiredText>(필수)</RequiredText>위치기반 서비스
            </div>
            <div
              style={{ flexShrink: 0 }}
              onClick={(e) => {
                e.stopPropagation(); // Prevent the click event from propagating to the checkbox
                console.log('위치기반 서비스 상세');
              }}
            >
            <img src={icArrowRightGraySmall} alt="arrow.png" />
            </div>
          </CheckBoxLabel>

          <CheckBoxLabel style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <StyledCheckbox
                type="checkbox"
                checked={checkboxes.marketing}
                onChange={(e) => handleChange(e, 'marketing')}
              />
              <span>(선택)</span>
              <span style={{marginLeft: '4px'}}>마케팅 수신동의</span>
            </div>
            <div
              style={{ flexShrink: 0 }}
              onClick={(e) => {
                e.preventDefault();
                console.log('마케팅 수신동의 상세');
              }}
            >
            <img src={icArrowRightGraySmall} alt="arrow.png"/>
            </div>
          </CheckBoxLabel>

          <div style={{ fontSize: '15px', color: '#B0B0B0', fontWeight: 400, marginLeft: '36px' }}>
            주차장 및 다양한 이벤트 정보를 보내 드립니다.
          </div>
        </CheckBoxContainer>
        <CheckBoxContainer style={{ margin: 'auto 0px 40px 0px' }}>
          <button
            style={buttonStyle}
            onClick={handleButtonClick}
            disabled={!isButtonEnabled}
          >
          다음
          </button>
        </CheckBoxContainer>
      </MainContainer>
  );
};

export default AgreeTerms;
