import { makeAutoObservable } from "mobx";
import terraceTowerService from '../../../service/TerraceTower/terraceTowerService';
import BaseError from '../../../models/baseError';
import { ITerraceTowerUsageHistoryDetailData } from 'models/TerraceTower/terraceTowerUsageHistoryDetail';


class TerraceTowerUsageHistoryDetailStore {
  isLoading = false;
  error: string | null = null;
  usageHistoryDetail: ITerraceTowerUsageHistoryDetailData | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setLoading(value: boolean) {
    this.isLoading = value;
  }

  setUsageHistoryDetail(detail: ITerraceTowerUsageHistoryDetailData) {
    this.usageHistoryDetail = detail;
  }

  async fetchTerraceTowerUsageHistoryDetail(saleHistoryUID: string) {
    const requestBody = {
      saleHistoryUID,
    }

    this.setLoading(true)

    const response = await terraceTowerService.postTerraceTowerUsageHistoryDetail(requestBody)

    if (response instanceof BaseError) {
      this.error = response.errorMessage
      this.setLoading(false)
      return false
    } else {
      const { data } = response
      if (data) {
        this.setUsageHistoryDetail(data)
      }
      this.setLoading(false)
      return true
    }
  }
}

export const terraceTowerUsageHistoryDetailStore = new TerraceTowerUsageHistoryDetailStore()
