import React from 'react';
import { CloseIcon, DotTittleContentsContainer, MinganParkingInfoModalContainer } from './styles';
import icCloseGray from '../../../../assets/ic/ic_close_gray.svg';
import {
  ContentsSpan,
  DashContainer,
  TitleContentsContainer, TitleSpan,
} from './styles';
import icDotPurple from '../../../../assets/ic/ic_dot_purple.svg'

interface MinganParkingInfoBottomModalProps {
  isVisible: boolean;
  onClose: () => void
}

const infoItems = [
  {
    title: '자주식',
    description: '운전자가 직접 주차하는 방식으로, 가장 일반적인 주차방식 입니다.',
  },
  {
    title: '기계식 (승용 전용)',
    description:
      'RV는 주차가 불가능한 기계식 주차장입니다. 기계식 제원에 따라 일부 차종이 제한될 수 있습니다.',
  },
  {
    title: '기계식 (RV 가능)',
    description:
      '승용차 외에도 RV까지 주차가 가능합니다. 기계식 제원에 따라 일부 차종이 제한될 수 있습니다.',
  },
  {
    title: '차단기',
    description:
      '차단기가 설치되어 있는 주차장입니다. 리모컨 구동 방식일 경우 보증금이 발생합니다. (최초 1회)',
  },
  {
    title: '단일 주차',
    description: '단독으로 주차(병렬 주차) 하여 입출차가 원활합니다.',
  },
  {
    title: '이중 주차',
    description: '이중으로 주차(직렬 주차) 하여 상황에 따라 차량 이동이 필요합니다.',
  },
  {
    title: '빈자리',
    description: '비어있는 자리에 주차하는 방식입니다.',
  },
  {
    title: '지정석',
    description: '항상 고정적인 자리에 주차해야 합니다. 자리는 매칭 이후 안내드립니다.',
  },
  {
    title: '차량등록',
    description:
      '필요에 따라 차량등록증을 제출해야 하며, 차량등록 완료 이후 이용 가능한 주차장입니다.',
  },
];

const MinganParkingInfoBottomModal: React.FC<MinganParkingInfoBottomModalProps> = ({
  isVisible,
  onClose
}) => {
  if (!isVisible) return null

  return(
    <>
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          zIndex: 9999,
        }}
        onClick={onClose}
      />

      <MinganParkingInfoModalContainer isVisible={isVisible}>
        <div style={{ paddingTop: '25px', paddingRight: '25px', textAlign: 'right' }}>
          <CloseIcon src={icCloseGray} alt="닫기 아이콘" onClick={onClose} />
        </div>

        {infoItems.map((item, index) => (
          <DashContainer key={index}>
            <DotTittleContentsContainer>
              <img
                style={{
                  marginTop: '4px'
                }}
                src={icDotPurple} alt={'보라색 점 아이콘'}/>
              <TitleContentsContainer>
                <TitleSpan>{item.title}</TitleSpan>
                <ContentsSpan>
                  {item.description.split('\n').map((line, i) => (
                    <React.Fragment key={i}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </ContentsSpan>
              </TitleContentsContainer>
            </DotTittleContentsContainer>
          </DashContainer>
        ))}

      </MinganParkingInfoModalContainer>
    </>
  )
}

export default MinganParkingInfoBottomModal