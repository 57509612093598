import { styled } from '@stitches/react';

export const GoodsItemContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: '20px',
})

export const GoodsImageContainer = styled('div', {
  borderRadius: '8px',
  border: '1px solid #CCC',
  width: '100px',
  height: '100px',
  flexShrink: 0,
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
})

export const GoodsImage = styled('img', {
  maxWidth: '100px',
  maxHeight: '100px',
  objectFit: 'cover',
})

export const GoodsContentsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100px',
  marginLeft: '8px',
  justifyContent: 'space-between',
  color: '#868686',
  fontFamily: 'Pretendard',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal'
})

export const GoodsNameCompositionContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 'auto'
})

export const GoodsContentSpan = styled('span', {
  color: '#555',
  fontWeight: 500,
  fontSize: '15px',
  marginTop: '2px'
})

export const GoodsPriceSpan = styled('span', {
  fontWeight: 600,
  fontSize: '20px',
  color: '#333',
})

export const GoodsPriceBuyButtonContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
})

export const BuyButton = styled('button', {
  borderRadius: '5px',
  border: 'none',
  color: '#8B63BB',
  backgroundColor: '#EBEBFB',
  padding: '8px 16px',
  fontFamily: 'Pretendard',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal'
})

export const PurchaseContainer = styled('div', {
  color: '#666',
  fontFamily: 'Pretendard',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal'
})

export const PurchaseCouponNameContainer = styled('div', {
  backgroundColor: '#F8F8FA',
  color: '#8B63BB',
  padding: '20px',
  marginTop: '20px',
  borderRadius: '8px',
  fontSize: '14px',
  fontWeight: 500
})
