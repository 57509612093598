import React from "react";
import { useNavigate } from "react-router-dom";
import { MainContainer } from "../../../components/styles";
import Title from "../../../components/atoms/Title/PageTitle";
import {
  CautionListContainer,
  CautionSpan,
  CheckBoxLabel,
  DeleteAccountEssentialSpan,
  StyledCheckbox,
  CautionTitleContentsContainer,
} from './styles';
import { DeleteAccountTitleContainer } from '../styles';
import { BottomButtonContainer, ButtonEnableDisable } from '../../../components/atoms/Button/styles';

const DeleteAccountNotification: React.FC = () => {
  const navigate = useNavigate();

  const [deleteAccountAgreement, setDeleteAccountAgreement] = React.useState(false);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDeleteAccountAgreement(event.target.checked);
  };
  const handleSubmit = () => {
    if (!deleteAccountAgreement) {
      console.log("주의 사항에 동의하셔야 합니다.");
      return;
    }
    console.log("정보 삭제 요청");
    navigate('/deleteAccountReason');
  };

  return (
    <MainContainer>
      <Title
        title="회원 탈퇴"
        leftArrowButtonVisible={true}
        leftOnClick={() => navigate(-1)}
        rightTextButtonVisible={false}
      />
      <DeleteAccountTitleContainer>
        회원 탈퇴 전 아래 내용을
        <br />꼭 확인해 주세요!
      </DeleteAccountTitleContainer>

      <CautionTitleContentsContainer>
        <CautionSpan>주의사항</CautionSpan>
        <CautionListContainer>
          <span>
            1. 보존이 끝난 후 <span style={{ color: '#ED1659' }}>회원님의 연락처</span>를 포함한{' '}
            <span style={{ color: '#ED1659' }}>모든 정보는 영구적으로 삭제</span>되며 복구할 수
            없습니다.
          </span>
          <span>
            2. <span style={{ color: '#ED1659' }}>휴대폰 번호가 변경</span>되었을 경우{' '}
            <span style={{ color: '#ED1659' }}>
              고객센터로 문의
              <span style={{ color: '#333' }}>
                주<br />시
              </span>
              면 처리 도와드리겠습니다.
            </span>
          </span>
          <span>
            3.{' '}
            <span style={{ color: '#ED1659' }}>
              보유 중인 포인트 및 정산 예정금은 탈퇴 시 소멸
            </span>
            되<br />며 어떠한 경우에도 복구할 수 없습니다. 잔여 포인트 <br />및 정산금은 회원탈퇴
            전 미리 사용하시거나 교환해 주<br />
            세요.
          </span>
          <span>
            4. 회원탈퇴 시{' '}
            <span style={{ color: '#ED1659' }}>
              불량 이용자의 재가입 방지, 부정 이용
              <br />
              방지, 명예훼손 등
            </span>{' '}
            권리침해 분쟁 및 수사협조 목적으
            <br />로 최초 접속 시 제공한 정보를
            <span style={{ color: '#ED1659' }}>
              회원 탈퇴 후 3개월 간<br />
              보존
            </span>
            합니다.
          </span>
          <span>
            5. 주차장을 공유하고 계시거나 이용 중이신 경우{' '}
            <span style={{ color: '#ED1659' }}>
              공유
              <br />를 종료하신 후 탈퇴
            </span>
            할 수 있습니다.
          </span>
          <span>
            6. 혹시 이용 과정에서 불편한 점이 있으셨다면 고객
            <br /> 센터로 문의 부탁드립니다.
          </span>
        </CautionListContainer>
      </CautionTitleContentsContainer>

      <CheckBoxLabel>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <StyledCheckbox
            type="checkbox"
            checked={deleteAccountAgreement}
            onChange={handleCheckboxChange}
          />
          <DeleteAccountEssentialSpan>
            주의 사항을 확인 하였으며 정보를 삭제하는 것에 동의합니다.
          </DeleteAccountEssentialSpan>
        </div>
      </CheckBoxLabel>

      <BottomButtonContainer>
        <ButtonEnableDisable
          onClick={handleSubmit}
          disabled={!deleteAccountAgreement}
        >
          약관 동의 및 공유 신청
        </ButtonEnableDisable>
      </BottomButtonContainer>
    </MainContainer>
  );
};

export default DeleteAccountNotification;
