import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import {
  AllDeleteContainer,
  BottomButtonKeyboardContainer,
  BottomGridContainer,
  ButtonEnableDisable,
  CautionContainer,
  InputButton,
  InputGridContainer,
  OneDeleteContainer,
  OneDeleteImg,
  SearchPasswordContainer,
  WithdrawalMainContainer,
  WithdrawalMoneyInput,
  WithdrawalPossibleMoneyContainer,
} from '../styles';
import Title from '../../../../components/atoms/Title/PageTitle';
import icArrowDelete from '../../../../assets/ic/ic_arrow_delete.svg';
import WithdrawBottomModal from '../../../../components/atoms/Modal/WithdrawBottomModal';
import PurpleOneButtonCheckModal from '../../../../components/atoms/Modal/PurpleOneButtonCheckModal';
import icExclamation from '../../../../assets/ic/ic_exclamation.svg';
import { pointWithdrawalMoneyStore } from '../../../../stores/store/Point/pointWithdrawalMoneyStore';
import { observer } from 'mobx-react';
import { LINK_UID } from '../../../../constants/userInfoConstants';

const PointWithdrawalMoney = observer(() => {
  const formatNumberWithCommas = (value: string) => {
    const numberValue = value.replace(/[^0-9]/g, '')
    return numberValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  const [withdrawalAmount, setWithdrawalAmount] = useState('')
  const [inputValues, setInputValues] = useState<string[]>(Array(7).fill(''));
  const [error, setError] = useState<string | null>(null);
  const possibleAmountRef = React.useRef<HTMLSpanElement>(null);
  const navigate = useNavigate();
  const [isWithdrawBottomModalVisible, setIsWithdrawBottomModalVisible] = React.useState(false);
  const [isSearchPasswordModal, setIsSearchPasswordModal] = useState(false);
  const possibleWithdrawalAmount = Number(
    pointWithdrawalMoneyStore.withdrawalMoney.replace(/[^0-9]/g, ''),
  );
  const withdrawalAmountDigits = Number(withdrawalAmount.replace(/[^0-9]/g, ''));

  const isButtonDisabled =
    inputValues.every((value) => value === '') || !!error ||
    withdrawalAmountDigits > possibleWithdrawalAmount

  const checkAmountError = (value: string) => {
    const numericValue = Number(value.replace(/[^0-9]/g, ''))

    if (numericValue > possibleWithdrawalAmount && numericValue <= 3000000) {
      setError('출금 가능 포인트 금액을 확인해 주세요.')
    } else if (numericValue > 3000000) {
      setError('1회 출금한도는 300만원이에요.')
    } else {
      setError(null)
    }
  }

  const linkUID = parseInt(sessionStorage.getItem(LINK_UID) || '0', 10);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/,/g, '').replace(/원/g, '');
    const formattedValue = formatNumberWithCommas(value);
    setWithdrawalAmount(formattedValue + '원');
    checkAmountError(formattedValue);
  };

  const handleMaxAmountClick = () => {
    if (possibleAmountRef.current) {
      const textContent = possibleAmountRef.current.textContent;
      const maxAmount = textContent ? textContent.trim() : '';

      const maxAmountDigits = maxAmount.replace(/[^0-9]/g, '').split('');
      const newInputValues = Array(7).fill('');

      for (let i = 0; i < Math.min(maxAmountDigits.length, 7); i++) {
        newInputValues[i] = maxAmountDigits[i];
      }
      setInputValues(newInputValues);
      setWithdrawalAmount(maxAmount);
      checkAmountError(maxAmount);
    }
  }

  const handleNumberClick = (index: number, number: string) => {
    setInputValues((prevValues) => {
      if (index === 0 && number === '0') {
        return prevValues
      }

      const newValues = [...prevValues]
      newValues[index] = number

      const updatedValue = newValues.join('')
      const formattedValue = formatNumberWithCommas(updatedValue)
      setWithdrawalAmount(formattedValue + '원')
      checkAmountError(updatedValue)

      return newValues
    })
  }

  const handleClearAll = () => {
    setInputValues(Array(7).fill(''));
    setWithdrawalAmount('');
    setError(null);
  }

  const handleBackspace = () => {
    setInputValues((prevValues) => {
      const lastNonEmptyIndex = prevValues
        .map((value, index) => (value !== '' ? index : -1))
        .filter((index) => index !== -1)
        .pop();

      if (lastNonEmptyIndex !== undefined) {
        const newValues = [...prevValues]
        newValues[lastNonEmptyIndex] = ''

        const updatedValue = newValues.join('')
        const formattedValue = formatNumberWithCommas(updatedValue)

        if (lastNonEmptyIndex === 0 && withdrawalAmount.endsWith('원')) {
          setWithdrawalAmount(formattedValue);
        } else {
          setWithdrawalAmount(formattedValue + '원')
        }
        // checkAmountError(updatedValue)
        if (updatedValue !== '') {
          checkAmountError(updatedValue);
        } else {
          setError(null)
        }

        return newValues
      }
      return prevValues
    })
  }

  useEffect(() => {
    const fetchWithdrawalMoney = async () => {
      await pointWithdrawalMoneyStore.fetchPointWithdrawalMoney(linkUID);
      console.log('출금 가능 금액 가져오기 성공');
    };

    fetchWithdrawalMoney().catch((error) => {
      console.log('출금 가능 금액 가져오기 중 오류가 발생했습니다.', error);
    });
  }, []);

  useEffect(() => {
    sessionStorage.setItem('withdrawalMoney', withdrawalAmount);
  }, [withdrawalAmount]);

  useEffect(() => {
    window.onBtnBackClicked = function () {
      navigate('/pointHistory');
    };
    return () => {
      delete window.onBtnBackClicked;
    };
  }, []);

  return (
    <>
      <WithdrawalMainContainer>
        <Title
          title="출금"
          leftArrowButtonVisible={true}
          leftOnClick={() => navigate('/pointHistory')}
          rightTextButtonVisible={false}
        />

        <WithdrawalPossibleMoneyContainer
          onClick={() => {
            setIsWithdrawBottomModalVisible(true);
          }}
        >
          <span>출금 가능 금액:</span>
          <span ref={possibleAmountRef}>&nbsp;{pointWithdrawalMoneyStore.withdrawalMoney}원</span>
          <img
            style={{
              width: '16px',
              height: '16px',
              marginLeft: '4px',
            }}
            src={icExclamation}
            alt={'느낌표 회색 아이콘'}
          />
        </WithdrawalPossibleMoneyContainer>

        <WithdrawalMoneyInput
          placeholder={'출금 금액 입력'}
          value={withdrawalAmount}
          onChange={handleChange}
          readOnly
        />

        {error && (
          <CautionContainer style={{ marginTop: '17px' }}>
            <span>{error}</span>
          </CautionContainer>
        )}

        <SearchPasswordContainer onClick={handleMaxAmountClick}>전액 사용</SearchPasswordContainer>
        <BottomButtonKeyboardContainer>
          <ButtonEnableDisable
            onClick={() => {
              navigate('/pointWithdrawalPassword');
            }}
            disabled={isButtonDisabled}
          >
            확인
          </ButtonEnableDisable>
          <BottomGridContainer>
            <InputGridContainer>
              {[1, 2, 3].map((number: number) => (
                <InputButton
                  key={number}
                  onClick={() => {
                    const emptyIndex = inputValues.indexOf('');
                    if (emptyIndex !== -1) {
                      handleNumberClick(emptyIndex, number.toString());
                    }
                  }}
                >
                  {number}
                </InputButton>
              ))}
            </InputGridContainer>
            <InputGridContainer>
              {[4, 5, 6].map((number: number) => (
                <InputButton
                  key={number}
                  onClick={() => {
                    const emptyIndex = inputValues.indexOf('');
                    if (emptyIndex !== -1) {
                      handleNumberClick(emptyIndex, number.toString());
                    }
                  }}
                >
                  {number}
                </InputButton>
              ))}
            </InputGridContainer>
            <InputGridContainer>
              {[7, 8, 9].map((number: number) => (
                <InputButton
                  key={number}
                  onClick={() => {
                    const emptyIndex = inputValues.indexOf('');
                    if (emptyIndex !== -1) {
                      handleNumberClick(emptyIndex, number.toString());
                    }
                  }}
                >
                  {number}
                </InputButton>
              ))}
            </InputGridContainer>
            <InputGridContainer>
              <AllDeleteContainer onClick={handleClearAll}>전체삭제</AllDeleteContainer>
              <InputButton
                onClick={() => {
                  handleNumberClick(inputValues.indexOf(''), '0');
                }}
              >
                0
              </InputButton>
              <OneDeleteContainer onClick={handleBackspace}>
                <OneDeleteImg src={icArrowDelete} alt={'입력 필드 지우는 아이콘'} />
              </OneDeleteContainer>
            </InputGridContainer>
          </BottomGridContainer>
        </BottomButtonKeyboardContainer>

        <WithdrawBottomModal
          isVisible={isWithdrawBottomModalVisible}
          onClose={() => setIsWithdrawBottomModalVisible(false)}
        />

        <PurpleOneButtonCheckModal
          isVisible={isSearchPasswordModal}
          onClose={() => {
            setIsSearchPasswordModal(false);
            navigate('/pointWithdrawalIdentityCert');
          }}
          title={'비밀번호를 다시 설정해주세요.'}
          content={''}
          buttonText={'확인'}
        />
      </WithdrawalMainContainer>
    </>
  );
});

export default PointWithdrawalMoney;
