import { styled } from '@stitches/react';

export const MinganInfoModalContainer = styled('div', {
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  height: 'auto', // 모달의 높이 설정
  background: '#fff',
  boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.2)',
  transition: 'transform 0.3s ease-in-out',
  transform: 'translateY(100%)', // 모달을 화면 아래로 숨김
  display: 'flex',
  flexDirection: 'column',
  padding: '16px',
  boxSizing: 'border-box',
  borderRadius: '20px 20px 0px 0px',
  zIndex: 10000,
  variants: {
    isVisible: {
      true: { transform: 'translateY(0)' }, // 모달을 화면 위로 슬라이드
      false: { transform: 'translateY(100%)' }, // 화면 아래로 숨김
    },
  },
});

export const TitleContainer = styled('div', {
  flex: 1,
  display: 'flex',
  flexDirection: 'row',
  textAlign: 'center',
  fontFamily: 'Pretendard',
  fontSize: '20px',
  fontWeight: 700,
  fontStyle: 'normal',
  lineHeight: 'normal',
  marginTop: '30px',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#1A1A1A',
})

export const MinganListNumberContainer = styled('div', {
  color: '#868686',
  fontFamily: 'Pretendard',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  textAlign: 'right',
  marginTop: '10px'
})

export const TicketContainer = styled('div', {
  backgroundColor: '#F8F8FA',
  padding: '20px',
  marginTop: '20px',
  borderRadius: '5px',
})

export const MinganParkingWeekStatusText = styled('div', {
  color: '#333',
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal'
})

export const ParkingNumberText = styled('div', {
  color: '#1A1A1A',
  fontFamily: 'Pretendard',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  marginTop: '3px',
})