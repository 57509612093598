import { styled } from '@stitches/react';

export const AccountInfoTransmissionContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  marginTop: '80px',
})

export const AccountInfoContentsContainer = styled('div', {
  marginTop: '13px',
  display: 'flex',
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  color: '#8B63BB',
  fontFamily: 'Pretendard',
  fontSize: '30px',
  fontStyle: 'normal',
  fontWeight: 800,
  lineHeight: 'normal',
})

export const BankAccountInfoContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center'
})

export const BankIcon = styled('img', {
  width: '28px',
  height: '28px',
})

export const AccountContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  color: '#666',
  fontFamily: 'Pretendard',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
})

export const AccountDetailContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  marginLeft: '4px',
})

export const TransmissionSpan = styled('span', {
  color: '#444446',
  fontFamily: 'Pretendard',
  fontSize: '22px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
})

export const CautionContainer = styled('div', {
  height: '46px',
  borderRadius: '5px',
  border: '2px solid #E6E6E6',
  marginBottom: '28px',
  textAlign: 'center',
  alignItems: 'center',
  display: 'flex',
  color: '#555',
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
})

export const CautionIcon = styled('img', {
  marginLeft: '16px',
})

export const CautionSpan= styled('span', {
  marginLeft: '8px',
})