import { MainContainer } from '../../../../components/styles';
import Title from '../../../../components/atoms/Title/PageTitle';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BottomButtonContainer,
  ButtonEnableDisable,
} from '../../../../components/atoms/Button/styles';
import icExclamationBlack from '../../../../assets/ic/ic_exclamation_black.svg';
import PurpleOneButtonCheckModal from '../../../../components/atoms/Modal/PurpleOneButtonCheckModal';
import {
  AccountContainer,
  AccountDetailContainer,
  AccountInfoContentsContainer,
  AccountInfoTransmissionContainer,
  BankAccountInfoContainer,
  BankIcon,
  CautionContainer,
  CautionIcon,
  CautionSpan,
  TransmissionSpan,
} from './styles';
import { userInfoStore } from '../../../../stores/store/UserInfo/userInfoStore';
import icNotBank from 'assets/ic/ic_not_bank.svg';
import { observer } from 'mobx-react';
import { pointWithdrawalMoneyStore } from '../../../../stores/store/Point/pointWithdrawalMoneyStore';
import { LINK_UID } from '../../../../constants/userInfoConstants';
import { isBankNotOperationTime } from '../../../../utils/bankUtil';
import LoadingSpinnerScreen from '../../../../components/atoms/LoadingSpinner/LoadingSpinnerScreen';
import { useFetchUserInfo } from '../../../../hooks/useFetchUserInfo';

const PointWithdrawalFinalCheck = observer(() => {
  const [isBankNotOperationModal, setIsBankNotOperationModal] = useState(false);
  const navigate = useNavigate();
  const storedWithdrawalAmount = sessionStorage.getItem('withdrawalMoney');
  const getToken = sessionStorage.getItem('token');
  const { fetchUserInfo } = useFetchUserInfo();

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        await fetchUserInfo();
        console.log('유저 정보 가져오기 성공');
      } catch (error) {
        console.error('유저 정보 가져오기 실패:', error);
      }
    }
    getUserInfo().catch((error) => {
      console.error('유저 정보 가져오기 실패:', error);
    });
  }, []);

  const handleLeftOnClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    window.onBtnBackClicked = function () {
      handleLeftOnClick();
    };
    return () => {
      delete window.onBtnBackClicked;
    };
  }, []);

  const handleWithdrawal = async () => {
    const withdrawalAmount = Number(storedWithdrawalAmount);
    const linkUID = parseInt(sessionStorage.getItem(LINK_UID) || '0', 10);

    if (!getToken) {
      console.error('토큰이 없습니다.');
      return;
    }

    if (isBankNotOperationTime()) {
      setIsBankNotOperationModal(true);
      return;
    }

    try {
      await pointWithdrawalMoneyStore.fetchPointWithdrawal(linkUID, getToken, withdrawalAmount);
      navigate('/pointWithdrawalFinalSuccess');
    } catch (error) {
      console.error('API 호출 중 오류 발생:', error);
    }
  }

  if (userInfoStore.isLoading && pointWithdrawalMoneyStore.withdrawalLoading) {
    return <LoadingSpinnerScreen />;
  }

  return (
    <MainContainer>
      <Title
        title="출금"
        leftArrowButtonVisible={true}
        leftOnClick={handleLeftOnClick}
        rightTextButtonVisible={false}
      />
      <AccountInfoTransmissionContainer>
        <BankAccountInfoContainer>
          <BankIcon src={userInfoStore.bankIconSrc || icNotBank} alt="은행 아이콘" />
          <AccountContainer>
            <AccountDetailContainer>
              <span>{userInfoStore.bankName}</span>
              <span>&nbsp;{userInfoStore.accountNumber}</span>
              <span>&nbsp;/&nbsp;</span>
              <span>{userInfoStore.accountHolder}</span>
              <span style={{ fontWeight: 400 }}>&nbsp;으로</span>
            </AccountDetailContainer>
          </AccountContainer>
        </BankAccountInfoContainer>
        <AccountInfoContentsContainer>
          <span>{storedWithdrawalAmount}</span>
          <TransmissionSpan>보낼까요?</TransmissionSpan>
        </AccountInfoContentsContainer>
      </AccountInfoTransmissionContainer>
      <BottomButtonContainer>
        {parseInt(pointWithdrawalMoneyStore.withdrawalMoney.replace(/,/g, ''), 10) >= 1000000 && (
          <CautionContainer>
            <CautionIcon src={icExclamationBlack} alt={'검정 느낌표 아이콘'} />
            <CautionSpan>100만원 이상은 담당자 승인 후 출금 됩니다.</CautionSpan>
          </CautionContainer>
        )}
        <ButtonEnableDisable onClick={handleWithdrawal}>출금 신청하기</ButtonEnableDisable>
      </BottomButtonContainer>

      {pointWithdrawalMoneyStore.isShowWithdrawalExceededModal && (
        <PurpleOneButtonCheckModal
          isVisible={pointWithdrawalMoneyStore.isShowWithdrawalExceededModal}
          onClose={() => {
            pointWithdrawalMoneyStore.setShowWithdrawalExceededModal(false);
          }}
          title={'출금 가능 금액을 초과했어요.\n금액을 다시 확인해 주세요.'}
          content={''}
          buttonText={'확인'}
        />
      )}

      <PurpleOneButtonCheckModal
        isVisible={isBankNotOperationModal}
        onClose={() => {
          setIsBankNotOperationModal(false);
        }}
        title={'은행 점검 시간(23:30~00:30)에는\n출금 신청을 할 수 없습니다.'}
        content={''}
        buttonText={'확인'}
      />
    </MainContainer>
  );
});
export default PointWithdrawalFinalCheck;