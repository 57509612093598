import { keyframes, styled } from '@stitches/react';

export const AccountInfoContainer = styled('div', {
  width: '100%',
  height: '76px',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#F8F8FA',
  borderRadius: '10px',
  color: '#6B6B6B',
  border: '1px solid transparent',
  transition: 'border 0.3s',
  variants: {
    selected: {
      true: {
        border: '1px solid #8B63BB',
        backgroundColor: '#FFF'
      },
      false: {
        border: '1px solid transparent',
        backgroundColor: '#F8F8FA'
      }
    },
  },
  '&:focus': {
    backgroundColor: '#FFF',
  },
  defaultVariants: {
    selected: false
  }
});

export const InfoContentsMargin = styled('div', {
  margin: '15px 15px 0px 15px'
})

export const InfoContentsContainer = styled('div', {
  fontFamily: 'Pretendard',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '20px',
  color: '#8B63BB',
  display: 'flex',
  height: 'auto',
  width: 'auto'
})

export const NameInputCloseContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '7px',
})

export const InfoInput = styled('input', {
  width: '100%',
  backgroundColor: '#F8F8FA',
  border: 'none',
  outline: 'none',
  fontFamily: 'Pretendard',
  fontSize: '17px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '23px',
  marginLeft: 'auto',
  '&:focus': {
    backgroundColor: '#FFF',
  },

  '&::placeholder': {
    color: '#B0B0B0',
  },
});

export const BankNameSpan = styled('span', {
  fontFamily: 'Pretendard',
  fontSize: '17px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '23px',
  color: '#B0B0B0',

  variants: {
    hasSelectedBankName: {
      true: {
        color: '#1A1A1A',
      },
      false: {
        color: '#B0B0B0',
      },
    },
  },
})

export const dotsAnimation = keyframes({
  '0%': { content: '"."' },
  '33%': { content: '".."' },
  '66%': { content: '"..."' },
  '100%': { content: '"."' },
});

export const LoadingText = styled('span', {
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  '&::after': {
    content: '"."',
    animation: `${dotsAnimation} 1.5s infinite`,
    marginLeft: '4px',
    marginBottom: '8px'
  },
});