import React, { useState } from 'react';
import {
  MinganInfoModalContainer,
  MinganListNumberContainer,
  MinganParkingWeekStatusText,
  ParkingNumberText,
  TicketContainer,
  TitleContainer,
} from './styles';
import MinganInfoModal from '../MinganInfoModal';

interface MinganInfoListBottomModalProps {
  isVisible: boolean;
  onClose: () => void;
  onOpenAnotherModal: () => void;
}

interface ParkingInfo {
  status: string;
  type: string;
  price: string;
  parkingNumber: string;
}

const parkingData: ParkingInfo[] = [
  { status: '판매완료', type: '평일권', price: '140,000원', parkingNumber: '4345 주차장' },
  { status: '판매중', type: '주말권', price: '120,000원', parkingNumber: '1234 주차장' },
];

const MinganInfoListBottomModal: React.FC<MinganInfoListBottomModalProps> = ({ isVisible, onClose, onOpenAnotherModal }) => {
  const [isMinganInfoModal, setIsMinganInfoModal] = useState(false)


  if (!isVisible) return null;

  return (
    <>
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          zIndex: 9999,
        }}
        onClick={onClose}
      />
      <MinganInfoModalContainer isVisible={isVisible} onClick={onClose}>
        <TitleContainer>
          <span style={{ color: '#8B63BB' }}>희망하는 주차장</span>
          <span>을 선택해 주세요</span>
        </TitleContainer>

        <MinganListNumberContainer>총 {parkingData.length}건</MinganListNumberContainer>

        {parkingData.map((item, index) => (
          <TicketContainer key={index} onClick={() => onOpenAnotherModal() }>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  border: '1px solid #E4E4E4',
                  borderRadius: '20px',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  padding: '6px',
                  marginRight: '10px',
                  backgroundColor: item.status === '판매중' ? '#8B63BB' : '#FFFFFF',
                  color: item.status === '판매중' ? '#FFF' : '#B0B0B0',
                  fontSize: '13px',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  lineHeight: '15px',
                  minWidth: '50px',
                }}
              >
                <span>{item.status}</span>
              </div>
              <div
                style={{
                  textAlign: 'right',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <MinganParkingWeekStatusText style={{ color: item.status === '판매완료' ? '#B0B0B0' : '#8B63BB' }}>
                  <span style={{ marginRight: '3px' }}>{item.type}</span>
                  <span>{item.price}</span>
                </MinganParkingWeekStatusText>
              </div>
            </div>
            <ParkingNumberText style={{ color: item.status === '판매완료' ? '#B0B0B0' : '#000000' }}>
              {item.parkingNumber}
            </ParkingNumberText>
          </TicketContainer>
        ))}
      </MinganInfoModalContainer>
    </>
  );
}

export default MinganInfoListBottomModal;
