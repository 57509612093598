import { styled } from '@stitches/react';

export const TitleContainer = styled('div', {
  color: '#333',
  fontFamily: 'Inter',
  fontSize: '17px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '24px',
  textAlign: 'center'
})

export const CertNumberContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#F8F8FA',
  borderRadius: '10px',
  color: '#6B6B6B',
  marginTop: '10px',
  justifyContent: 'space-between',
  padding: '16px 16px 10px',

  variants: {
    selected: {
      true: {
        border: '1px solid #8B63BB',
        backgroundColor: '#FFF'
      },
      false: {
        border: '1px solid transparent',
        backgroundColor: '#F8F8FA'
      }
    },
  },

  defaultVariants: {
    selected: false
  }
});

export const SafeTextSpan = styled('span', {
  color: '#8B63BB'
})

export const PhoneInfoContainer = styled('div', {
  height: '47px',
  display: 'flex',
  borderRadius: '10px',
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: '#F8F8FA',
  color: '#8B63BB',
  fontFamily: 'Pretendard',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '20px',
  marginTop: '30px',
})

export const CertNumberInput = styled('input', {
  border: 'none',
  outline: 'none',
  fontWeight: 500,
  fontFamily: 'Pretendard',
  fontSize: '18px',
  lineHeight: 'normal',
  color: '#333',
  backgroundColor: '#F8F8FA',
  width: '60%',

  '&:focus': {
    backgroundColor: '#FFF',
  },

  '&::placeholder': {
    color: '#B0B0B0',
  },
});

export const CertNumberContentsContainer = styled('div', {
  fontFamily: 'Pretendard',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '20px',
  color: '#8B63BB',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',
})

export const TimerSpan = styled('span', {
  color: '#868686',
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
})

export const PhoneNumberTextSpan = styled('span', {
  marginLeft: '15px',
})

export const PhoneNumberSpan = styled('span', {
  color: '#333',
  marginRight: '15px',
})

export const CertTransmissionContainer = styled('div', {
  marginTop: '4px',
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
})

export const CertTransmissionButtonContainer = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
})

export const CloseIcon = styled('img', {
  cursor: 'none', marginRight: '10px'
})

export const TransmissionButton = styled('button', {
  color: '#fff',
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  padding: '8px 17px',
  border: 'none',
  borderRadius: '5px',
  backgroundColor: '#8B63BB'
})
