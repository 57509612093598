import React, { useState, useEffect } from 'react';
import {
  BottomButtonContainer,
  ButtonEnableDisable,
  ContentsContainer,
  ModalOverlay,
  PaymentMethodChoiceContainer,
  PaymentMethodChoiceItem,
  PaymentMethodModalContainer,
  TitleSpan,
} from './styles';

interface PaymentExpenditureBottomModalProps {
  isVisible: boolean;
  onClose: () => void;
  expenditure?: string
  onExpenditure: (method: string) => void;
}

const PaymentExpenditureBottomModal:
  React.FC<PaymentExpenditureBottomModalProps> =
    ({ isVisible, onClose, expenditure, onExpenditure }) => {

  const [selectedExpenditure, setSelectedExpenditure] = useState<string | null>(expenditure || null);

  const handleOverlayClick = () => {
    onClose();
  };

  const paymentMethod = ['사업자등록번호', '현금영수증카드'];

  // 모달이 열릴 때 선택된 방법 초기화
  useEffect(() => {
    if (isVisible) {
      setSelectedExpenditure(expenditure || '사업자등록번호');
    }
  }, [isVisible, expenditure]);

  useEffect(() => {
    if (selectedExpenditure) {
      onExpenditure(selectedExpenditure);
    }
  }, [selectedExpenditure, onExpenditure]);

  if (!isVisible) return null;

  return (
    <>
      <ModalOverlay onClick={handleOverlayClick} />
      <PaymentMethodModalContainer isVisible={isVisible}>
        <TitleSpan>유형선택</TitleSpan>
        <ContentsContainer>
          <PaymentMethodChoiceContainer>
            {paymentMethod.map((method, index) => (
              <PaymentMethodChoiceItem
                key={index}
                isSelected={selectedExpenditure === method}
                onClick={() => setSelectedExpenditure(method)}
              >
                {method}
              </PaymentMethodChoiceItem>
            ))}
          </PaymentMethodChoiceContainer>
        </ContentsContainer>

        <BottomButtonContainer>
          <ButtonEnableDisable
            onClick={() => {
              if (selectedExpenditure) {
                onExpenditure(selectedExpenditure)
                onClose();
              }
            }}
            disabled={!selectedExpenditure}
          >
            확인
          </ButtonEnableDisable>
        </BottomButtonContainer>
      </PaymentMethodModalContainer>
    </>
  );
};

export default PaymentExpenditureBottomModal;
