import React, { useState } from 'react';
import { MainContainer } from "../../../components/styles";
import Title from '../../../components/atoms/Title/PageTitle';
import { useNavigate } from "react-router-dom";
import icPhoto from '../../../assets/ic/ic_photo.svg'
import icExclamationPurple from "../../../assets/ic/ic_exclamation_purple.svg";
import {
  PhotoContainer,
  ReductionNotifyButtonContainer,
  ReductionNotifyNumberText,
  ReductionNotifyNumberTitleText,
  ReductionNotifyTitleContainer,
  ReductionNotifyTitleText,
  ReductionTitleContainer,
  ViewDivideContainer
} from "../styles";
import {
  ReductionNotifyFirstContentsText,
  ReductionNotifyFirstListContainer,
  UploadedImage,
  UploadedImageFileSizeSpan,
  UploadedImageRemoveButton,
  UploadedImageWrapper,
  UploadImageSection,
} from "./styles";
import ReductionFileSubmitBottomModal from "../../../components/atoms/Modal/ReductionFileSubmitBottomModal";
import {CameraFileUpload} from "../../../components/atoms/CameraFileUpload";
import { observer } from 'mobx-react';

const ReductionFileAttach = () => {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleLeftOnClick = () => {
    navigate(-1);
  };

  const handleSubmitClick = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);

    {/* 신청완료, 등록완료, 등록실패에 따라 화면 어디로 보낼지 확인 필요함 */}
    navigate('/reductionRegistrationSuccess')
    // navigate('/reductionRegistrationFail')
    // navigate('/reductionApplySuccess')
  };

  const TotalFileCount = observer(() => (
      <UploadedImageFileSizeSpan>
        {/* 최대 사진 갯수({directStore.uploadFileCount}개 / 10개) */}
      </UploadedImageFileSizeSpan>
  ));

  const TotalFileSize = observer(() => (
      <UploadedImageFileSizeSpan>
        최대용량(
        {/* {directStore.uploadFileSize ?? 0} */}
        mb / 80mb)
      </UploadedImageFileSizeSpan>
  ));

  return (
      <MainContainer>
        <Title
            title="복지대상 신청"
            leftArrowButtonVisible
            leftOnClick={handleLeftOnClick}
            rightTextButtonVisible={true}
            rightOnClick={() => {
              navigate('/carInfo')
            }}
            rightTitle={'건너뛰기'}
        />
        <ReductionTitleContainer>
          <span>공영 주차장 요금감면</span>
          <br />
          <span style={{ color: '#8B63BB' }}>증빙자료<span style={{ color: '#333' }}>를 업로드 해주세요.</span></span>
        </ReductionTitleContainer>

        <PhotoContainer onClick={() => { }}>
          <img src={icPhoto} alt={'포토 아이콘'} />
          <span style={{
            fontFamily: 'Pretendard',
            fontSize: '15px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: 'normal',
            color: '#B0B0B0'
          }}>파일첨부</span>
        </PhotoContainer>

        <div style={{ marginTop: '11px'}}>

          <TotalFileSize />
          <TotalFileCount />
          <UploadImageSection>
            <CameraFileUpload multiple />
            <CameraFileUpload multiple />
            <CameraFileUpload multiple />
            <CameraFileUpload multiple />
            <CameraFileUpload multiple />
            <CameraFileUpload multiple />
            <CameraFileUpload multiple />
            {/* {directStore.uploadFileList.map((file, index) => (
            <UploadedImageWrapper key={`uploadedFile_${index}`}>
              <UploadedImage src={file} alt={`uploadedFile_${index}`} />
              <UploadedImageRemoveButton onClick={() => openRemoveImageModal(index)}>
                <RemoveImageIcon />
              </UploadedImageRemoveButton>
            </UploadedImageWrapper>
          ))} */}
          </UploadImageSection>
        </div>
        <ReductionNotifyButtonContainer>
          <div style={{marginTop: '71px'}}>
            <ReductionNotifyTitleContainer>
              <img src={icExclamationPurple} alt="유의사항 아이콘" style={{marginRight: '8px'}}/>
              <ReductionNotifyTitleText>유의사항</ReductionNotifyTitleText>
            </ReductionNotifyTitleContainer>
            <ViewDivideContainer/>
            <div style={{marginBottom: '6px'}}>
              <ReductionNotifyNumberText>01</ReductionNotifyNumberText>
              <ReductionNotifyNumberTitleText>
                <span style={{color: '#ED1659'}}>필수 제출 서류</span>
                <span>를 꼭 업로드 해주세요.</span>
              </ReductionNotifyNumberTitleText>
            </div>
            <div style={{backgroundColor: '#F8F8FA', borderRadius: '10px'}}>
              <ReductionNotifyFirstListContainer>
                <ReductionNotifyFirstContentsText>장애인 복지카드</ReductionNotifyFirstContentsText>
                <ReductionNotifyFirstContentsText>장애인 자동차 표지</ReductionNotifyFirstContentsText>
                <ReductionNotifyFirstContentsText>자동차 등록증</ReductionNotifyFirstContentsText>
              </ReductionNotifyFirstListContainer>
            </div>
            <div style={{marginBottom: '10px'}}>
              <ReductionNotifyNumberText>02</ReductionNotifyNumberText>
              <ReductionNotifyNumberTitleText>
                요금 감면 증빙자료 미제출 시 할인 적용이 불가합니다.
              </ReductionNotifyNumberTitleText>
            </div>
            <div style={{marginBottom: '10px'}}>
              <ReductionNotifyNumberText>03</ReductionNotifyNumberText>
              <ReductionNotifyNumberTitleText>
                증빙서류를 촬영 후 이미지 파일로 첨부해주세요.
              </ReductionNotifyNumberTitleText>
            </div>
            <div style={{marginBottom: '10px'}}>
              <ReductionNotifyNumberText>04</ReductionNotifyNumberText>
              <ReductionNotifyNumberTitleText>
                <span>제출 시 주민등록번호 뒷자리, 카드 번호와 주소 등</span>
                <br/>
                <span style={{marginLeft: '26px'}}> 개인정보를 가려서 전송해 주세요.</span>
              </ReductionNotifyNumberTitleText>
            </div>
          </div>
          <button
              style={{
                padding: '0px',
                border: '0px',
                width: '100%',
                height: '46px',
                fontWeight: 700,
                fontSize: '16px',
                lineHeight: 'normal',
                borderRadius: '5px',
                marginBottom: '40px',
                marginTop: '26px',
                cursor: 'pointer',
              }}
              onClick={() => {
                handleSubmitClick()
              }}
          >
            제출하기
          </button>
        </ReductionNotifyButtonContainer>
        <ReductionFileSubmitBottomModal
            isVisible={isModalVisible}
            onClose={handleModalClose}
        />
      </MainContainer>
  );
}

export default ReductionFileAttach;
