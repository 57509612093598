import { styled } from '@stitches/react';

export const NotSharedTextNotifyContainer = styled('div', {
  marginTop: '20px',
  textAlign: 'center',
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  color: '#868686',
});

export const CardContainer = styled('div', {
  width: '100%',
  height: '120px',
  display: 'flex',
  backgroundColor: '#EBEBFB',
  borderRadius: '10px',
  marginTop: '20px',
});

// 텍스트와 버튼을 이미지를 포함하는 내부 컨테이너
export const CardContentContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  margin: '20px 20px',
  flex: 1,
});

// 제목 텍스트
export const CardTitleText = styled('div', {
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
});

export const CardContentsText = styled('div', {
  fontFamily: 'Pretendard',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  color: '#6B6B6B',
  marginTop: '5px'
});

export const CardApplyButton = styled('button', {
  marginTop: '5px',
  color: '#8B63BB',
  textAlign: 'center',
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontStyle: 'normal',
  lineHeight: 'normal',
  background: '#fff',
  borderRadius: '5px',
  borderColor: '#8B63BB',
  borderWidth: '1px',   // Border width
  borderStyle: 'solid'
})

export const CardImageContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  flex: 1,
  width: '100%',
  marginRight: '21px',
  background: 'none',
  height: '100%'
})

export const CardImage = styled('img', {
  width: '120px',
  height: '120px',
  objectFit: 'contain',
})
