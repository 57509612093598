import {useNavigate} from "react-router-dom";
import {MainContainer} from "../../../components/styles";
import Title from "../../../components/atoms/Title/PageTitle";
import icZoomansa from "../../../assets/ic/ic_zoomansa.svg";
import React from "react";

const NotificationCenterImportantMessageDetail = () => {
  const navigate = useNavigate()
  const handleLeftOnClick = () => {
    navigate(-1);
  };

  return(
      <MainContainer>
        <Title
            leftArrowButtonVisible
            leftOnClick={handleLeftOnClick}
            rightTextButtonVisible={false}
            titleImage={icZoomansa}
        />
        <div style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: '1px solid #E6E6E6',
          paddingBottom: '10px',
          marginBottom: '26px',
        }}>
          <span style={{
            color: '#8B63BB',
            fontFamily: 'Pretendard',
            fontSize: 'normal',
            fontWeight: 400,
            lineHeight: 'normal'
          }}>공유 등록 안내</span>
          <span
          style={{
            color: '#6B6B6B',
            fontFamily: 'Pretendard',
            fontSize: 'normal',
            fontWeight: 400,
            lineHeight: 'normal'
          }}
          >2024-08-21</span>
        </div>
        <div>
          [입금요청] 49,000원 (예금주 : 주차장만드는사람들 (주) 국민은행) 입금바랍니다.
        </div>
      </MainContainer>
  )

}

export default NotificationCenterImportantMessageDetail