import React from "react";
import icExclamation from '../../../assets/ic/ic_exclamation.svg'
import imgPark from '../../../assets/images/img_park.png'
import imgParkingLot from '../../../assets/images/img_parking_lot.png'
import {
  CardApplyButton,
  CardContainer, CardContentContainer, CardContentsText, CardImage, CardImageContainer, CardTitleText,
  NotSharedTextNotifyContainer
} from "./styles";
import {useNavigate} from "react-router-dom";

const NotSharedParking = () => {
  const navigate = useNavigate()

  const handleMinganClick = () => {
    navigate('/shareRequestMingan')
  }

  const handleGeojuClick = () => {
    navigate('/shareRequestGeoju')
  }

  return (
      <>
        <CardContainer>
          <CardContentContainer>
            <CardTitleText>
              부설 주차장
            </CardTitleText>
            <CardContentsText>
              빌라, 오피스, 상가 등
            </CardContentsText>
            <CardContentsText>
              일반 주차장
            </CardContentsText>
            <CardApplyButton onClick={() => {
              handleMinganClick()
            }}>
              신정하기
            </CardApplyButton>
          </CardContentContainer>
          <CardImageContainer onClick ={() => {
            handleMinganClick()
          }}>
            <CardImage src={imgPark} alt={"주차장 이미지"}/>
          </CardImageContainer>
        </CardContainer>
        <CardContainer>
          <CardContentContainer>
            <CardTitleText>
              거주자 우선 주차장
            </CardTitleText>
            <CardContentsText>
              구청에서 정기배정 받은
            </CardContentsText>
            <CardContentsText>
              주차구획
            </CardContentsText>
            <CardApplyButton onClick={() => {
              handleGeojuClick()
            }}>
              신정하기
            </CardApplyButton>
          </CardContentContainer>
          <CardImageContainer onClick ={() => {
            handleGeojuClick()
          }}>
            <CardImage src={imgParkingLot} alt={"거주지 이미지"}/>
          </CardImageContainer>
        </CardContainer>
        <NotSharedTextNotifyContainer>
          주차장 유형을 선택 후 공유신청하시면 등록된 연락처로
          <br/>
          순차적 안내를 드립니다.
        </NotSharedTextNotifyContainer>
      </>
  );
}

export default NotSharedParking;