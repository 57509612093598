import { styled } from '@stitches/react';

export const SharedRequestMinganMainContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  margin: 'auto',

  '@media screen and (max-height: 480px)': {
    padding: '20px', // 화면 높이가 480px 이하일 때 패딩 조정
  },
});

export const PhoneNumberContainer = styled('div', {
  fontFamily: 'Pretendard',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  marginLeft: '25px',
  marginRight: '25px',
});

// 전화번호와 레이블을 가로로 정렬
export const PhoneNumberHeader = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  color: '#868686'
});

// 전화번호 오른쪽 정렬
export const PhoneNumber = styled('div', {
  fontSize: '16px',
  textAlign: 'right',
  flex: 1,
  display: 'flex',
  justifyContent: 'flex-end',
  fontFamily: 'Pretendard',
  textSize: '18px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  color: '#8B63BB'
});

// 연락처 안내 정보
export const ContactInfo = styled('div', {
  fontSize: '12px',
  color: '#6B6B6B',
  display: 'flex',
  fontFamily: 'Pretendard',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  marginTop: '7px',
  justifyContent: 'flex-end',
});

export const SearchContainer = styled('div', {
  height: '45px',
  background: '#F8F8FA',
  fontFamily: 'Pretendard',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
  borderRadius: '5px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: '10px',
  paddingRight: '10px',
  marginTop: '32px',
  color: '#868686',
});

export const SearchIcon = styled('img', {
  width: '20px',
  height: '20px',
})

export const SharedRequestNaverMapContainer = styled('div', {
  position: 'relative',
  backgroundColor: 'green',
  flex: '1',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  width: '100%',
  marginTop: '22px'
})

export const NaverMapContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
})

export const AdviceButton = styled('div', {
  width: '310px',
  height: '46px',
  background: '#F2F2F2',
  borderRadius: '5px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  color: '#8B63BB',
  marginTop: 'auto',
  marginBottom: '29px',
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontWeight: 'bold',
});
