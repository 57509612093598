import { makeAutoObservable } from 'mobx';
import BrandListService from '../../../service/Brand/brandService';
import BaseError from '../../../models/baseError';
import { IBrandData, IBrandCoupon } from '../../../models/Brand/brandModel';
import { LINK_UID } from '../../../constants/userInfoConstants';

class BrandListStore {
  brands: IBrandData[] = []
  loading: boolean = false
  error: string | null = null
  useAvailPoints: string = '0'
  couponCount: number = 0
  brandCoupons: IBrandCoupon[] = []
  selectedGoods: IBrandCoupon | null = null

  constructor() {
    makeAutoObservable(this)
  }

  async fetchBrands( mLinkUID: number) {
    this.loading = true
    this.error = null

    try {
      const response = await BrandListService.postBrandList({ mLinkUID: mLinkUID })
      if (response instanceof BaseError) {
        this.error = response.errorMessage
      } else {
        this.brands = response.data.brandData
        this.useAvailPoints = response.data.useAvailPoints
        this.couponCount = response.data.couponCount
      }
    } catch (error) {
      this.error = '브랜드 목록을 가져오는 중 오류가 발생했습니다.'
    } finally {
      this.loading = false
    }
  }

  async fetchBrandCoupons(brandName: string) {
    this.loading = true
    this.error = null

    try {
      const linkUID = parseInt(sessionStorage.getItem(LINK_UID) || '0', 10)
      const requestBody = { mLinkUID: linkUID, brandName }
      const response = await BrandListService.postBrandCoupon(requestBody)

      if (response instanceof BaseError) {
        this.error = response.errorMessage
      } else {
        this.brandCoupons = response.data.couponData
        this.useAvailPoints = response.data.useAvailPoints
        this.couponCount = response.data.couponCount
      }
    } catch (err) {
      this.error = '브랜드 쿠폰을 가져오는 중 오류가 발생했습니다.'
    } finally {
      this.loading = false
    }
  }

  setSelectedGoods(coupon: IBrandCoupon | null) {
    this.selectedGoods = coupon
  }

  async purchaseCoupon(mLinUID: number, couponCode: string) {
    if (!this.selectedGoods) return

    const requestBody = { mLinkUID: mLinUID, couponCode }
    try {
      const response = await BrandListService.postPointPurchaseCoupon(requestBody)

      if (response instanceof BaseError) {
        this.error = response.errorMessage
        console.log(response.message)
        return false
      } else {
        return true
      }
    } catch (error) {
      console.error('구매 처리 중 오류 발생:', error)
      throw error
    }
  }
}

export const brandListStore = new BrandListStore();
