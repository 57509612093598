
import React, { useState, useRef, useEffect } from 'react';
import { FixedSizeList as List } from 'react-window';
import {
  Sidebar,
  SidebarOpen,
  NonMemberContainer,
  UserIconSize,
  ArrowIconSize,
  UserText,
  PointContainer,
  DashContainer,
  BurgerIc,
  NotificationIc,
  AddressContainer,
  MenuTopContainer,
  MenuBottomContainer,
  CoinIc1,
  CoinIc2,
  PointButton,
  BottomBannerContainer,
  HandCoinImage,
  ParkingShareSpan,
  ProfitsSpan,
} from './styles';
import icBurger from '../../assets/ic/ic_burger.svg';
import icUser from '../../assets/ic/ic_user.svg';
import icArrowRightWhite from '../../assets/ic/ic_arrow_right_white.svg';
import icParkingHistory from '../../assets/ic/ic_parking_history.svg'
import icShareHistory from '../../assets/ic/ic_share_history.svg'
import icCamera from '../../assets/ic/ic_camera.svg'
import icNotificationHome from '../../assets/ic/ic_notification_home.svg'
import icNotificationSetting from '../../assets/ic/ic_notification_setting.svg'
import icSetting from '../../assets/ic/ic_setting.svg'
import icArrowRightPurple from '../../assets/ic/ic_arrow_right_purple.svg'
import icArrowRightGrayMedium from '../../assets/ic/ic_arrow_right_gray_medium.svg'
import icDropDown from '../../assets/ic/ic_dropdown.svg'
import icGoldCoin1 from '../../assets/ic/ic_gold_coin1.png'
import icGoldCoin2 from '../../assets/ic/ic_gold_coin2.png'
import imgHandCoin from '../../assets/images/img_hand_coin.png'
import { useNavigate } from "react-router-dom";
import BottomSlideBanner from '../../components/atoms/BottomSlideBanner'
import DesiredAddressBottomModal from "../../components/atoms/Modal/DesiredAddressBottomModal";
import CheckModal from "../../components/atoms/Modal/CheckModal";
import {set} from "react-hook-form";
import TwoButtonModal from "../../components/atoms/Modal/TwoButtonModal";
import {toggleStore} from "../../stores/toggle/toggleStore";
import MinganInfoListBottomModal from '../../components/atoms/Modal/MinganListBottomModal';
import GeojuInfoBottomModal from '../../components/atoms/Modal/GeojunInfoModal';
import testNaverMap from '../../assets/images/img_test_map.png';
import icClockGray from '../../assets/ic/ic_clock_gray.svg'
import icClockPurple from '../../assets/ic/ic_clock_purple.svg'

import icCalendarGray from '../../assets/ic/ic_calendar_gray.svg'
import icCalendarWhite from '../../assets/ic/ic_calendar_white.svg'

import icChargeGray from '../../assets/ic/ic_charger_grey.svg'
import icChargeWhite from '../../assets/ic/ic_charger_white.svg'
import MinganInfoModal from '../../components/atoms/Modal/MinganInfoModal';
import ChargeDetailBottomModal from '../../components/atoms/Modal/ChargeDetailBottomModal';

const Home = () => {
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const backgroundRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate('/');
  };

  const handleMyPageClick = () => {
    navigate('/myPage');
  };

  const handleNotifyClick = () => {
    {/* 로그인이 되어있지 않다면 로그인 요청 모달, 되어 있다면 NotiCenter로 이동 */}
    toggleStore.setSelectedIndex(0);
    navigate('/notificationCenter')
  }

  const [isAddressModalVisible, setIsAddressModalVisible] = useState(false);

  const addressBottomModal = () => {
    setIsAddressModalVisible(true);
  };

  const closeAddressModal = () => {
    setIsAddressModalVisible(false);
  };

  // 시간권 클릭
  const [isClockClicked, setIsClockClicked] = useState(false);
  const handleClockClick = () => {
    setIsClockClicked(true)
    setIsCalendarClicked(false)
    setIsChargerClicked(false)
  };

  // 월주차 클릭
  const [isCalendarClicked, setIsCalendarClicked] = useState(true);
  const handleCalendarClick = () => {
    setIsCalendarClicked(true)
    setIsClockClicked(false)
    setIsChargerClicked(false)
  };

  // 충전소 클릭
  const [isChargerClicked, setIsChargerClicked] = useState(false);
  const handleChargerClick = () => {
    setIsChargerClicked(true);
    setIsClockClicked(false)
    setIsCalendarClicked(false)
  };



  // 햄버거 클릭
  const burgerClick = () => {
    setSideBarOpen(!sideBarOpen);
  };

  const [isLoginRequestModal, setIsLoginRequestModal] = React.useState(false);
  const loginRequestModal = () => {
    setIsLoginRequestModal(true)
  }

  const [isGeojuInfoModal, setIsGeojuInfoModal] = React.useState(false);
  const geojuInfoModal = () => {
    setIsGeojuInfoModal(true)
  }

  const [isMinganInfoListModal, setIsMinganInfoListModal] = React.useState(false)

  const MinganInfoListModal = () => {
    setIsMinganInfoListModal(true)
  }

  const [isMinganInfoModal, setIsMinganInfoModal] = React.useState(false)

  const handleMinganInfoModal = () => {
    setIsMinganInfoModal(true)
  }

  const handleClickOutside = (event: MouseEvent | TouchEvent) => {
    if (
      sidebarRef.current &&
      !sidebarRef.current.contains(event.target as Node) &&
      backgroundRef.current &&
      !backgroundRef.current.contains(event.target as Node)
    ) {
      setSideBarOpen(false);
    }
  };

  useEffect(() => {
    const handleContextMenu = (event: MouseEvent) => {
      event.preventDefault(); // 기본 우클릭 메뉴 막기
      event.stopPropagation(); // 이벤트 전파 막기
      return false;
    };

    window.addEventListener('contextmenu', handleContextMenu);
    // 데스크탑 환경
    document.addEventListener('mousedown', handleClickOutside);
    // 모바일 환경`
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      window.removeEventListener('contextmenu', handleContextMenu);
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  // 사이드바 아이템 데이터
  // 리스트 항목의 데이터
  // {/* 로그인이 되어있지 않다면 로그인 요청 모달, 되어 있다면 NotiCenter로 이동 */}

  const items = [
    { itemImg: icParkingHistory, text: '주차장 이용내역', imgArrowLeft: icArrowRightPurple, onClick: () => navigate('/parkingUserList') },
    { itemImg: icShareHistory, text: '주차장 공유내역', imgArrowLeft: icArrowRightPurple, onClick: () => navigate('/shareRequestMain') },
    { itemImg: icCamera, text: '주차장 사진 업로드', imgArrowLeft: icArrowRightGrayMedium, onClick: () => navigate('/parkingPhotoUpload') },
    { itemImg: icNotificationSetting, text: '알림', imgArrowLeft: icArrowRightGrayMedium, onClick: handleNotifyClick },
    { itemImg: icSetting, text: '설정', imgArrowLeft: icArrowRightGrayMedium, onClick: () => navigate('/setting') }
  ];

  // 사이드바 아이템 렌더링
  const Row = ({ index, style }: { index: number; style: React.CSSProperties }) => {
    const { itemImg, text, imgArrowLeft, onClick } = items[index];
    return (
      <div
        style={{
          ...style,
          color: index === 0 || index === 1 ? '#8B63BB' : '#000000',
          padding: '16px 31px',
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          justifyContent: 'space-between',
          marginTop: '18px'
        }}
        onClick={onClick}
      >
        <div style={{display: 'flex', alignItems: 'center'}}>
          <img src={itemImg} alt={'각 리스트 아이콘'} style={{width: '24px', height: '24px', marginRight: '10px'}}/>
          <span>{text}</span>
        </div>
        <img src={imgArrowLeft} alt={'애로우 아이콘'} style={{marginLeft: '10px', alignSelf: 'center'}}/>
        <div
          style={{
            content: '""',
            position: 'absolute',
            left: '20px',
            right: '20px',
            bottom: '0',
            height: '1px',
            backgroundColor: '#E6E6E6',
            zIndex: -1
          }}
        />
      </div>
    );
  };

  return (
    <div ref={backgroundRef}>
      <div>
        {/*<div*/}
        {/*  style={{*/}
        {/*    position: 'absolute',*/}
        {/*    top: 0,*/}
        {/*    left: 0,*/}
        {/*    width: '100%',*/}
        {/*    height: '100%',*/}
        {/*    backgroundImage: `url(${testNaverMap})`,*/}
        {/*    backgroundSize: 'cover',*/}
        {/*    backgroundPosition: 'center',*/}
        {/*  }}*/}
        {/*/>*/}

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: '25px 25px',
            border: '1px solid #E4E4E4',
            borderRadius: '3px',
            position: 'relative',
            zIndex: 1,
            backgroundColor: 'white',
          }}
        >
          <MenuTopContainer>
            <BurgerIc onClick={burgerClick} src={icBurger} alt="Burger Icon" />
            <AddressContainer onClick={addressBottomModal}>
              서울 특별시 강동구 천호동
              <img style={{ marginLeft: '4px' }} src={icDropDown} alt={'드랍다운 아이콘'} />
            </AddressContainer>
            <DesiredAddressBottomModal isVisible={isAddressModalVisible} onClose={closeAddressModal}/>
            <NotificationIc
              onClick={handleNotifyClick}
              src={icNotificationHome}
              alt="홈화면 알림 아이콘"
            />
          </MenuTopContainer>

          <MenuBottomContainer>
            <div
              onClick={handleClockClick}
              style={{
                flex: 1,
                border: '1px solid #E4E4E4',
                borderRadius: '20px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                padding: '6px',
                marginRight: '10px',
                backgroundColor: isClockClicked ? '#8B63BB' : 'transparent',
                borderColor: isClockClicked ? '#8B63BB' : '#E4E4E4',
              }}
            >
              <img
                src={isClockClicked ? icClockPurple : icClockGray}
                alt={isClockClicked ? '시계 아이콘 보라색' : '시계 아이콘'}
                style={{ marginRight: '8px' }}
              />
              <span style={{ color: isClockClicked ? '#FFFFFF' : '#000000' }}>시간권</span>{' '}
              {/* 클릭 시 텍스트 색상 변경 */}
            </div>

            <div
              onClick={handleCalendarClick}
              style={{
                flex: 1,
                border: '1px solid #E4E4E4',
                borderRadius: '20px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                padding: '6px',
                marginRight: '10px',
                backgroundColor: isCalendarClicked ? '#8B63BB' : 'transparent', // 클릭 시 배경색 보라색으로 변경
                borderColor: isCalendarClicked ? '#8B63BB' : '#E4E4E4', // 클릭 시 테두리 색상 변경
              }}
            >
              <img
                src={isCalendarClicked ? icCalendarWhite : icCalendarGray} // 클릭 시 아이콘 변경
                alt={isCalendarClicked ? '달력 아이콘 보라색' : '달력 아이콘'}
                style={{ marginRight: '8px' }}
              />
              <span style={{ color: isCalendarClicked ? '#FFFFFF' : '#000000' }}>월주차</span>{' '}
              {/* 클릭 시 텍스트 색상 변경 */}
            </div>

            <div
              onClick={handleChargerClick}
              style={{
                flex: 1,
                border: '1px solid #E4E4E4',
                borderRadius: '20px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                padding: '6px',
                marginRight: '10px',
                backgroundColor: isChargerClicked ? '#8B63BB' : 'transparent',
                borderColor: isChargerClicked ? '#8B63BB' : '#E4E4E4',
              }}
            >
              <img
                src={isChargerClicked ? icChargeWhite : icChargeGray}
                alt={isChargerClicked ? '달력 아이콘 보라색' : '달력 아이콘'}
                style={{ marginRight: '8px' }}
              />
              <span style={{ color: isChargerClicked ? '#FFFFFF' : '#000000' }}>충전소</span>{' '}
              {/* 클릭 시 텍스트 색상 변경 */}
            </div>
          </MenuBottomContainer>
        </div>

        {sideBarOpen ? (
          <SidebarOpen
            ref={sidebarRef}
            style={{ display: 'flex', flexDirection: 'column', width: '280px' }}
          >
            <NonMemberContainer>
              <UserIconSize src={icUser} alt="icUser" />
              <UserText
                onClick={() => {
                  {
                    /* 로그인이 되어있지 않다면 로그인 화면 이동, 되어 있다면 MyPage로 이동 */
                  }
                  handleLoginClick();
                  // handleMyPageClick()
                }}
              >
                로그인을 해주세요
              </UserText>
              <ArrowIconSize
                src={icArrowRightWhite}
                alt="icArrowRightWhite"
                onClick={() => {
                  {
                    /* 로그인이 되어있지 않다면 로그인 화면 이동, 되어 있다면 MyPage로 이동 */
                  }
                  // handleLoginClick();
                  handleMyPageClick();
                }}
              />
            </NonMemberContainer>
            <div className="whiteBg">
              <div
                style={{
                  margin: '29px 30px',
                  color: '#6b6b6b',
                  fontSize: '12px',
                  alignItems: 'flex-start',
                }}
              >
                보유 포인트
                <div>
                  <DashContainer>-</DashContainer>
                  <PointContainer>P</PointContainer>
                  <PointButton>사용하기</PointButton>
                </div>
              </div>
            </div>
            <div
              style={{
                background: '#F7F7F7',
                height: '100%',
                zIndex: 3,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ flexGrow: 1, overflowY: 'auto' }}>
                <List height={350} itemCount={items.length} itemSize={50} width={280}>
                  {Row}
                </List>
              </div>
              <div style={{ marginLeft: '63px' }}>
                <CoinIc1 src={icGoldCoin1} alt="코인 이미지1" />
              </div>
              <CoinIc2 src={icGoldCoin2} alt="코인 이미지2" />

              <BottomBannerContainer
                onClick={() => window.open('http://hostlanding.zoomansa.com/?type=25', '_blank')}
              >
                <div style={{ display: 'flex' }}>
                  <div style={{ marginLeft: '16px' }}>
                    <HandCoinImage
                      src={imgHandCoin}
                      alt="핸드코인 이미지"
                    />
                  </div>
                  <div style={{ textAlign: 'left', display: 'inline-block', marginLeft: '18px' }}>
                    <ParkingShareSpan>
                      빈 주차장 공유
                    </ParkingShareSpan>
                    <ProfitsSpan>
                      하고
                    </ProfitsSpan>
                    <br/>
                    <ProfitsSpan>
                      매달 수익금 받아가세요!
                    </ProfitsSpan>
                  </div>
                </div>
              </BottomBannerContainer>
            </div>
            <button onClick={() => loginRequestModal()}>모달 확인용</button>
          </SidebarOpen>
        ) : (
          <Sidebar ref={sidebarRef}>
            <div>Sidebar Content</div>
          </Sidebar>
        )}
        <BottomSlideBanner />
        {/* 모달 확인 테스트 용 */}
        <div
          style={{
            marginLeft: '20px',
            marginRight: '20px',
          }}
        >
          <div
            onClick={() => {
              geojuInfoModal();
            }}
          >
            거주자 바텀 다이얼로그
          </div>
          <div
            style={{
              marginTop: '40px'
            }}
            onClick={() => {
              MinganInfoListModal();
            }}
          >
            월주차 바텀 다이얼로그 여러개 리스트
          </div>

          // 페이지로 넘기기
          <div
            style={{ marginTop: '40px' }} onClick={() => {
            // handleMinganInfoModal()
            navigate('/monthParking')
          }}
          >
            월주차 바텀 다이얼로그 한개
          </div>

          <div
            style={{
              marginTop: '40px'
            }}
            onClick={() => {
              geojuInfoModal();
            }}
          >
            충전소 바텀 다이얼로그 여러개 리스트
          </div>
          <div
            style={{
              marginTop: '40px'
            }}
            onClick={() => {
              geojuInfoModal();
            }}
          >
            충전소 바텀 다이얼로그 한개
          </div>
        </div>

        <GeojuInfoBottomModal
          isVisible={isGeojuInfoModal}
          onClose={() => {
            setIsGeojuInfoModal(false);
          }}
        />

        <MinganInfoListBottomModal
          isVisible={isMinganInfoListModal}
          onClose={() => {
            setIsMinganInfoListModal(false);
          }}
          onOpenAnotherModal={() => {
            setIsMinganInfoListModal(false);
            setIsMinganInfoModal(true)
          }}
        />

        <MinganInfoModal
          isVisible={isMinganInfoModal}
          onClose={() => setIsMinganInfoModal(false)}/>

        <TwoButtonModal
          isVisible={isLoginRequestModal}
          onClose={() => setIsLoginRequestModal(false)}
          title={'공유 신청이 완료되었습니다.'}
          content={<div>로그인을 진행후 이용해주세요.</div>}
          leftButtonText={'닫기'}
          rightButtonText={'로그인'}
          rightClick={() => {
            navigate('/');
          }}
        />
        <ChargeDetailBottomModal isVisible={isLoginRequestModal}
                                 onClose={() => setIsLoginRequestModal(false)}/>
      </div>
    </div>
  );
};

export default Home;