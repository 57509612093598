import React from 'react';
import {
  CheckButton,
  ContentsContainer, FirstMarginTopSpan,
  FirstTitleSpan, SecondTitleSpan,
  CouponContentsTitleContainer,
  CouponModalContainer, ModalOverlay, ContentsDetailContainer, ContentsDetailSpan,
} from './styles';

import icDotGray from '../../../../assets/ic/ic_dot_gray.svg'

interface CouponBottomModalProps {
  isVisible: boolean;
  onClose: () => void;
}

const CouponBottomModal: React.FC<CouponBottomModalProps> = ({ isVisible, onClose }) => {
  const handleOverlayClick = () => {
    onClose();
  };

  const handleModalContentClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  if (!isVisible) return null;

  return (
    <>
      <ModalOverlay onClick={handleOverlayClick}></ModalOverlay>
      <CouponModalContainer isVisible={isVisible} onClick={handleOverlayClick}>
        <CouponContentsTitleContainer onClick={handleModalContentClick}>
          <FirstTitleSpan>쿠폰 사용법</FirstTitleSpan>
          <ContentsContainer>
            <FirstMarginTopSpan>
              [교환]을 누르면 [메뉴&gt;내정보]에 적힌 휴대폰 번호로
              <br />
              교환권 정보를 보냅니다. 문자를 확인해 주세요
            </FirstMarginTopSpan>

            <SecondTitleSpan>쿠폰 유의사항</SecondTitleSpan>
            <ContentsDetailContainer>
              <img src={icDotGray} alt={'회색 점 아이콘'}/>
              <ContentsDetailSpan>
                쿠폰 구매 및 교환이 완료될 경우 포인트 복구가
                <br />
                불가능합니다.
              </ContentsDetailSpan>
            </ContentsDetailContainer>

            <ContentsDetailContainer>
              <img src={icDotGray} alt={'회색 점 아이콘'}/>
              <ContentsDetailSpan>
                사용하였거나 유효기간이 지난 쿠폰은 사용이 불가
                <br />
                합니다.
              </ContentsDetailSpan>
            </ContentsDetailContainer>

            <ContentsDetailContainer>
              <img src={icDotGray} alt={'회색 점 아이콘'} style={{ marginTop: '16px'}} />
              <ContentsDetailSpan>
                구매한 쿠폰 당 하나의 쿠폰으로만 교환 가능합니다.
              </ContentsDetailSpan>
            </ContentsDetailContainer>

            <ContentsDetailContainer>
              <img src={icDotGray} alt={'회색 점 아이콘'}/>
              <ContentsDetailSpan>
                교환하신 상품 관련 문의 사항은 해당 브랜드의 고객
                <br />
                센터로 문의해 주세요.
              </ContentsDetailSpan>
            </ContentsDetailContainer>

            <ContentsDetailContainer>
              <img src={icDotGray} alt={'회색 점 아이콘'}/>
              <ContentsDetailSpan>
                기타 문의 사항은 주만사 고객센터(1666-6248)로
                <br />
                문의해 주세요.
              </ContentsDetailSpan>
            </ContentsDetailContainer>
          </ContentsContainer>
        </CouponContentsTitleContainer>
        <CheckButton onClick={handleOverlayClick}>확인</CheckButton>
      </CouponModalContainer>
    </>
  );
};

export default CouponBottomModal
