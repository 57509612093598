import BaseService from '../baseService';
import { IPointResponse, IPointSearch } from '../../models/Point/pointHistoryModel';
import BaseError from '../../models/baseError';
import ErrorHandler from '../../utils/error/errorHandler';
import AxiosClient from '../../utils/axiosClient';

interface PointServiceApiEndpoints {
  POINT_SEARCH: string;
}

class PointSearchService extends BaseService<PointServiceApiEndpoints> {
  apiEndpoints: PointServiceApiEndpoints = {
    POINT_SEARCH: 'getPoint',
  };

  postPointSearch = async (requestBody: IPointSearch): Promise<IPointResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .data({ ...requestBody })
        .url(`${this.apiEndpoints.POINT_SEARCH}`)
        .build<IPointResponse>();
      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };
}

export default new PointSearchService();
