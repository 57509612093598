import { styled } from '@stitches/react';


export const PasswordMessage = styled('div', {
  marginTop: '7px',
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
  variants: {
    isInvalid: {
      true: {
        color: '#ED1659',
      },
      false: {
        color: '#B0B0B0',
      },
    },
    isEmpty: {
      true: {
        color: '#ED1659',
      },
      false: {
        color: '#B0B0B0',
      },
    },
    isMismatch: {
      true: {
        color: '#ED1659',
      },
      false: {
        color: '#B0B0B0',
      },
    },
  },
  defaultVariants: {
    isInvalid: false,
    isEmpty: false,
    isMismatch: false,
  },
});