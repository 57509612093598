import AxiosClient from '../../utils/axiosClient'
import BaseService from '../baseService';
import BaseError from '../../models/baseError';
import ErrorHandler from '../../utils/error/errorHandler';
import {
  IBrandListRequest,
  IBrandListResponse,
  IGetBrandCouponRequest,
  IGetBrandCouponResponse,
} from '../../models/Brand/brandModel';
import { IPointPurchaseCoupon, IPointPurchaseCouponResponse } from '../../models/Point/purchaseCouponModel';

interface BrandListEndpoints {
  BRAND_LIST: string
  BRAND_COUPON: string
  POINT_PURCHASE_COUPON: string
}
class BrandService extends BaseService<BrandListEndpoints>{
  apiEndpoints: BrandListEndpoints = {
    BRAND_LIST: 'getBrands',
    BRAND_COUPON: 'getBrandCoupon',
    POINT_PURCHASE_COUPON: 'purchaseCoupon'
  }

  postBrandList = async (requestBody: IBrandListRequest): Promise<IBrandListResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .data({...requestBody})
        .url(`${this.apiEndpoints.BRAND_LIST}`)
        .build<IBrandListResponse>();

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError()
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError()
    }
  }

  postBrandCoupon = async (requestBody: IGetBrandCouponRequest): Promise<IGetBrandCouponResponse | BaseError> => {
    try {
      console.log('Sending request with body:', requestBody.brandName);
      const response = await AxiosClient.create()
        .post()
        .data({...requestBody})
        .url(`${this.apiEndpoints.BRAND_COUPON}`)
        .build<IGetBrandCouponResponse>();
      return response.data
    } catch (error) {
      console.error(error)
      return ErrorHandler.create(error).getError()
    }
  }

  postPointPurchaseCoupon = async (requestBody: IPointPurchaseCoupon) : Promise<IPointPurchaseCouponResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .data({...requestBody})
        .url(`${this.apiEndpoints.POINT_PURCHASE_COUPON}`)
        .build<IPointPurchaseCouponResponse>();

      if (response.data.res_code === 200) {
        console.log('쿠폰 구매 성공')
        return response.data;
      } else {
        console.log('쿠폰 구매 실패')
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.error(error)
      return ErrorHandler.create(error).getError()
    }
  }
}

export default new BrandService();
