import { styled } from '@stitches/react';

export const Overlay = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  position: 'relative',
  backgroundColor: 'rgba(0, 0, 0, 0.5)', // 배경 투명도 설정
});

export const Content = styled('div', {
  position: 'relative',
  width: '80%',
  height: '80%',
  maxWidth: '90vw',
  maxHeight: '90vh',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

export const ImgClose = styled('img', {
  position: 'absolute',
  top: '10px',
  right: '10px',
  width: '20px',
  height: '20px',
  border: 'none',
  borderRadius: '50%',
  padding: '6px 10px',
  cursor: 'pointer',
  zIndex: '10',
  '@media (max-width: 768px)': {
    top: '8px',
    right: '10px',
  },
});

export const NavigationButtonContainer = styled('div', {
  display: 'flex',
  position: 'absolute',
  bottom: '160px', // 이미지 바로 아래에 위치하도록 설정
  zIndex: '10',
  left: '50%',
  transform: 'translateX(-50%)', // 중앙 정렬을 위해 좌우로 이동
});

export const NavigationButton = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '40px',
  height: '40px',
  borderRadius: '50%',
  cursor: 'pointer',
  opacity: 1,
  position: 'relative',
  fontSize: '16px',
  fontWeight: 'bold',
  backgroundColor: 'transparent',
  color: '#000',

  '&:hover': {
    opacity: 1,
  },
});
