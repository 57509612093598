// src/components/styles.ts
import { styled } from '@stitches/react';

export const TabMenu = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  marginTop: '20px'
});

export const Submenu = styled('button', {
  padding: '8px 64px',
  backgroundColor: 'transparent',
  border: 'none',
  borderBottom: '2px solid transparent',
  transition: 'border-color 0.3s, color 0.3s',
  position: 'sticky',
  top: 0,

  '&:after': {
    content: '""',
    position: 'absolute',
    left: 0,
    right: 0,
    marginTop: '29px',
    height: '1px',
    backgroundColor: '#E4E4E4',
    borderBottom: '1px solid transparent',
    zIndex: -1,
  },
  variants: {
    focused: {
      true: {
        borderBottomColor: '#8B63BB',
        color: '#8B63BB',
        fontFamily: 'Pretendard',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: 'normal'
      },
      false: {
        color: '#666',
        fontFamily: 'Pretendard',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: 'normal'
      },
    },
  },
  defaultVariants: {
    focused: 'false',
  },
});

export const Desc = styled('div', {
  border: '1px solid $border',
  borderRadius: '$medium',
  paddingTop: '20px'
});
