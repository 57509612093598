import { ITerraceTowerUsageHistoryCancelData } from 'models/TerraceTower/terraceTowerUsageHistoryCancel';
import terraceTowerUsageHistoryDetailService from '../../../service/TerraceTower/terraceTowerService';
import BaseError from '../../../models/baseError';
import { makeAutoObservable } from 'mobx';

class TerraceTowerUsageHistoryCancelStore {
  isLoading = false;
  error: string | null = null;
  usageHistoryCancel: ITerraceTowerUsageHistoryCancelData | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setLoading(value: boolean) {
    this.isLoading = value;
  }

  setUsageHistoryCancel(data: ITerraceTowerUsageHistoryCancelData) {
    this.usageHistoryCancel = data;
  }

  async fetchTerraceTowerUsageHistoryCancel(saleHistoryUID: string, comment: string) {
    const requestBody = { saleHistoryUID, comment };

    this.setLoading(true);

    const response =
      await terraceTowerUsageHistoryDetailService.postTerraceTowerUsageHistoryCancel(requestBody);

    if (response instanceof BaseError) {
      this.error = response.errorMessage;
      this.setLoading(false);
      return false;
    } else {
      const { data } = response;
      if (data) {
        this.setUsageHistoryCancel(data);
      }
      this.setLoading(false);
      return true;
    }
  }
}

export const terraceTowerUsageHistoryCancelStore = new TerraceTowerUsageHistoryCancelStore();