export const showWebBrowser = (url: string) => {

  const browserInfo = navigator.userAgent;

  if (browserInfo.indexOf("arsparking_android") > -1) {
    if (window.arsparkingapp) {
      console.log('Android 인터페이스를 사용가능');
      window.arsparkingapp.showWebBrowser(url);
    } else {
      console.error('iOS 호출');
    }
  }
  else if (browserInfo.indexOf("arsparking_ios") > -1) {
    if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.arsparkingapp) {
      console.log('iOS 인터페이스를 사용가능');
      window.webkit.messageHandlers.arsparkingapp.postMessage({
        func: 'showWebBrowser',
        url: url
      });
    } else {
      console.error('AOS 호출');
    }
  } else {
    console.log('웹 에서 URL 열기')
    window.open(url, '_blank')
  }
}

export const movePayment = (productUID: string) => {

  const browserInfo = navigator.userAgent;

  if (browserInfo.indexOf("arsparking_android") > -1) {
    if (window.arsparkingapp) {
      console.log('Android 인터페이스를 사용가능');
      window.arsparkingapp.movePayment(productUID);
    } else {
      console.error('iOS 호출');
    }
  }
  else if (browserInfo.indexOf("arsparking_ios") > -1) {
    if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.arsparkingapp) {
      console.log('iOS 인터페이스를 사용가능');
      window.webkit.messageHandlers.arsparkingapp.postMessage({
        func: 'movePayment',
        productUID: productUID
      })
    } else {
      console.error('AOS 호출');
    }
  } else {
    console.log('웹 에서 URL 열기')
  }
}

export const moveMain = () => {

  const browserInfo = navigator.userAgent;

  if (browserInfo.indexOf("arsparking_android") > -1) {
    if (window.arsparkingapp) {
      console.log('Android 인터페이스를 사용가능');
      window.arsparkingapp.moveMain();
    } else {
      console.error('iOS 호출');
    }
  }
  else if (browserInfo.indexOf("arsparking_ios") > -1) {
    if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.arsparkingapp) {
      console.log('iOS 인터페이스를 사용가능');
      window.webkit.messageHandlers.arsparkingapp.postMessage({
        func: 'moveMain',
      });
    } else {
      console.error('AOS 호출');
    }
  } else {
    console.log('웹 에서 URL 열기')
  }
}

export const webViewClose = () => {

  const browserInfo = navigator.userAgent;

  if (browserInfo.indexOf("arsparking_android") > -1) {
    if (window.arsparkingapp) {
      console.log('Android 인터페이스를 사용가능');
      window.arsparkingapp.webViewClose();
    } else {
      console.error('iOS 호출');
    }
  } else if (browserInfo.indexOf("arsparking_ios") > -1) {
    if (window.webkit && window.webkit.messageHandlers.arsparkingapp && window.webkit.messageHandlers.arsparkingapp) {
      console.log('iOS 인터페이스를 사용가능')
      window.webkit.messageHandlers.arsparkingapp.postMessage({
        func: 'webViewClose'
      });
    } else {
      alert('ios 인터페이스 정의 되지 않음')
      console.error('AOS 호출')
    }
  } else {
    console.log('웹 에서 URL 열기')
  }
}

export const moveInnoPay = () => {

  const browserInfo = navigator.userAgent;

  if (browserInfo.indexOf("arsparking_android") > -1) {
    if (window.arsparkingapp) {
      console.log('Android 인터페이스를 사용가능');
      window.arsparkingapp.moveInnoPay();
    } else {
      console.error('iOS 호출');
    }
  } else if (browserInfo.indexOf("arsparking_ios") > -1) {
    if (window.webkit && window.webkit.messageHandlers.arsparkingapp && window.webkit.messageHandlers.arsparkingapp) {
      console.log('iOS 인터페이스를 사용가능')
      window.webkit.messageHandlers.arsparkingapp.postMessage({
        func: 'moveInnoPay'
      });
    } else {
      alert('ios 인터페이스 정의 되지 않음')
      console.error('AOS 호출')
    }
  } else {
    console.log('웹 에서 URL 열기')
  }
}

export const setDial = (telNumber: string) => {

  const browserInfo = navigator.userAgent;

  if (browserInfo.indexOf("arsparking_android") > -1) {
    if (window.arsparkingapp) {
      console.log('Android 인터페이스를 사용가능');
      window.arsparkingapp.setDial(telNumber);
    } else {
      console.error('iOS 호출');
    }
  }
  else if (browserInfo.indexOf("arsparking_ios") > -1) {
    if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.arsparkingapp) {
      console.log('iOS 인터페이스를 사용가능');
      window.webkit.messageHandlers.arsparkingapp.postMessage({
        func: 'setDial',
        telNumber: telNumber
      });
    } else {
      console.error('AOS 호출');
    }
  } else {
    console.log('웹 에서 URL 열기')
    window.open(telNumber, '_blank')
  }
}