import { useState } from "react";
import { MainContainer } from "../../../components/styles";
import Title from "../../../components/atoms/Title/PageTitle";
import React from "react";
import { useNavigate } from "react-router-dom";
import CheckModal from '../../../components/atoms/Modal/CheckModal';
import { DeleteAccountTitleContainer } from '../styles';
import { DeleteAccountReasonContainer, DeleteAccountReasonText } from './styles';
import { BottomButtonContainer, ButtonEnableDisable } from '../../../components/atoms/Button/styles';

type Reason = '개인정보 유출 우려로' | '사용 빈도가 낮아서' | '원하는 주차자가 없어서' | '이미 주차장을 구해서';

const DeleteAccountReason: React.FC = () => {
  const [selectedReason, setSelectedReason] = useState<Reason | null>(null)
  const [textareaValue, setTextareaValue] = useState('')
  const navigate = useNavigate();

  const handleLeftOnClick = () => {
    navigate(-1);
  };

  // 탈퇴 사유 항목 목록을 정의합니다.
  const reasons: Reason[] = [
    '개인정보 유출 우려로',
    '사용 빈도가 낮아서',
    '원하는 주차자가 없어서',
    '이미 주차장을 구해서'
  ];

  // 리즌 클릭 핸들러
  const handleReasonClick = (reason: Reason) => {
    setSelectedReason(reason)
  };

  // 텍스트 영역 변경 핸들러
  const handleTextareaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextareaValue(event.target.value)
  };

  // 버튼 활성화 조건
  const isButtonDisabled = !selectedReason && !textareaValue.trim();

  const [isAccountDeleteModal, setIsAccountDeleteModal] = React.useState(false)
  const accountDeleteModal = () => {
    setIsAccountDeleteModal(true)
  }


  return (
    <MainContainer>
      <Title
        title="회원 탈퇴"
        leftArrowButtonVisible={true}
        leftOnClick={handleLeftOnClick}
        rightTextButtonVisible={false}
      />
        <DeleteAccountTitleContainer>
          탈퇴하시는 이유를 알려주세요.
        </DeleteAccountTitleContainer>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            marginTop: '34px',
          }}
        >
          {reasons.map((reason, index) => (
            <div
              key={index}
              onClick={() => handleReasonClick(reason)} // 클릭 이벤트 핸들러 추가
              style={{
                width: '100%',
                textAlign: 'left',
                height: '45px',
                marginTop: index === 0 ? '0' : '10px',
                borderRadius: '5px',
                border: selectedReason === reason ? '2px solid #8B63BB' : 'none',
                backgroundColor: '#F8F8FA',
                display: 'flex',
                alignItems: 'center',
                paddingLeft: '10px',
                cursor: 'pointer',
                fontWeight: selectedReason === reason ? 700 : 400,
                boxShadow: selectedReason === reason ? '0 0 5px rgba(0, 0, 0, 0.1)' : 'none', // Optional: add shadow for selected item
              }}
            >
              {reason}
            </div>
          ))}
        </div>
      <DeleteAccountReasonContainer>
        <DeleteAccountReasonText
          placeholder={'탈퇴 사유를 작성해 주세요'}
          value={textareaValue}
          onChange={handleTextareaChange}
        />
      </DeleteAccountReasonContainer>

      <BottomButtonContainer>
        <ButtonEnableDisable
          onClick={() => accountDeleteModal()}
          disabled={isButtonDisabled}>
          회원 탈퇴
        </ButtonEnableDisable>
      </BottomButtonContainer>
      <CheckModal
        isVisible={isAccountDeleteModal}
        onClose={() => {
          setIsAccountDeleteModal(false)
          navigate('/')
        }} title={'회원 탈퇴가 완료 되었습니다.'} content={'이용해주셔서 감사합니다.'} buttonText={'확인'} />
    </MainContainer>
  );
}

export default DeleteAccountReason;
