import { styled } from '@stitches/react';

export const ParkingPhotoUploadDetailMainContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  margin: '0 25px'
})

export const ParkingPhotoDetailTitleContainer = styled('div', {
  textAlign: 'center',
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  color: '#8B63BB',
  marginBottom: '26px',
})

export const LocationIconContainer = styled('div', {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: '1px solid #E6E6E6',
  paddingBottom: '10px',
  marginBottom: '26px',
})

export const IconTextContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
})

export const PhotoPictureContainer = styled('div', {
  marginLeft: '12px',
  border: '2px solid #F2F2F2',
  borderRadius: '4px',
  padding: '4px 10px'
})

export const PhotoDescriptionSpan = styled('span', {
  color: '#868686',
  fontFamily: 'Pretendard',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '18px'
})

export const IconImage = styled('img', {
  maxWidth: '50px',
  height: 'auto',
  marginRight: '10px',
})

export const ParkingInfoContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  marginTop: '10px',
  justifyContent: 'space-between',
})

export const ParkingNameSpan = styled('span', {
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontWeight: '400',
  color: '#1A1A1A',
})

export const ParkingNameResultSpan = styled('span', {
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontWeight: '700',
  color: '#1A1A1A',
})

export const AddressContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  marginTop: '10px',
  justifyContent: 'space-between',
  width: '100%',
  flexWrap: 'wrap',
})

export const AddressNameSpan = styled('span', {
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontWeight: '400',
  color: '#1A1A1A',
  flex: '1 1 50%',
  overflowWrap: 'break-word',
})

export const AddressNameResultSpan = styled('div', {
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontWeight: '700',
  color: '#1A1A1A',
  flex: '1 1 50%',
  overflowWrap: 'break-word',
  textAlign: 'right',
})

export const ParkingPhotoCautionContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '47px',
  textAlign: 'center',
  color: '#1A1A1A',
  fontFamily: 'Pretendard',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  height: '180px',
  borderRadius: '5px',
  border: '2px solid #B799DB'
})

export const CautionTitleSpan = styled('span', {
  marginTop: '20px'
})

export const CautionContentContainer = styled('div', {
  fontFamily: 'Pretendard',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  marginTop: '20px',
  textAlign: 'left',
  color: '#333',
  marginBottom: '20px'
})

export const GalleryIconContainer = styled('div', {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: '1px solid #E6E6E6',
  paddingBottom: '10px',
  marginTop: '32px',
})

export const PhotoParkingInfoContainer = styled('div', {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingBottom: '10px',
  marginTop: '32px',
})

// export const UploadImageSection = styled('div', {
//   display: 'grid',
//   gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
//   gap: '20px',
//   justifyContent: 'space-between',
//   label: {
//     width: '100%',
//     aspectRatio: '1 / 1',
//   },
// });

export const UploadImageSection = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
  gap: '20px',
  justifyContent: 'space-between',
  position: 'relative', // 자식 요소가 상대적으로 위치할 수 있도록 설정

  label: {
    width: '100%',
    aspectRatio: '1 / 1',
    position: 'relative', // 자식 요소가 상대적으로 위치할 수 있도록 설정
  },
});

export const ExampleTextContainer = styled('div', {
  position: 'absolute',
  top: '8px',
  left: '8px',
  color: '#ffffff',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  padding: '2px 5px',
  borderRadius: '3px',
  textAlign: 'center',
  fontFamily: 'Inter',
  fontSize: '9px',
  fontWeigh: 276,
  linHeight: 'normal'
});

export const UploadedImage = styled('img', {
  border: '1px solid #DDD',
  aspectRatio: '1 / 1',
  width: '100%',
  borderRadius: '4px',
});

export const SubmitButtonContainer = styled('button', {
  marginTop: '26px',
  marginBottom: '26px',
  height: '46px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  border: 'none',
  backgroundColor: '#8B63BB',
  color: '#fff',
  borderRadius: '4px',
  padding: '10px 20px',
  cursor: 'pointer',
  fontSize: '16px',
})

export const BottomContainer = styled('div', {
  height: '94px',
  backgroundColor: '#F8F8FA',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  color: '#6B6B6B',
  fontFamily: 'Pretendard',
  fontSize: '9px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '13px'
})

export const CustomerCenterCallContainer = styled('div', {
  flexDirection: 'column',
})

export const CallIconTextContainer = styled('div', {
  color: '#6B6B6B',
  fontFamily: 'Pretendard',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '13px',
  marginTop: '7px',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center'
})