import { styled } from '@stitches/react';

export const CarNumberBackGroundContainer = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  marginTop: '30px',
  color: '#6B6B6B',
  borderRadius: '10px',
  background: '#EBEBFB',
  lineHeight: '22px',
  height: '80px',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '8px',
})

export const CarNumberOutlineContainer = styled('div', {
  borderRadius: '6px',
  border: '2px solid #000',
  background: '#fff',
  width: '100%',
  height: '64px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  boxSizing: 'border-box',
})

export const LeftDotImg = styled('img', {
  position: 'absolute',
  left: '10px',
  top: '50%',
  transform: 'translateY(-50%)',
})

export const CarNumberText = styled('span', {
  position: 'relative',
  zIndex: 1,
  color: '#333',
  fontFamily: 'Pretendard',
  fontSize: '35px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '20px'
})

export const RightDotImg = styled('img', {
  position: 'absolute',
  right: '10px',
  top: '50%',
  transform: 'translateY(-50%)',
})