import icArrowRightPurple from "../../../assets/ic/ic_arrow_right_purple.svg";
import React from "react";
import icArrowRightGrayMedium from "../../../assets/ic/ic_arrow_right_gray_medium.svg";
import { useNavigate } from "react-router-dom";
import {
  ArrowIcon,
  TitleSpan,
  PhoneNumberContainer,
  AppVersionContainer, ListItemContainer, SettingAppInfoContainer, SectionHeaderContainer
} from "./styles";

// 비회원 유저라면 회원탈퇴 gone 처리 필요
// 앱 버전은 추후 핸들러 이용해서 불러오자
const SettingAppInfoList = () => {
  const navigate = useNavigate();

  const appInfoItems = [
    {
      textTitle: '회원 탈퇴하기',
      imgArrowLeft: icArrowRightGrayMedium,
      onClick: () => navigate('/deleteAccount')
    },
    {
      textTitle: '고객센터',
      customerCenterNumber: '1666-4369'
    },
    {
      textTitle: '앱버전',
      textAppVersion: '1.0.0',
    },
  ];

  return (
      <>
        <SectionHeaderContainer></SectionHeaderContainer>
        <SettingAppInfoContainer>
          {appInfoItems.map((item, index) => {
            const { textTitle, imgArrowLeft, customerCenterNumber, textAppVersion, onClick } = item;
            const shouldShowAppVersion = textTitle === "앱버전";
            const shouldShowPhoneNumber = textTitle === "고객센터";

            return (
                <ListItemContainer
                    key={index}
                    onClick={onClick}
                    style={{ cursor: onClick ? 'pointer' : 'default' }}
                >
                  <div style={{ flex: 1 }}>
                    <TitleSpan>{textTitle}</TitleSpan>
                  </div>
                  {shouldShowAppVersion ? (
                      <AppVersionContainer>
                        <TitleSpan>{textAppVersion}</TitleSpan>
                      </AppVersionContainer>
                  ) : shouldShowPhoneNumber ? (
                      <PhoneNumberContainer>
                        <TitleSpan>{customerCenterNumber}</TitleSpan>
                      </PhoneNumberContainer>
                  ) : (
                      <ArrowIcon
                          src={imgArrowLeft}
                          alt="애로우 아이콘"
                      />
                  )}
                </ListItemContainer>
            );
          })}
        </SettingAppInfoContainer>
      </>
  );
};

export default SettingAppInfoList;
