import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { MainContainer } from "../../components/styles";
import Title from "../../components/atoms/Title/PageTitle";
import ToggleButton from '../../components/atoms/Toggle';
import {
  CautionTextContainer,
  PhoneNumberBorderContainer,
  UserInfoInput, TimerContainer, VerificationCodeInput, VerificationCodeInputContainer,
} from '../../components/atoms/UserInfoInputsContainers/styles';
import { BottomButtonContainer, ButtonEnableDisable } from '../../components/atoms/Button/styles';

const SearchMyInfo: React.FC = () => {
  const navigate = useNavigate();

  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleLeftOnClick = () => {
    navigate(-1)
  }

  const handleToggle = (index: number) => {
    setSelectedIndex(index)
  }

  return (
    <MainContainer>
      <Title
        title="아이디/비밀번호 찾기"
        leftArrowButtonVisible={true}
        leftOnClick={handleLeftOnClick}
        rightTextButtonVisible={false}
      />
      <div>
        <ToggleButton
          options={['아이디 찾기', '비밀번호 찾기']}
          selectedIndex={selectedIndex}
          content={[
            <div key="아이디 찾기">
              <div
                style={{
                  color: '#333',
                  fontFamily: 'Pretendard',
                  fontSize: '22px',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  lineHeight: '30px',
                  marginTop: '37px'
                }}
              >
                <span>회원가입시 입력한</span>
                <br />
                <span style={{ color: '#8B63BB' }}>
                  휴대폰 번호<span style={{ color: '#333' }}>를 입력 해주세요.</span>
                </span>

                <div style={{ marginTop: '37px' }}>
                  <PhoneNumberBorderContainer>
                    <UserInfoInput
                      type='tel'
                      id="userPhoneNumber"
                      placeholder="휴대폰 번호"
                    />
                  </PhoneNumberBorderContainer>
                </div>
              </div>
              <CautionTextContainer>
                test
              </CautionTextContainer>

              <div>
                <VerificationCodeInputContainer>
                  <VerificationCodeInput
                    type="password"
                    placeholder="인증번호 입력"
                  />
                  <TimerContainer>
                    00:00
                  </TimerContainer>
                </VerificationCodeInputContainer>
              </div>
              <div style={{ display: 'flex',  flexDirection: 'column'}}>
                <BottomButtonContainer>
                  <ButtonEnableDisable>
                    다음
                  </ButtonEnableDisable>
                </BottomButtonContainer>
              </div>
            </div>,
            <div key="비밀번호 찾기">
              <div style={{
                color: '#333',
                fontFamily: 'Pretendard',
                fontSize: '22px',
                fontStyle: 'normal',
                fontWeight: 700,
                lineHeight: '30px',
                marginTop: '37px'
              }}>
                <span style={{ color: '#8B63BB' }}>새로운 비밀번호<span style={{ color: '#333' }}>를 입력 해주세요.</span>
                </span>

              </div>
            </div>,
          ]}
          onToggle={handleToggle}
        />
      </div>
    </MainContainer>
  )
}

export default SearchMyInfo
