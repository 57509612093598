import React from 'react'
import { Spinner, SpinnerOverlay } from './styles';

const LoadingSpinnerScreen = () => {
  return (
    <SpinnerOverlay>
      <Spinner />
    </SpinnerOverlay>
  )
}

export default LoadingSpinnerScreen
