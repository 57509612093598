import { makeAutoObservable } from 'mobx';
import { IPointData } from '../../../models/Point/pointHistoryModel';
import pointService from '../../../service/Point/pointSearchService';
import BaseError from '../../../models/baseError';

class PointBottomListStore {
  pointsData: IPointData[] = []
  isAccountAllClicked = true
  isAccountEarnClicked = false
  isAccountUseClicked = false
  isThreeMonthHistory = true
  isSixMonthHistory = false
  selectedYear: number = new Date().getFullYear()
  isLoading = false
  error: string | null = null
  pageNum: number = 1
  isScrollLoading = false
  totalCount: number = 0

  constructor() {
    makeAutoObservable(this);
  }

  setLoading(value: boolean) {
    this.isLoading = value;
  }

  getSelectedStatus() {
    if (this.isAccountAllClicked) return '전체';
    if (this.isAccountEarnClicked) return '적립';
    if (this.isAccountUseClicked) return '사용';
    return '전체';
  }

  getMonthDiff() {
    if (this.isThreeMonthHistory) return 3;
    if (this.isSixMonthHistory) return 6;
    return 0;
  }

  resetPageNum() {
    this.pageNum = 1 // 페이지 번호를 1로 초기화
    this.pointsData = [] // 데이터 초기화
  }

  setAccountAllClicked() {
    this.isAccountAllClicked = true
    this.isAccountEarnClicked = false
    this.isAccountUseClicked = false
    this.resetPageNum();
  }

  setAccountEarnClicked() {
    this.isAccountEarnClicked = true
    this.isAccountAllClicked = false
    this.isAccountUseClicked = false
    this.resetPageNum();
  }

  setAccountUseClicked() {
    this.isAccountUseClicked = true
    this.isAccountAllClicked = false
    this.isAccountEarnClicked = false
    this.resetPageNum()
  }

  setThreeMonthHistory() {
    this.isThreeMonthHistory = true
    this.isSixMonthHistory = false
    this.resetPageNum()
  }

  setSixMonthHistory() {
    this.isSixMonthHistory = true
    this.isThreeMonthHistory = false
    this.resetPageNum()
  }

  setCurrentYear() {
    this.setSelectedYear(new Date().getFullYear());
  }

  setSelectedYear(year: number) {
    this.selectedYear = year
    this.resetPageNum()
  }

  setScrollLoading(value: boolean) {
    this.isScrollLoading = value
  }

  setTotalCount(value: number) {
    this.totalCount = value
  }

  async fetchPointHistoryBottomList(mLinkUID: number, isScrollLoad: boolean = false) {
    if (!isScrollLoad) {
      this.resetPageNum(); // 첫 페이지 로드 시, 페이지 번호 초기화
    }

    if (isScrollLoad) {
      this.setScrollLoading(true);
    } else {
      this.setLoading(true);
    }

    try {
      const response = await pointService.postPointSearch({
        mLinkUID: mLinkUID,
        status: this.getSelectedStatus(),
        searchYear: this.selectedYear,
        monthDiff: this.getMonthDiff(),
        pageNum: this.pageNum
      })

      console.log('response:', response)

      if (response instanceof BaseError) {
        this.error = response.message
      } else {
        const { pointData } = response.data
        this.setTotalCount(response.totalCount)

        if (pointData.length === 0) {
          console.log('No point data available')
        } else {
          // 스크롤로 로드한 경우 (isScrollLoad 가 true)
          if (isScrollLoad) {
            // 데이터 중복 방지를 위해 현재 데이터 개수와 총 개수를 비교
            if (this.pointsData.length + pointData.length < this.totalCount) {
              this.pageNum += 1; // 불러올 데이터가 있을 경우에만 페이지 번호 증가
              this.pointsData = [...this.pointsData, ...pointData]
            } else {
              console.log('모든 데이터를 불러왔습니다.')
            }
          }
          // 처음 로드하거나 필터를 변경한 경우 (isScrollLoad 가 false)
          else {
            this.pointsData = pointData;
          }
        }
      }
    } catch (error) {
      console.error('Error fetching points:', error)
    } finally {
      if (isScrollLoad) {
        this.setScrollLoading(false)
      }
      this.setLoading(false)
    }
  }
}

export const pointBottomListStore = new PointBottomListStore()
