import BaseError, { ErrorData } from '../../models/baseError';
import { AxiosError } from 'axios';
import ErrorCodeMap from '../../constants/errorCodeMap';

const NETWORK_TIMEOUT_CODE = 'ECONNABORTED';

export default class ErrorParser {
  static parseError(error: AxiosError): BaseError {
    const { response } = error;
    const status = response?.status || ErrorCodeMap.UNKNOWN.status;

    // 타입 가드를 사용해 data가 있는지 확인 후 처리
    const serverErrorType = response?.data && (response.data as any).errorType;
    const serverErrorMessage = response?.data && (response.data as any).message;

    console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!');
    console.log(response?.request);
    console.log(response?.data);
    console.log(response?.statusText);
    console.log(status);
    console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!');

    const savedErrorDataKey = Object.keys(ErrorCodeMap)
      .filter((key: string) => ErrorCodeMap[key].status === status)
      .shift();

    if (error.code === NETWORK_TIMEOUT_CODE) {
      return new BaseError(ErrorCodeMap.NETWORK_TIMEOUT);
    }

    if (!savedErrorDataKey) {
      return new BaseError({
        status,
        errorMessage: serverErrorMessage,
      });
    }

    const savedErrorData: ErrorData = {
      ...ErrorCodeMap[savedErrorDataKey],
    };

    return new BaseError({
      status: savedErrorData.status,
      errorMessage: serverErrorMessage || savedErrorData.errorMessage,
      errorType: serverErrorType,
    });
  }
}