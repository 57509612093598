import React from 'react';
import { Dialog } from './styles'; // 경로는 실제 파일 위치에 맞게 조정하세요
import Title from '../../Title/PageTitle';
import { ViewDivide } from '../../../../pages/AgreeTerms/styles';

// Props 타입 정의
interface ChargeBottomModalProps {
  isVisible: boolean; // 모달의 가시성 상태
  onClose: () => void; // 모달을 닫는 함수
}

const ChargeDetailBottomModal: React.FC<ChargeBottomModalProps> = ({ isVisible, onClose }) => {
  return (
    <Dialog isVisible={isVisible}>
      <Title
        title="충전소 상세정보"
        leftArrowButtonVisible={true}
        leftOnClick={onClose}
        rightTextButtonVisible={false}
      />
      <div style={{ padding: '16px' }}>
        {/* 기본 정보 */}
        <InfoRow label="test" value="명일동 공용주차장" />
        <InfoRow label="test" value="서울 특별시 강동구 구천면로 395" />
        <InfoRow label="test" value="02-2640-5199" />

        <ViewDivide />

        {/* 충전기 정보 */}
        <InfoRow label="test" value="충전기 정보" />
        <InfoRow label="운영기관" value="서울에너지공사" />
        <InfoRow label="충전기 유형" value="급속" />
        <InfoRow label="운영시간" value="24시간 이용가능" />
        <InfoRow label="주차비" value="유료" />

        {/* 추가 정보 */}
        <div style={{ height: '300px', backgroundColor: 'gray', margin: '16px 0' }}>
          하위 난 로드뷰
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
          <div>
            <span>test 이미지얌</span>
            <span>충전기 목록</span>
          </div>
          <span style={{ textAlign: 'right' }}>2대</span>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex' }}>
              <span>test</span>
              <span style={{ marginLeft: '8px' }}>test2</span>
            </div>
            <span>test3</span>
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>하위1</span>
            <span>하위2</span>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

// `InfoRow` 컴포넌트는 라벨과 값을 표시하는 줄을 생성합니다.
const InfoRow: React.FC<{ label: string; value: string }> = ({ label, value }) => (
  <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
    <span>{label}</span>
    <span>{value}</span>
  </div>
);

export default ChargeDetailBottomModal;
