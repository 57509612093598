import { styled } from '@stitches/react';

export const ToggleButtonContainer = styled('div', {
  display: 'flex',
  marginLeft: '20px',
  marginBottom: '28px',
  justifyContent: 'flex-start',
  flexDirection: 'row',
  alignItems: 'center',
})

export const PointMallStorageBoxToggleFirstContainer = styled('div', {
  border: '1px solid #E4E4E4',
  borderRadius: '20px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  padding: '6px 10px',
  marginRight: '10px',
  backgroundColor: 'transparent',
  borderColor: '#E4E4E4',

  variants: {
    isStorageBoxAllClicked: {
      true: {
        backgroundColor: '#47474A',
        borderColor: '#47474A',
      },
      false: {
        backgroundColor: '#fff',
        borderColor: '#E4E4E4',
      },
    },
  },
  defaultVariants: {
    isStorageBoxAllClicked: true,
  },
})

export const PointMallStorageBoxToggleFirstSpan = styled('span', {
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  color: '#000000',

  variants: {
    isStorageBoxAllClicked: {
      true: {
        color: '#FFFFFF',
      },
      false: {
        color: '#000000',
      },
    },
  },

  defaultVariants: {
    isStorageBoxAllClicked: true,
  },
});

export const PointMallStorageBoxToggleSecondContainer = styled('div', {
  border: '1px solid #E4E4E4',
  borderRadius: '20px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  padding: '6px 10px',
  marginRight: '10px',
  backgroundColor: 'transparent',
  borderColor: '#E4E4E4',

  variants: {
    isStorageBoxChangePossibleClicked: {
      true: {
        backgroundColor: '#47474A',
        borderColor: '#47474A',
      },
      false: {
        backgroundColor: '#fff',
        borderColor: '#E4E4E4',
      },
    },
  },
  defaultVariants: {
    isStorageBoxChangePossibleClicked: false,
  },
})

export const PointMallStorageBoxToggleSecondSpan = styled('span', {
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  color: '#000000',

  variants: {
    isStorageBoxChangePossibleClicked: {
      true: {
        color: '#FFFFFF',
      },
      false: {
        color: '#000000',
      },
    },
  },

  defaultVariants: {
    isStorageBoxChangePossibleClicked: false,
  },
});

export const PointMallStorageBoxToggleThirdContainer = styled('div', {
  border: '1px solid #E4E4E4',
  borderRadius: '20px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  padding: '6px 10px',
  marginRight: '10px',
  backgroundColor: 'transparent',
  borderColor: '#E4E4E4',

  variants: {
    isStorageBoxChangeImpossibleClicked: {
      true: {
        backgroundColor: '#47474A',
        borderColor: '#47474A',
      },
      false: {
        backgroundColor: '#fff',
        borderColor: '#E4E4E4',
      },
    },
  },
  defaultVariants: {
    isStorageBoxChangeImpossibleClicked: false,
  },
})

export const PointMallStorageBoxToggleThirdSpan = styled('span', {
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  color: '#000000',

  variants: {
    isStorageBoxChangeImpossibleClicked: {
      true: {
        color: '#FFFFFF',
      },
      false: {
        color: '#000000',
      },
    },
  },

  defaultVariants: {
    isStorageBoxChangeImpossibleClicked: false,
  },
});

export const TicketContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  margin: '0px 20px 16px'
})

export const TicketTopContainer = styled('div', {
  padding: '16px',
  borderRadius: '8px',
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'row',
  height: 'auto',
  justifyContent: 'space-between',
})

export const DotLineImg = styled('img', {
  backgroundColor: '#fff',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  padding: '0 10px',
  marginLeft: '6px',
  marginRight: '6px'
})

export const TicketTopContentsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '8px',
  color: '#8B63BB',
  fontFamily: 'Pretendard',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',

  variants: {
    state: {
      expired: {
        color: '#B0B0B0'
      },
      resend: {
        color: '#8B63BB',
      },
      exchange: {
        color: '#8B63BB',
      },
    },
  }
})

export const TicketExpirationDateSpan = styled('div', {
  fontSize: '12px',
  fontWeight: 500,
  marginTop: '4px'
})

export const ChangeButton = styled('button', {
  cursor: 'none',
  border: 'none',
  borderRadius: '8px',
  color: '#fff',
  backgroundColor: '#8565B6',
  padding: '8px 16px',
  fontSize: '15px',
  fontStyle: 'normal',
  fontFamily: 'Pretendard',
  fontWeight: 500,
  lineHeight: 'normal',

  variants: {
    state: {
      expired: {
        color: '#B0B0B0',
        backgroundColor: '#F2F2F2'
      },
      resend: {
        backgroundColor: '#FFF',
        color: '#666',
        border: '1px solid #CCC',
      },
      exchange: {
        color: '#fff',
        backgroundColor: '#8B63BB',
      },
    },
  }
});

export const TicketBottomContainer = styled('div', {
  padding: '16px',
  height: 'auto',
  borderRadius: '8px',
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
})

export const StampContainer = styled('div', {
  position: 'relative'
})

export const StampIcon = styled('img', {
  position: 'absolute', top: '-10px', left: '0', zIndex: 1
})

export const GoodsContainer = styled('div', {
  borderRadius: '8px',
  border: '1px solid #E4E4E4',
  width: '68px',
  height: '68px',
  flexShrink: 0,
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',

  variants: {
    state: {
      expired: {
        opacity: 0.4
      },
      resend: {
        opacity: 1
      },
      exchange: {
        opacity: 1
      },
    }
  }
});

export const GoodsImage = styled('img', {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

export const TicketBottomContentsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  color: '#666',
  fontFamily: 'Pretendard',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  justifyContent: 'space-between',
  height: '68px',
  marginLeft: '12px',
})

export const BrandTitleDetailContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column'
})

export const BrandDetailSpan = styled('span', {
  color: '#555',
  fontWeight: 600,
  marginTop: '4px'
})

export const PurchaseDateSpan = styled('span', {
  marginTop: 'auto',
})

export const NotChangeCouponContainer = styled('div', {
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  margin: 'auto',
  fontFamily: 'Pretendard',
  fontSize: '15px',
  fontStyle: 'normal',
  color: '#555',
  fontWeight: 500,
  height: '20vh',
  variants: {
    hasLottie: {
      true: {
        height: '40vh',
      },
    },
  },
});

export const NotCouponSpan = styled('div', {
  fontFamily: 'Pretendard',
  fontSize: '15px',
  fontStyle: 'normal',
  color: '#555',
  fontWeight: 500,
  lineHeight: 'normal',
})

export const NotCouponContentSpan = styled('span', {
  fontSize: '14px',
  color: '#868686',
  fontWeight: 400,
  marginTop: '8px',
})

export const MovePointMallBrandButton = styled('button', {
  margin: '28px auto',
  height: '48px',
  padding: '0 40px',
  justifyContent: 'center',
  alignItems: 'center',
  flexShrink: 0,
  border: 'none',
  borderRadius: '8px',
  color: '#FFF',
  fontSize: '16px',
  fontWeight: 700,
  backgroundColor: '#8B63BB',
})

export const PhoneNumberModalContentContainer = styled('div', {
  color: '#868686',
  fontFamily: 'Pretendard',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
  marginTop: '4px'
})

export const TransmissionSpan = styled('span', {
  fontWeight: 400,
})




