import { styled } from '@stitches/react';

export const ModalOverlay = styled('div', {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.3)',
  zIndex: 9999,
})

export const PaymentMethodModalContainer = styled('div', {
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  height: 'auto',
  background: '#fff',
  boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.2)',
  transition: 'transform 0.3s ease-in-out',
  transform: 'translateY(100%)',
  display: 'flex',
  flexDirection: 'column',
  padding: '16px',
  boxSizing: 'border-box',
  borderRadius: '20px 20px 0px 0px',
  zIndex: 10000,
  variants: {
    isVisible: {
      true: { transform: 'translateY(0)' },
      false: { transform: 'translateY(100%)' },
    },
  },
});

export const TitleSpan = styled('div', {
  display: 'flex',
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#1A1A1A',
  fontFamily: 'Pretendard',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '25px'

})

export const ContentsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  width: '100%',
});

export const PaymentMethodChoiceContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '16px 20px'
});

export const PaymentMethodChoiceItem = styled('div', {
  borderRadius: '10px',
  padding: '10px',
  margin: '8px 0',
  fontSize: '16px',
  width: '100%',
  textAlign: 'center',
  fontFamily: 'Pretendard',
  fontWeight: 700,
  lineHeight: 'normal',
  variants: {
    isSelected: {
      true: {
        color: '#8B63BB',
        backgroundColor: '#F5F5FF'
      },
      false: {
        color: '#868686',
      },
    },
  },
});

export const BottomButtonContainer = styled('div', {
  margin: '0 20px 20px',
  width: 'calc(100% - 40px)',
  backgroundColor: '#fff',
  textAlign: 'center',
  zIndex: 10,
})

export const ButtonEnableDisable = styled('button', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  width: '100%',
  height: '46px',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: 'normal',
  borderRadius: '5px',
  backgroundColor: '#8B63BB',
  fontFamily: 'Pretendard',
  color: '#fff',
  border: 'none',
  userSelect: 'none',
  '&:disabled': {
    backgroundColor: '#d3d3d3',
    color: '#fff',
  },
});