import React, { useEffect, useRef, useState } from 'react';
import Title from '../../../../components/atoms/Title/PageTitle';
import { MainContainer } from '../../../../components/styles';
import { useNavigate } from 'react-router-dom';
import {
  CertNumberContainer,
  CertNumberContentsContainer,
  CertNumberInput,
  CertTransmissionButtonContainer,
  CertTransmissionContainer,
  CloseIcon,
  PhoneInfoContainer,
  PhoneNumberSpan,
  PhoneNumberTextSpan,
  SafeTextSpan,
  TimerSpan,
  TitleContainer,
  TransmissionButton,
} from './styles';
import icCloseCircleGray from '../../../../assets/ic/ic_close_circle_gray.svg';
import { useForm } from 'react-hook-form';
import {
  BottomButtonContainer,
  ButtonEnableDisable,
} from '../../../../components/atoms/Button/styles';
import PurpleOneButtonCheckModal from '../../../../components/atoms/Modal/PurpleOneButtonCheckModal';
import BaseError from '../../../../models/baseError';
import PointSmsCertService from '../../../../service/Point/pointSmsCertService';
import { IPointSmsCert, IPointSmsCertSuccess } from '../../../../models/Point/pointSmsCertModel';
import { useToast } from '../../../../components/atoms/Toast/ToastProvider';
import { LINK_UID } from '../../../../constants/userInfoConstants';
import { userInfoStore } from '../../../../stores/store/UserInfo/userInfoStore';
import { observer } from 'mobx-react';
import icFailCheckRed from '../../../../assets/ic/ic_fail_check_red.svg';
import { useFetchUserInfo } from '../../../../hooks/useFetchUserInfo';
import { pointAccountRegiStore } from '../../../../stores/store/Point/pointAccountRegiStore';

interface IFormInputs {
  certNumber: string;
}

const PointWithdrawalIdentityCert = observer(() => {
  const navigate = useNavigate();
  const [buttonText, setButtonText] = useState('전송');
  const [timerVisible, setTimerVisible] = useState(false);
  const [timeLeft, setTimeLeft] = useState(180);
  const [inputPlaceholder, setInputPlaceholder] = useState('[전송]을 눌러 주세요.');
  const [isInputDisabled, setIsInputDisabled] = useState(true);
  const [isCertSuccess, setIsCertSuccess] = useState(false);
  const [isCloseIconVisible, setIsCloseIconVisible] = useState(false);
  const { showToast } = useToast();
  const certNumberInputRef = useRef<HTMLInputElement | null>(null);
  const { fetchUserInfo } = useFetchUserInfo();
  const handleLeftOnClick = () => {
    navigate('/pointHistory');
  };
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
  const [focusedField, setFocusedField] = useState<string | null>(null);

  const handleSendClick = async () => {
    setButtonText('재전송');
    setTimerVisible(true);
    setTimeLeft(180);
    setInputPlaceholder('6자리를 입력해주세요.');
    setIsInputDisabled(false);
    setIsCloseIconVisible(true);

    const linkUID = parseInt(sessionStorage.getItem(LINK_UID) || '0', 10);

    const requestBody: IPointSmsCert = {
      memberUID: linkUID,
    };

    try {
      await PointSmsCertService.postAuthCode(requestBody);
    } catch (error) {
      console.error('API 호출 중 오류 발생:', error);
    }


    if (isKeyboardOpen && certNumberInputRef.current) {
      certNumberInputRef.current.focus();
    } else {
      requestAnimationFrame(() => {
        if (certNumberInputRef.current) {
          certNumberInputRef.current.focus();
        }
      });
    }
  };

  useEffect(() => {
    if (timerVisible && certNumberInputRef.current) {
      certNumberInputRef.current.focus();
    }
  }, [timerVisible]);

  const handleCertSuccess = async () => {
    const linkUID = parseInt(sessionStorage.getItem(LINK_UID) || '0', 10);

    const requestBody: IPointSmsCertSuccess = {
      memberUID: linkUID,
      authCode: certNumberValue,
    };

    try {
      const response = await PointSmsCertService.postAuthCertSuccess(requestBody);

      if (response instanceof BaseError) {
        console.log(`인증 실패: ${response.message}`);
      } else {
        const { verifiedResult, err_msg } = response;

        if (verifiedResult) {
          setIsCertSuccess(true);
        } else {
          setIsCertSuccess(false);
          showToast('인증 번호가 틀렸어요.', icFailCheckRed);
          setFocus('certNumber');
          console.log(`인증 실패: ${err_msg}`);
        }
      }
    } catch (error) {
      console.error('API 호출 중 오류 발생:', error);
    }
  };

  const { register, watch, setValue, setFocus } = useForm<IFormInputs>({
    defaultValues: {
      certNumber: '',
    },
  });

  useEffect(() => {
    if (!isInputDisabled) {
      setFocus('certNumber');
    }
  }, [isInputDisabled, setFocus]);

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        await fetchUserInfo();
        console.log('유저 정보 가져오기 성공');
      } catch (error) {
        console.error('유저 정보 가져오기 실패:', error);
      }
    };
    getUserInfo().catch((error) => {
      console.error('유저 정보 가져오기 실패:', error);
    });
  }, []);

  const certNumberValue = watch('certNumber', '');

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;
    if (timerVisible && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setButtonText('전송');
      setTimerVisible(false);
      setIsInputDisabled(true);
      setInputPlaceholder('[전송]을 눌러 주세요');
      setIsCloseIconVisible(false);
      showToast('인증 시간이 지났어요.[전송]을 눌러 주세요.', icFailCheckRed);
    }
    return () => {
      if (timer) clearInterval(timer)
    }
  }, [timerVisible, timeLeft])

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  }

  const handleClearInput = () => {
    console.log('Clearing input...')
    setValue('certNumber', '', { shouldDirty: false, shouldTouch: false })
  }

  return (
    <MainContainer>
      <Title
        title="본인 인증"
        leftArrowButtonVisible={true}
        leftOnClick={() => handleLeftOnClick()}
        rightTextButtonVisible={false}
      />
      <TitleContainer>
        <SafeTextSpan>안전한 서비스 이용</SafeTextSpan>
        <span>을 위해</span>
        <br />
        <span>본인인증을 먼저 진행해 주세요.</span>
      </TitleContainer>
      <PhoneInfoContainer>
        <PhoneNumberTextSpan>휴대폰 번호</PhoneNumberTextSpan>
        <PhoneNumberSpan>{userInfoStore.phoneNumber}</PhoneNumberSpan>
      </PhoneInfoContainer>
      <CertNumberContainer
        selected={focusedField === 'certNumber'}
        css={{ marginTop: '13px' }}
        onClick={() => {
          setFocusedField('certNumber');
        }}>

        <CertNumberContentsContainer>
          <span>인증번호</span>
          {timerVisible && <TimerSpan>{formatTime(timeLeft)}</TimerSpan>}
        </CertNumberContentsContainer>
        <CertNumberContentsContainer>
          <CertTransmissionContainer>
            <CertNumberInput
              type="text"
              inputMode="numeric"
              id={'certNumber'}
              placeholder={inputPlaceholder}
              value={certNumberValue}
              {...register('certNumber', {
                required: '인증번호를 입력해주세요.',

                onChange: (e) => {
                  const value = e.target.value.slice(0, 6);
                  setValue('certNumber', value);
                  setIsCloseIconVisible(value.length > 0);
                },
              })}

              onFocus={(e) => {
                setFocusedField('certNumber')
              }}
              onBlur={() => setFocusedField(null)}
              ref={certNumberInputRef}
              maxLength={6}
              disabled={isInputDisabled}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  (e.target as HTMLInputElement).blur();
                }
              }}

              style={{ backgroundColor: focusedField === 'certNumber' ? '#fff' : '#F8F8FA' }}
            />
            <CertTransmissionButtonContainer>
              {buttonText === '재전송' && isCloseIconVisible && (
                <CloseIcon src={icCloseCircleGray} alt="닫기 아이콘" onClick={handleClearInput} />
              )}
              <TransmissionButton onClick={handleSendClick}>{buttonText}</TransmissionButton>
            </CertTransmissionButtonContainer>
          </CertTransmissionContainer>
        </CertNumberContentsContainer>
      </CertNumberContainer>
      <BottomButtonContainer>
        <ButtonEnableDisable
          onClick={async () => {
            if (certNumberValue.length >= 6) {
              await handleCertSuccess();
            }
          }}
          disabled={certNumberValue.length < 6}
        >
          {certNumberValue.length >= 6 ? '완료' : '다음'}
        </ButtonEnableDisable>
      </BottomButtonContainer>

      {isCertSuccess && (
        <PurpleOneButtonCheckModal
          isVisible={isCertSuccess}
          onClose={() => {
            setIsCertSuccess(false);
            navigate('/pointWithdrawalPasswordSetting');
          }}
          title={'본인 인증이 완료되었습니다.'}
          content={''}
          buttonText={'확인'}
        />
      )}
    </MainContainer>
  );
});

export default PointWithdrawalIdentityCert;
