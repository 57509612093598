import { keyframes, styled } from '@stitches/react';

export const spin = keyframes({
  '0%': { transform: 'rotate(0deg)' },
  '100%': { transform: 'rotate(360deg)' },
})

export const Spinner = styled('div', {
  border: '4px solid rgba(0, 0, 0, 0.1)',
  borderTopColor: '#8B63BB',
  borderRadius: '50%',
  width: '40px',
  height: '40px',
  animation: `${spin} 1s linear infinite`,
})