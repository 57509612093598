import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import {
  BankFundContainer,
  BankFundContentContainer,
  BanKFundImg,
  BanKFundListContainer,
  BanKFundSpan,
  BankFundSpan,
  BankSelectModalContainer,
  FundContainer,
  ModalOverlay, SpinnerContainer,
} from './styles';
import { bankListStore } from '../../../../stores/store/Point/bankListStore';
import { Spinner } from '../../LoadingSpinner/LoadingSpinnerImage/styles';

interface BankSelectBottomModalProps {
  isVisible: boolean;
  onClose: () => void;
  onSelectBank: (bankCode: string, bankName: string) => void;
}

const BankSelectBottomModal: React.FC<BankSelectBottomModalProps> = ({
                                                                       isVisible,
                                                                       onClose,
                                                                       onSelectBank,
                                                                     }) => {
  useEffect(() => {
    const fetchBankData = async () => {
      await bankListStore.fetchBankData();
    };

    if (isVisible) {
      fetchBankData().catch((error) => {
        console.error('Error fetching bank data:', error);
      });
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [isVisible]);

  const handleOverlayClick = () => {
    onClose();
  };

  const handleModalContentClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const bankEntries = Object.entries(bankListStore.bankData);
  const fundEntries = Object.entries(bankListStore.fundData);

  const handleBankClick = (code: string) => {
    const bankName = bankListStore.bankData[code]?.name || '알 수 없는 은행';
    onSelectBank(code, bankName);
    onClose();
  };

  const handleFundClick = (code: string) => {
    const fundName = bankListStore.fundData[code]?.name || '알 수 없는 펀드';
    onSelectBank(code, fundName);
    onClose();
  };

  return (
    <>
      <ModalOverlay onClick={handleOverlayClick} />
      <BankSelectModalContainer isVisible={isVisible} onClick={handleOverlayClick}>
        <BankFundContainer>
          <BankFundSpan>은행</BankFundSpan>
          { bankListStore.loading ? (
            <SpinnerContainer>
              <Spinner />
            </SpinnerContainer>
          ) : (
            <BanKFundListContainer>
              {bankEntries.map(([code, bank]) => (
                <BankFundContentContainer key={code} onClick={() => handleBankClick(code)}>
                  <BanKFundImg src={bank.logo} alt={bank.name} />
                  <BanKFundSpan>{bank.name}</BanKFundSpan>
                </BankFundContentContainer>
              ))}
            </BanKFundListContainer>
          )}
          <FundContainer>
            <BankFundSpan>증권사</BankFundSpan>
            { bankListStore.loading ? (
              <SpinnerContainer>
                <Spinner />
              </SpinnerContainer>
            ) : (
              <BanKFundListContainer>
                {fundEntries.map(([code, fund]) => (
                  <BankFundContentContainer key={code} onClick={() => handleFundClick(code)}>
                    <BanKFundImg src={fund.logo} alt={fund.name} />
                    <BanKFundSpan>{fund.name}</BanKFundSpan>
                  </BankFundContentContainer>
                ))}
              </BanKFundListContainer>
            )}
          </FundContainer>
        </BankFundContainer>
      </BankSelectModalContainer>
    </>
  );
};

export default observer(BankSelectBottomModal);
