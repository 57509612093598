import { makeAutoObservable } from 'mobx';
import { IPointPendingData } from '../../../models/Point/pointPendingModel';
import pointPendingService from '../../../service/Point/pointPendingService';
import BaseError from '../../../models/baseError';

class PointPendingStore {
  isLoading = false;
  error: string | null = null;
  pendingSum: string = '0';
  pendingPoints: string = '0';
  pendingPays: string = '0';
  pointPendingData: IPointPendingData[] = [];
  pageNum = 1;
  isScrollLoading = false;
  isEnd = false;

  constructor() {
    makeAutoObservable(this);
  }

  setLoading(value: boolean) {
    this.isLoading = value;
  }

  setScrollLoading(value: boolean) {
    this.isScrollLoading = value;
  }

  // 적립 예정 포인트 데이터를 불러오는 스토어
  async fetchPointPending(mLinkUID: number, isScrollLoad: boolean = false) {
    if (isScrollLoad) {
      this.setScrollLoading(true);
    } else {
      this.setLoading(true);
      this.pageNum = 1;
    }

    try {
      const response = await pointPendingService.postPointPending({
        mLinkUID: mLinkUID,
        pageNum: this.pageNum,
      });

      if (response instanceof BaseError) {
        this.error = response.errorMessage;
        return;
      }

      const { pointData, sumData } = response.data;

      this.pendingSum = sumData.pendingSum;
      this.pendingPoints = sumData.pendingPoints;
      this.pendingPays = sumData.pendingPays;

      if (pointData.length === 0) {
        console.log('적립 예정 내역 데이터가 없습니다');
        this.isEnd = true;
        return;
      }

      if (isScrollLoad) {
        this.pageNum += 1;
        this.pointPendingData = [...this.pointPendingData, ...pointData];
        console.log('추가 데이터 로드됨:', pointData);
      } else {
        this.pointPendingData = pointData;
        this.pageNum = 1;
        console.log('데이터 덮어 쓰기');
      }
    } catch (error) {
      console.error('적립예정 포인트 불러 오기 실패', error);
      this.error = '적립예정 포인트 불러 오기 실패 하였습니다.';
      throw error;
    } finally {
      this.setLoading(false);
      this.setScrollLoading(false);
    }
  }
}

export const pointPendingStore = new PointPendingStore();
