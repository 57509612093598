import Title from '../../../components/atoms/Title/PageTitle';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  CautionTitleContainer,
  ExclamationBlackIc,
  PhotoCountContainer,
  RoadViewContainer,
  RoadViewIcon,
  RoadViewSpan,
  RoadViewTextContainer,
  UploadedImage,
  UploadedImageContainer,
  UploadImageSection,
} from './styles';
import icDotGray from 'assets/ic/ic_dot_gray.svg';
import icReceipt from 'assets/ic/ic_receip.svg';
import icExclamationBlack from 'assets/ic/ic_exclamation_black.svg';
import icRoadView from 'assets/ic/ic_road_view.svg';
import {
  AddressContainer,
  BottomButtonContainer,
  BottomDifferentParkingTicketButton,
  BottomLeftButton,
  BottomRightButton,
  CarParkingInfoContainer,
  CautionContainer,
  ListDotContainer,
  ListItemContainer,
  ListItemDotIc,
  ListItemSpan,
  ParkingInfoContainer,
  PaymentCancelContainer,
  PaymentInfoContentContainer,
  PaymentItemContainer,
  PaymentValue,
  ReceiptContainer,
  ReceiptIc,
  TerraceTowerBottomContentsContainer,
  TerraceTowerContainer,
  TicketTimeInfoContainer,
  ViewDivide1,
  ViewDivide2,
  ViewDivide3,
} from '../styles';
import imgParkingForeground from 'assets/images/img_parking_foreground.png';
import imgParkingGate from 'assets/images/img_parking_gate.png';
import imgParkingInside1 from 'assets/images/img_parking_inside1.png';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { JSP_URL } from 'constants/baseConstants';
import { useToast } from 'components/atoms/Toast/ToastProvider';
import icSuccessCheckGreen from 'assets/ic/ic_success_check_green.svg';
import icFailCheckRed from 'assets/ic/ic_fail_check_red.svg';
import icNotImage from 'assets/ic/ic_not_image.svg';
import { observer } from 'mobx-react';
import { terraceTowerUsageHistoryDetailStore } from 'stores/store/TerraceTower/terraceTowerUsageHistoryDetailStore';
import { copyToClipboard } from 'utils/clipboardUtils';
import { showWebBrowser } from 'utils/deviceUtils';
import { Caption1, Caption2, Caption3, Headline, Subheadline } from 'styles/typography';
import LoadingSpinnerScreen from 'components/atoms/LoadingSpinner/LoadingSpinnerScreen';
import RefundBottomModal from 'components/atoms/Modal/RefundBottomModal';

interface Image {
  src: string;
  alt: string;
}

const TerraceTowerDetailPage = observer(() => {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const {
    usageStatus,
    lotName,
    address,
    productName,
    carNumber,
    parkingAvailTime,
    paymentMethod,
    paymentAmount,
    paymentDate,
    receiptURL,
    photoAvailableYN,
    usageDate,
    refundReqDate
  } = terraceTowerUsageHistoryDetailStore.usageHistoryDetail || {}

  const location = useLocation()

  const queryParams = new URLSearchParams(location.search)

  const saleHistoryUID = queryParams.get('saleHistoryUID') || ''

  const [isRefundBottomModal, setIsRefundBottomModal] = useState(false)

  if (saleHistoryUID != null) {
    sessionStorage.setItem('saleHistoryUID', saleHistoryUID)
  }

  const useList = [
    '이 주차장은 원격 관제소에서 주차권을 사전에 확인해요. 주차중에 사용처리 될 수 있어요.',
    '출차 방법 : 번호판 자동 인식',
    '사전정산기 사용시 : 주차권 적용 불가',
  ]

  const cautionList = [
    '해당 주차권은 결제 당일 18:00~23:59 사이에만 이용 가능',
    '만차 또는 현장 사정에 따라 주차가 어려울 수 있음',
    '입출차 1회만 사용 가능하며, 출차 후 재입차 시 현장 요금이 적용',
    '해당 주차권은 유효시간 내에서만 사용 가능하며 유효시간 외 주차 시 전액 현장요금이 적용',
    '주차권의 잔여 시간은 환불이 불가 하며, 초과 주차 시간에 대해서는 현장 요금이 적용',
    '주차장 내에서 발생한 도난 및 분실에 대한 사고는 일체 책임 지지 않음',
    '구매 당일 동일한 주차권은 동일한 차량번호로 중복 적용 불가',
    '미사용 주차권에 대해서는 유효시간 내 취소 신청이 가능',
    '차량번호 오기입으로 인한 주차권은 환불 불가',
    '구매후 입차이력이 없는 경우 구매 일자 기준 7일 이내 환불 가능',
  ]

  const images: Image[] = [
    { src: imgParkingForeground, alt: '주차장 전경 이미지' },
    { src: imgParkingGate, alt: '주차장 게이트 이미지' },
    { src: imgParkingInside1, alt: '주차장 내부 이미지' },
  ]

  const [currentSlide, setCurrentSlide] = useState(0)

  const handleAfterChange = (index: number) => {
    setCurrentSlide(index)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const fetchTerraceTowerUsageHistoryDetail = async () => {
      await terraceTowerUsageHistoryDetailStore.fetchTerraceTowerUsageHistoryDetail(saleHistoryUID);
    }
    fetchTerraceTowerUsageHistoryDetail().catch((error) => {
      console.log('테라스 타워 주차정 정보 가져오는 중 에러 발생', error)
    })
  }, [])

  const moveJSP = () => {
    window.location.href = JSP_URL
  }

  useEffect(() => {
    window.onBtnBackClicked = function () {
      moveJSP()
    }
    return () => {
      delete window.onBtnBackClicked
    }
  }, [])

  const ticketInfoList = [
    { label: '차량정보', value: carNumber },
    { label: '주차가능 일시', value: parkingAvailTime },
    usageStatus === '결제취소'
      ? { label: '취소일시', value: refundReqDate }
      : usageStatus === '이용완료'
        ? { label: '이용완료', value: usageDate }
        : null,
  ]

  const paymentInfo = [
    { label: '결제수단', value: paymentMethod },
    { label: '결제금액', value: paymentAmount },
    { label: '결제일시', value: paymentDate },
    usageStatus === '결제취소' ? { label: '취소일시', value: refundReqDate } : null,
  ]

  const slideSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    zIndex: 1,
    afterChange: handleAfterChange,
  };

  if (terraceTowerUsageHistoryDetailStore.isLoading) {
    return <LoadingSpinnerScreen />;
  }

  return (
    <>
      <Title
        title="상세내역"
        leftArrowButtonVisible={true}
        leftOnClick={() => {
          moveJSP()
        }}
        rightTextButtonVisible={false}
      />

      <TerraceTowerContainer>
        <Headline weight={'bold'} color={'primary'}>
          {usageStatus}
        </Headline>
        <Subheadline weight={'semibold'} color={'black1'} css={{ marginTop: 6 }}>
          {lotName}
        </Subheadline>
        <AddressContainer
          onClick={() => {
            if (address) {
              copyToClipboard(address, showToast, icSuccessCheckGreen, icFailCheckRed)
            } else {
              console.error('주소가 없습니다.');
            }
          }}
        >
          <Caption2 weight={'regular'} css={{ color: '#6b6b6b', marginRight: 4 }}>
            {address}
          </Caption2>
          <Caption2 weight={'medium'} color={'blue'}>
            복사
          </Caption2>
        </AddressContainer>
      </TerraceTowerContainer>

      {/* TODO 추후에 세팅 */}
      {/*<MapContainer />*/}
      {/*<GuideLoadContainer>*/}
      {/*  <GuideLoadIc src={icGuideLoad} alt={'길 안내 아이콘'} />*/}
      {/*  <GuideLoadSpan>길 안내받기</GuideLoadSpan>*/}
      {/*</GuideLoadContainer>*/}

      <ViewDivide1 />
      {usageStatus === '이용전' ? (
        <TerraceTowerContainer>
          <Subheadline weight={'bold'} css={{ color: '#333' }}>
            이렇게 이용해요
          </Subheadline>
          <ViewDivide2 />
          <ListDotContainer>
            {useList.map((useText, index) => (
              <ListItemContainer key={index} hasMargin={index !== 0}>
                <ListItemDotIc src={icDotGray} alt="dot icon" />
                <Subheadline weight={'regular'} css={{ color: '#6b6b6b', marginLeft: 8 }}>
                  {useText}
                </Subheadline>
              </ListItemContainer>
            ))}
          </ListDotContainer>

          <TicketTimeInfoContainer>
            <Subheadline weight={'semibold'} css={{ color: '#333' }}>
              {productName}
            </Subheadline>
            <ViewDivide3 />
            <CarParkingInfoContainer>
              {ticketInfoList.map((ticketItem, index) => {
                if (ticketItem === null) {
                  return null;
                }
                return (
                  <ParkingInfoContainer key={index} style={{ marginTop: index === 0 ? 0 : '10px' }}>
                    <Caption1 weight={'regular'} css={{ color: '#868686' }}>
                      {ticketItem.label}
                    </Caption1>
                    <Caption1 weight={'medium'} css={{ color: '#555555' }}>
                      {ticketItem.value}
                    </Caption1>
                  </ParkingInfoContainer>
                );
              })}
            </CarParkingInfoContainer>
          </TicketTimeInfoContainer>

          {photoAvailableYN ? (
            <UploadImageSection>
              <RoadViewTextContainer>
                <RoadViewIcon src={icRoadView} alt={'로드뷰 아이콘'} />
                <RoadViewSpan>로드뷰</RoadViewSpan>
              </RoadViewTextContainer>
              <PhotoCountContainer>{currentSlide + 1}/3</PhotoCountContainer>
              <Slider {...slideSettings}>
                {images.map((image, index) => (
                  <UploadedImageContainer key={index}>
                    <UploadedImage src={image.src} alt={image.alt} />
                  </UploadedImageContainer>
                ))}
              </Slider>
            </UploadImageSection>
          ) : (
            <RoadViewContainer>
              <img src={icNotImage} alt={'이미지 없는 아이콘'} />
              <span style={{ marginTop: '10px' }}>이미지 준비중이에요</span>
            </RoadViewContainer>
          )}
        </TerraceTowerContainer>
      ) : (
        <div
          style={{
            margin: '0px 20px',
          }}
        >
          <TicketTimeInfoContainer>
            <Subheadline weight={'semibold'} css={{ color: '#333' }}>
              {productName}
            </Subheadline>
            <ViewDivide3 />
            <CarParkingInfoContainer>
              {ticketInfoList.map((ticketItem, index) => {
                if (ticketItem === null) {
                  return null;
                }
                return (
                  <ParkingInfoContainer key={index} style={{ marginTop: index === 0 ? 0 : '10px' }}>
                    <Caption1 weight={'regular'} css={{ color: '#868686' }}>
                      {ticketItem.label}
                    </Caption1>
                    <Caption1 weight={'medium'} css={{ color: '#555555' }}>
                      {ticketItem.value}
                    </Caption1>
                  </ParkingInfoContainer>
                );
              })}
            </CarParkingInfoContainer>
          </TicketTimeInfoContainer>
        </div>
      )}

      <ViewDivide1 />
      <TerraceTowerContainer>
        <PaymentInfoContentContainer>
          <Subheadline weight={'bold'} css={{ color: '#333333' }}>
            결제정보
          </Subheadline>
          <ReceiptContainer
            onClick={() => {
              if (!receiptURL) {
                console.error('receiptURL이 정의되지 않음');
                return;
              }
              showWebBrowser(receiptURL);
            }}
          >
            <ReceiptIc src={icReceipt} alt={'영수증 아이콘'} />
            <Caption3 weight={'medium'} css={{ color: '#555555', marginLeft: 4 }}>
              영수증
            </Caption3>
          </ReceiptContainer>
        </PaymentInfoContentContainer>

        {/*{paymentInfo.map((info, index) => (*/}
        {/*  <PaymentItemContainer key={index} isFirst={index === 0}>*/}
        {/*    <Caption1 weight={'regular'} css={{ color: '#866666' }}>*/}
        {/*      {info.label}*/}
        {/*    </Caption1>*/}
        {/*    <PaymentValue isUsePayment={index === 0}>{info.value}</PaymentValue>*/}
        {/*  </PaymentItemContainer>*/}
        {/*))}*/}
        {paymentInfo.map((info, index) => {
          if (info)
            return (
              <PaymentItemContainer key={index} isFirst={index === 0}>
                <Caption1 weight={'regular'} style={{ color: '#868686' }}>
                  {info.label}
                </Caption1>
                <PaymentValue isUsePayment={index === 0}>{info.value}</PaymentValue>
              </PaymentItemContainer>
            );
        })}
      </TerraceTowerContainer>

      {usageStatus === '이용전' && (
        <ViewDivide1 />
      )}

      {usageStatus === '이용전' && (
        <TerraceTowerBottomContentsContainer>
          <CautionTitleContainer>
            <ExclamationBlackIc src={icExclamationBlack} alt={'검정 배경 느낌표'} />
            <Subheadline weight={'bold'} css={{ color: '#333333', marginLeft: 10 }}>
              주차권 유의사항
            </Subheadline>
          </CautionTitleContainer>
          <ViewDivide2 />
          <ListDotContainer>
            {cautionList.map((cautionItem, index) => (
              <ListItemContainer key={index} hasMargin={index !== 0}>
                <ListItemDotIc src={icDotGray} alt="dot icon" />
                <ListItemSpan>{cautionItem}</ListItemSpan>
              </ListItemContainer>
            ))}
          </ListDotContainer>
          <CautionContainer>
            <Caption3 weight={'regular'} css={{ color: '#868686' }}>
              유의사항 미숙지로 인해 발생한 피해와 주차장 내에서
            </Caption3>
            <Caption3 weight={'regular'} css={{ color: '#868686' }}>
              발생한 사고에 대해서는 일체 책임지지 않습니다.
            </Caption3>
          </CautionContainer>
          <PaymentCancelContainer>
            <BottomLeftButton
              onClick={() => {
                navigate('/terraceTowerPaymentCancelRequest');
              }}
            >
              결제 취소하기
            </BottomLeftButton>
            <BottomRightButton
              onClick={() => {
                window.location.href = JSP_URL;
              }}
            >
              다른 주차권 보기
            </BottomRightButton>
          </PaymentCancelContainer>
        </TerraceTowerBottomContentsContainer>
      )}

      {usageStatus !== '이용전' && (
        <BottomButtonContainer>
          {usageStatus === '이용완료' || usageStatus === '기간만료' ? (
            <BottomButtonContainer>
              <BottomLeftButton
                onClick={() => {
                  setIsRefundBottomModal(true);
                }}
              >
                환불 요청 하기
              </BottomLeftButton>
              <BottomRightButton
                onClick={() => {
                  window.location.href = JSP_URL;
                }}
              >
                다른 주차권 보기
              </BottomRightButton>
            </BottomButtonContainer>
          ) : (
            <BottomDifferentParkingTicketButton
              onClick={() => {
                window.location.href = JSP_URL;
              }}
            >
              다른 주차권 보기
            </BottomDifferentParkingTicketButton>
          )}
        </BottomButtonContainer>
      )}

      <RefundBottomModal
        isVisible={isRefundBottomModal}
        onClose={() => {
          setIsRefundBottomModal(false);
        }}
        usageStatus={usageStatus ?? ''}
      />
    </>
  );
});

export default TerraceTowerDetailPage;
