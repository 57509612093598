import { ErrorData } from 'models/baseError'

export type ErrorCodeType = {
  [key: string]: ErrorData
};

const BASIC_ERROR_MESSAGE = '연결 상태가 일시적으로 불안정합니다.\n다시 시도해 주세요.';

const TEMPORARY_ERROR_MESSAGE =
  '일시적인 문제로 정보를 불러올 수 없습니다. 잠시 후 다시 시도해주세요.';

const ErrorCodeMap: ErrorCodeType = {
  SUCCESS: {
    status: 200,
    errorMessage: 'api 호출에 성공하였습니다.',
  },
  BAD_REQUEST: {
    status: 400,
    errorMessage: BASIC_ERROR_MESSAGE,
  },
  UNAUTHORIZED: {
    status: 401,
    errorMessage: '로그인이 필요합니다.',
  },
  FORBIDDEN: {
    status: 403,
    errorMessage: '권한이 없습니다.',
  },
  NOT_FOUND: {
    status: 404,
    errorMessage: TEMPORARY_ERROR_MESSAGE,
  },
  TIMEOUT_ERROR: {
    status: 408,
    errorMessage: BASIC_ERROR_MESSAGE,
  },
  INTERNAL_SERVER_ERROR: {
    status: 500,
    errorMessage: BASIC_ERROR_MESSAGE,
  },
  GATEWAY_TIMEOUT: {
    status: 504,
    errorMessage: BASIC_ERROR_MESSAGE,
  },
  NETWORK_TIMEOUT: {
    status: 599,
    errorMessage: BASIC_ERROR_MESSAGE,
  },
  UNKNOWN: {
    status: 600,
    errorMessage: '네트워크가 연결되지 않았습니다. \n Wi-Fi 또는 데이터를 활성화 해주세요.',
  },
  CLIENT_ERROR: {
    status: 999,
    errorMessage: BASIC_ERROR_MESSAGE,
  },
};

export default ErrorCodeMap;