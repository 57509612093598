import React, { useState, useEffect } from 'react';
import { CarReductionBottomModal, CheckButton } from './styles';

interface BottomModalProps {
  isVisible: boolean;
  onClose: () => void;
  onConfirm: (selectedItem: string) => void;
  selectedItem?: string | null; // Allow null here
}

const reductionsItems = [
  '전기 자동차',
  '저공해 자동차',
  '경형 자동차',
  '다둥이 자녀',
  '보훈보상 대상자',
  '국가 유공자',
  '장애인'
];

const CareReductionBottomModal: React.FC<BottomModalProps> = ({ isVisible, onClose, onConfirm, selectedItem }) => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  useEffect(() => {
    if (isVisible) {
      if (selectedItem) {
        const index = reductionsItems.indexOf(selectedItem);
        setSelectedIndex(index !== -1 ? index : null);
      } else {
        setSelectedIndex(null);
      }
    }
  }, [isVisible, selectedItem]);

  const handleItemClick = (index: number) => {
    setSelectedIndex(index);
  };

  const handleOverlayClick = () => {
    onClose();
  };

  const handleConfirm = () => {
    if (selectedIndex !== null) {
      onConfirm(reductionsItems[selectedIndex]);
      onClose();
    }
  };

  if (!isVisible) return null;

  return (
      <>
        <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.3)',
              zIndex: 9999,
            }}
            onClick={handleOverlayClick}
        />
        <CarReductionBottomModal isVisible={isVisible}>
          <div
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
                fontFamily: 'Pretendard',
                fontSize: '18px',
                fontWeight: 700,
                fontStyle: 'normal',
                lineHeight: '20px',
                marginTop: '34px',
                paddingBottom: '44px',
              }}>
            <span>감면 유형</span>
            <div style={{ marginTop: '19px' }}>
              {reductionsItems.map((item, index) => (
                  <div
                      key={index}
                      onClick={() => handleItemClick(index)}
                      style={{
                        width: '310px',
                        height: '40px',
                        flexShrink: 0,
                        borderRadius: '10px',
                        opacity: selectedIndex === index ? 1 : 0.7,
                        background: selectedIndex === index ? '#F5F5FF' : '#FFFFFF',
                        color: selectedIndex === index ? '#8B63BB' : '#6B6B6B',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                        margin: '4px auto',
                        border: 'none',
                        fontWeight: selectedIndex === index ? 700 : 400,
                        fontFamily: 'Pretendard',
                        boxShadow: selectedIndex === index ? '0px 4px 8px rgba(0, 0, 0, 0.1)' : 'none',
                      }}>
                    {item}
                  </div>
              ))}
            </div>
            <CheckButton onClick={handleConfirm}>확인</CheckButton>
          </div>
        </CarReductionBottomModal>
      </>
  );
};

export default CareReductionBottomModal;
