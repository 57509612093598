import BaseService from '../baseService';
import BaseError from '../../models/baseError';
import AxiosClient from '../../utils/axiosClient';
import ErrorHandler from '../../utils/error/errorHandler';
import {
  IPointPasswordGenerate,
  IPointPasswordGenerateResponse,
} from '../../models/Point/pointPasswordGenerateModel';
import {
  IPointPasswordValidate,
  IPointPasswordValidateResponse,
} from '../../models/Point/pointPasswordValidate';
import {
  IPointPasswordDelete,
  IPointPasswordDeleteResponse,
} from '../../models/Point/pointPasswordDeleteModel';

interface PointPasswordGenerateEndPoints {
  // 출금 비밀번호 등록
  POINT_PASSWORD_GENERATE: string;
  // 출금 비밀번호 검증
  POINT_PASSWORD_VALIDATE: string;
  // 출금 비밀번호 수정
  POINT_PASSWORD_UPDATE: string;
  // 출금 비밀번호 삭제
  POINT_PASSWORD_DELETE: string;
}

class PointPasswordGenerateService extends BaseService<PointPasswordGenerateEndPoints> {
  apiEndpoints: PointPasswordGenerateEndPoints = {
    POINT_PASSWORD_GENERATE: 'pwGenerate',
    POINT_PASSWORD_VALIDATE: 'pwValidate',
    POINT_PASSWORD_UPDATE: 'pwUpdate',
    POINT_PASSWORD_DELETE: 'pwDelete',
  };

  postPointPasswordGenerate = async (
    requestBody: IPointPasswordGenerate,
    iv: string,
    salt: string,
  ): Promise<IPointPasswordGenerateResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .headers({
          'Content-Type': 'application/json',
          iv: iv,
          salt: salt,
        })

        .data({ ...requestBody })
        .url(`${this.apiEndpoints.POINT_PASSWORD_GENERATE}`)
        .build<IPointPasswordGenerateResponse>();

      console.log('포스트 바디:' + requestBody.wdPassword);
      console.log('포스트 iv:' + iv);
      console.log('포스트 salt:' + salt);

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  }

  postPointPasswordValidate = async (
    requestBody: IPointPasswordValidate,
    iv: string,
    salt: string,
  ): Promise<IPointPasswordValidateResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .headers({
          'Content-Type': 'application/json',
          iv: iv,
          salt: salt,
        })
        .data({ ...requestBody })
        .url(`${this.apiEndpoints.POINT_PASSWORD_VALIDATE}`)
        .build<IPointPasswordValidateResponse>();
      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  }

  postPointPasswordUpdate = async (
    requestBody: IPointPasswordGenerate,
    iv: string,
    salt: string,
  ): Promise<IPointPasswordGenerateResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .headers({
          'Content-Type': 'application/json',
          iv: iv,
          salt: salt,
        })
        .data({ ...requestBody })
        .url(`${this.apiEndpoints.POINT_PASSWORD_UPDATE}`)
        .build<IPointPasswordGenerateResponse>();
      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };

  postPointPasswordDelete = async (
    requestBody: IPointPasswordDelete,
    iv: string,
    salt: string,
  ): Promise<IPointPasswordDeleteResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .headers({
          'Content-Type': 'application/json',
          iv: iv,
          salt: salt,
        })
        .data({ ...requestBody })
        .url(`${this.apiEndpoints.POINT_PASSWORD_DELETE}`)
        .build<IPointPasswordDeleteResponse>();
      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };
}

export const pointPasswordGenerateService = new PointPasswordGenerateService();