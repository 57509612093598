import { styled } from "@stitches/react"

export const TerraceCancelRequestContainer = styled('div', {
  display: 'flex', flexDirection: 'column', margin: '20px'
})

export const TerraceCancelRequestCheckSpan = styled('div', {
  color: '#1A1A1A',
  fontFamily: 'Pretendard',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
})

export const TerraceCancelDetailSpan = styled('span', {
  marginTop: '16px',
  color: '#555',
  fontSize: '15px',
  fontWeight: 400
})

export const ListCancelUseItemContainer = styled('div', {
  display: 'flex',
  padding: '16px 20px',
  backgroundColor: '#F8F8FA',
  borderRadius: '5px',
  fontFamily: 'Pretendard',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',

  variants: {
    hasMargin: {
      true: { marginTop: '10px' },
      false: { marginTop: '0' }
    }
  }
})

export const RequestPaymentCancelContainer = styled('div', {
  width: '100%',
  height: '46px',
  display: 'flex',
  marginTop: '28px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  boxSizing: 'border-box',
})

export const ReturnLeftButton = styled('button', {
  flex: 1,
  width: '100%',
  padding: '14px',
  fontSize: '14px',
  height: '46px',
  fontFamily: 'Pretendard',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  color: '#868686',
  border: 'none',
  backgroundColor: '#F2F2F2',
  borderRadius: '4px',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  cursor: 'none',
})

export const PaymentCancelLeftButton = styled('button', {
  flex: 1,
  width: '100%',
  height: '46px',
  fontSize: '14px',
  fontFamily: 'Pretendard',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  color: '#FFFFFF',
  border: 'none',
  backgroundColor: '#868686',
  borderRadius: '4px',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  cursor: 'none',

  variants: {
    state: {
      active: {
        backgroundColor: '#8B63BB', // 선택된 상태일 때 배경색
      },
      inactive: {
        backgroundColor: '#868686', // 비활성화된 상태일 때 배경색
      },
    },
  }
})


