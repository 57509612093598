import React from 'react';
import { NotificationModalContainer, TitleContainer } from './styles';
import icCloseDarkGray from '../../../../assets/ic/ic_close_dark_gray.svg';

interface NotificationModalProps {
  isVisible: boolean;
  onClose: () => void;
  title: string;
  content: React.ReactNode;
}

const NotificationModal: React.FC<NotificationModalProps> = ({
                                                               isVisible,
                                                               onClose,
                                                               title,
                                                               content,
                                                             }) => {
  if (!isVisible) return null;

  return (
    <>
      <NotificationModalContainer onClick={(e) => e.stopPropagation()}>
        <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center' }}>
          <TitleContainer>{title}</TitleContainer>
          <img
            src={icCloseDarkGray}
            alt={'닫기 회색 아이콘'}
            onClick={onClose}
            style={{
              marginBottom: '6px',
            }}
          />
        </div>
        <div>{content}</div>
      </NotificationModalContainer>
    </>
  );
};

export default NotificationModal;
