import { makeAutoObservable, runInAction } from 'mobx';
import BaseError from '../../../models/baseError';
import BankListService from '../../../service/Bank/bankListService';

class BankListStore {
  bankData: { [key: string]: { name: string; logo: string } } = {}
  fundData: { [key: string]: { name: string; logo: string } } = {}
  loading: boolean = true;

  constructor() {
    makeAutoObservable(this)
  }

  setBankData(data: { [key: string]: { name: string; logo: string } }) {
    this.bankData = data
  }

  setFundData(data: { [key: string]: { name: string; logo: string } }) {
    this.fundData = data;
  }

  async fetchBankData() {
    this.loading = true
    try {
      const response = await BankListService.getBankList();
      if (response instanceof BaseError) {
        console.error('은행 데이터 가져오기 오류:', response.message);
      } else {
        const formattedBankData: { [key: string]: { name: string; logo: string } } = {}
        const formattedFundData: { [key: string]: { name: string; logo: string } } = {}

        response.bankList.forEach((item) => {
          formattedBankData[item.bankCode] = {
            name: item.bankName,
            logo: `/assets/ic/bankLogos/${item.bankCode}.svg`,
          }
        })

        response.fundList.forEach((item) => {
          formattedFundData[item.bankCode] = {
            name: item.bankName,
            logo: `/assets/ic/bankLogos/${item.bankCode}.svg`,
          }
        })

        runInAction(() => {
          this.setBankData(formattedBankData)
          this.setFundData(formattedFundData)
        })
      }
    } catch (error) {
      console.error('은행 데이터 가져오기 오류:', error);
    } finally {
      this.loading = false
    }
  }
}

export const bankListStore = new BankListStore()
