import React from 'react';
import { useNavigate } from "react-router-dom";
import { MainContainer } from "../../../components/styles";
import Title from "../../../components/atoms/Title/PageTitle";
// import icSuccess from "../../../assets/ic/ic_success.png";
import imgSuccess from "assets/images/img_shield.svg";
const ReductionRegistrationSuccess = () => {
  const navigate = useNavigate();
  const handleLeftOnClick = () => {
    navigate(-1);
  };

  return (
      <MainContainer>
        <Title
            title="복지대상 신청"
            leftArrowButtonVisible
            leftOnClick={handleLeftOnClick}
            rightTextButtonVisible={false}
        />
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '20px',
          justifyContent: 'center',
        }}>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <span style={{
            color: '#555',
            textAlign: 'center',
            fontFamily: 'Pretendard',
            fontSize: '17px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '23px'
          }}>공영주차장 요금감면 대상</span>
            <span style={{
              color: '#8B63BB',
              textAlign: 'center',
              fontFamily: 'Pretendard',
              fontSize: '30px',
              fontStyle: 'normal',
              fontWeight: 700,
              lineHeight: '20px',
              marginTop: '11px'
            }}>신청 완료</span>
            <img
                style={{ marginTop: '30px' }}
                src={imgSuccess}
                alt='성공 아이콘'
            />
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              marginTop: '20px',
              backgroundColor: '#F8F8FA',
              padding: '10px',
            }}>
              {/* 첫 번째 줄 */}
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: '10px',
              }}>
                <div style={{
                  color: '#555',
                  fontFamily: 'Pretendard',
                  fontSize: '15px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '22px',
                }}>
                  감면대상 구분
                </div>
                <div style={{
                  color: '#333',
                  fontFamily: 'Pretendard',
                  fontSize: '15px',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  lineHeight: '22px',
                  textAlign: 'right',
                }}>
                  장애인 (50%)
                </div>
              </div>
              {/* 두 번째 줄 */}
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: '10px',
              }}>
                <div style={{
                  color: '#555',
                  fontFamily: 'Pretendard',
                  fontSize: '15px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '22px',
                }}>
                  차량번호
                </div>
                <div style={{
                  color: '#333',
                  fontFamily: 'Pretendard',
                  fontSize: '15px',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  lineHeight: '22px',
                  textAlign: 'right',
                }}>
                  123가1234
                </div>
              </div>
              {/* 세 번째 줄 */}
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}>
                <div style={{
                  color: '#555',
                  fontFamily: 'Pretendard',
                  fontSize: '15px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '22px',
                }}>
                  성명
                </div>
                <div style={{
                  color: '#333',
                  fontFamily: 'Pretendard',
                  fontSize: '15px',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  lineHeight: '22px',
                  textAlign: 'right',
                }}>
                  홍길동
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
            style={{
              width: '100%',
              height: '46px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              border: 'none',
              backgroundColor: '#8B63BB',
              color: '#fff',
              borderRadius: '4px',
              padding: '10px 20px',
              cursor: 'pointer',
              fontSize: '16px',
              marginTop: 'auto',
              marginBottom: '40px'
            }}
        >
          확인
        </button>
      </MainContainer>
  );
}

export default ReductionRegistrationSuccess;
