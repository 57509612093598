import { MainContainer } from '../../../../components/styles';
import {
  AllDeleteContainer,
  BottomButtonKeyboardContainer, BottomGridContainer, ButtonEnableDisable,
  CautionContainer,
  InputBoxContainer, InputButton, InputGridContainer, OneDeleteContainer, OneDeleteImg,
  PasswordInput,
  PasswordTitleInputContainer, SearchPasswordContainer,
  WithdrawalMainContainer,
} from '../styles';
import Title from '../../../../components/atoms/Title/PageTitle';
import icArrowDelete from '../../../../assets/ic/ic_arrow_delete.svg';
import WithdrawBottomModal from '../../../../components/atoms/Modal/WithdrawBottomModal';
import PurpleOneButtonCheckModal from '../../../../components/atoms/Modal/PurpleOneButtonCheckModal';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const PointWithdrawalPasswordSetting = () => {
  const navigate = useNavigate();
  const [isWithdrawBottomModalVisible, setIsWithdrawBottomModalVisible] = React.useState(false);
  const [inputValues, setInputValues] = React.useState<string[]>(Array(6).fill(''));
  const [isSearchPasswordModal, setIsSearchPasswordModal] = useState(false)
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);

  const handleSearchPasswordClick = () => {
    setIsSearchPasswordModal(true)
  }

  const handleNumberClick = (index: number, number: string) => {
    setInputValues(prevValues => {
      const newValues = [...prevValues];
      newValues[index] = number;
      return newValues;
    });
  };

  const handleClearAll = () => {
    setInputValues(Array(6).fill(''));
  };

  const handleBackspace = () => {
    setInputValues(prevValues => {
      const lastNonEmptyIndex = prevValues
        .map((value, index) => value !== '' ? index : -1)
        .filter(index => index !== -1)
        .pop();

      if (lastNonEmptyIndex !== undefined) {
        const newValues = [...prevValues];
        newValues[lastNonEmptyIndex] = '';
        return newValues;
      }

      return prevValues;
    });
  };

  const getDisplayValue = (value: string, index: number) => {
    const filledCount = inputValues.filter(v => v !== '').length;
    return index < filledCount - 1 ? '*' : value;
  };
  const isAllFieldsFilled = inputValues.every(value => value !== '');

  return (
    <>
      <MainContainer>
        <Title
          title="출금"
          leftArrowButtonVisible={true}
          leftOnClick={() => {
            navigate(-1);
          }}
          rightTextButtonVisible={false}
        />

        <PasswordTitleInputContainer>
          <span>고객님의 안전한 서비스 이용을 위해</span>
          <br />
          <span>송금 비밀번호를 설정해 주세요.</span>
        </PasswordTitleInputContainer>
        <InputBoxContainer>
          {inputValues.map((value, index) => (
            <PasswordInput key={index} value={getDisplayValue(value, index)} readOnly />
          ))}
        </InputBoxContainer>

        {/* 비밀번호 확인 UI */}
        {isConfirmPasswordVisible && (
          <CautionContainer>
            <span>비밀번호를 다시 한번 확인해주세요.</span>
          </CautionContainer>
        )}

        <WithdrawBottomModal
          isVisible={isWithdrawBottomModalVisible}
          onClose={() => setIsWithdrawBottomModalVisible(false)}
        />

        <PurpleOneButtonCheckModal
          isVisible={isSearchPasswordModal}
          onClose={() => {
            setIsSearchPasswordModal(false)
            navigate('/pointWithdrawalIdentityCert')
          }}
          title={'비밀번호를 다시 설정해주세요.'}
          content={''}
          buttonText={'확인'}/>
      </MainContainer>

      <BottomButtonKeyboardContainer>
        <ButtonEnableDisable
          onClick={() => {
            if (isAllFieldsFilled) {
              const password = inputValues.join('')
              navigate('/pointWithdrawalPasswordSettingRe', { state: { password } }); // 비밀번호를 다음 페이지로 넘김
            }
          }}
          disabled={!isAllFieldsFilled}
        >
          확인
        </ButtonEnableDisable>
        <BottomGridContainer>
          <InputGridContainer>
            {[1, 2, 3].map((number: number) => (
              <InputButton
                key={number}
                onClick={() => {
                  const emptyIndex = inputValues.indexOf('');
                  if (emptyIndex !== -1) {
                    handleNumberClick(emptyIndex, number.toString());
                  }
                }}
              >
                {number}
              </InputButton>
            ))}
          </InputGridContainer>
          <InputGridContainer>
            {[4, 5, 6].map((number: number) => (
              <InputButton
                key={number}
                onClick={() => {
                  const emptyIndex = inputValues.indexOf('');
                  if (emptyIndex !== -1) {
                    handleNumberClick(emptyIndex, number.toString());
                  }
                }}
              >
                {number}
              </InputButton>
            ))}
          </InputGridContainer>
          <InputGridContainer>
            {[7, 8, 9].map((number: number) => (
              <InputButton
                key={number}
                onClick={() => {
                  const emptyIndex = inputValues.indexOf('');
                  if (emptyIndex !== -1) {
                    handleNumberClick(emptyIndex, number.toString());
                  }
                }}
              >
                {number}
              </InputButton>
            ))}
          </InputGridContainer>
          <InputGridContainer>
            <AllDeleteContainer onClick={handleClearAll}>전체삭제</AllDeleteContainer>
            <InputButton
              onClick={() => {
                const emptyIndex = inputValues.indexOf('');
                if (emptyIndex !== -1) {
                  handleNumberClick(emptyIndex, '0');
                }
              }}
            >
              0
            </InputButton>
            <OneDeleteContainer onClick={handleBackspace}>
              <OneDeleteImg
                src={icArrowDelete}
                alt={'입력 필드 지우는 아이콘'}
              />
            </OneDeleteContainer>
          </InputGridContainer>
        </BottomGridContainer>
      </BottomButtonKeyboardContainer>
    </>
  )
}

export default PointWithdrawalPasswordSetting
