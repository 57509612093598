import { styled } from '@stitches/react';

export const CautionTitleContentsContainer = styled('div', {
  border: '2px solid #8B63BB',
  borderRadius: '10px',
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  padding: '20px',
})

export const CautionSpan = styled('span', {
  color: '#1A1A1A',
  fontFamily: 'Pretendard',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  marginTop: '20px'
})

export const CautionListContainer = styled('div', {
  color: '#333',
  fontFamily: 'Pretendard',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '1.6',
  marginTop: '20px',
  marginLeft: '16px',
  marginRight: '16px',
  textAlign: 'left',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
})

export const StyledCheckbox = styled('input', {
  appearance: 'none',
  width: '20px',
  height: '20px',
  border: '2px solid #E6E6E6',
  borderRadius: '4px',
  outline: 'none',
  cursor: 'pointer',
  marginRight: '8px', // 체크 박스와 레이블 사이의 간격
  '&:checked': {
    backgroundColor: '#8B63BB',
    borderColor: '#8B63BB',
  },
  '&:focus': {
    borderColor: '#8B63BB',
  },
});

export const CheckBoxLabel = styled('label', {
  display: 'flex',
  alignItems: 'center',
  fontSize: '16px',
  lineHeight: '1.5',
  marginBottom: '12px',
  justifyContent: 'space-between',
  marginTop: '20px',
});

export const DeleteAccountEssentialSpan = styled('span', {
  color: '#868686', // Change to your desired color
  marginRight: '4px',
  fontFamily: 'Pretendard',
  fontStyle: 'normal',
  fontSize: '13px',
  fontWeight: 400,
});
