import { styled } from '@stitches/react';

export const MainContainer = styled('div', {
  display: 'block',
  margin: 'auto 16px auto 16px',

  '@media screen and (max-height: 480px)': {
    padding: '20px',
  },
});

export const UserInfoInputContainer = styled('div', {
  width: '100%',
  background: '#FFFFFF',
  borderRadius: '12px',
  display: 'flex',
  paddingLeft: '16px',
  paddingRight: '4px',
  textAlign: 'center',
});

