import React from 'react';
import { RegionSelectModalContainer } from './styles';

interface BottomModalProps {
  isVisible: boolean;
  onClose: () => void;
  onSelectRegion: (region: string) => void; // Add t`his prop
}

const regions = [
  '강동구', '강북구', '강서구',
  '관악구', '금천구', '노원구',
  '북구', '성남시', '성동구',
  '양천구', '은평구', '중랑구',
  '이 외', '', ''
];

const RegionSelectBottomModal: React.FC<BottomModalProps> = ({ isVisible, onClose, onSelectRegion }) => {
  const handleOverlayClick = () => {
    onClose();
  };

  const handleModalContentClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  if (!isVisible) return null;

  return (
      <>
        <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.3)',
              zIndex: 9999,
            }}
            onClick={handleOverlayClick}
        />
        <RegionSelectModalContainer
            isVisible={isVisible}
            onClick={handleOverlayClick}
        >
          <div onClick={handleModalContentClick}
               style={{
                 flex: 1,
                 display: 'flex',
                 flexDirection: 'column',
                 textAlign: 'center',
                 fontFamily: 'Pretendard',
                 fontSize: '18px',
                 fontWeight: 600,
                 fontStyle: 'normal',
                 lineHeight: '20px',
                 marginTop: '36px',
                 paddingBottom: '44px'
               }}>
            <span>지역 선택</span>
            <div style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '8px',
              boxSizing: 'border-box',
              marginTop: '30px',
              color: '#6B6B6B',
              fontFamily: 'Pretendard',
              fontSize: '17px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '22px',
              justifyContent: 'space-between'
            }}>
              {regions.map((region) => (
                  <div
                      key={region}
                      style={{
                        flex: '1 0 calc(33.333% - 8px)',
                        padding: '10px',
                        backgroundColor: '#fff',
                        textAlign: 'center',
                        cursor: 'pointer',
                        userSelect: 'none',
                        boxSizing: 'border-box',
                      }}
                      onClick={() => {
                        onSelectRegion(region); // Call onSelectRegion when an item is clicked
                        onClose();
                      }}
                  >
                    {region}
                  </div>
              ))}
            </div>
            <button
                style={{
                  marginTop: '26px',
                  height: '46px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  border: 'none',
                  backgroundColor: '#8B63BB',
                  color: '#fff',
                  borderRadius: '4px',
                  padding: '10px 20px',
                  cursor: 'pointer',
                  fontSize: '16px',
                }}
                onClick={() => handleOverlayClick()}
            >
              닫기
            </button>
          </div>
        </RegionSelectModalContainer>
      </>
  );
};

export default RegionSelectBottomModal;
