import icLoudSpeakerPurple from '../../../assets/ic/ic_loudspeaker_purple.svg';
import icLoudSpeakerGray from '../../../assets/ic/ic_loudspeaker_gray.svg';
import icCloseGray from '../../../assets/ic/ic_close_gray.svg';
import {
  AllMessageReadContainer,
  DateContainer,
  IconNotification,
  MessageBackGroundContainer,
  MessageContentContainer,
  MessageTitleContainer,
  MessageTitleContentsContainer,
  MessageTitleContentsDateContainer,
  MessageTitleSpan,
  NewTextSpan,
  NotNotifyContainer,
  NotNotifyText
} from "../styles";
import TwoButtonModal from "../../../components/atoms/Modal/TwoButtonModal";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import icNotificationCenter from "../../../assets/ic/ic_notification_notification_center.svg";

const initialMessages = [
  {
    title: "월 정액 가상계좌 안내",
    content: "[입금요청] 49,000원 (예금주 : 주차장만드는사람들 (주) 국민은행) 입금바랍니다.",
    date: "오늘",
    icon: icLoudSpeakerPurple,
    new: true
  },
  {
    title: "다음 달 요금 안내",
    content: "[입금요청] 50,000원 (예금주 : 예금주(주) 국민은행) 입금바랍니다.",
    date: "어제",
    icon: icLoudSpeakerGray,
    new: false
  },
];

const NotificationCenterCenterImportantMessageList: React.FC<{ updateMessageDeleteImage: boolean }> = ({ updateMessageDeleteImage }) => {
  const navigate = useNavigate();

  const [isAllMessageReadModal, setIsAllMessageReadModal] = useState(false);
  const [messages, setMessages] = useState(initialMessages);
  const [allMessagesRead, setAllMessagesRead] = useState(false);
  const [showCloseIcon, setShowCloseIcon] = useState(false);
  const [isMessageDeleteModal, setIsMessageDeleteModal] = useState(false);
  const [selectedMessageIndex, setSelectedMessageIndex] = useState<number | null>(null); // 선택된 메시지의 인덱스

  const allMessageReadModel = () => {
    setIsAllMessageReadModal(true);
  };

  const handleAllReadMessage = () => {
    const updatedMessages = messages.map(message => ({
      ...message,
      icon: icLoudSpeakerGray,
      new: false // 모든 메시지의 'New' 태그 제거
    }));
    setMessages(updatedMessages);
    setAllMessagesRead(true);
    setIsAllMessageReadModal(false);
  };

  const messageDeleteModal = (index: number) => {
    setSelectedMessageIndex(index); // 삭제할 메시지의 인덱스 설정
    setIsMessageDeleteModal(true);
  }

  const handleDeleteMessage = () => {
    if (selectedMessageIndex !== null) {
      const updatedMessages = messages.filter((_, index) => index !== selectedMessageIndex);
      setMessages(updatedMessages);
      setIsMessageDeleteModal(false);
    }
  };

  useEffect(() => {
    setShowCloseIcon(updateMessageDeleteImage);
  }, [updateMessageDeleteImage]);

  return (
      <>
        {messages.length > 0 ? (
            <>
              <AllMessageReadContainer onClick={allMessageReadModel}>
                모두읽기
              </AllMessageReadContainer>
              {messages.map((message, index) => (
                  <MessageBackGroundContainer key={index}>
                    <MessageTitleContentsDateContainer>
                      <MessageTitleContentsContainer
                          onClick={() => {
                            navigate('/notificationCenterImportantMessageDetail');
                          }}
                      >
                        <MessageTitleContainer>
                          <IconNotification src={message.icon} alt="확성기 아이콘" />
                          <MessageTitleSpan
                              style={{
                                fontWeight: allMessagesRead || !message.new ? 400 : 700,
                                color: allMessagesRead || !message.new ? '#B0B0B0' : '#333',
                              }}
                          >
                            {message.title}
                          </MessageTitleSpan>
                          {!allMessagesRead && message.new && (
                              <NewTextSpan>
                                New
                              </NewTextSpan>
                          )}
                        </MessageTitleContainer>
                        <MessageContentContainer>
                          <span>{message.content}</span>
                        </MessageContentContainer>
                      </MessageTitleContentsContainer>
                      <DateContainer>
                        {showCloseIcon ? (
                            <img src={icCloseGray} alt="닫기 아이콘" onClick={() => messageDeleteModal(index)} />
                        ) : (
                            message.date
                        )}
                      </DateContainer>
                    </MessageTitleContentsDateContainer>
                  </MessageBackGroundContainer>
              ))}
            </>
        ) : (
            <NotNotifyContainer>
              <img src={icNotificationCenter} alt="느낌표 아이콘" style={{ width: '28px', height: '28px' }} />
              <NotNotifyText>알림이 없습니다.</NotNotifyText>
            </NotNotifyContainer>
        )}

        <TwoButtonModal
            isVisible={isAllMessageReadModal}
            onClose={() => setIsAllMessageReadModal(false)}
            title={'모든 알림을 읽음\n처리 하시겠습니까?'}
            content={''}
            leftButtonText={'취소'}
            rightButtonText={'확인'}
            rightClick={handleAllReadMessage}
        />

        {isMessageDeleteModal && (
            <TwoButtonModal
                isVisible={isMessageDeleteModal}
                onClose={() => setIsMessageDeleteModal(false)}
                title={'알림을 삭제하시겠습니까?'}
                content={'삭제된 알림은 다시 복구할 수 없습니다.'}
                leftButtonText={'취소'}
                rightButtonText={'확인'}
                rightClick={handleDeleteMessage}
            />
        )}
      </>
  );
};

export default NotificationCenterCenterImportantMessageList;
