import Title from '../../../components/atoms/Title/PageTitleUsually';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AgreeContainer,
  BlackBoldSpan,
  BlackSpan,
  CautionContainer,
  CautionSpan,
  ListDotContainer,
  ListItemContainer,
  ListItemDotIc,
  ListItemSpan,
  TerraceCautionColumnContainer,
  TerraceCautionContainer,
  TerraceCautionIcon,
  TerraceTowerContainer,
  ViewDivide1,
  ViewDivide2,
  WarningContainer,
} from '../styles';
import icWarningPurple from 'assets/ic/ic_warning_purple.svg';
import {
  CautionTitleContainer,
  CautionTitleSpan,
  ExclamationBlackIc,
} from '../TerraceTowerDetail/styles';
import icExclamationBlack from 'assets/ic/ic_exclamation_black.svg';
import icDotGray from 'assets/ic/ic_dot_gray.svg';
import {
  AgreeButton,
  BottomButtonContainer,
  RequiredText,
  StyledCheckbox,
  TerraceTowerBottomContentsContainer,
} from './styles';
import { showWebBrowser, webViewClose } from 'utils/deviceUtils';
import { terraceTowerProductStore } from 'stores/store/TerraceTower/terraceTowerProductStore';
import { observer } from 'mobx-react';
import { Caption1, Caption2, Caption3, Headline, Subheadline } from 'styles/typography';
import { colors } from 'styles/colors';
import { terraceTowerUsageHistoryDetailStore } from 'stores/store/TerraceTower/terraceTowerUsageHistoryDetailStore';
import LoadingSpinnerScreen from 'components/atoms/LoadingSpinner/LoadingSpinnerScreen';
import icCarCaution from 'assets/ic/ic_car_caution.svg'
import icSteeringWheel from 'assets/ic/ic_steering_wheel_caution.svg'

const TerraceTowerReservationCautionPage = observer(() => {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false)

  const handleAgreeClick = () => {
    setIsChecked((prev) => !prev)
  }

  const { productName } = terraceTowerProductStore.terraceTowerOrder || {}

  useEffect(() => {
    const handleSetProductUIDEvent = (event: CustomEvent) => {
      const productUID = event.detail

      if (productUID) {
        console.log('Received productUID:', productUID)

        sessionStorage.setItem('productUID', productUID)

        const fetchTerraceTowerProduct = async () => {
          await terraceTowerProductStore.fetchTerraceTowerProduct(productUID)
          console.log(productUID)
        }
        fetchTerraceTowerProduct().catch((error) => {
          console.log('주문정보 불러오는 중 에러', error)
          alert(error)
        })
      } else {
        console.error('No productUID found in event')
      }
    }
    window.addEventListener('setProductUID', handleSetProductUIDEvent as EventListener)

    return () => {
      window.removeEventListener('setProductUID', handleSetProductUIDEvent as EventListener)
    }
  }, [])

  const handleButtonClick = () => {
    if (isChecked) {
      sessionStorage.setItem('carNumber', '')
      sessionStorage.setItem('isNotFocus', 'false')
      sessionStorage.setItem('isPolicyPersonalCheck', 'false')
      navigate('/terraceTowerPayment')
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      window.scrollTo(0, 0)
    }, 100)
    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    setIsChecked(true)
  }, [])

  const handleWebViewClose = () => {
    webViewClose()
  }

  const cautionList = [
    '해당 주차권은 결제 당일 18:00~23:59 사이에만 이용 가능',
    '만차 또는 현장 사정에 따라 주차가 어려울 수 있음',
    '입출차 1회만 사용 가능하며, 출차 후 재입차 시 현장 요금이 적용',
    '해당 주차권은 유효시간 내에서만 사용 가능하며 유효시간 외 주차 시 전액 현장요금이 적용',
    '주차권의 잔여 시간은 환불이 불가 하며, 초과 주차 시간에 대해서는 현장 요금이 적용',
    '주차장 내에서 발생한 도난 및 분실에 대한 사고는 일체 책임 지지 않음',
    '구매 당일 동일한 주차권은 동일한 차량번호로 중복 적용 불가',
    '미사용 주차권에 대해서는 유효시간 내 취소 신청이 가능',
    '차량번호 오기입으로 인한 주차권은 환불 불가',
    '구매후 입차이력이 없는 경우 구매 일자 기준 7일 이내 환불 가능',
  ];

  if (terraceTowerProductStore.isLoading) {
    return <LoadingSpinnerScreen />
  }

  return (
    <div>
      <Title
        title="유의사항"
        leftArrowButtonVisible={true}
        leftOnClick={() => {
          handleWebViewClose()
        }}
      />
      <TerraceCautionContainer>
        <TerraceCautionColumnContainer>
          <Headline weight={'bold'} style={{ color: colors.primary}}>{productName ? productName : ''}</Headline>
          <Headline weight={'bold'} style={{ color: colors.black3 }}>유의사항을 확인해 주세요</Headline>
        </TerraceCautionColumnContainer>
        <TerraceCautionIcon src={icWarningPurple} alt={'보라색 경고'} />
      </TerraceCautionContainer>

      <TerraceTowerContainer>
        <WarningContainer>
          <img src={icCarCaution} alt={'첫번째 경고 이미지'} width="50px" height="50px" />
          <TerraceCautionColumnContainer>
            <Caption2 weight={'regular'} style={{ color: colors.gray08 }}>만차 및 현장 사정으로</Caption2>
            <Caption1 weight={'semibold'}>주차가 어려울 수 있습니다</Caption1>
          </TerraceCautionColumnContainer>
        </WarningContainer>
        <WarningContainer>
          <img src={icSteeringWheel} alt={'두번째 경고 이미지'} width="50px" height="50px" />
          <TerraceCautionColumnContainer>
            <Caption2 weight={'regular'} style={{ color: colors.gray08 }}>유의사항 미숙지로 발생된 피해는</Caption2>
            <Caption1 weight={'semibold'}>운전자에게 책임이 있습니다</Caption1>
          </TerraceCautionColumnContainer>
        </WarningContainer>
      </TerraceTowerContainer>
      <ViewDivide1 />

      <TerraceTowerBottomContentsContainer>
        <CautionTitleContainer>
          <ExclamationBlackIc src={icExclamationBlack} alt={'검정 배경 느낌표'} />
          <Subheadline weight={'bold'} style={{ color : colors.black3 , marginLeft: 10 }}>주차권 유의사항</Subheadline>
        </CautionTitleContainer>
        <ViewDivide2 />
        <ListDotContainer>
          {cautionList.map((cautionItem, index) => (
            <ListItemContainer key={index} hasMargin={index !== 0}>
              <ListItemDotIc src={icDotGray} alt="dot icon" />
              <ListItemSpan>{cautionItem}</ListItemSpan>
            </ListItemContainer>
          ))}
        </ListDotContainer>
        <CautionContainer>
          <Caption3 weight={'regular'} css={{ color: colors.gray06 }}>유의사항 미숙지로 인해 발생한 피해와 주차장 내에서</Caption3>
          <Caption3 weight={'regular'} css={{ color: colors.gray06  }}>발생한 사고에 대해서는 일체 책임지지 않습니다.</Caption3>

        </CautionContainer>
      </TerraceTowerBottomContentsContainer>
      <AgreeContainer onClick={handleAgreeClick}>
        <StyledCheckbox type="checkbox" checked={isChecked} readOnly />
        <RequiredText>(필수)</RequiredText>
        <Caption2 weight={'regular'} style={{ color: colors.gray09 }}>주차권 유의사항 동의</Caption2>

      </AgreeContainer>
      <BottomButtonContainer onClick={handleButtonClick}>
        <AgreeButton state={isChecked ? 'agreeCheck' : 'notCheck'}>유의사항 확인했어요</AgreeButton>
      </BottomButtonContainer>
    </div>
  );
});

export default TerraceTowerReservationCautionPage