import BaseService from '../baseService';
import {
  IPointCouponStorage,
  IPointCouponStorageResponse,
} from '../../models/Point/pointCouponStorageModel';
import BaseError from '../../models/baseError';
import AxiosClient from '../../utils/axiosClient';
import ErrorHandler from '../../utils/error/errorHandler';
import {
  IPointCouponCertSend,
  IPointCouponCertSendResponse,
} from '../../models/Point/pointCouponCertSendModel';

interface PointCouponEndPoints {
  POINT_COUPON_STORAGE: string;
  POINT_CERT_SEND: string;
}

class PointCouponService extends BaseService<PointCouponEndPoints> {
  apiEndpoints: PointCouponEndPoints = {
    POINT_COUPON_STORAGE: 'getStorage',
    POINT_CERT_SEND: 'sendCoupon',
  };

  postPointCouponStorage = async (
    requestBody: IPointCouponStorage,
  ): Promise<IPointCouponStorageResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .data({ ...requestBody })
        .url(`${this.apiEndpoints.POINT_COUPON_STORAGE}`)
        .build<IPointCouponStorageResponse>();

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  }

  postPointCertSend = async (
    requestBody: IPointCouponCertSend,
  ): Promise<IPointCouponCertSendResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .data({ ...requestBody })
        .url(`${this.apiEndpoints.POINT_CERT_SEND}`)
        .build<IPointCouponCertSendResponse>();

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };
}

export const pointCouponService = new PointCouponService();