import BaseService from '../baseService';
import {
  IPointSmsCert,
  IPointSmsCertResponse,
  IPointSmsCertSuccess,
  IPointSmsCertSuccessResponse,
} from '../../models/Point/pointSmsCertModel';
import BaseError from '../../models/baseError';
import AxiosClient from '../../utils/axiosClient';
import ErrorHandler from '../../utils/error/errorHandler';

interface PointSmsCertEndpoints {
  POINT_SMS_CERT: string;
  POINT_SMS_CERT_VALIDATE: string;
}

class PointSmsCertService extends BaseService<PointSmsCertEndpoints> {
  apiEndpoints: PointSmsCertEndpoints = {
    POINT_SMS_CERT: 'getAuthCode',
    POINT_SMS_CERT_VALIDATE: 'validateAuthCode',
  };

  // 인증번호 요청
  postAuthCode = async (requestBody: IPointSmsCert): Promise<IPointSmsCertResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .data({ ...requestBody })
        .url(`${this.apiEndpoints.POINT_SMS_CERT}`)
        .build<IPointSmsCertResponse>();

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };

  // 인증번호 검증
  postAuthCertSuccess = async (
    requestBody: IPointSmsCertSuccess,
  ): Promise<IPointSmsCertSuccessResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .data({ ...requestBody })
        .url(`${this.apiEndpoints.POINT_SMS_CERT_VALIDATE}`)
        .build<IPointSmsCertSuccessResponse>()

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError()
      }
    } catch (error) {
      console.error(error)
      return ErrorHandler.create(error).getError()
    }
  };
}

export default new PointSmsCertService()
