import { styled } from '@stitches/react';



export const PaymentTopContainer = styled('div', {
  marginTop: '80px',
  justifyContent: 'center',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  textAlign: 'center'
})

export const PaymentCheckContainer = styled('div', {
  marginTop: '16px',
})

export const BottomContainer = styled('div', {
  position: 'fixed',
  bottom: 10,
  left: 0,
  width: '100%',
  padding: '20px'
})

export const TicketButtonContainer = styled('div', {
  backgroundColor: '#F8F8FA',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '10px',
  width: 'calc(100% - 80px)',
  padding: '20px'
})


export const CheckButton = styled('button', {
  height: '46px',
  backgroundColor: '#8B63BB',
  border: 'none',
  borderRadius: '4px',
  marginTop: '28px',

  width: 'calc(100% - 40px)',
})