import { styled } from '@stitches/react';

export const BankSelectModalContainer = styled('div', {
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  height: '80vh', // 모달의 높이 설정
  background: '#fff',
  boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.2)',
  transition: 'transform 0.3s ease-in-out',
  transform: 'translateY(100%)', // 모달을 화면 아래로 숨김
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  borderRadius: '20px 20px 0px 0px',
  zIndex: 10000,
  overflowY: 'auto',
  variants: {
    isVisible: {
      true: { transform: 'translateY(0)' }, // 모달을 화면 위로 슬라이드
      false: { transform: 'translateY(100%)' }, // 화면 아래로 숨김
    },
  },
});

export const ModalOverlay = styled('div', {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.3)',
  zIndex: 9999,
})

export const BankFundContainer = styled('div', {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  fontFamily: 'Pretendard',
  fontStyle: 'normal',
  lineHeight: 'normal',
  marginTop: '28px',
  color: '#333',
  fontSize: '15px',
  fontWeight: 400,
  marginLeft: '28px',
  marginRight: '28px',
})

export const FundContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  marginTop: '49px',
  marginBottom: '28px'
})


export const BankFundSpan = styled('span', {
  color: ' #1A1A1A',
  fontSize: '18px',
  fontWeight: '500',
})

export const BanKFundListContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  marginTop: '25px',
  gap: '19px',
})

export const BankFundContentContainer = styled('div', {
  flex: '0 1 calc(50% - 15px)',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
})

export const BanKFundImg = styled('img', {
  width: '32px', height: '32px', objectFit: 'contain'
})

export const BanKFundSpan = styled('span', {
  marginLeft: '16px'
})

export const SpinnerContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '50vh'
})

