import { styled } from '@stitches/react';

export const TestContainer = styled('div', {
  height: '101px',
  paddingLeft: '16px',
  paddingRight: '16px',
  marginTop: '24px',
  marginLeft: '16px',
  marginRight: '16px',
  borderRadius: '3px',
  border: '1px solid #E4E4E4',
  background: '#FFFFFF',
  boxShadow: '0px 3px 2px 0px rgba(0, 0, 0, 0.05)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const Sidebar = styled('div', {
  position: 'fixed',
  top: 0,
  left: '-280px', // 사이드바가 화면에서 벗어나 있음
  width: '280px',
  height: '100%', // 사이드바의 전체 높이 설정
  backgroundColor: '#8B63BB',
  color: 'white',
  transition: 'left 0.3s ease',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  zIndex: 1,
  '.whiteBg': {
    width: '100%',
    top: 0,
    height: '94px',
    backgroundColor: '#fff',
    borderRadius: '35px 0 0 0',
    position: 'relative',
  },
  // '.grayBg': {
  //   width: '100%',
  //   backgroundColor: '#F7F7F7',
  //   marginTop: '16px',
  //   height: 'calc(100% - 94px - 16px)',
  // }
});

export const MenuTopContainer = styled('div', {
  display: 'flex',
  padding: 'auto',
  margin: '20px 16px',
  justifyContent: 'space-between',
})

export const MenuBottomContainer = styled('div', {
  display: 'flex',
  paddingLeft: '12px',
  paddingRight: '12px',
  paddingBottom: '20px',
  justifyContent: 'space-between',
})

export const BurgerIc = styled('img', {
  width: '24px',
  height: '24px',
  cursor: 'pointer',
})

export const AddressContainer = styled('div', {
  fontWeight: 600,
  textAlign: 'center',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
})

export const NotificationIc = styled('img', {
  width: '24px',
  height: '24px',
  cursor: 'pointer',
})
export const SidebarOpen = styled(Sidebar, {
  left: 0,
});

export const NonMemberContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '8px',
  paddingTop: '44px',
  paddingBottom: '44px',
  color: '#fff',
  whiteSpace: 'nowrap',
});


export const UserIconSize = styled('img', {
  width: '34px',
  height: '34px',
});

export const ArrowIconSize = styled('img',{
  width: '8px',
  height: '15px',
})

// 텍스트 스타일
export const UserText = styled('span', {
  fontSize: '22px',
  fontWeight: 800,
  fontStyle: 'normal',
  lineHeight: 'normal',
  fontFamily: 'Pretendard',
});

export const DashContainer = styled('div', {
  display: 'inline-block',
  fontSize: '20px',
  fontWeight: 800,
  lineHeight: 'normal',
  color: '#8b63bb',
  verticalAlign: 'middle',
});

export const PointContainer = styled('div', {
  display: 'inline-block',
  width: '2.7778vw',
  height: '5vw',
  margin: '1.6667vw auto 0 0.5556vw',
  fontSize: '4.1667vw',
  lineHeight: 'normal',
  color: '#8b63bb',
  verticalAlign: 'middle',
});

export const PointButton = styled('button', {
  width: '90px',
  height: '28px',
  position: 'absolute',
  right: 0,
  top: 0,
  color: '#8B63BB',
  margin: '29px 30px',
  textAlign: 'center',
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
  background: '#fff',
  borderRadius: '5px',
  borderColor: '#8B63BB',
})

export const CoinIc1 = styled('img', {
  width: '17px', height: '15px'
})

export const CoinIc2 = styled('img', {
  width: '17px', height: '15px', marginLeft: '38px'
})

export const BottomBannerContainer = styled('div', {
  background: '#B0D0FF',
  height: '70px',
  marginBottom: '60px',
  display: 'flex',
  alignItems: 'center',
  padding: '0 10px',
})

export const HandCoinImage = styled('img', {
  width: '74px',
  height: '53px',
  flexShrink: 0,
})

export const ParkingShareSpan = styled('span', {
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
  color: '#5372DE',
})

export const ProfitsSpan = styled('span', {

})
