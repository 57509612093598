import React from 'react';
import { Dialog } from './styles';
import Title from '../../Title/PageTitle';
import { FixedSizeList as List } from "react-window";
import icArrowRightGrayMedium from "../../../../assets/ic/ic_arrow_right_gray_medium.svg";

// Props 타입 정의
interface BottomModalProps {
  isVisible: boolean;
  onClose: () => void;
  onSelectAddress: (address: string) => void; // 주소를 선택했을 때 호출되는 함수
}

// 추후 api 확인 후 검색 결과 갖고와야됨
const placeResultItems = [
  { placeText: 'ㄱ 카센터', addressText: '1', imgArrowLeft: icArrowRightGrayMedium },
  { placeText: '기억 인테리어 디자인ㄱ', addressText: '1', imgArrowLeft: icArrowRightGrayMedium },
  { placeText: '카페 ㄱ해', addressText: '1', imgArrowLeft: icArrowRightGrayMedium },
  { placeText: '문화도시 청주 시민 아카이브', addressText: '1', imgArrowLeft: icArrowRightGrayMedium },
];

const AddressResultItem = [
  { addressText: '서울 강동구 명일동', imgArrowLeft: icArrowRightGrayMedium },
  { addressText: '서울 강동구 명일1동', imgArrowLeft: icArrowRightGrayMedium },
  { addressText: '서울 강동구 명일2동', imgArrowLeft: icArrowRightGrayMedium },
  { addressText: '서울 강동구 명일2동', imgArrowLeft: icArrowRightGrayMedium },
  { addressText: '서울 강동구 명일2동', imgArrowLeft: icArrowRightGrayMedium },
  { addressText: '서울 강동구 명일2동', imgArrowLeft: icArrowRightGrayMedium },
]

const PlaceRow = ({ index, style, onClose, onSelectAddress }: { index: number; style: React.CSSProperties; onClose: () => void; onSelectAddress: (address: string) => void }) => {
  const { placeText, addressText, imgArrowLeft } = placeResultItems[index];

  return (
      <div
          style={{
            color: '#000000',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            position: 'relative',
            ...style,
            boxSizing: 'border-box',
          }}
          onClick={() => {
            onSelectAddress(addressText); // 주소를 선택
            onClose(); // 모달 닫기
          }}
      >
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        }}>
        <span style={{
          fontSize: '13px',
          fontWeight: '700',
          lineHeight: 'normal',
          fontFamily: 'Pretendard',
          fontStyle: 'normal'
        }}>{placeText}</span>
          <span style={{
            fontSize: '11px',
            color: '#868686',
            fontWeight: '400',
            lineHeight: 'normal',
            fontFamily: 'Pretendard',
            fontStyle: 'normal'
          }}>{addressText}</span>
        </div>
        <img
            src={imgArrowLeft}
            alt="애로우 아이콘"
            style={{
              width: '14px',
              height: '15px',
              marginLeft: '10px',
              alignSelf: 'center',
            }}
        />
      </div>
  );
};

const AddressRow = ({ index, style, onClose, onSelectAddress }: { index: number; style: React.CSSProperties; onClose: () => void; onSelectAddress: (address: string) => void }) => {
  const { addressText, imgArrowLeft } = AddressResultItem[index];

  return (
      <div
          style={{
            color: '#000000',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            justifyContent: 'space-between',
            position: 'relative',
            ...style
          }}
          onClick={() => {
            onSelectAddress(addressText); // 주소를 선택
            onClose(); // 모달 닫기
          }}
      >
        <div style={{
          display: 'flex',
          flexDirection: 'column'
        }}>
        <span style={{
          fontSize: '13px',
          fontWeight: '700',
          lineHeight: 'normal',
          fontFamily: 'Pretendard',
          fontStyle: 'normal'
        }}>{addressText}</span>
        </div>
        <img
            src={imgArrowLeft}
            alt="애로우 아이콘"
            style={{
              width: '14px',
              height: '15px',
              marginLeft: '10px',
              alignSelf: 'center',
            }}
        />
      </div>
  );
};

const AddressSearchModal: React.FC<BottomModalProps> = ({ isVisible, onClose, onSelectAddress }) => {
  return (
      <Dialog isVisible={isVisible}>
        <Title
            title="검색창이 들어가야됨"
            leftArrowButtonVisible={true}
            leftOnClick={onClose}
            rightTextButtonVisible={false}
        />
        <div style={{ marginTop: '100px', margin: 'auto 25px' }}>
          <div style={{
            fontFamily: 'Pretendard',
            fontSize: '13px',
            fontStyle: 'normal',
            width: '100%',
            lineHeight: 'normal',
            display: 'flex',
            alignItems: 'center',
            textAlign: 'left',
            paddingBottom: '3px',
            color: '#868686',
            position: 'relative',
            borderBottom: '1px solid #868686',
          }}>
            <span>장소 결과</span>
          </div>
          <div style={{
            width: '400px',
            height: '350px',
            overflowY: 'auto',
            marginTop: '12px'
          }}>
            <List
                height={350}
                itemCount={placeResultItems.length}
                itemSize={50}
                width={'100%'}
            >
              {({ index, style }) => (
                  <PlaceRow index={index} style={style} onClose={onClose} onSelectAddress={onSelectAddress} />
              )}
            </List>
          </div>
          <div style={{
            fontFamily: 'Pretendard',
            fontSize: '13px',
            fontStyle: 'normal',
            width: '100%',
            lineHeight: 'normal',
            display: 'flex',
            alignItems: 'center',
            textAlign: 'left',
            paddingBottom: '3px',
            color: '#868686',
            position: 'relative'
          }}>
            <span>주소 결과</span>
            <div style={{
              position: 'absolute',
              bottom: '0',
              left: '0',
              width: 'calc(100% - 20px)',
              borderBottom: '1px solid #868686',
            }} />
          </div>
          <div style={{
            width: '400px',
            height: '350px',
            overflowY: 'auto',
            marginTop: '12px'
          }}>
            <List
                height={350}
                itemCount={AddressResultItem.length}
                itemSize={50}
                width={'100%'}
            >
              {({ index, style }) => (
                  <AddressRow index={index} style={style} onClose={onClose} onSelectAddress={onSelectAddress} />
              )}
            </List>
          </div>
        </div>
      </Dialog>
  );
};

export default AddressSearchModal;
