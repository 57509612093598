import BaseService from '../baseService';
import { IPointPending, IPointPendingResponse } from '../../models/Point/pointPendingModel';
import BaseError from '../../models/baseError';
import AxiosClient from '../../utils/axiosClient';
import ErrorHandler from '../../utils/error/errorHandler';

interface PointPendingEndPoint {
  POINT_PENDING: string;
}

class PointPendingService extends BaseService<PointPendingEndPoint> {
  apiEndpoints: PointPendingEndPoint = {
    POINT_PENDING: 'getPendingPoint',
  }

  postPointPending = async (
    requestBody: IPointPending,
  ): Promise<IPointPendingResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .data({ ...requestBody })
        .url(`${this.apiEndpoints.POINT_PENDING}`)
        .build<IPointPendingResponse>();
      if (response.data.res_code === 200) {
        return response.data
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError()
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError()
    }
  }
}

export default new PointPendingService()