import { styled } from '@stitches/react';
import icCheck from 'assets/ic/ic_check_white.svg'

export const PaymentContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

export const UserInfoInputContainer = styled('div', {
  margin: '0 20px',
})

export const MarginTopStyled = styled('div', {
  marginTop: '20px',
})

export const CarTypeContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'row',
  marginTop: '20px'
})

export const CarNumberAddressContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'row',
  marginTop: '15px',
})

export const PaymentInputContainer = styled('input', {
  borderRadius: '5px',
  border: '2px solid #E6E6E6',
  padding: '15px',
  boxSizing: 'border-box',
  lineHeight: '15px',
  resize: 'none',
  color: '#868686',
  fontFamily: 'Pretendard',
  fontSize: '11px',
  fontStyle: 'normal',
  fontWeight: 700,
  width: '70%',

  '&::placeholder': {
    color: '#B0B0B0',
  },

  '&:focus': {
    border: '2px solid #E6E6E6',
    outline: 'none',
  },
})

export const TextCenterSpan = styled('span', {
  textAlign: 'center',
})

export const SearchIcon = styled('img', {
  width: '20px',
  height: '20px',
  marginLeft: 'auto',
})

export const PostContainer = styled('div', {
  borderRadius: '5px',
  border: '2px solid #E6E6E6',
  padding: '15px',
  boxSizing: 'border-box',
  lineHeight: '15px',
  resize: 'none',
  color: '#B0B0B0',
  fontFamily: 'Pretendard',
  fontSize: '11px',
  fontStyle: 'normal',
  fontWeight: 700,
  textAlign: 'left',
  width: '70%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

export const AddressDetailIContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  marginTop: '15px',
})

export const ViewDivide = styled('div', {
  width: '100%',
  height: '6px',
  backgroundColor: '#F8F8FA',
  marginTop: '20px',
})

export const ParkingInfoContainer = styled('div', {
  margin: '20px 20px'
})

export const OperatingHourContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '30px',
})

export const ParkingInfoDetailContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '10px',
})

export const ParkingTitleSpan = styled('span', {
  color: '#6B6B6B',
  fontFamily: 'Pretendard',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
})

export const PaymentAccountDepositContentContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
});

export const WeekContainer = styled('div', {
  backgroundColor: '#8B63BB',
  color: '#fff',
  borderRadius: '20px',
  textAlign: 'center',
  padding: '2px 5px',
  fontFamily: 'Pretendard',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
})

export const TimeOperatingHoursSpan = styled('span', {
  color: '#1A1A1A',
  fontFamily: 'Pretendard',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
})

export const WeekInfoContainer = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: '8px',
  marginTop: '10px',
})

export const PaymentAccountDepositContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '8px',
});

export const DiscountAmountSpan = styled('span', {
  color: '#B0B0B0',
  fontFamily: 'Pretendard',
  fontSize: '11px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '13px',
  textDecoration: 'line-through',
})

export const ViewDivide2 = styled('div', {
  borderBottom: '1px solid #E6E6E6',
  marginTop: '13px'
})

export const PaymentAmountSpan = styled('span', {
  color: '#8B63BB',
  fontFamily: 'Pretendard',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
})

export const SearchContainer = styled('div', {
  borderRadius: '5px',
  border: '2px solid #E6E6E6',
  padding: '15px',
  boxSizing: 'border-box',
  lineHeight: '15px',
  resize: 'none',
  color: '#B0B0B0',
  fontFamily: 'Pretendard',
  fontSize: '11px',
  fontStyle: 'normal',
  fontWeight: 700,
  textAlign: 'left',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

export const PaymentMethodChoiceContainer = styled('div', {
  borderRadius: '5px',
  border: '2px solid #E6E6E6',
  padding: '15px',
  boxSizing: 'border-box',
  lineHeight: '14px',
  resize: 'none',
  color: '#6B6B6B',
  fontFamily: 'Pretendard',
  fontSize: '11px',
  fontStyle: 'normal',
  fontWeight: 400,
  textAlign: 'left',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '30px'
})

export const ArrowIcon = styled('img', {
  width: '10px',
  height: '7px',
  marginLeft: 'auto',
})

export const StyledCheckbox = styled('input', {
  appearance: 'none',
  width: '20px',
  height: '20px',
  border: '2px solid #E6E6E6',
  borderRadius: '4px',
  outline: 'none',

  marginRight: '8px', // 체크 박스와 레이블 사이의 간격
  '&:checked': {
    backgroundColor: '#8B63BB',
    borderColor: '#8B63BB',
    backgroundImage: `url(${icCheck})`, // 불러온 이미지를 URL로 설정
    backgroundSize: '12px 12px', // 이미지 크기
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  '&:focus': {
    borderColor: '#8B63BB',
  },
});

export const PaymentMethodNumberInput = styled('input', {
  borderRadius: '5px',
  border: '2px solid #E6E6E6',
  padding: '15px',
  boxSizing: 'border-box',
  lineHeight: '15px',
  resize: 'none',
  color: '#868686',
  fontFamily: 'Pretendard',
  fontSize: '11px',
  fontStyle: 'normal',
  fontWeight: 700,
  width: '100%',
  height: '30px',

  '&::placeholder': {
    color: '#B0B0B0',
  },

  '&:focus': {
    border: '2px solid #E6E6E6',
    outline: 'none',
  },
})

export const RequiredText = styled('span', {
  color: '#FF0000', // Change to your desired color
  marginRight: '4px'
});

export const FlexContainer = styled('div', {
  display: 'flex'
})

export const CashReceiptChoiceContainer = styled('div', {
  margin: '20px 20px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: '#1A1A1A',
  fontFamily: 'Pretendard',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400, lineHeight: 'normal'
})

export const IncomeDeductionContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '10px',
  color: '#1A1A1A',
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal'
})

export const ExpenditureContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#1A1A1A',
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal'
})

export const PaymentInfoContainer = styled('div', {
  margin: '20px 20px',
  display: 'flex',
  justifyContent: 'space-between',
})

export const SpacingBetweenContainer = styled('div', {
  flex: '0 0 55%',
  marginLeft: '10px',
})

export const BottomButtonContainer = styled('div', {
  margin: '0 20px 40px',
  width: 'calc(100% - 40px)',
  bottom: '0',
  left: '0',
  backgroundColor: '#fff',
  textAlign: 'center',
  zIndex: 10,
})

export const ButtonEnableDisable = styled('button', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  width: '100%',
  height: '46px',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: 'normal',
  borderRadius: '5px',
  backgroundColor: '#8B63BB',
  fontFamily: 'Pretendard',
  color: '#fff',
  border: 'none',
  userSelect: 'none',
  '&:disabled': {
    backgroundColor: '#d3d3d3',
    color: '#fff',
    cursor: 'not-allowed',
  },
});





