import { styled } from '@stitches/react';

export const MinganParkingInfoModalContainer = styled('div', {
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  height: 'auto',
  maxHeight: '600px', // 최대 높이 설정
  background: '#fff',
  boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.2)',
  transition: 'transform 0.3s ease-in-out',
  transform: 'translateY(100%)',
  display: 'flex',
  flexDirection: 'column',
  padding: '16px',
  boxSizing: 'border-box',
  borderRadius: '20px 20px 0px 0px',
  zIndex: 10000,
  overflowY: 'auto', // 스크롤 가능하도록 설정
  variants: {
    isVisible: {
      true: { transform: 'translateY(0)' },
      false: { transform: 'translateY(100%)' },
    },
  },
});

export const CloseIcon = styled('img', {
  cursor: 'pointer',
  width: '18px',
  height: '18px',
});

export const DashContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  borderBottom: '2px dashed #CCC',
  margin: '0px 25px',
  paddingBottom: '15px',
  paddingTop: '15px'
})

export const DotTittleContentsContainer = styled('div', {
  display: 'flex',
  alignItems: 'flex-start',
})

export const TitleSpan = styled('span', {
  color: '#333',
  fontFamily: 'Pretendard',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
});

export const ContentsSpan = styled('span', {
  color: '#868686', fontSize: '14px', marginTop: '4px'
})

export const TitleContentsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '8px',
})

