import React, { useState } from 'react';
import { ChoiceDateContainer, ChoiceDateItem, ContentsContainer, Dialog, SubTitleSpan, TitleSpan } from './styles';
import Title from '../../Title/PageTitle';
import { BottomButtonContainer, ButtonEnableDisable } from '../../Button/styles';
import { MainContainer } from '../../../styles';
import { useNavigate } from 'react-router-dom';

interface DateChoiceBottomModalProps {
  isVisible: boolean;
  onClose: () => void;
}

const DateChoiceBottomModal: React.FC<DateChoiceBottomModalProps> = ({ isVisible, onClose }) => {
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const navigate = useNavigate();

  const dates = [
    '2024년 9월 5일(목)',
    '2024년 9월 6일(금)',
    '2024년 9월 7일(토)',
    '2024년 9월 8일(일)',
    '2024년 9월 9일(월)',
    '2024년 9월 10일(화)',
  ];

  return (
    <Dialog isVisible={isVisible}>
      <MainContainer>
        <Title
          title="희망일 선택"
          leftArrowButtonVisible={true}
          leftOnClick={onClose}
          rightTextButtonVisible={true}
          rightOnClick={() => console.log('전화로 연결')}
          rightTitle="문의 하기"
        />
        <ContentsContainer>
          <TitleSpan>
            이용 시작 희망일을 선택해 주세요.
          </TitleSpan>
          <SubTitleSpan>
            희망일이 없을 경우 고객센터로 문의주세요
          </SubTitleSpan>
          <ChoiceDateContainer>
            {dates.map((date, index) => (
              <ChoiceDateItem
                key={index}
                isSelected={selectedDate === date}
                onClick={() => setSelectedDate(date)}
              >
                {date}
              </ChoiceDateItem>
            ))}
          </ChoiceDateContainer>
        </ContentsContainer>
        <BottomButtonContainer>
          <ButtonEnableDisable
            onClick={() => navigate('/paymentUserInfoCheck')}
            disabled={!selectedDate}
          >
            다음
          </ButtonEnableDisable>
        </BottomButtonContainer>
      </MainContainer>
    </Dialog>
  );
};

export default DateChoiceBottomModal;
