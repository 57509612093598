import React from "react";
import {
  ParkingAddressSpan,
  ParkingNameContainer,
  ParkingPhotoUploadChoiceSpan,
  RegistrationButton,
  ParkingRegistrationItemContainer,
  ParkingPhotoUploadChoiceContainer, ParkingNameButtonContainer
} from "./styles";
import { useNavigate } from "react-router-dom";

const ParkingPhotoList: React.FC = () => {
  const parkingHistoryItem = [
    { parkingName: '1011 주차장', parkingAddress: '서울시 영등포구 경인로 775, 에이스하이테크' },
    { parkingName: '1011 주차장', parkingAddress: '서울시 영등포구 경인로 775, 에이스하이테크' },
    { parkingName: '1011 주차장', parkingAddress: '서울시 영등포구 경인로 775, 에이스하이테크' },
    { parkingName: '1011 주차장', parkingAddress: '서울시 영등포구 경인로 775, 에이스하이테크' },
    { parkingName: '1011 주차장', parkingAddress: '서울시 영등포구 경인로 775, 에이스하이테크' },
    { parkingName: '1011 주차장', parkingAddress: '서울시 영등포구 경인로 775, 에이스하이테크' },
    { parkingName: '1011 주차장', parkingAddress: '서울시 영등포구 경인로 775, 에이스하이테크' },
    { parkingName: '1011 주차장', parkingAddress: '서울시 영등포구 경인로 775, 에이스하이테크' },
    { parkingName: '1011 주차장', parkingAddress: '서울시 영등포구 경인로 775, 에이스하이테크' },
  ];

  const navigate = useNavigate();

  return (
      <>
        <ParkingPhotoUploadChoiceContainer>
          <ParkingPhotoUploadChoiceSpan>
            사진을 업로드하실 주차장을 선택해 주세요.
          </ParkingPhotoUploadChoiceSpan>
        </ParkingPhotoUploadChoiceContainer>
        {parkingHistoryItem.map((item, index) => (
            <ParkingRegistrationItemContainer key={index}>
              <ParkingNameButtonContainer>
                <ParkingNameContainer>
                  {item.parkingName}
                </ParkingNameContainer>
                <RegistrationButton onClick={() => navigate('/parkingPhotoUploadDetail')}>
                  등록
                </RegistrationButton>
              </ParkingNameButtonContainer>
              <ParkingAddressSpan>
                {item.parkingAddress}
              </ParkingAddressSpan>
            </ParkingRegistrationItemContainer>
        ))}
      </>
  );
};

export default ParkingPhotoList;
