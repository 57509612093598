import React, { useState } from 'react';
import Title from '../../components/atoms/Title/PageTitle';
import { useNavigate } from 'react-router-dom';
import icSearch from '../../assets/ic/ic_setting.svg';
import AddressSearchModal from '../../components/atoms/Modal/AddressSearchModal';
import {
  AdviceButton,
  ContactInfo, NaverMapContainer,
  PhoneNumber,
  PhoneNumberContainer,
  PhoneNumberHeader,
  SearchContainer,
  SearchIcon,
  SharedRequestMinganMainContainer, SharedRequestNaverMapContainer
} from "./styles";

const ShareRequestMingan: React.FC = () => {
  const navigate = useNavigate();
  const [isModalVisible, setModalVisible] = useState(false);
  const [addressText, setAddressText] = useState<string>(''); // 추가된 상태

  const handleLeftOnClick = () => {
    navigate(-1);
  };

  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleSelectAddress = (address: string) => {
    setAddressText(address); // 주소 설정
  };

  return (
      <SharedRequestMinganMainContainer style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <Title
            title="부설 주차장 신청"
            leftArrowButtonVisible
            leftOnClick={handleLeftOnClick}
            rightTextButtonVisible={false}
        />
        <PhoneNumberContainer>
          <PhoneNumberHeader>
            <div>휴대 전화번호</div>
            <PhoneNumber>010-1234-5678</PhoneNumber>
          </PhoneNumberHeader>
          <ContactInfo>
            위 연락처로 신청 관련 안내가 진행됩니다.
          </ContactInfo>
          <SearchContainer onClick={openModal}>
            <SearchIcon src={icSearch} alt={"검색아이콘"} />
            {addressText && <span>{addressText}</span>} {/* 주소 표시 */}
          </SearchContainer>
        </PhoneNumberContainer>
        <SharedRequestNaverMapContainer>
          <NaverMapContainer>
            네이버 지도
            <AdviceButton onClick={() => {
              navigate(-1)
            }}>
              상담 신청
            </AdviceButton>
          </NaverMapContainer>
        </SharedRequestNaverMapContainer>

        <AddressSearchModal
            isVisible={isModalVisible}
            onClose={closeModal}
            onSelectAddress={handleSelectAddress} // 콜백 함수 전달
        />
      </SharedRequestMinganMainContainer>
  );
};

export default ShareRequestMingan;
