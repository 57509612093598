import { MainContainer } from "components/styles"
import Title from '../../../../components/atoms/Title/PageTitle'
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { CouponContainer, MoneyContainer, MoneyCouponContainer, MoneySpan } from './styles';
import PointMallBranDetailList from "../PointMallBrandDetailList";
import Spacer from '../../../../components/atoms/Spacer/Spacer';
import { observer } from 'mobx-react';
import { brandListStore } from '../../../../stores/store/Point/brandListStore';

const PointMallBrandDetail = observer(() => {
  const navigate = useNavigate();
  const [isCouponNotesModal, setIsCouponNotesModal] = useState(false)
  const handleCouponNotes = () => {
    setIsCouponNotesModal(true)
  }
  const location = useLocation()
  const { brandName } = location.state || { brandName: '브랜드' }

  const handleLeftOnClick = () => {
    navigate(-1)
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    window.onBtnBackClicked = function() {
      handleLeftOnClick()
    };
    return () => {
      delete window.onBtnBackClicked
    }
  }, [])

  return(
    <MainContainer>
      <Title
        title={brandName}
        leftArrowButtonVisible={true}
        leftOnClick={handleLeftOnClick}
        rightTextButtonVisible={false}
      />
      <MoneyCouponContainer>
        <MoneyContainer>
          <span>사용 가능 금액</span>
          <MoneySpan>
            {brandListStore.useAvailPoints} {/* MobX 스토어에서 포인트 사용량 */}
          </MoneySpan>
        </MoneyContainer>
        <CouponContainer
          onClick={() => {
            navigate("/pointMallStorageBox");
          }}
        >
          <span>
            보유 쿠폰 {brandListStore.couponCount} {/* MobX 스토어에서 쿠폰 수량 */}
          </span>
        </CouponContainer>
      </MoneyCouponContainer>
      <Spacer spacer={28} />
      <PointMallBranDetailList />
    </MainContainer>
  );
});

export default PointMallBrandDetail
