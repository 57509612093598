import React, { useState } from "react";
import {useNavigate} from "react-router-dom";
import Title from "../../components/atoms/Title/PageTitle";
import NotSharedParking from "./NotSharedParking";
import SuccessSharedParking from "./SuccessSharedParking";
import {MainContainer} from "../../components/styles";

const ShareRequestMain: React.FC = () => {
  const navigate = useNavigate()

  const handleLeftOnClick = () => {
    navigate(-1);
  };

  return(
    <MainContainer>
      <Title
        title="주차장 공유내역"
        leftArrowButtonVisible
        leftOnClick={handleLeftOnClick}
        rightTextButtonVisible={false}
      />
      <NotSharedParking/>
        {/*<SuccessSharedParking/>*/}

    </MainContainer>
  )
}

export default ShareRequestMain;