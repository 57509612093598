import { styled } from '@stitches/react';

export const ShareRequestGeojuMainContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: 'auto 25px',
  minHeight: '100vh',

  '@media screen and (max-height: 480px)': {
    padding: '20px',
  },
});

// 전체 컨테이너: 세로 방향으로 정렬
export const PhoneNumberContainer = styled('div', {
  fontFamily: 'Pretendard',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  width: '100%',
});

// 전화번호와 레이블을 가로로 정렬
export const PhoneNumberHeader = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  color: '#868686'
});

// 전화번호 오른쪽 정렬
export const PhoneNumber = styled('div', {
  fontSize: '16px',
  textAlign: 'right',
  flex: 1,
  display: 'flex',
  justifyContent: 'flex-end',
  fontFamily: 'Pretendard',
  textSize: '18px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  color: '#8B63BB'
});

// 연락처 안내 정보
export const ContactInfo = styled('div', {
  fontSize: '12px',
  color: '#6B6B6B',
  display: 'flex',
  fontFamily: 'Pretendard',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  marginTop: '7px',
  justifyContent: 'flex-end',
});

export const GeojuInput = styled('input', {
  padding: '5px',
  border: '0px',
  width: '100%',
  marginRight: '16px',
  outline: 'none',
  borderBottom: '1px solid #dbdbdb',
  fontWeight: 400,
  fontFamily: 'Pretendard',
  fontSize: '14px',
  lineHeight: 'normal',
  color: '#868686',
  height: '45px',
  borderRadius: '5px',
  background: '#F2F2F2',
  marginTop: '4px'
})

export const StyledCheckbox = styled('input', {
  appearance: 'none',
  width: '20px',
  height: '20px',
  border: '2px solid #E6E6E6',
  borderRadius: '4px',
  outline: 'none',
  cursor: 'pointer',
  marginRight: '8px', // 체크 박스와 레이블 사이의 간격
  '&:checked': {
    backgroundColor: '#8B63BB',
    borderColor: '#8B63BB',
    backgroundImage: `url(${process.env.PUBLIC_URL}/assets/ic/ic_check.svg)`,
  },
  '&:focus': {
    borderColor: '#8B63BB',
  },
});

export const CheckBoxLabel = styled('label', {
  display: 'flex',
  alignItems: 'center',
  fontSize: '16px',
  lineHeight: '1.5',
  marginBottom: '12px',
});

export const ShareRequestGeojuEssentialText = styled('span', {
  color: '#ED1659', // Change to your desired color
  marginRight: '4px',
  fontFamily: 'Pretendard',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '20px',
});

export const ShareRequestGeojuRequestText = styled('span', {
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '20px'
})
