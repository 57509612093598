import React from 'react';
import {
  ContentsContainer,
  ModalOverlay,
  PurpleOneButtonCheckModalButton,
  PurpleOneButtonCheckModalContainer,
  PurpleOneButtonCheckModalTitleSpan, TitleContainer,
} from './styles';

interface PurpleOneButtonCheckModal {
  isVisible: boolean
  onClose: () => void
  title: string
  content: React.ReactNode
  buttonText: string
}

const PurpleOneButtonCheckModal: React.FC<PurpleOneButtonCheckModal> = ({ isVisible, onClose, title, content, buttonText }) => {

  if (!isVisible) return null

  return (
    <>
      <ModalOverlay onClick={onClose}>
        <PurpleOneButtonCheckModalContainer onClick={(e) => e.stopPropagation()}>
          <TitleContainer>
            <PurpleOneButtonCheckModalTitleSpan>
              {title}
            </PurpleOneButtonCheckModalTitleSpan>
          </TitleContainer>
          <ContentsContainer>
            {content}
          </ContentsContainer>
          <PurpleOneButtonCheckModalButton onClick={onClose}>
            {buttonText}
          </PurpleOneButtonCheckModalButton>
        </PurpleOneButtonCheckModalContainer>

      </ModalOverlay>
    </>
  )
}

export default PurpleOneButtonCheckModal