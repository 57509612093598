import { styled } from '@stitches/react';

export const CarReductionBottomModal = styled('div', {
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  height: 'auto',
  background: '#fff',
  boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.2)',
  transition: 'transform 0.3s ease-in-out',
  transform: 'translateY(100%)',
  display: 'flex',
  flexDirection: 'column',
  padding: '16px',
  boxSizing: 'border-box',
  borderRadius: '20px 20px 0px 0px',
  zIndex: 10000,
  variants: {
    isVisible: {
      true: { transform: 'translateY(0)' },
      false: { transform: 'translateY(100%)' },
    },
  },
});

export const CheckButton = styled('button', {
  marginTop: '26px',
  height: '46px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  border: 'none',
  backgroundColor: '#8B63BB',
  color: '#fff',
  borderRadius: '4px',
  padding: '10px 20px',
  cursor: 'pointer',
  fontSize: '16px',
})
