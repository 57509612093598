import { GeojuInfoModalContainer } from './styles'
import icZoomansaLogo from '../../../../assets/ic/ic_zoomansa_logo.svg'
import icIotLabel from '../../../../assets/ic/ic_iot_label.svg'
import React from 'react';

interface GeojuInfoBottomModalProps {
  isVisible: boolean;
  onClose: () => void;
}

const GeojuInfoBottomModal: React.FC<GeojuInfoBottomModalProps> = ({ isVisible, onClose }) => {
  const handleOverlayClick = () => {
    onClose();
  };

  if (!isVisible) return null;

  return (
    <>
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          zIndex: 9999,
        }}
        onClick={handleOverlayClick}
      />
      <GeojuInfoModalContainer isVisible={isVisible} onClick={handleOverlayClick}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              fontFamily: 'Pretendard',
              fontSize: '13px',
              fontWeight: 400,
              fontStyle: 'normal',
              lineHeight: '13px',
              marginTop: '36px',
              textAlign: 'center',
            }}
          >
            <div
              style={{
                borderRadius: '4px',
                border: '1px solid #999',
                padding: '4px 4px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img
                style={{
                  width: '13px',
                  height: '13px',
                }}
                src={icZoomansaLogo}
                alt={'주만사 아이콘'}
              />
              <span>공유</span>
            </div>

            <div
              style={{
                borderRadius: '4px',
                border: '1px solid #999',
                padding: '4px 4px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: '4px'
              }}
            >
              <img
                style={{
                  width: '13px',
                  height: '13px',
                }}
                src={icIotLabel}
                alt={'iot 아이콘'}
              />
              <span>IoT</span>
            </div>
            <div
              style={{
                borderRadius: '4px',
                border: '1px solid #999',
                padding: '4px 4px',
                marginLeft: '4px',
              }}
            >
              거주자
            </div>
            <div
              style={{
                borderRadius: '4px',
                border: '1px solid #999',
                padding: '4px 4px',
                marginLeft: '4px',
              }}
            >
              시간제
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: '20px',
            }}
          >
            <div
              style={{
                color: '#1A1A1A',
                fontFamily: 'Pretendard',
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: 700,
                lineHeight: 'normal',
                textAlign: 'left',
                flex: '1'
              }}
            >
              길동 18 구역 노상
            </div>
            <div
              style={{
                border: '2px solid #E6E6E6',
                textAlign: 'center',
                marginLeft: 'auto',
                height: '18px',
                flex: '1'
              }}
            >
              22-16-9
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: '20px',
            }}
          >
            <span>5분당</span>
            <div
              style={{
                textAlign: 'right',
                marginLeft: 'auto',
              }}
            >
              100원
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: '20px',
            }}
          >
            <div>1시간</div>
            <div
              style={{
                textAlign: 'right',
                marginLeft: 'auto',
              }}
            >
              1,200원
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: '20px',
            }}
          >
            <div>평일</div>
            <div
              style={{
                textAlign: 'right',
                marginLeft: 'auto',
              }}
            >
              08:30~18:00
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: '20px',
            }}
          >
            <div>주말(공휴일)</div>
            <div
              style={{
                textAlign: 'right',
                marginLeft: 'auto',
              }}
            >
              이용불가
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center', // 가운데 정렬
              marginTop: '20px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
                maxWidth: '300px', // 필요에 따라 수정
              }}
            >
              <div>길 안내 받기</div>
              <div>운영시간 종료</div>
            </div>
          </div>
        </div>
      </GeojuInfoModalContainer>
    </>
  );
}

export default GeojuInfoBottomModal;
