import React from 'react';
import { MainContainer } from 'components/styles';
import Title from '../../../components/atoms/Title/PageTitle';
import { useNavigate } from 'react-router-dom';
import { Body, Subheadline, TermsDetail } from 'styles/typography';
import { colors } from 'styles/colors';
import { DetailContainer } from 'pages/Policy/styles';

const PolicyLocationPage = () => {
  const navigate = useNavigate();

  const handleLeftOnClick = () => {
    navigate(-1);
  };

  const locationChapter1 =
    '이 약관은 주차장만드는사람들㈜(이하 “회사”)가 제공하는 위치기반서비스(이하 ‘서비스’)에 관하여 회사와 이용계약을 체결한 고객이 서비스를 이용하는 데 필요한 회사와 고객의 권리 및 의무, 기타 제반 사항을 정함을 목적으로 합니다.';

  const locationChapter2 = [
    '① 본 약관은 본 서비스를 이용하고자 하는 모든 고객을 대상으로 합니다.',
    '② 본 약관의 내용은 서비스 화면에 게시하거나 기타의 방법으로 고객에게 공시하고, 이에 동의한 고객이 본 서비스에 가입함으로써 효력이 발생합니다.',
    '③ 회사는 필요하다고 인정되면 본 약관을 변경할 수 있으며, 회사가 약관을 변경할 때에는 적용일자와 변경사유를 구체적으로 기재하여 제2항과 같은 방법으로 그 적용일자 7일 전부터 공지합니다. 다만, 이용자에게 불리한 약관을 변경할 때에는 그 적용일자 30일 전부터 공지하며, 메시지 또는 이메일(E-mail)을 통해 이용자에게 개별 통지합니다. 단, 이용자가 연락처를 기재하지 않았거나, 변경 후 수정하지 않아 개별 통지가 어려우면 본 항의 공지를 개별 통지한 것으로 간주합니다.',
    '④ 회사가 제3항에 따라 변경 약관을 공지하거나 통지할 때 약관 변경 적용일까지 거부 의사를 표시하지 않으면 약관의 변경에 동의한 것으로 간주한다는 내용을 이용자에게 통지하였음에도 이용자가 구체적으로 약관 변경에 거부 의사를 표시하지 않으면 서비스제공자는 이용자가 변경 약관에 동의한 것으로 간주합니다. 이용자는 변경된 약관 사항에 동의하지 않으면 서비스 이용을 중단하고 이용 계약을 해지할 수 있습니다.',
  ];

  const locationChapter3 =
    '이 약관에 명시되지 않은 사항은 위치정보의 보호 및 이용 등에 관한 법률(이하 “위치정보법”), 전기통신사업법, 정보통신망 이용촉진 및 보호 등에 관한 법률(이하 “정보통신망법”), 개인정보보호법 등 관계 법령 및 회사가 정한 서비스의 세부이용지침 등의 규정을 따릅니다.';

  const locationChapter4 = [
    '① 서비스에 가입할 수 있는 자는 위치기반서비스를 이용할 수 있는 이동전화 단말기, 기타 서비스를 이용할 수 있는 단말기(이하 “단말기”)의 소유자 본인이어야 합니다.',
    '② 법인은 가입된 휴대폰번호를 사용하여 서비스에 가입할 수 있습니다.',
  ];

  const locationChapter5 = [
    '회사는 다음 각 호의 고객 가입신청을 승낙하지 않을 수 있습니다.',
    '1. 실명이 아니거나 다른 사람의 명의를 사용하는 등 허위로 신청하는 경우',
    '2. 불법 및 루팅 등으로 부적절한 방식으로 서비스를 이용하고자 하는 경우',
    '3. 공공질서 또는 미풍양속을 해치거나 그럴 목적으로 신청한 경우',
    '4. 기타 회사가 정한 이용신청 요건을 충족하지 않았을 경우',
  ];

  const locationChapter6 =
    '서비스 해지를 희망하는 고객은 회사가 정한 절차(유무선 인터넷 홈페이지 등을 통해 공지합니다)를 통해 서비스 해지를 신청할 수 있습니다.';

  const locationChapter7 = [
    '① 서비스의 이용은 연중무휴 1일 24시간을 원칙으로 합니다. 단, 회사의 업무 또는 기술상의 이유로 서비스가 일시 중지될 수 있으며, 운영상의 목적으로 회사가 정한 기간에도 서비스는 일시 중지될 수 있습니다. 이때 회사는 사전 또는 사후에 이를 공지합니다. 단, 주차서비스 이용은 개별 운영시간에 따라 제공되어 운영시간 외에는 이용이 제한될 수 있습니다.',
    '② 위치정보는 관련 기술의 발전에 따라 오차가 발생할 수 있습니다.',
  ];

  const locationChapter8 = [
    '회사는 다음 각 호의 1에 해당하는 경우, 해당 고객의 서비스 이용을 제한하거나 정지시킬 수 있습니다.',
    '1. 등록된 휴대폰번호와 다른 휴대폰번호로 서비스를 이용하고자 한 경우',
    '2. 타인의 서비스 이용을 방해하거나 타인의 개인정보를 무단으로 사용한 경우',
    '3. 서비스를 이용하여 법령, 공공질서, 미풍양속 등을 거스르는 행위를 한 경우',
  ];

  const locationChapter9 =
    '① 회사는 다음 각 호의 1에 해당하면 고객에게 서비스의 전부 또는 일부를 제한·변경하거나 중지할 수 있습니다.';

  const locationChapter9FirstDetail = [
    '1. 서비스용 설비의 보수 등 공사로 어쩔 수 없는 경우',
    '2. 정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우',
    '3. 서비스 제휴업체와의 계약 종료 등과 같은 회사의 제반 사정 또는 법률상의 장애 등으로 유지할 수 없는 경우 기타 천재지변, 국가비상사태 등 불가항력의 사유가 있는 경우',
  ];

  const locationChapter9Second =
    '② 제1항에 따른 서비스 중단일 때에는 회사는 해당 사실을 인터넷 등에 공지하거나 고객에게 통지합니다. 다만, 회사가 통제할 수 없는 사유로 서비스의 중단(운영자의 고의, 과실이 없는 디스크 장애, 시스템 다운 등)으로 말미암아 미리 통지할 수 없을 때에는 사후에 통지합니다.';

  const locationChapter10 =
    '회사가 제공하는 서비스의 종류와 세부내용, 이용 요금은 서비스내에 고지된 주차장별 이용요금에 따릅니다.';

  const locationChapter11 = [
    '① 본 약관에서 사용하는 “위치정보”란 이동성이 있는 물건 또는 개인이 특정한 시간에 존재하거나 존재했던 장소에 관한 정보(현 위치 및 방문기록 등 포함)로서 전기통신설비 및 전기통신회선설비를 이용하여 수집된 것을 말합니다.',
    '② 회사는 본 약관에 동의하여 위치정보의 이용을 승낙한 고객에 한하여 위치정보법 등 관련 법률에서 정한 바에 따라 위치정보를 취득하며 서비스의 목적범위 내에서 위치정보를 이용합니다.',
  ];

  const locationChapter12 =
    '회사는 관련 법령이 정하는 바에 따라 고객의 개인위치정보를 보호하기 위해 노력합니다.';

  const locationChapter13 = [
    '① 회사는 서비스 제공을 위하여 회사가 수집한 고객의 위치정보를 이용할 수 있으며, 고객이 본 약관에 동의하면 위치정보 이용에 동의한 것으로 간주됩니다.',
    '② 회사는 고객이 제공한 개인위치정보를 해당 고객의 동의 없이 서비스 제공 이외의 목적으로 이용하지 않습니다.',
    '③ 회사는 아래 각호의 경우 고객이 지정한 휴대전화나 이메일(E-mail) 주소로 통보할 수 있습니다.',
  ];

  const locationChapter13ThirdDetail = [
    '1. 개인위치정보를 수집한 해당 통신단말장치가 문자, 음성 또는 영상의 수신기능을 갖추지 않은 경우',
    '2. 개인위치정보 주체가 개인위치정보를 수집한 해당 외의 통신단말장치 또는 (E-mail) 주소 등으로 통보할 것을 요청한 경우',
  ];

  const locationChapter13Remain =
    '④ 회사는 개인위치정보를 회원이 지정하는 제3자에게 제공하는 경우에는 개인위치정보를 수집한 당해 통신 단말장치로 회원에게 제공받는 자, 제공일시 및 제공목적을 매회 즉시 또는 위치정보 보호법의 범위 내에서 회사가 정한 주기 중 회원이 선택한 방식으로 통보합니다.단, 회사가 제3자에 대한 정보제공 내역을 주기적으로 모아서 통보할 경우에는 아래의 내용에 대해 알리고 고객의 동의를 받습니다.';

  const locationChapter13FourthDetail = [
    '1. 정보제공내역을 모아서 통보하는 횟수 또는 기간',
    '2. 개인위치정보주체의 요청이 있는 경우에는 즉시 통보 방법으로 변경할 수 있다는 사실 및 그 요청 방법',
  ];

  const locationChapter14 = [
    '① 회사는 위치정보법 제16조 제2항에 근거하여 고객에 대한 위치정보 이용/제공사실 확인자료를 위치정보시스템에 자동으로 기록하며, 고객 불만에 응대하기 위하여 기록시점으로부터 6개월간 보존합니다.',
    '② 위치정보법 제24조 제4항의 규정에 따라 고객이 동의의 전부 또는 일부를 철회하면 그 즉시 회사는 수집된 개인위치정보와 위치정보 이용제공사실 확인자료(동의의 일부를 철회할 때에는 철회하는 부분의 개인위치정보와 위치정보 이용제공사실 확인자료에 한합니다)를 파기합니다. 다만, 국세기본법, 법인세법, 부가가치세법, 기타 관계 법령의 규정에 따라 보존할 필요성이 있으면 관계 법령에 따라 보존합니다.',
  ];

  const locationChapter15 =
    '회사가 고객의 개인위치정보를 이용하였다면 회사는 위치정보법 제16조 제2항의 규정에 따라 기록·보존해야 하는 위치정보이용 제공사실 확인자료 이외의 해당 개인위치정보를 위치정보법 제23조에 따라 고객이 동의한 목적범위 내에서 이용하고 고객의 불만에 응대하기 위하여 6개월간 보유하며 6개월이 지나면 바로 파기합니다.';

  const locationChapter16 = [
    '① 회사가 위치정보법 제18조 제1항, 제19조 제1항 및 제2항 또는 제21조의 규정에 따라 14세 미만 아동의 개인위치정보를 이용하거나 제공하고자 할 때에는 그 법정 대리인의 동의를 얻어야 합니다.',
    '② 제17조(고객의 권리)의 규정은 위치정보법 제25조 제2항의 규정에 따라 법정 대리인이 동의하는 경우에 이를 준용합니다. 이때 법정 대리인을 고객으로 봅니다.',
  ];

  const locationChapter17 =
    '① 회사는 아래 각호 1에 해당하는 자(이하 "8세 이하의 아동 등")의 보호의무자가 8세 이하의 아동 등의 생명 또는 신체보호를 위하여 개인위치정보의 수집·이용 또는 제공에 동의하면 본인의 동의가 있는 것으로 봅니다.';

  const locationChapter17FirstDetail = [
    '1. 8세 이하의 아동',
    '2. 피성년후견인',
    '3. 장애인복지법 제2조 제2항 제2호의 규정에 따른 정신적 장애를 가진 자로서 장애인 고용촉진 및 직업재활법 제2조 제2호의 규정에 따라 중증장애인에 해당하는 자(장애인복지법 제32조의 규정에 따라 장애인등록을 한 자에 한합니다.)',
  ];

  const locationChapter17Second =
    '② 제1항에 따른 서비스 중단일 때에는 회사는 해당 사실을 인터넷 등에 공지하거나 고객에게 통지합니다. 다만, 회사가 통제할 수 없는 사유로 서비스의 중단(운영자의 고의, 과실이 없는 디스크 장애, 시스템 다운 등)으로 말미암아 미리 통지할 수 없을 때에는 사후에 통지합니다.';

  const locationChapter17SecondDetail = [
    '1. 8세 이하의 아동의 법정 대리인 또는 보호시설에 있는 미성년자의 후견 직무에 관한 법률 제3조의 규정에 따른 후견인',
    '2. 피성년후견인의 법정 대리인',
    '3. 본 조 제1항 제3호의 자의 법정 대리인 또는 「장애인복지법」 제58조 제1항 제1호의 규정에 따른 장애인생활시설(국가 또는 지방자치단체가 설치·운영하는 시설에 한합니다)의 장, 정신보건법 제3조 제4호의 규정에 따른 정신질환자 사회복귀시설(국가 또는 지방자치단체가 설치·운영하는 시설에 한합니다)의 장, 정신보건법 제3조 제5호의 규정에 따른 정신요양시설의 장',
  ];

  const locationChapter17Remain = [
    '③ 8세 이하 아동 등의 생명 또는 신체의 보호를 위하여 개인위치정보의 수집·이용 또는 제공에 동의하고자 하는 보호의무자는 서명동의서에 보호의무자임을 증명하는 서류를 첨부하여 회사에 제출해야 합니다.',
    '④ 제18조(고객의 권리)의 규정은 위치정보법 제26조 제4항의 규정에 따라 보호의무자가 동의하는 경우에 이를 준용합니다. 이때 보호의무자를 고객으로 봅니다.',
  ];

  const locationChapter18 = [
    '① 회사는 서비스와 관련한 고객의 불만사항이 접수되면 이를 신속하게 처리해야 하며, 신속한 처리가 어려우면 그 사유와 처리 일정을 고객에게 통지합니다.',
    '② 회사는 위치정보의 보호 및 이용 등에 관한 법률, 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 통신비밀보호법, 전기통신사업법, 개인정보보호법 등 서비스의 운영·유지와 관련 있는 법규를 지킵니다.',
    '③ 고객서비스를 더욱 활성화하여 최적화된 서비스를 제공하고 신상품이나 이벤트 정보 안내, 설문조사 등 고객 지향적인 마케팅을 수행하기 위하여 회사는 이용계약 체결 때 수집한 고객의 개인정보 및 서비스 이용과 관련한 정보를 활용할 수 있습니다. 단, 고객의 동의 범위를 초과하여 이용하거나 제3자에게 제공하고자 할 때에는 미리 해당 고객에게 동의를 받아야 합니다. 이때 고객은 회사의 동의 요청을 거절할 수 있습니다.',
    '④ 회사는 요금연체와 관련하여 이용자를 신용정보의 이용 및 보호에 관한 법률 제17조의 신용정보집중기관 등 관계 기관 등에 신용 불량자로 등록 요청할 때에는 등록요청 대상자의 본인 여부 등 필요한 확인절차를 거쳐야 합니다.',
  ];

  const locationChapter19 = [
    '① 고객은 유무선 인터넷 등을 통해 회사의 고객 개인위치정보의 이용과 제공에 대한 동의의 전부 또는 일부를 철회할 수 있습니다.',
    '② 고객은 유무선 인터넷 등을 통해 회사의 고객 개인위치정보의 이용과 제공의 일시 중지를 요구할 수 있습니다.',
    '③ 고객은 회사에 다음 각 호의 1의 자료 등의 열람 또는 고지를 요구할 수 있고, 그 자료에 오류가 있으면 정정을 요구할 수 있습니다',
  ];

  const locationChapter19ThirdDetail = [
    '1. 고객에 대한 위치정보 수집·이용·제공 사실 확인자료',
    '2. 고객의 개인위치정보가 위치정보법 또는 다른 법률의 규정에 따라 제3자에게 제공된 이유와 그 내용',
  ];

  const locationChapter20 = [
    '① 고객은 서비스 이용계약에 따라 요금을 지정된 날짜까지 내야 하며, 회사에 알린 요금청구 주소와 연락처가 변경되었다면 해당 내용을 회사에 알려야 합니다.',
    '② 고객이 서비스 이용에 필요한 제반 정보를 제공·등록할 때에는 현재의 사실과 일치하는 완전한 정보를 제공·등록해야 하며, 변경사항이 있으면 즉시 갱신해야 합니다.',
    '③ 고객은 단말기가 정상 동작을 유지하도록 관리해야 하며, 단말기가 정상적으로 작동하지 않아 서비스 제공에 지장이 있을 때에는 해당 단말기의 보수, 교환 등을 신속히 처리하여 원활한 서비스가 이루어지도록 합니다.',
    '④ 고객은 서비스를 이용할 때 다음 각 호의 1에 해당하는 행위를 해서는 안 됩니다.',
  ];

  const locationChapter20fourthDetail = [
    '1. 가입신청 또는 변경 때 허위 사실을 기재하는 행위',
    '2. 타인의 명예를 손상하거나 타인에게 불이익을 주는 행위',
    '3. 공공질서와 미풍양속을 거스르는 내용의 정보·문장·도형·음성 등을 타인에게 유포하는 행위',
    '4. 서비스와 관련된 설비의 오동작이나 정보 등의 파괴·혼란을 유발하는 컴퓨터 바이러스 감염 자료를 등록 또는 유포하는 행위',
    '5. 타인으로 가장하는 행위 및 타인과의 관계를 허위로 명시하는 행위',
    '6. 자기 또는 타인에게 재산상의 이익을 주거나 타인에게 손해를 끼칠 목적으로 거짓된 정보를 유통하는 행위',
    '8. 기타 불법적이거나 부당한 행위',
  ];

  const locationChapter20Remain =
    '⑤ 고객은 관계 법령, 이 약관의 규정, 이용안내 및 서비스상에 공지한 주의사항, 회사가 통지하는 사항 등을 지켜야 하며, 기타 회사의 업무에 방해되는 행위를 해서는 안 됩니다.';

  const locationChapter21 = '① 회사의 상호, 주소, 전화번호, 그 밖의 연락처는 다음과 같습니다.';

  const locationChapter21FirstDetail = [
    '1. 상호: 주차장만드는사람들(주)',
    '2. 주소: 서울시 영등포구 경인로 775, 2동 10층 1003호 에이스하이테크시티 (우편번호 07299)',
    '3. 전화번호: 070) 7931-7997',
  ];

  const locationChapter21Remain =
    '② 회사는 개인위치정보를 보호하기 위한 기술적·관리적 대책을 수립·실행하고 개인위치정보주체의 불만을 원활히 처리하기 위하여 실질적인 책임을 질 수 있는 지위에 있는 자를 위치정보관리책임자로 지정해 운영하고 있으며, 성명과 연락처는 아래와 같습니다.';

  const locationChapter21RemainDetail = [
    '1) 성명: 성수환',
    '2) 직위: 연구소장',
    '3) 전화번호: 070) 7931-7998',
    '4) 이메일: sung@zoomansa.com',
  ];

  const locationChapter22 =
    '고객과 회사는 고객의 서비스 가입에 따른 본 약관상의 지위 또는 권리, 의무의 전부 또는 일부를 제3자에게 양도하거나 위임할 수 없으며 담보 제공 등의 목적으로 처분할 수 없습니다.';

  const locationChapter23 = [
    '① 고객이 고의나 과실로 이 약관의 규정을 위반하여 회사에 손해가 발생하였다면, 고객은 회사에 발생하는 모든 손해를 배상해야 합니다.',
    '② 고객이 서비스를 이용하면서 불법행위 또는 고의나 과실로 이 약관을 위반하여 회사가 해당 고객 이외의 제3자로부터 손해배상청구 또는 소송을 비롯한 각종 이의제기를 받는다면 해당 고객은 그 때문에 회사에 발생한 손해를 배상해야 합니다.',
    '③ 회사가 위치정보법 제15조 또는 제26조의 규정을 위반한 행위 혹은 회사가 제공하는 서비스로 말미암아 고객에게 손해가 발생하였을 때 회사가 고의 또는 과실 없음을 입증하지 않으면 회사는 고객의 손해에 대하여 책임을 부담합니다.',
  ];

  const locationChapter24 = [
    '① 회사가 천재지변 또는 이에 준하는 불가항력으로 서비스를 제공할 수 없다면 회사는 서비스 제공에 관한 책임이 면제됩니다.',
    '② 회사는 고객의 귀책사유로 인한 서비스의 이용장애에 대하여 책임을 지지 않습니다.',
    '③ 회사는 고객이 서비스를 이용하여 기대하는 수익을 상실한 것에 대해 책임을 지지 않으며, 그 밖의 서비스를 통하여 얻은 자료 때문에 손해를 보았을 때에도 책임을 지지 않습니다.',
    '④ 회사에서 제공하는 서비스 및 서비스를 이용하여 얻은 정보에 대한 최종 판단은 고객이 직접 해야 하고, 그에 따른 책임은 전적으로 고객 자신에게 있으며, 회사는 그 때문에 발생하는 손해에 대해 책임을 부담하지 않습니다.',
    '⑤ 회사의 업무나 기술상의 장애로 서비스를 개시하지 못하면 회사는 인터넷 홈페이지 등에 해당 내용을 공지하거나 이메일(E-mail) 등의 방법으로 고객에게 통지합니다. 단, 회사가 통제할 수 없는 사유로 미리 공지할 수 없으면 사후에 공지합니다.',
  ];

  const locationChapter25 = [
    '① 서비스 이용과 관련하여 회사와 고객 사이에 분쟁이 발생하면, 회사와 고객은 분쟁의 해결을 위해 성실히 협의합니다.',
    '② 제1항의 협의에서도 분쟁이 해결되지 않으면 회사와 고객 양 당사자는 위치정보의 보호 및 이용 등에 관한 법률 제28조(방송통신위원회의 재정), 개인정보보호법 제43조(조정의 신청 등)의 규정에 따라 방송통신위원회 또는 개인정보분쟁조정위원회에 재정 또는 분쟁조정을 신청할 수 있습니다.',
    '③ 본 조 제2항으로도 분쟁이 해결되지 않으면 회사와 고객 양 당사자는 민사소송법상의 관할법원에 소를 제기할 수 있습니다.',
  ];

  return (
    <MainContainer>
      <Title
        title="위치정보 이용약관"
        leftArrowButtonVisible={true}
        leftOnClick={handleLeftOnClick}
        rightTextButtonVisible={false}
        titleColor={'darkPurple'}
        showSpacer={true}
      />
      <div style={{ marginBottom: '40px'}}>
        <Body
          style={{
            marginBottom: '28px',
          }}
          weight={'semibold'}
        >
          제1장 총칙
        </Body>
        <Subheadline weight={'semibold'}>제 1조 (목적)</Subheadline>
        <TermsDetail
          color={'gray09'}
          weight={'regular'}
          style={{
            marginTop: '10px',
          }}
        >
          {locationChapter1}
        </TermsDetail>
        <Subheadline
          weight={'semibold'}
          style={{
            marginTop: '40px',
          }}
        >
          제2조 (약관의 효력 및 변경)
        </Subheadline>
        <div
          style={{
            marginTop: '10px',
          }}
        >
          {locationChapter2.map((chapter2, index) => (
            <TermsDetail color={'gray09'} weight={'regular'} key={index}>
              {chapter2}
            </TermsDetail>
          ))}
        </div>
        <Subheadline
          weight={'semibold'}
          style={{
            marginTop: '40px',
          }}
        >
          제3조 (약관 외 준칙)
        </Subheadline>
        <TermsDetail
          color={'gray09'}
          weight={'regular'}
          style={{
            marginTop: '10px',
          }}
        >
          {locationChapter3}
        </TermsDetail>
        <Body
          style={{
            marginTop: '40px',
            marginBottom: '28px',
          }}
          weight={'semibold'}
        >
          제2장 서비스의 이용
        </Body>
        <Subheadline weight={'semibold'}>제4조 (가입자격)</Subheadline>
        <TermsDetail
          color={'gray09'}
          weight={'regular'}
          style={{
            marginTop: '10px',
          }}
        >
          {locationChapter4.map((chapter4, index) => (
            <TermsDetail color={'gray09'} weight={'regular'} key={index}>
              {chapter4}
            </TermsDetail>
          ))}
        </TermsDetail>
        <Subheadline
          weight={'semibold'}
          style={{
            marginTop: '40px',
          }}
        >
          제5조 (서비스 가입)
        </Subheadline>
        <div
          style={{
            marginTop: '10px',
          }}
        >
          {locationChapter5.map((chapter5, index) => (
            <TermsDetail color={'gray09'} weight={'regular'} key={index}>
              {chapter5}
            </TermsDetail>
          ))}
        </div>
        <Subheadline
          weight={'semibold'}
          style={{
            marginTop: '40px',
          }}
        >
          제6조 (서비스의 해지)
        </Subheadline>
        <TermsDetail
          color={'gray09'}
          weight={'regular'}
          style={{
            marginTop: '10px',
          }}
        >
          {locationChapter6}
        </TermsDetail>
        <Subheadline
          weight={'semibold'}
          style={{
            marginTop: '40px',
          }}
        >
          제7조 (서비스의 수준)
        </Subheadline>
        <div
          style={{
            marginTop: '10px',
          }}
        >
          {locationChapter7.map((chapter7, index) => (
            <TermsDetail color={'gray09'} weight={'regular'} key={index}>
              {chapter7}
            </TermsDetail>
          ))}
        </div>
        <Subheadline
          weight={'semibold'}
          style={{
            marginTop: '40px',
          }}
        >
          제8조 (서비스 이용의 제한 및 정지)
        </Subheadline>
        <div
          style={{
            marginTop: '10px',
          }}
        >
          {locationChapter8.map((chapter8, index) => (
            <TermsDetail color={'gray09'} weight={'regular'} key={index}>
              {chapter8}
            </TermsDetail>
          ))}
        </div>
        <Subheadline
          weight={'semibold'}
          style={{
            marginTop: '40px',
          }}
        >
          제9조 (서비스의 변경 및 중지)
        </Subheadline>
        <div
          style={{
            marginTop: '10px',
          }}
        >
          <TermsDetail
            color={'gray09'}
            weight={'regular'}
            style={{
              marginTop: '10px',
            }}
          >
            {locationChapter9}
          </TermsDetail>

          <DetailContainer>
            {locationChapter9FirstDetail.map((firstDetail, index) => (
              <TermsDetail color={'gray09'} weight={'regular'} key={index}>
                {firstDetail}
              </TermsDetail>
            ))}
          </DetailContainer>

          <TermsDetail
            color={'gray09'}
            weight={'regular'}
            style={{
              marginTop: '16px',
            }}
          >
            {locationChapter9Second}
          </TermsDetail>
        </div>
        <Subheadline
          weight={'semibold'}
          style={{
            marginTop: '40px',
          }}
        >
          제10조 (서비스 종류 및 이용요금)
        </Subheadline>
        <TermsDetail
          color={'gray09'}
          weight={'regular'}
          style={{
            marginTop: '10px',
          }}
        >
          {locationChapter10}
        </TermsDetail>
        <Body
          style={{
            marginTop: '40px',
            marginBottom: '28px',
          }}
          weight={'semibold'}
        >
          제3장 개인위치정보의 보호
        </Body>
        <Subheadline weight={'semibold'}>제11조 (위치정보의 정의 등)</Subheadline>
        <TermsDetail
          color={'gray09'}
          weight={'regular'}
          style={{
            marginTop: '10px',
          }}
        >
          {locationChapter11.map((chapter11, index) => (
            <TermsDetail color={'gray09'} weight={'regular'} key={index}>
              {chapter11}
            </TermsDetail>
          ))}
        </TermsDetail>
        <Subheadline
          weight={'semibold'}
          style={{
            marginTop: '40px',
          }}
        >
          제12조 (고객의 개인위치정보보호)
        </Subheadline>
        <TermsDetail
          color={'gray09'}
          weight={'regular'}
          style={{
            marginTop: '10px',
          }}
        >
          {locationChapter12}
        </TermsDetail>
        <Subheadline
          weight={'semibold'}
          style={{
            marginTop: '40px',
          }}
        >
          제13조 (개인위치정보의 이용 또는 제공)
        </Subheadline>
        <div
          style={{
            marginTop: '10px',
          }}
        >
          <TermsDetail
            color={'gray09'}
            weight={'regular'}
            style={{
              marginTop: '10px',
            }}
          >
            {locationChapter13.map((chapter13, index) => (
              <TermsDetail color={'gray09'} weight={'regular'} key={index}>
                {chapter13}
              </TermsDetail>
            ))}
          </TermsDetail>

          <DetailContainer>
            {locationChapter13ThirdDetail.map((thirdDetail, index) => (
              <TermsDetail color={'gray09'} weight={'regular'} key={index}>
                {thirdDetail}
              </TermsDetail>
            ))}
          </DetailContainer>
          <TermsDetail
            color={'gray09'}
            weight={'regular'}
            style={{
              marginTop: '10px',
            }}
          >
            {locationChapter13Remain}
          </TermsDetail>

          <DetailContainer>
            {locationChapter13FourthDetail.map((fourthDetail, index) => (
              <TermsDetail color={'gray09'} weight={'regular'} key={index}>
                {fourthDetail}
              </TermsDetail>
            ))}
          </DetailContainer>
        </div>
        <Subheadline
          weight={'semibold'}
          style={{
            marginTop: '40px',
          }}
        >
          제14조 (개인위치정보 이용/제공사실 확인자료의 보유)
        </Subheadline>
        <div
          style={{
            marginTop: '10px',
          }}
        >
          <TermsDetail
            color={'gray09'}
            weight={'regular'}
            style={{
              marginTop: '10px',
            }}
          >
            {locationChapter14.map((chapter14, index) => (
              <TermsDetail color={'gray09'} weight={'regular'} key={index}>
                {chapter14}
              </TermsDetail>
            ))}
          </TermsDetail>
        </div>
        <Subheadline
          weight={'semibold'}
          style={{
            marginTop: '40px',
          }}
        >
          제15조 (개인위치정보의 보유기간 및 이용기간)
        </Subheadline>
        <div
          style={{
            marginTop: '10px',
          }}
        >
          <TermsDetail
            color={'gray09'}
            weight={'regular'}
            style={{
              marginTop: '10px',
            }}
          >
            {locationChapter15}
          </TermsDetail>
        </div>
        <Subheadline
          weight={'semibold'}
          style={{
            marginTop: '40px',
          }}
        >
          제16조 (법정대리인의 권리)
        </Subheadline>
        <div
          style={{
            marginTop: '10px',
          }}
        >
          <TermsDetail
            color={'gray09'}
            weight={'regular'}
            style={{
              marginTop: '10px',
            }}
          >
            {locationChapter16.map((chapter16, index) => (
              <TermsDetail color={'gray09'} weight={'regular'} key={index}>
                {chapter16}
              </TermsDetail>
            ))}
          </TermsDetail>
        </div>
        <Subheadline
          weight={'semibold'}
          style={{
            marginTop: '40px',
          }}
        >
          제17조 (8세 이하의 아동 등의 보호를 위한 위치정보 이용)
        </Subheadline>
        <div
          style={{
            marginTop: '10px',
          }}
        >
          <TermsDetail
            color={'gray09'}
            weight={'regular'}
            style={{
              marginTop: '10px',
            }}
          >
            {locationChapter17}
          </TermsDetail>

          <DetailContainer>
            {locationChapter17FirstDetail.map((firstDetail, index) => (
              <TermsDetail color={'gray09'} weight={'regular'} key={index}>
                {firstDetail}
              </TermsDetail>
            ))}
          </DetailContainer>

          <TermsDetail
            color={'gray09'}
            weight={'regular'}
            style={{
              marginTop: '16px',
            }}
          >
            {locationChapter17Second}
          </TermsDetail>

          <DetailContainer>
            {locationChapter17SecondDetail.map((secondDetail, index) => (
              <TermsDetail color={'gray09'} weight={'regular'} key={index}>
                {secondDetail}
              </TermsDetail>
            ))}
          </DetailContainer>

          {locationChapter17Remain.map((remainDetail, index) => (
            <TermsDetail color={'gray09'} weight={'regular'} key={index}>
              {remainDetail}
            </TermsDetail>
          ))}
        </div>
        <Body
          style={{
            marginTop: '40px',
            marginBottom: '28px',
          }}
          weight={'semibold'}
        >
          제4장 회사와 고객의 권리 및 의무
        </Body>
        <Subheadline weight={'semibold'}>제18조 (회사의 의무)</Subheadline>
        <TermsDetail
          color={'gray09'}
          weight={'regular'}
          style={{
            marginTop: '10px',
          }}
        >
          {locationChapter18.map((chapter18, index) => (
            <TermsDetail color={'gray09'} weight={'regular'} key={index}>
              {chapter18}
            </TermsDetail>
          ))}
        </TermsDetail>
        <Subheadline
          style={{
            marginTop: '40px',
          }}
          weight={'semibold'}
        >
          제19조 (고객의 권리)
        </Subheadline>
        <TermsDetail
          color={'gray09'}
          weight={'regular'}
          style={{
            marginTop: '10px',
          }}
        >
          {locationChapter19.map((chapter19, index) => (
            <TermsDetail color={'gray09'} weight={'regular'} key={index}>
              {chapter19}
            </TermsDetail>
          ))}

          <DetailContainer>
            {locationChapter19ThirdDetail.map((thirdDetail, index) => (
              <TermsDetail color={'gray09'} weight={'regular'} key={index}>
                {thirdDetail}
              </TermsDetail>
            ))}
          </DetailContainer>
        </TermsDetail>

        <Subheadline
          style={{
            marginTop: '40px',
          }}
          weight={'semibold'}
        >
          제20조 (고객의 의무)
        </Subheadline>
        <TermsDetail
          color={'gray09'}
          weight={'regular'}
          style={{
            marginTop: '10px',
          }}
        >
          {locationChapter20.map((chapter20, index) => (
            <TermsDetail color={'gray09'} weight={'regular'} key={index}>
              {chapter20}
            </TermsDetail>
          ))}

          <DetailContainer>
            {locationChapter20fourthDetail.map((fourthDetail, index) => (
              <TermsDetail color={'gray09'} weight={'regular'} key={index}>
                {fourthDetail}
              </TermsDetail>
            ))}
          </DetailContainer>
          <TermsDetail
            color={'gray09'}
            weight={'regular'}
            style={{
              marginTop: '10px',
            }}
          >
            {locationChapter20Remain}
          </TermsDetail>
        </TermsDetail>

        <Body
          style={{
            marginTop: '40px',
            marginBottom: '28px',
          }}
          weight={'semibold'}
        >
          제5장 기타
        </Body>
        <Subheadline weight={'semibold'}>제21조 (주소 및 연락처 등)</Subheadline>

        <TermsDetail
          color={'gray09'}
          weight={'regular'}
          style={{
            marginTop: '10px',
          }}
        >
          {locationChapter21}
        </TermsDetail>

        <DetailContainer>
          {locationChapter21FirstDetail.map((firstDetail, index) => (
            <TermsDetail color={'gray09'} weight={'regular'} key={index}>
              {firstDetail}
            </TermsDetail>
          ))}
        </DetailContainer>

        <TermsDetail
          color={'gray09'}
          weight={'regular'}
          style={{
            marginTop: '10px',
          }}
        >
          {locationChapter21Remain}
        </TermsDetail>

        <DetailContainer>
          {locationChapter21RemainDetail.map((remainDetail, index) => (
            <TermsDetail color={'gray09'} weight={'regular'} key={index}>
              {remainDetail}
            </TermsDetail>
          ))}
        </DetailContainer>

        <Subheadline
          style={{
            marginTop: '40px',
          }}
          weight={'semibold'}
        >
          제22조 (양도금지)
        </Subheadline>

        <TermsDetail
          color={'gray09'}
          weight={'regular'}
          style={{
            marginTop: '10px',
          }}
        >
          {locationChapter22}
        </TermsDetail>

        <Subheadline
          style={{
            marginTop: '40px',
          }}
          weight={'semibold'}
        >
          제23조 (손해배상)
        </Subheadline>

        <TermsDetail
          color={'gray09'}
          weight={'regular'}
          style={{
            marginTop: '10px',
          }}
        >
          {locationChapter23.map((chapter23, index) => (
            <TermsDetail color={'gray09'} weight={'regular'} key={index}>
              {chapter23}
            </TermsDetail>
          ))}
        </TermsDetail>

        <Subheadline
          style={{
            marginTop: '40px',
          }}
          weight={'semibold'}
        >
          제24조 (면책사항)
        </Subheadline>

        <TermsDetail
          color={'gray09'}
          weight={'regular'}
          style={{
            marginTop: '10px',
          }}
        >
          {locationChapter24.map((chapter24, index) => (
            <TermsDetail color={'gray09'} weight={'regular'} key={index}>
              {chapter24}
            </TermsDetail>
          ))}
        </TermsDetail>

        <Subheadline
          style={{
            marginTop: '40px',
          }}
          weight={'semibold'}
        >
          제25조 (분쟁의 해결 및 관할법원)
        </Subheadline>

        <TermsDetail
          color={'gray09'}
          weight={'regular'}
          style={{
            marginTop: '10px',
          }}
        >
          {locationChapter25.map((chapter25, index) => (
            <TermsDetail color={'gray09'} weight={'regular'} key={index}>
              {chapter25}
            </TermsDetail>
          ))}
        </TermsDetail>
      </div>
    </MainContainer>
  );
};

export default PolicyLocationPage;
