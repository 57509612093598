import React, { useState, useEffect } from "react";
import { MainContainer } from "../../../components/styles";
import Title from "../../../components/atoms/Title/PageTitle";
import { useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from "react-hook-form";
import {
  VerificationButton,
} from './styles';
import { DeleteAccountTitleContainer } from '../styles';
import CheckModal from '../../../components/atoms/Modal/CheckModal';
import { BottomButtonContainer, ButtonEnableDisable } from '../../../components/atoms/Button/styles';
import {
  CautionTextContainer, PhoneNumberBorderContainer,
  UserInfoInput, TimerContainer, VerificationCodeInput,
  VerificationCodeInputContainer,
} from '../../../components/atoms/UserInfoInputsContainers/styles';

interface IFormInputs {
  phoneNumber: string;
  verificationCode?: string;
}

const DeleteAccount: React.FC = () => {
  const navigate = useNavigate();
  const [showVerificationCodeInput, setShowVerificationCodeInput] = useState(false);
  const [timeLeft, setTimeLeft] = useState(180);
  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (showVerificationCodeInput && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft(prevTime => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsExpired(true);
      setTimeLeft(0);
    }

    return () => clearInterval(timer);
  }, [showVerificationCodeInput, timeLeft]);

  const handleLeftOnClick = () => {
    navigate(-1);
  };

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitting }
  } = useForm<IFormInputs>({
    defaultValues: {
      phoneNumber: '',
      verificationCode: ''
    }
  });

  const phoneNumber = watch('phoneNumber', '');
  const verificationCode = watch('verificationCode', '');

  const validatePhoneNumber = (value: string) => {
    const phoneNumberPattern = /^(01[016789]{1})[0-9]{3,4}[0-9]{4}$/;
    return phoneNumberPattern.test(value) || '유효하지 않은 핸드폰 번호입니다.';
  };

  const onSubmit: SubmitHandler<IFormInputs> = (data) => {
    console.log('폼 데이터:', data);
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    if (/^[0-9]*$/.test(value)) {
      value = value.slice(0, 11);
      e.target.value = value;
      setValue('phoneNumber', value, { shouldValidate: true });
    }
  };

  const isPhoneNumberValid = !errors.phoneNumber && phoneNumber.length > 0;
  const isFormValid = isPhoneNumberValid && !isSubmitting;

  const handleVerificationButtonClick = () => {
    if (showVerificationCodeInput) {
      setTimeLeft(180);
      setIsExpired(false);
      setValue('verificationCode', '');
    } else {
      setShowVerificationCodeInput(true);
      setIsVerificationCodeTransmissionModal(true);
    }
  };

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  };

  const [isVerificationCodeTransmissionModal, setIsVerificationCodeTransmissionModal] = React.useState(false);

  return (
    <MainContainer>
      <Title
        title="회원 탈퇴"
        leftArrowButtonVisible={true}
        leftOnClick={handleLeftOnClick}
        rightTextButtonVisible={false}
      />
      <DeleteAccountTitleContainer>
        고객님의 개인정보 보호를 위해
        <br />
        휴대폰 인증을 진행해 주세요.
      </DeleteAccountTitleContainer>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <PhoneNumberBorderContainer>
            <UserInfoInput
              type='tel'
              id="userPhoneNumber"
              placeholder="휴대폰 번호"
              {...register('phoneNumber', {
                required: '휴대폰 번호를 입력해주세요.',
                validate: validatePhoneNumber,
              })}
              onChange={handlePhoneNumberChange}
              disabled={showVerificationCodeInput}
            />
          </PhoneNumberBorderContainer>

          <CautionTextContainer>
            {errors.phoneNumber ? <span>{errors.phoneNumber.message}</span> : null}
          </CautionTextContainer>
        </div>

        {showVerificationCodeInput && (
          <div>
            <VerificationCodeInputContainer>
              <VerificationCodeInput
                type="password"
                placeholder="인증번호 입력"
                {...register('verificationCode', {
                  required: '인증번호를 입력해주세요.',
                  onChange: (e) => {
                    const value = e.target.value.slice(0, 5)
                    e.target.value = value
                  },
                })}
              />
              <TimerContainer expired={isExpired}>
                {isExpired ? "00:00" : formatTime(timeLeft)}
              </TimerContainer>
            </VerificationCodeInputContainer>

            <CautionTextContainer>
              {isExpired ? (
                <span>인증 시간이 만료되었습니다. 인증 번호를 다시 받아주세요.</span>
              ) : !verificationCode ? (
                <span>인증번호를 입력해주세요.</span>
              ) : null}
            </CautionTextContainer>
          </div>
        )}
        <VerificationButton
          type="button"
          isVerificationActive={showVerificationCodeInput}
          isFormValid={isFormValid}
          disabled={!isFormValid && !showVerificationCodeInput}
          onClick={handleVerificationButtonClick}
        >
          {showVerificationCodeInput ? '인증번호 다시 받기' : '인증번호 요청'}
        </VerificationButton>
      </form>

      <BottomButtonContainer>
        <ButtonEnableDisable
          onClick={() => navigate('/deleteAccountNotification')}
          disabled={!verificationCode}
        >
          다음
        </ButtonEnableDisable>
      </BottomButtonContainer>

      <CheckModal
        isVisible={isVerificationCodeTransmissionModal}
        onClose={() => {
          setIsVerificationCodeTransmissionModal(false);
        }}
        title={'인증번호가 발송되었습니다.'}
        content={''}
        buttonText={'확인'}
      />
    </MainContainer>
  );
}

export default DeleteAccount;
