import React, { useEffect, useState } from 'react';
import {
  BrandDetailSpan,
  BrandTitleDetailContainer,
  ChangeButton,
  DotLineImg,
  GoodsContainer,
  GoodsImage,
  MovePointMallBrandButton,
  NotChangeCouponContainer,
  NotCouponContentSpan,
  NotCouponSpan,
  PhoneNumberModalContentContainer,
  PointMallStorageBoxToggleFirstContainer,
  PointMallStorageBoxToggleFirstSpan,
  PointMallStorageBoxToggleSecondContainer,
  PointMallStorageBoxToggleSecondSpan,
  PointMallStorageBoxToggleThirdContainer,
  PointMallStorageBoxToggleThirdSpan,
  StampContainer,
  StampIcon,
  TicketBottomContainer,
  TicketBottomContentsContainer,
  TicketContainer,
  TicketExpirationDateSpan,
  TicketTopContainer,
  TicketTopContentsContainer,
  ToggleButtonContainer,
  TransmissionSpan,
} from './styles';
import Lottie from 'react-lottie';
import lottieEmpty from '../../../../assets/lottie/lottieEmpty80.json';
import imgLineDotted from '../../../../assets/images/img_lline_dotted.svg';
import PurpleOneButtonCheckModal from '../../../../components/atoms/Modal/PurpleOneButtonCheckModal';
import { useNavigate } from 'react-router-dom';
import icStampRed from '../../../../assets/ic/ic_stamp_red.svg';
import icStampGray from '../../../../assets/ic/ic_stamp_gray.svg';
import { userInfoStore } from '../../../../stores/store/UserInfo/userInfoStore';
import { pointCouponStorageStore } from '../../../../stores/store/Point/pointCouponStorageStore';
import { observer } from 'mobx-react';
import { pointCouponSendStore } from '../../../../stores/store/Point/pointCouponSendStore';
import LoadingSpinnerScreen from '../../../../components/atoms/LoadingSpinner/LoadingSpinnerScreen';
import { LINK_UID } from '../../../../constants/userInfoConstants';
import { Simulate } from 'react-dom/test-utils';
import { useFetchUserInfo } from '../../../../hooks/useFetchUserInfo';

const PointMallStorageBoxList = observer(() => {
  const navigate = useNavigate();
  const [isStorageBoxAllClicked, setIsStorageBoxAllClicked] = useState(true);
  const [isSendSmsModal, setIsSendSmsModal] = useState(false);
  const [isCertErrorModal, setIsCertErrorModal] = useState(false);
  const [clickedButtons, setClickedButtons] = useState<number[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number | null>(null);
  const { couponStorageData } = pointCouponStorageStore;
  const linkUID = parseInt(sessionStorage.getItem(LINK_UID) || '0', 10);
  const { fetchUserInfo } = useFetchUserInfo();

  useEffect(() => {
    pointCouponStorageStore.setStorageBoxAllClicked(true); // Setting initial state
    pointCouponStorageStore.fetchCouponStorage(linkUID, false).catch((error) => {
      console.error('Error loading coupon storage data:', error);
    });
  }, [linkUID]);

  const handleScroll = async () => {
    const { scrollTop, scrollHeight, clientHeight } = document.documentElement;

    if (
      scrollTop + clientHeight >= scrollHeight - 5 &&
      !pointCouponStorageStore.isLoading &&
      !pointCouponStorageStore.isEnd
    ) {
      try {
        await pointCouponStorageStore.fetchCouponStorage(linkUID, true);
      } catch (error) {
        console.error('데이터 로드 에러:', error);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleButtonClick = async (index: number) => {
    if (index < 0 || index >= couponStorageData.length) {
      console.error('Invalid index:', index);
      return;
    }

    const selectedCoupon = couponStorageData[index];

    if (!selectedCoupon) {
      console.error('Selected coupon is undefined');
      return;
    }

    try {
      if (selectedCoupon.state === '교환') {
        await pointCouponSendStore.sendCouponCert(
          linkUID,
          selectedCoupon.storageUID,
          selectedCoupon.couponCode,
        );
        pointCouponStorageStore.updateCouponState(index, '문자 재발송');
      }
      setClickedButtons((prev) => [...prev, index]);
      setIsSendSmsModal(true);
    } catch (error) {
      console.error('Error sending coupon cert:', error);
      setIsCertErrorModal(true);
    }
  };

  const hasLottie = isStorageBoxAllClicked;

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        await fetchUserInfo();
        console.log('유저 정보 가져오기 성공');
      } catch (error) {
        console.error('유저 정보 가져오기 실패:', error);
      }
    };
    getUserInfo().catch((error) => {
      console.error('유저 정보 가져오기 실패:', error);
    });
  }, []);

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: lottieEmpty,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  if (pointCouponStorageStore.isLoading && !pointCouponStorageStore.isScrollLoading) {
    return <LoadingSpinnerScreen />;
  }

  return (
    <>
      <ToggleButtonContainer>
        <PointMallStorageBoxToggleFirstContainer
          onClick={() => {
            pointCouponStorageStore.toggleStorageBoxAll();
            pointCouponStorageStore.fetchCouponStorage(linkUID, false).catch((error) => {
              console.error('Error loading coupon storage data:', error);
            });
          }}
          isStorageBoxAllClicked={pointCouponStorageStore.isStorageBoxAllClicked}
        >
          <PointMallStorageBoxToggleFirstSpan
            isStorageBoxAllClicked={pointCouponStorageStore.isStorageBoxAllClicked}
          >
            전체
          </PointMallStorageBoxToggleFirstSpan>
        </PointMallStorageBoxToggleFirstContainer>

        <PointMallStorageBoxToggleSecondContainer
          onClick={() => {
            pointCouponStorageStore.toggleStorageBoxChangePossible();
            pointCouponStorageStore.fetchCouponStorage(linkUID, false).catch((error) => {
              console.error('Error loading coupon storage data:', error);
            });
          }}
          isStorageBoxChangePossibleClicked={
            pointCouponStorageStore.isStorageBoxChangePossibleClicked
          }
        >
          <PointMallStorageBoxToggleSecondSpan
            isStorageBoxChangePossibleClicked={
              pointCouponStorageStore.isStorageBoxChangePossibleClicked
            }
          >
            교환 가능
          </PointMallStorageBoxToggleSecondSpan>
        </PointMallStorageBoxToggleSecondContainer>

        <PointMallStorageBoxToggleThirdContainer
          onClick={() => {
            pointCouponStorageStore.toggleStorageBoxChangeImpossible();
            pointCouponStorageStore.fetchCouponStorage(linkUID, false).catch((error) => {
              console.error('Error loading coupon storage data:', error);
            });
          }}
          isStorageBoxChangeImpossibleClicked={
            pointCouponStorageStore.isStorageBoxChangeImpossibleClicked
          }
        >
          <PointMallStorageBoxToggleThirdSpan
            isStorageBoxChangeImpossibleClicked={
              pointCouponStorageStore.isStorageBoxChangeImpossibleClicked
            }
          >
            교환 기간 만료
          </PointMallStorageBoxToggleThirdSpan>
        </PointMallStorageBoxToggleThirdContainer>
      </ToggleButtonContainer>

      { pointCouponStorageStore.couponStorageData.length === 0 ? (
        pointCouponStorageStore.isStorageBoxAllClicked ? (
          <NotChangeCouponContainer hasLottie={hasLottie}>
            <Lottie options={defaultOptions} height={80} width={80} />
            <NotCouponSpan>아직 교환한 쿠폰이 없어요.</NotCouponSpan>
            <NotCouponContentSpan>교환한 쿠폰은 이곳에서 확인할 수 있어요.</NotCouponContentSpan>
            <MovePointMallBrandButton onClick={() => navigate('/pointMallBrand')}>
              쿠폰 구경가기
            </MovePointMallBrandButton>
          </NotChangeCouponContainer>
        ) : (
          <NotChangeCouponContainer hasLottie={false}>
            { pointCouponStorageStore.isStorageBoxChangePossibleClicked ? (
              <NotCouponSpan>교환 가능한 쿠폰이 없어요.</NotCouponSpan>
            ) : pointCouponStorageStore.isStorageBoxChangeImpossibleClicked ? (
              <NotCouponSpan>교환 기간 만료한 쿠폰이 없어요.</NotCouponSpan>
            ) : (
              <NotCouponSpan>교환 가능한 쿠폰이 없어요.</NotCouponSpan>
            )}
            <MovePointMallBrandButton onClick={() => navigate('/pointMallBrand')}>
              쿠폰 구경가기
            </MovePointMallBrandButton>
          </NotChangeCouponContainer>
        )
      ) : (
        pointCouponStorageStore.couponStorageData.map((couponStorage, index) => (
          <TicketContainer key={index}>
            <TicketTopContainer>
              <TicketTopContentsContainer
                state={
                  couponStorage.state === '기간 만료'
                    ? 'expired'
                    : couponStorage.state === '문자 재발송'
                      ? 'resend'
                      : couponStorage.state === '교환'
                        ? 'exchange'
                        : 'exchange'
                }
              >
                <span>{couponStorage.purchasePrice}</span>
                <TicketExpirationDateSpan>{couponStorage.endDate}</TicketExpirationDateSpan>
              </TicketTopContentsContainer>
              <ChangeButton
                onClick={() => handleButtonClick(index)}
                state={
                  couponStorage.state === '기간 만료'
                    ? 'expired'
                    : couponStorage.state === '문자 재발송'
                      ? 'resend'
                      : couponStorage.state === '교환'
                        ? 'exchange'
                        : 'exchange'
                }
                disabled={couponStorage.state === '기간 만료'}
              >
                {couponStorage.state === '기간 만료'
                  ? '기간 만료'
                  : couponStorage.state === '문자 재발송'
                    ? '문자 재발송'
                    : '교환'}
              </ChangeButton>
            </TicketTopContainer>
            <DotLineImg src={imgLineDotted} alt={'점선 이미지'} />
            <TicketBottomContainer>
              <StampContainer>
                {clickedButtons.includes(index) &&
                  couponStorage.state !== '기간 만료' &&
                  couponStorage.state !== '교환' && <StampIcon src={icStampRed} alt="stamp" />}

                {couponStorage.state === '문자 재발송' && couponStorage.exchangeYN && (
                  <StampIcon src={icStampRed} alt="stamp" />
                )}

                {couponStorage.state === '기간 만료' && couponStorage.exchangeYN && (
                  <StampIcon src={icStampGray} alt="stamp" />
                )}

                <GoodsContainer
                  state={
                    couponStorage.state === '기간 만료'
                      ? 'expired'
                      : couponStorage.state === '문자 재발송'
                        ? 'resend'
                        : couponStorage.state === '교환'
                          ? 'exchange'
                          : 'exchange'
                  }
                >
                  <GoodsImage src={couponStorage.couponImage} alt={couponStorage.couponName} />
                </GoodsContainer>
              </StampContainer>

              <TicketBottomContentsContainer>
                <BrandTitleDetailContainer>
                  <span>{couponStorage.couponBrand}</span>
                  <BrandDetailSpan>{couponStorage.couponName}</BrandDetailSpan>
                </BrandTitleDetailContainer>
                <span>{couponStorage.purchaseDate}</span>
              </TicketBottomContentsContainer>
            </TicketBottomContainer>
          </TicketContainer>
        ))
      )}
      <PurpleOneButtonCheckModal
        isVisible={isSendSmsModal}
        onClose={() => {
          if (currentIndex !== null) {
            setClickedButtons((prev) => [...prev, currentIndex]);
          }
          setIsSendSmsModal(false);
          setCurrentIndex(null);
        }}
        title={'쿠폰 발송'}
        content={
          <PhoneNumberModalContentContainer>
            {userInfoStore.phoneNumber}
            <TransmissionSpan> 로 문자 보냈어요.</TransmissionSpan>
          </PhoneNumberModalContentContainer>
        }
        buttonText={'확인'}
      />

      <PurpleOneButtonCheckModal
        isVisible={isCertErrorModal}
        onClose={() => {
          setIsCertErrorModal(false);
        }}
        title={'쿠폰 발송'}
        content={'쿠폰 발송 중 에러가 발생 했습니다.\n잠시 후 다시 이용해주세요.'}
        buttonText={'확인'}
      />
    </>
  );
});

export default PointMallStorageBoxList;
