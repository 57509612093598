import { styled } from '@stitches/react';

export const VerificationButton = styled('button', {
  padding: '0px',
  border: '2px solid #8B63BB',
  width: '100%',
  height: '46px',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: 'normal',
  borderRadius: '5px',
  cursor: 'pointer',
  marginTop: '20px',

  variants: {
    isVerificationActive: {
      true: {
        backgroundColor: '#fff',
        color: '#8B63BB',
      },
      false: {
        backgroundColor: '#8B63BB',
        color: '#fff',
      },
    },
    isFormValid: {
      true: {
        opacity: 1,
        pointerEvents: 'auto',
      },
      false: {
        opacity: 0.5,
        pointerEvents: 'none',
      },
    },
  },
});
