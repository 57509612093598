import React from 'react';
import {
  CloseIcon,
  NumberSpan,
  TitleSpan,
  IconContainer,
  ContentsSpan,
  DashContainer,
  TitleContentsContainer,
  NumberTittleContentsContainer,
  MinganParkingGuideModalContainer, BottomContainer, OperatingHoursContainer,
} from './styles';
import icGuideParking from '../../../../assets/ic/ic_guide_parking.svg';
import icGuideUser from '../../../../assets/ic/ic_guide_user.svg';
import icGuideCar from '../../../../assets/ic/ic_guide_car.svg';
import icGuideWallet from '../../../../assets/ic/ic_guide_wallet.svg';
import icCloseGray from '../../../../assets/ic/ic_close_gray.svg';

interface MinganParkingGuideBottomModalProps {
  isVisible: boolean;
  onClose: () => void;
}

const items = [
  {
    number: '1',
    title: '정기권 구매',
    content: '금액, 운영시간, 주차장 정보 검토 후 결제 바랍니다',
    icon: icGuideParking,
  },
  {
    number: '2',
    title: '승인 대기',
    content: '공유자의 승인 후 이용확정 카카오 알림톡이 올 때까지 기다려주세요.',
    icon: icGuideUser,
  },
  {
    number: '3',
    title: '주차장 이용',
    content: '유의사항을 꼭 확인 후 주차장 이용을 시작해주세요.',
    icon: icGuideCar,
  },
  {
    number: '4',
    title: '연장결제',
    content: '월정기권 연장은 알림 센터를 통해 이용기간 종료 전 결제해주세요.',
    icon: icGuideWallet,
  },
];

const MinganParkingGuideBottomModal: React.FC<MinganParkingGuideBottomModalProps> = ({
                                                                                       isVisible,
                                                                                       onClose,
                                                                                     }) => {
  if (!isVisible) return null;

  // 모달 배경 클릭 시 onClose 호출
  const handleBackgroundClick = () => {
    onClose();
  };

  // 모달 내용 클릭 시 클릭 이벤트 전파 방지
  const handleModalContentClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return (
    <>
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 9999,
      }}
      onClick={handleBackgroundClick}
    />
    <MinganParkingGuideModalContainer isVisible={isVisible} onClick={handleModalContentClick}>
      <div style={{ paddingTop: '25px', paddingRight: '25px', textAlign: 'right' }}>
        <CloseIcon src={icCloseGray} alt="닫기 아이콘" onClick={onClose} />
      </div>

      {items.map((item, index) => (
        <DashContainer key={index}>
          <NumberTittleContentsContainer>
            <NumberSpan>{item.number}</NumberSpan>
            <TitleContentsContainer>
              <TitleSpan>{item.title}</TitleSpan>
              <ContentsSpan>
                {item.content.split('\n').map((line, i) => (
                  <React.Fragment key={i}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </ContentsSpan>
            </TitleContentsContainer>
          </NumberTittleContentsContainer>
          <IconContainer>
            <img src={item.icon} alt={`${item.title} 아이콘`} />
          </IconContainer>
        </DashContainer>
      ))}

      <BottomContainer>
        <div>
          <span>고객센터</span>
          <span style={{
            color: '#8B63BB'
          }}>1666-4369</span>
        </div>
        <OperatingHoursContainer>
          평일 09시 ~ 18시
        </OperatingHoursContainer>
      </BottomContainer>
    </MinganParkingGuideModalContainer>
    </>
  )
}

export default MinganParkingGuideBottomModal;
