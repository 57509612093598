import React, { useEffect } from 'react';
import { useToast } from '../../../../components/atoms/Toast/ToastProvider';
import {
  GoodsContentsContainer,
  GoodsImageContainer,
  GoodsNameCompositionContainer,
  GoodsPriceBuyButtonContainer,
  BuyButton,
  GoodsItemContainer,
  GoodsImage,
  GoodsContentSpan,
  GoodsPriceSpan,
  PurchaseContainer,
  PurchaseCouponNameContainer,
} from './styles';
import { observer } from 'mobx-react';
import { useNavigate, useParams } from 'react-router-dom';
import { IBrandCoupon } from '../../../../models/Brand/brandModel';
import PurpleTwoButtonCheckModal from '../../../../components/atoms/Modal/PurpleTwoButtonCheckModal';
import icSuccessCheckGreen from '../../../../assets/ic/ic_success_check_green.svg';
import icFailCheckRed from '../../../../assets/ic/ic_fail_check_red.svg'
import { brandListStore } from '../../../../stores/store/Point/brandListStore';
import placeholderImage from '../../../../assets/ic/ic_not_bank.svg'
import LoadingSpinnerScreen from '../../../../components/atoms/LoadingSpinner/LoadingSpinnerScreen';
import { LINK_UID } from '../../../../constants/userInfoConstants';

const PointMallBranDetailList = observer(() => {
  const { showToast } = useToast()
  const navigate = useNavigate()
  const { brandName = 'defaultBrand' } = useParams<{ brandName?: string }>()
  const linkUID = parseInt(sessionStorage.getItem(LINK_UID) || '0', 10)

  const handleBuyClick = (coupon: IBrandCoupon) => {
    brandListStore.setSelectedGoods(coupon)
  }

  const handlePurchase = async () => {
    if (!brandListStore.selectedGoods) {
      showToast('구매할 쿠폰이 선택되지 않았습니다.', icFailCheckRed)
      return
    }

    try {
      const purchaseSuccess = await brandListStore.purchaseCoupon(linkUID, brandListStore.selectedGoods.couponCode)

      if (purchaseSuccess) {
        showToast('교환권 구매 완료했어요!\n쿠폰 보관함으로 이동합니다.', icSuccessCheckGreen)
        navigate('/pointMallStorageBox')
      } else {
        const errorMessage = brandListStore.error || '알 수 없는 오류가 발생했습니다.'
        showToast(errorMessage, icFailCheckRed)
      }
    } catch (error) {
      console.error('구매 처리 중 오류 발생:', error)
      const errorMessage = brandListStore.error || '알 수 없는 오류가 발생했습니다.'
      showToast(errorMessage, icFailCheckRed)
    }
  }

  useEffect(() => {
    const fetchLoadBrandCoupons = async () => {
      await brandListStore.fetchBrandCoupons(brandName)
    }
    fetchLoadBrandCoupons().catch((error) => {
      console.log('유저정보 가져오기 중 오류가 발생했습니다.', error)
    })
  }, [brandName, brandListStore])

  if (brandListStore.loading) {
    return <LoadingSpinnerScreen />
  }

  return (
    <>
      {brandListStore.brandCoupons.map((coupon) => (
        <GoodsItemContainer key={coupon.couponID}>
          <GoodsImageContainer>
            <GoodsImage
              src={coupon.couponImage}
              alt={`${coupon.couponImage} 이미지`}
              onError={(e) => e.currentTarget.src = placeholderImage}
            />
          </GoodsImageContainer>
          <GoodsContentsContainer>
            <GoodsNameCompositionContainer>
              <span>{coupon.couponBrand}</span>
              <GoodsContentSpan>{coupon.couponName}</GoodsContentSpan>
            </GoodsNameCompositionContainer>

            <GoodsPriceBuyButtonContainer>
              <GoodsPriceSpan>{coupon.couponPrice.toLocaleString()}</GoodsPriceSpan>
              <BuyButton onClick={() => handleBuyClick(coupon)}>구매</BuyButton>
            </GoodsPriceBuyButtonContainer>
          </GoodsContentsContainer>
        </GoodsItemContainer>
      ))}
      {brandListStore.selectedGoods && (
        <PurpleTwoButtonCheckModal
          isVisible={true}
          onClose={() => brandListStore.setSelectedGoods(null)}
          title={'해당 교환권을 구매하시겠어요?'}
          content={
            <PurchaseContainer>
              <div>
                <span>{brandListStore.selectedGoods.couponPrice}원이 사용됩니다.</span>
              </div>
              <PurchaseCouponNameContainer>
                <span style={{ fontWeight: 500 }}>{brandListStore.selectedGoods.couponName}</span>
              </PurchaseCouponNameContainer>
            </PurchaseContainer>
          }
          leftButtonText={'취소'}
          rightButtonText={'구매하기'}
          rightClick={handlePurchase}
        />
      )}
    </>
  )
})

export default PointMallBranDetailList;
