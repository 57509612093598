import React, { useState, useCallback } from 'react';
import icArrowRightGrayMedium from '../../../assets/ic/ic_arrow_right_gray_medium.svg';
import icNaviKakao from '../../../assets/ic/ic_navi_kakao.png';
import icNaviNaver from '../../../assets/ic/ic_navi_naver.png';
import icNaviApple from '../../../assets/ic/ic_navi_apple.png';
import { isIOS } from 'react-device-detect';
import {
  ArrowIcon,
  ContentContainer,
  SettingRowContainer,
  SettingServiceContainer,
  SettingServiceSubTitleSpan,
  SettingServiceTitleSpan,
  ToggleButton,
  ToggleContainer
} from './styles';
import CheckModal from '../../../components/atoms/Modal/CheckModal';
import { ItemContainer, ItemText, ModalContentContainer, SkipSelectionText } from 'pages/Setting/SettingMainPage/styles';
import { useToast } from 'components/atoms/Toast/ToastProvider';
import { getFormattedDate } from 'utils/dateUtils';

const SettingServiceList = () => {
  const settingItems = [
    {
      textTitle: '주차장 이용 정보 안내',
      subTitle: '주차장 이용내역 및 정보를 알림으로 보내 드립니다.',
      onClick: () => console.log('1')
    },
    {
      textTitle: '주변 주차장 정보 안내',
      subTitle: '희망지역 인근의 주차장 정보를 제공합니다.',
      onClick: () => console.log('2')
    },
    {
      textTitle: '마케팅 정보 수신 동의',
      subTitle: '다양한 이벤트 정보를 드립니다.',
      onClick: () => console.log('3')
    },
    {
      textTitle: '내비게이션 설정',
      subTitle: '',
      imgArrowLeft: icArrowRightGrayMedium,
      onClick: () => navigateModal()
    }
  ];

  const [isNavigateModal, setIsNavigateModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [toggledStates, setToggledStates] = useState<boolean[]>(settingItems.map(() => false)); // 상태 배열 생성

  const { showToast } = useToast();

  const handleToggle = (index: number) => {
    setToggledStates((prevStates) => {
      const newStates: boolean[] = [...prevStates];
      newStates[index] = !newStates[index];

      // 토글 상태 변경 시 토스트 메시지 표시
      const formattedDate: string = getFormattedDate();
      const message: string = `${formattedDate} ${settingItems[index].textTitle} ${newStates[index] ? '동의 처리' : '미동의 처리'}되었습니다.`;
      showToast(message); // 토스트 메시지 표시

      return newStates;
    });
  };

  const navigateModal = () => {
    setIsNavigateModal(true);
  };

  const handleItemClick = (index: number) => {
    setSelectedIndex(index);
  };

  const mapItems = [
    { src: icNaviKakao, text: '카카오내비' },
    { src: icNaviNaver, text: '네이버 지도' },
    ...(isIOS ? [{ src: icNaviApple, text: '애플 지도' }] : []), // iOS에서만 애플 지도를 표시
  ];

  return (
      <>
        <div>
          <SettingServiceContainer>서비스 설정</SettingServiceContainer>
          <div style={{ flexGrow: 1, overflowY: 'auto', marginTop: '13px' }}>
            {settingItems.map((item, index) => {
              const { textTitle, subTitle, imgArrowLeft, onClick } = item;
              const shouldShowArrow = textTitle === '내비게이션 설정';
              const isToggled = toggledStates[index] || false;

              return (
                  <SettingRowContainer
                      key={index}
                      cursor={shouldShowArrow ? 'pointer' : 'default'}
                      onClick={shouldShowArrow ? onClick : undefined}
                      style={{ marginBottom: '13px' }} // 항목 사이에 16px의 마진 추가
                  >
                    <ContentContainer>
                      <SettingServiceTitleSpan>{textTitle}</SettingServiceTitleSpan>
                      <SettingServiceSubTitleSpan style={{
                        color: '#CCCCCC'
                      }}>{subTitle}</SettingServiceSubTitleSpan>
                    </ContentContainer>
                    {shouldShowArrow ? (
                        <ArrowIcon src={imgArrowLeft} alt="애로우 아이콘" />
                    ) : (
                        <ToggleContainer
                            onClick={() => handleToggle(index)}
                            style={{ backgroundColor: isToggled ? '#8B63BB' : '#ccc' }}
                        >
                          <ToggleButton style={{ left: isToggled ? '20px' : '1px' }} />
                        </ToggleContainer>
                    )}
                  </SettingRowContainer>
              );
            })}
          </div>
        </div>

        <CheckModal
            isVisible={isNavigateModal}
            onClose={() => setIsNavigateModal(false)}
            title="기본으로 설정할 앱을 선택해 주세요."
            content={
              <ModalContentContainer>
                {mapItems.map((item, index) => (
                    <ItemContainer
                        key={index}
                        isActive={selectedIndex === index}
                        onClick={() => handleItemClick(index)}
                    >
                      <img
                          style={{
                            width: '45px',
                            height: '45px',
                            marginLeft: '25px',
                          }}
                          src={item.src}
                          alt={item.text}
                      />
                      <ItemText>{item.text}</ItemText>
                    </ItemContainer>
                ))}
                <SkipSelectionText onClick={() => setIsNavigateModal(false)}>
                  기본 내비게이션 선택 안함
                </SkipSelectionText>
              </ModalContentContainer>
            }
            buttonText="확인"
        />
      </>
  );
};

export default SettingServiceList;
