import React from 'react';
import {
  CheckButton,
  ContentsContainer,
  ContentsMarginLeftSpan,
  DotIc,
  FirstContentContainer,
  FirstLimitContentContainer,
  FirstTitleSpan,
  MainColorSpan,
  SecondContentContainer,
  SecondLimitContentContainer,
  TitleSpan,
  WithdrawContentsTitleContainer,
  WithdrawModalContainer,
  WithdrawModalOverlay,
} from './styles';

import icDotGray from '../../../../assets/ic/ic_dot_gray.svg';

interface WithdrawBottomModalProps {
  isVisible: boolean;
  onClose: () => void;
}

const WithdrawBottomModal: React.FC<WithdrawBottomModalProps> = ({ isVisible, onClose }) => {
  const handleOverlayClick = () => {
    onClose();
  };

  const handleModalContentClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  if (!isVisible) return null;

  return (
    <>
      <WithdrawModalOverlay onClick={handleOverlayClick} />
      <WithdrawModalContainer isVisible={isVisible} onClick={handleOverlayClick}>
        <WithdrawContentsTitleContainer onClick={handleModalContentClick}>
          <FirstTitleSpan>출금 가능 금액이란?</FirstTitleSpan>
          <ContentsContainer>
            <FirstContentContainer>
              <DotIc src={icDotGray} alt={'회색 점 아이콘'} />
              <ContentsMarginLeftSpan>
                주차 공간을 제공하여 얻은 정산 받은 수익금을 의미해요.
              </ContentsMarginLeftSpan>
            </FirstContentContainer>

            <SecondContentContainer>
              <DotIc src={icDotGray} alt={'회색 점 아이콘'} />
              <ContentsMarginLeftSpan>
                해당 금액은 포인트 몰에서도 현금처럼 사용 가능해요.
              </ContentsMarginLeftSpan>
            </SecondContentContainer>

            <TitleSpan>출금 안내</TitleSpan>

            <FirstContentContainer>
              <DotIc src={icDotGray} alt={'회색 점 아이콘'} />
              {/* 여백 조정 */}
              <ContentsMarginLeftSpan>
                출금 신청 시 영업일 기준 1일 내 등록하신 계좌로 입금됩니다.
              </ContentsMarginLeftSpan>
            </FirstContentContainer>

            <SecondContentContainer>
              <DotIc src={icDotGray} alt={'회색 점 아이콘'} />
              <ContentsMarginLeftSpan>
                1회 100만원 이상 출금 시 담당자 승인 후 출금 됩니다.
              </ContentsMarginLeftSpan>
            </SecondContentContainer>

            <TitleSpan>출금 한도</TitleSpan>
            <div>
              <FirstLimitContentContainer>
                <span>1회 출금한도</span>

                <MainColorSpan>300만원</MainColorSpan>
              </FirstLimitContentContainer>
              <SecondLimitContentContainer>
                <span>월 출금한도</span>
                <MainColorSpan>3,000만원</MainColorSpan>
              </SecondLimitContentContainer>
            </div>
          </ContentsContainer>
        </WithdrawContentsTitleContainer>
        <CheckButton onClick={handleOverlayClick}>확인</CheckButton>
      </WithdrawModalContainer>
    </>
  );
};

export default WithdrawBottomModal;