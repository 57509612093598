import React, { useEffect, useRef, useState } from 'react';
import Title from '../../Title/PageTitle';
import Tab from '../../TabBar';
import MinganParkingGuideBottomModal from '../MinganParkingGuideBottomModal';
import MinganParkingInfoBottomModal from '../MinganParkingInfoBottomModal';
import TwoButtonModal from '../TwoButtonModal';
import DateChoiceBottomModal from '../DateChoiceBottomModal';
import { Dialog } from './styles';
import MonthParkingInfoDetail from './MonthParkingInfoDetail';

interface MinganInfoDetailProps {
  isVisible: boolean;
  onClose: () => void;
}

const MonthParkingInfoDetailBottomModal: React.FC<MinganInfoDetailProps> = ({
                                                                              isVisible,
                                                                              onClose,
                                                                            }) => {
  const monthParkingInfoDetailTab = MonthParkingInfoDetail();
  const [isMinganParkingGuideBottomModal, setIsMinganParkingGuideBottomModal] = useState(false);
  const [isMinganParkingInfoBottomModal, setIsMinganParkingInfoBottomModal] = useState(false);
  const parkingRef = useRef<HTMLDivElement>(null);
  const cautionRef = useRef<HTMLDivElement>(null);
  const [currentTab, setCurrentTab] = useState(0);

  const handleTabClick = (index: number) => {
    setCurrentTab(index);
    if (index === 0) {
      parkingRef.current?.scrollIntoView({ behavior: 'smooth' });
    } else {
      cautionRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          if (entry.target === parkingRef.current) {
            setCurrentTab(0);
          } else if (entry.target === cautionRef.current) {
            setCurrentTab(1);
          }
        }
      });
    }, {
      threshold: 0.5, // 10% 가시성 기준으로 설정
    });

    // Ensure the refs are valid before observing
    if (parkingRef.current) {
      observer.observe(parkingRef.current);
    }
    if (cautionRef.current) {
      observer.observe(cautionRef.current);
    }

    return () => {
      if (parkingRef.current) {
        observer.unobserve(parkingRef.current);
      }
      if (cautionRef.current) {
        observer.unobserve(cautionRef.current);
      }
    };
  }, []);

  return (
    <Dialog isVisible={isVisible}>
      <Title
        title="상세정보"
        leftArrowButtonVisible={true}
        leftOnClick={onClose}
        rightTextButtonVisible={true}
        rightOnClick={() => setIsMinganParkingGuideBottomModal(true)}
        rightTitle={'이용방법'}
      />
      <Tab
        menuArr={monthParkingInfoDetailTab}
        currentTab={currentTab}
        onTabClick={handleTabClick}
      />

      <div style={{ height: 'auto', overflowY: 'auto', marginBottom: '86px' }}>
        <div ref={parkingRef}>
          {monthParkingInfoDetailTab[0].content}
        </div>
        <div ref={cautionRef}>
          {monthParkingInfoDetailTab[1].content}
        </div>
      </div>

      <MinganParkingGuideBottomModal
        isVisible={isMinganParkingGuideBottomModal}
        onClose={() => setIsMinganParkingGuideBottomModal(false)}
      />

      <MinganParkingInfoBottomModal
        isVisible={isMinganParkingInfoBottomModal}
        onClose={() => setIsMinganParkingInfoBottomModal(false)}
      />
    </Dialog>
  );
};

export default MonthParkingInfoDetailBottomModal;
