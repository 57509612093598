import { format } from 'date-fns'; // date-fns 라이브러리를 사용하는 경우

/**
 * 현재 날짜를 YYYY년 MM월 DD일 형식으로 포맷팅하는 함수
 * @returns {string} 포맷된 날짜 문자열
 */
export const getFormattedDate = (): string => {
  const today: Date = new Date()
  const year: number = today.getFullYear()
  const month: string = String(today.getMonth() + 1).padStart(2, '0')
  const day: string = String(today.getDate()).padStart(2, '0')

  return `${year}년 ${month}월 ${day}일`
}

export function getFormattedDateHyphen(date?: Date): string {
  const d = date || new Date()
  return format(d, 'yyyy-MM-dd')
}