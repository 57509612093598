import { styled } from '@stitches/react';

export const TicketContainer = styled('div', {
  background:
    'linear-gradient(132deg, rgba(187, 138, 244, 0.10) 4.19%, rgba(119, 73, 174, 0.10) 100%)',
  borderRadius: '8px',
  padding: '16px',
  display: 'flex',
  flexDirection: 'column',
  margin: '0 20px',
})

export const TitleContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  color: '#8B63BB',
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal'
})

export const PointEarnExpectedContainer = styled('div', {
  '@media (max-width: 812px) and (min-width: 375px) and (-webkit-device-pixel-ratio: 3)': {
    paddingTop: '30px',
  },
  '@media (max-width: 896px) and (min-width: 414px) and (-webkit-device-pixel-ratio: 3)': {
    paddingTop: '30px',
  },
  '@media (max-width: 2796px) and (min-width: 414px) and (-webkit-device-pixel-ratio: 3)': {
    paddingTop: '30px',
  }
})

export const ExpectedMoneySpan = styled('span', {
  fontSize: '18px',
  fontWeight: '700'
})

export const ViewDivideContainer1 = styled('div', {
  width: '100%',
  height: '1px',
  backgroundColor: '#B799DB',
  marginTop: '20px'
})

export const MoneyContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginTop: '16px',
  color: '#8B63BB',
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal'
})

export const PointContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginTop: '8px',
  color: '#8B63BB',
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal'
})

export const ViewDivideContainer2 = styled('div', {
  width: '100%',
  height: '8px',
  backgroundColor: '#F2F2F2',
  marginTop: '28px'
})