export interface ErrorData<T = any> {
  status: number;
  errorMessage?: string;
  errorType?: string;
  response?: T;
}

export default class BaseError<T = any> extends Error {
  status: number;
  errorMessage: string;
  errorType: string;
  response: T | undefined;

  constructor(errorData: ErrorData) {
    super(errorData.errorMessage);

    this.status = errorData.status;
    this.errorMessage = errorData.errorMessage || '';
    this.errorType = errorData.errorType || '';
    this.response = errorData.response||'';

    Object.setPrototypeOf(this, BaseError.prototype);
  }
}