import { styled } from '@stitches/react';

export const DeleteAccountTitleContainer = styled('div', {
  marginBottom: '34px',
  textAlign: 'center',
  fontFamily: 'Pretendard',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  color: '#8B63BB',
});