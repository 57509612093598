import { styled } from '@stitches/react'
import icCheckWhite from 'assets/ic/ic_check_white.svg'
import icCheckGray from 'assets/ic/ic_check_gray.svg'
import { colors } from 'styles/colors';

export const CarNumberInputContainer = styled('div', {
  width: '100%',
  height: '76px',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '10px',
  color: '#6B6B6B',
  border: '1px solid #d3d3d3',
  marginTop: '16px',

  variants: {
    isFocus: {
      true: {
        borderColor: '#8B63BB',
        backgroundColor: '#ffffff',
      },
      false: {
        border: 'none',
        backgroundColor: '#F8F8FA'
      },
    },
  },
})

export const CarNumberText = styled('span', {
  color: colors.primary,
  fontFamily: 'Pretendard',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '20px',
  margin: '13px 15px 7px'
})

export const CarNumberInput = styled('input', {
  border: '0px',
  outline: 'none',
  fontWeight: 500,
  marginLeft: '15px',
  fontSize: '18px',
  backgroundColor: '#F8F8FA',
  color: '#868686',
  '&::placeholder': {
    color: '#B0B0B0',
    fontSize: '17px',
    fontWeight: 400,
  },



  variants: {
    hasText: {
      true: {
        color: '#333',
      },
    },

    isFocus: {
      true: {
        borderColor: '#8B63BB',
        backgroundColor: '#ffffff',
      },
      false: {
        border: 'none',
        backgroundColor: '#F8F8FA'
      },
    },
  },
})

export const CarNumberErrorContainer = styled('div', {
  width: '100%',
  marginTop: '8px',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal'
})

export const RequiredText = styled('span', {
  color: '#FF0000',
  marginRight: '4px',
})

export const BottomButtonContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: '10px',
  margin: '0 20px 40px',
  width: 'calc(100% - 40px)',
  textAlign: 'center',
})

export const AgreeButton = styled('button', {
  flex: 1,
  width: '100%',
  height: '46px',
  fontSize: '14px',
  fontFamily: 'Pretendard',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  color: '#FFFFFF',
  border: 'none',
  backgroundColor: '#868686',
  borderRadius: '4px',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  cursor: 'none',

  variants: {
    state: {
      agreeCheck: {
        backgroundColor: '#8B63BB', // 선택된 상태일 때 배경색
      },
      notCheck: {
        backgroundColor: '#868686', // 비활성화된 상태일 때 배경색
      },
    },
  }
})

export const StyledCheckbox = styled('input', {
  appearance: 'none',
  width: '24px',
  height: '24px',
  border: '2px solid #e4e4e4',
  borderRadius: '4px',
  outline: 'none',
  backgroundImage: `url(${icCheckGray})`,
  backgroundSize: '12px 12px',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  marginRight: '8px',
  marginLeft: '0px',
  '&:checked': {
    backgroundColor: '#514263',
    borderColor: '#514263',
    backgroundImage: `url(${icCheckWhite})`,
    backgroundSize: '12px 12px',
    backgroundPositiPon: 'center',
    backgroundRepeat: 'no-repeat',
  },
})

export const PolicyPersonalContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '0px 20px 28px 20px',
  flexWrap: 'wrap',
})

export const PolicyPersonalCheckBoxContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',

})

export const PolicyPersonalClickSpan = styled('span', {
  color: '#B0B0B0',
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
})

export const CautionTitleContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  color: '#555',
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal'
})

export const ExclamationIcon = styled('img', {
  marginRight: '8px',
  alignSelf: 'flex-start',
  width: '18px',
  height: '18px',
})

