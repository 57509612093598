import { useNavigate } from 'react-router-dom'
import Title from '../../../components/atoms/Title/PageTitle'
import PointLoseExpectedList from '../PointLoseExpectedList'
import React, { useEffect } from 'react'
import icExclamationBlack from '../../../assets/ic/ic_exclamation_black.svg'
import {
  ViewDivideContainer1,
  ExpectedMoneySpan,
  MoneyContainer,
  PointContainer,
  TicketContainer,
  TitleContainer,
  ViewDivideContainer2, CautionContainer, CautionIcon, CautionSpan, PointLoseExpectedContainer,
} from './styles';
import { observer } from 'mobx-react'
import { pointExpiresStore } from '../../../stores/store/Point/pointExpiresStore'
import { LINK_UID } from '../../../constants/userInfoConstants'

const PointLoseExpected = observer(() => {
  const navigate = useNavigate()

  const handleLeftOnClick = () => {
    navigate(-1)
  }

  useEffect(() => {
    const fetchPointExpires = async () => {
      const linkUID = parseInt(sessionStorage.getItem(LINK_UID) || '0', 10)
      await pointExpiresStore.fetchPointExpires(linkUID)
    }
    fetchPointExpires().catch((error) => {
      console.log('유저정보 가져오기 중 오류가 발생했습니다.', error)
    })
  }, [])

  useEffect(() => {
    window.onBtnBackClicked = function() {
      handleLeftOnClick()
    };
    return () => {
      delete window.onBtnBackClicked
    }
  }, [])

  return (
    <>
      <Title
        title="소멸 예정 내역"
        leftArrowButtonVisible={true}
        leftOnClick={handleLeftOnClick}
        rightTextButtonVisible={false}
      />

      <PointLoseExpectedContainer>
        <TicketContainer>
          <TitleContainer>
            <span>30일 이내 소멸 예정 금액</span>
            <ExpectedMoneySpan>{pointExpiresStore.expiresSum}
            </ExpectedMoneySpan>
          </TitleContainer>
          <ViewDivideContainer1 />
          <MoneyContainer>
            <span>ㄴ 금액</span>{pointExpiresStore.expiresPays}
          </MoneyContainer>
          <PointContainer>
            <span>ㄴ 포인트</span>
            <span>{pointExpiresStore.expiresPoints}</span>
          </PointContainer>
        </TicketContainer>
      </PointLoseExpectedContainer>
      <CautionContainer>
        <CautionIcon src={icExclamationBlack} alt={'느낌표 검정 아이콘'}/>
        <CautionSpan>
          30일 이후에는 소멸됩니다. 소멸된 포인트는 복구
          할 수 없습니다.
        </CautionSpan>
      </CautionContainer>
      <ViewDivideContainer2 />
      <PointLoseExpectedList />
    </>
  )
})

export default PointLoseExpected
