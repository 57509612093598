import { styled } from '@stitches/react';

// Dialog - 다이얼로그
export const Dialog = styled('div', {
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  height: '100%', // 다이얼로그의 높이 조절
  background: '#fff',
  boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.2)',
  transition: 'transform 0.3s ease-in-out',
  display: 'flex',
  flexDirection: 'column',
  padding: '16px',
  boxSizing: 'border-box',
  zIndex: 10000,
  variants: {
    isVisible: {
      true: { transform: 'translateY(0)' },
      false: { transform: 'translateY(100%)' },
    },
  },
});

// CloseButton - 다이얼로그 닫기 버튼
export const CloseButton = styled('button', {
  background: 'none',
  border: 'none',
  fontSize: '20px',
  cursor: 'pointer',
  alignSelf: 'flex-end',
});

// Content - 다이얼로그의 내용 영역
export const Content = styled('div', {
  flex: 1,
  overflowY: 'auto',
});
