import React, { useState } from 'react';
import {
  ModalContainer,
  ModalOverlay,
  ConsultButtonWrapper,
  ConsultButtonContainer,
  MinganPlacePhotoWeekContainer,
  PhotoSizeContainer,
  MinganPlaceWeekContainer,
  PlaceSpan,
  WeekSpan,
  WeekEmphasisSpan,
  MinganParkingKindContainer,
  ShareContainer,
  ZoomansaIc,
  MinganKindListContainer,
  MinganListSpan,
  AmountContainer,
  DiscountText, DiscountPercentText, DiscountAmountText,
  Bubble, CarKind,
} from './styles';
import icQuestion from '../../../../assets/ic/ic_question.svg';
import icZoomansaLogo from '../../../../assets/ic/ic_zoomansa_logo.svg';
import TwoButtonModal from '../../../../components/atoms/Modal/TwoButtonModal';
import icCalendarGray from '../../../../assets/ic/ic_calendar_gray.svg';
import { getFormattedDateHyphen } from '../../../../utils/dateUtils';
import CustomCalendar from '../../CustomCalendar';
import MonthParkingInfoDetailBottomModal from '../MonthParkingInfoDetailBottomModal';

interface MinganInfoModalProps {
  isVisible: boolean;
  onClose: () => void;
}

const MinganInfoModal: React.FC<MinganInfoModalProps> = ({ isVisible, onClose }) => {
  const [isCounselingModalVisible, setIsCounselingModalVisible] = useState(false);
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [isMinganInfoDetailBottomModal, setIsMinganInfoDetailBottomModal] = useState(false);

  // Adjust date formatting
  const formattedDate = selectedDate ? getFormattedDateHyphen(selectedDate) : getFormattedDateHyphen();

  const handleCalendarClose = () => {
    setIsCalendarVisible(false);
  };

  const handleDateSelect = (date: Date) => {
    setSelectedDate(date);
    setIsCalendarVisible(false);
  };

  if (!isVisible) return null;

  return (
    <>
      <ModalOverlay onClick={onClose}>
        <ModalContainer onClick={(e) => e.stopPropagation()}>
          <ConsultButtonWrapper>
            <ConsultButtonContainer
              onClick={() => setIsCounselingModalVisible(true)}
            >
              <img src={icQuestion} alt={'질문 아이콘'} />
              상담 신청
            </ConsultButtonContainer>
          </ConsultButtonWrapper>

          <div>
            <MinganPlacePhotoWeekContainer onClick={() => {
              setIsMinganInfoDetailBottomModal(true)
            }}>
              <MinganPlaceWeekContainer>
                <PlaceSpan>영등포아트자이 아파트</PlaceSpan>
                <WeekSpan>
                  <WeekEmphasisSpan>평일 00:00 ~ 24:00 | </WeekEmphasisSpan>
                  <WeekEmphasisSpan>주말 00:00 ~ 24:00</WeekEmphasisSpan>
                </WeekSpan>
              </MinganPlaceWeekContainer>
              <PhotoSizeContainer />
            </MinganPlacePhotoWeekContainer>

            <MinganParkingKindContainer>
              <ShareContainer>
                <ZoomansaIc src={icZoomansaLogo} alt="주만사 아이콘" /> 공유
              </ShareContainer>
              <MinganKindListContainer>
                <MinganListSpan>지정석</MinganListSpan>
              </MinganKindListContainer>
            </MinganParkingKindContainer>
            <AmountContainer>
              <DiscountText style={{ flex: 1 }}>142,600원</DiscountText>
              <div style={{ flex: 1, textAlign: 'right' }}>
                <DiscountPercentText>15%</DiscountPercentText>
                <DiscountAmountText>124,000원</DiscountAmountText>
              </div>
            </AmountContainer>
          </div>
        </ModalContainer>
      </ModalOverlay>

      <TwoButtonModal
        isVisible={isCounselingModalVisible}
        onClose={() => {
          setIsCounselingModalVisible(false);
        }}
        title={'상담 신청'}
        content={
          <div>
            <div
              style={{
                color: '#7D7D7D',
                textAlign: 'center',
                fontFamily: 'Pretendard',
                fontSize: '13px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
              }}
            >
              <span>아래 신청서를 작성해 주시면</span>
              <span style={{ color: '#FC0C0C' }}>평일09시~18시</span>에<br />
              <span style={{ color: '#FC0C0C' }}>안내</span>를 드리도록 하겠습니다.
              <br />
              신청은 모두 선청순으로 진행 되는 점 참고 부탁 드립나다.
              <div
                style={{
                  borderTop: '1px dashed #8A8A8A',
                  margin: '20px 24px',
                }}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  margin: '0px 25px',
                }}
              >
                <span>주차장 번호</span>
                <span>1725번 주차장</span>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  textAlign: 'center',
                  alignItems: 'center',
                  margin: '10px 25px 0px 25px',
                }}
              >
                <span>차종</span>
                <CarKind placeholder="ex.아반떼, 소나타"></CarKind>
              </div>
              {isCalendarVisible && (
                <Bubble>
                  <CustomCalendar
                    onClose={handleCalendarClose}
                    onDateSelect={handleDateSelect}
                  />
                </Bubble>
              )}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  textAlign: 'center',
                  alignItems: 'center',
                  margin: '10px 25px 0px 25px',
                }}
              >
                <span>희망 시작일</span>
                <div
                  style={{
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '5px',
                    border: '1px solid #dbdbdb',
                    width: '174px',
                    outline: 'none',
                    fontWeight: 400,
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    lineHeight: 'normal',
                    color: '#868686',
                    height: '35px',
                    borderRadius: '5px',
                    marginTop: '4px',
                    justifyContent: 'space-between',
                  }}
                >
                  <span style={{ textAlign: 'left' }}>{formattedDate}</span>
                  <img
                    onClick={() => setIsCalendarVisible(!isCalendarVisible)}
                    src={icCalendarGray}
                    alt={'달력 아이콘'}
                    style={{ cursor: 'pointer' }}
                  />
                </div>
              </div>
              <div
                style={{
                  color: '#B0B0B0',
                  textAlign: 'right',
                  marginTop: '2px',
                  marginRight: '25px',
                  fontFamily: 'Pretendard',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '16px',
                }}
              >
                <span>희망하시는 주차 시작일을 선택해 주세요.</span>
              </div>
            </div>
          </div>
        }
        leftButtonText={'취소'}
        rightButtonText={'확인'}
        rightClick={() => {
          alert('');
        }}
      />

      <MonthParkingInfoDetailBottomModal
        isVisible={isMinganInfoDetailBottomModal}
        onClose={() => setIsMinganInfoDetailBottomModal(false)}
      />
    </>
  );
};

export default MinganInfoModal;
