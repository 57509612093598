import BaseService from '../baseService';
import BaseError from '../../models/baseError';
import AxiosClient from '../../utils/axiosClient';
import ErrorHandler from '../../utils/error/errorHandler';
import { IPointExpires, IPointExpiresResponse } from '../../models/Point/pointExpiresModel';

interface PointExpiresEndPoint {
  POINT_EXPIRES: string;
}

class PointExpiresService extends BaseService<PointExpiresEndPoint> {
  apiEndpoints: PointExpiresEndPoint = {
    POINT_EXPIRES: 'getExpiresPoint',
  }

  postPointExpires = async (
    requestBody: IPointExpires,
  ): Promise<IPointExpiresResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .data({ ...requestBody })
        .url(`${this.apiEndpoints.POINT_EXPIRES}`)
        .build<IPointExpiresResponse>();
      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.error(error)
      return ErrorHandler.create(error).getError()
    }
  }
}

export default new PointExpiresService()