import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import RegionSelectBottomModal from '../../components/atoms/Modal/RegionSelectBottomModal';
import CheckModal from "../../components/atoms/Modal/CheckModal";
import Title from '../../components/atoms/Title/PageTitle';
import icArrowRightGraySmall from "../../assets/ic/ic_arrow_right_gray_small.svg";
import {
  ShareRequestGeojuMainContainer,
  GeojuInput,
  ContactInfo,
  PhoneNumber,
  PhoneNumberContainer,
  PhoneNumberHeader,
  CheckBoxLabel,
  ShareRequestGeojuEssentialText,
  ShareRequestGeojuRequestText,
  StyledCheckbox
} from '../ShareRequestGeoju/styles';

interface IFormInputs {
  vehicleNumber: string;
  compartmentNumber: string;
  region: string;
}

const ShareRequestGeoju: React.FC = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, watch, setValue } = useForm<IFormInputs>();

  const [isRegionSelectBottomModalVisible, setIsRegionSelectBottomModalVisible] = React.useState(false);
  const [isApplyCheckModal, setIsApplyCheckModal] = React.useState(false);

  const vehicleNumber = watch('vehicleNumber', '');
  const compartmentNumber = watch('compartmentNumber', '');
  const region = watch('region', '');

  const [isCheckedAll, setIsCheckedAll] = React.useState(false);
  const [checkboxes, setCheckboxes] = React.useState({
    serviceTerms: false,
    privacyPolicy: false,
    locationBased: false,
    marketing: false,
  });

  // Check if the button should be disabled
  const isButtonDisabled = !(vehicleNumber && compartmentNumber && region && checkboxes.privacyPolicy);

  // Determine button style based on disabled state
  const buttonStyle = {
    padding: '0px',
    border: '0px',
    width: '100%',
    height: '46px',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: 'normal',
    borderRadius: '5px',
    backgroundColor: isButtonDisabled ? '#d3d3d3' : '#8B63BB', // Gray if disabled, purple if enabled
    color: isButtonDisabled ? '#a9a9a9' : '#fff', // Gray text if disabled, white text if enabled
    cursor: isButtonDisabled ? 'not-allowed' : 'pointer', // Pointer cursor if enabled, not-allowed if disabled
  };

  const onSubmit: SubmitHandler<IFormInputs> = (data) => {
    console.log(data);
    // setIsApplyCheckModal(true)
    navigate(-1)
  };

  const handleLeftOnClick = () => {
    navigate(-1);
  };

  const handleSelectRegion = (selectedRegion: string) => {
    setValue('region', selectedRegion); // Set the selected region in the form
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, key: keyof typeof checkboxes) => {
    const { checked } = event.target;
    setCheckboxes(prev => {
      const updatedCheckboxes = {
        ...prev,
        [key]: checked,
      };

      const allChecked = Object.values(updatedCheckboxes).every(value => value);
      setIsCheckedAll(allChecked);

      return updatedCheckboxes;
    });
  };

  return (
      <>
        <ShareRequestGeojuMainContainer>
          <Title
              title="거주자 우선 주차장 신청"
              leftArrowButtonVisible
              leftOnClick={handleLeftOnClick}
              rightTextButtonVisible={false}
          />
          <PhoneNumberContainer>
            <PhoneNumberHeader>
              <div>휴대 전화번호</div>
              <PhoneNumber>010-1234-5678</PhoneNumber>
            </PhoneNumberHeader>
            <ContactInfo>
              위 연락처로 신청 관련 안내가 진행됩니다.
            </ContactInfo>
            <div style={{ marginTop: '40px' }}>
              <div>
                차량번호
                <GeojuInput
                    type="text"
                    id="vehicleNumber"
                    placeholder="배정 받으신 차량번호를 입력"
                    {...register('vehicleNumber', { required: '필수 입력 항목입니다.' })}
                />
              </div>
              <div style={{ marginTop: '20px' }}>
                구획번호
                <GeojuInput
                    type="text"
                    id="compartmentNumber"
                    placeholder="‘-’를 포함 한 배정 받으신 전체 구획번호를 입력"
                    {...register('compartmentNumber', { required: '필수 입력 항목입니다.' })}
                />
              </div>
              <div style={{ marginTop: '20px' }}>
                지역 (자치구)
                <div
                    style={{ marginTop: '20px' }}
                    onClick={() => setIsRegionSelectBottomModalVisible(true)}
                >
                  <GeojuInput
                      type="text"
                      id="region"
                      placeholder="지역 선택"
                      value={region} // Use the region value from the form
                      readOnly
                      {...register('region', { required: '필수 입력 항목입니다.' })}
                  />
                </div>
              </div>
            </div>
          </PhoneNumberContainer>

          <div style={{ marginTop: 'auto', width: '100%', marginBottom: '40px' }}>
            <CheckBoxLabel style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <StyledCheckbox
                    type="checkbox"
                    checked={checkboxes.privacyPolicy}
                    onChange={(e) => handleChange(e, 'privacyPolicy')}
                />
                <ShareRequestGeojuEssentialText>(필수)</ShareRequestGeojuEssentialText>
                <ShareRequestGeojuRequestText> 개인정보 수집 및 이용동의</ShareRequestGeojuRequestText>
              </div>
              <div
                  style={{ flexShrink: 0 }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
              >
                <img src={icArrowRightGraySmall} alt="arrow.png" onClick={() => {
                  navigate('/personalPolicy');
                }} />
              </div>
            </CheckBoxLabel>
            <button
                style={buttonStyle}
                onClick={handleSubmit(onSubmit)}
                disabled={isButtonDisabled} // Disable button based on isButtonDisabled
            >
              약관 동의 및 공유 신청
            </button>
          </div>
        </ShareRequestGeojuMainContainer>

        {/* RegionSelectBottomModal 컴포넌트 사용 */}
        <RegionSelectBottomModal
            isVisible={isRegionSelectBottomModalVisible}
            onClose={() => setIsRegionSelectBottomModalVisible(false)}
            onSelectRegion={handleSelectRegion}
        />

        <CheckModal
            isVisible={isApplyCheckModal}
            onClose={() =>
                setIsApplyCheckModal(false)}
            title={'공유 신청이 완료되었습니다.'}
            content={
              <div>
                <div style={{
                  textAlign: 'center',
                  fontFamily: 'Pretendard',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                  color: '#868686'
                }}>영업시간(평일 09시 ~ 18시)내 <br/>상담드리겠습니다.</div>
                <div style={{
                  marginTop: '22px',
                  fontFamily: 'Pretendard',
                  fontSize: '13px',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  color: '#8B63BB',
                  lineHeight: 'normal'}}>빠른 상담
                </div>
                <button
                  style={{
                    width: '224px',
                    height: '44px',
                    marginTop: '5px',
                    color: '#8B63BB',
                    textAlign: 'center',
                    fontFamily: 'Pretendard', fontSize: '14px',
                    fontStyle: 'normal', lineHeight: 'normal',
                    background: '#fff',
                    borderRadius: '5px', borderColor: '#8B63BB', borderWidth: '1px',
                    borderStyle: 'solid'
                }}
                  onClick={() => alert('전화로 연결됨')}>고객센터 1666-6248</button>
              </div>
            }
            buttonText={'확인'} />
      </>
  );
};

export default ShareRequestGeoju;
