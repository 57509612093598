import { styled } from '@stitches/react';

export const MyPageUserInfoContainer = styled('div', {
  width: '100%',
  height: '100px',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#8B63BB',
  borderRadius: '10px',
})

export const MyPageGreetingPhoneNumberContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  flex: 1,
  marginLeft: '21px'
})

export const TextGreeting = styled('span', {
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  color: '#FFF',
  marginTop: '2px'
})

export const  MyPagePhoneNumberContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
})

export const TextPhoneNumber = styled('span', {
  fontFamily: 'Pretendard',
  fontSize: '22px',
  fontStyle: 'normal',
  fontWeight: 800,
  lineHeight: 'normal',
  color: '#fff'
})

export const TextSir = styled('span', {
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontStyle: '14px',
  fontWeight: 400,
  lineHeight: 'normal',
  marginLeft: '2px',
  color: '#fff'
})

export const MyPageUserInfoPhotoContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  flex: 1,
  marginRight: '21px',
  position: 'relative'
})

export const MyPageUserInfoPhotoEllipseContainer = styled('div', {
  width: '68px',
  height: '68px',
  backgroundColor: '#F3E4FF',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  position: 'relative',
})

export const ImgUserProfile = styled('img', {
  width: '28px', height: '40px'
})

export const MyPageSettingListContainer = styled('div', {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  textAlign: 'left',
  borderBottom: '1px solid #E6E6E6',
  paddingBottom: '10px',
  marginTop: '26px'
})

export const ButtonModify = styled('button', {
  width: '50px',
  height: '24px',
  border: '1px solid #8B63BB',
  borderColor: '#8B63BB',
  borderRadius: '20px',
  background: '#fff',
  textAlign: 'center',
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
  display: 'flex',
  color: '#8B63BB',
  justifyContent: 'center',
  alignItems: 'center'
})

export const ImgMyPage = styled('img', {
  maxWidth: '50px',
  height: 'auto',
  marginRight: '10px',
})
