import BaseService from '../baseService';
import { IPointSmsCert, IPointSmsCertResponse } from '../../models/Point/pointSmsCertModel';
import BaseError from '../../models/baseError';
import AxiosClient from '../../utils/axiosClient';
import ErrorHandler from '../../utils/error/errorHandler';
import { IPointWithdrawalMoney, IPointWithdrawalMoneyResponse } from '../../models/Point/pointWithdrawalMoneyModel';
import { IPointWithdrawal, IPointWithdrawalResponse } from '../../models/Point/pointWithdrawalModel';

interface PointWithdrawalMoneyEndpoint {
  POINT_WITHDRAWAL_MONEY: string;
  POINT_WITHDRAWAL: string;
}

class PointWithdrawalMoneyService extends BaseService<PointWithdrawalMoneyEndpoint> {
  apiEndpoints: PointWithdrawalMoneyEndpoint = {
    POINT_WITHDRAWAL_MONEY: 'balanceInquiry',
    POINT_WITHDRAWAL: 'withdrawal'
  };

  postWithdrawalMoney = async (requestBody: IPointWithdrawalMoney): Promise<IPointWithdrawalMoneyResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .data({ ...requestBody })
        .url(`${this.apiEndpoints.POINT_WITHDRAWAL_MONEY}`)
        .build<IPointWithdrawalMoneyResponse>()

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError()
      }
    } catch (error) {
      console.error(error)
      return ErrorHandler.create(error).getError()
    }
  }

  postWithdrawal = async (requestBody: IPointWithdrawal) : Promise<IPointWithdrawalResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .data({ ...requestBody })
        .url(`${this.apiEndpoints.POINT_WITHDRAWAL}`)
        .build<IPointWithdrawalResponse>()

      if (response.data.res_code === 200) {
        console.log('출금 성공')
        return response.data;
      } else {
        console.log('출금 실패')
        return ErrorHandler.create(new Error(response.data.err_msg)).getError()
      }
    } catch (error) {
      console.error(error)
      return ErrorHandler.create(error).getError()
    }
  }
}

export default new PointWithdrawalMoneyService()


