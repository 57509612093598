// tabsConfig.ts
import React, { ReactElement, useRef, useState } from 'react';
import icLocation from 'assets/ic/ic_location_gray.svg';
import icSiren from 'assets/ic/ic_siren.png';
import icCaution1 from 'assets/ic/ic_caution_1.svg';
import icCaution2 from 'assets/ic/ic_caution_2.svg';
import icCaution3 from 'assets/ic/ic_caution_3.svg';
import icCallGray from 'assets/ic/ic_call_gray.svg';
import icDotGray from 'assets/ic/ic_dot_gray.svg';
import icNotRoadView from 'assets/ic/ic_not_road_view.svg';
import icParkingPurple from 'assets/ic/ic_parking_purple.svg';
import icExclamationGray from 'assets/ic/ic_exclamation.svg';
import icNotImage from 'assets/ic/ic_not_image.svg';
import icBoxTruck from 'assets/ic/ic_box_truck.svg';
import icCarRegi from 'assets/ic/ic_car_regi.svg';
import icCircuitBreaker from 'assets/ic/ic_circuit_breaker.svg';
import icCompactCarExclusive from 'assets/ic/ic_compact_car_exclusive.svg';
import icDualParking from 'assets/ic/ic_dual_parking.svg';
import icFullCharge from 'assets/ic/ic_full_charge.svg';
import icMachine from 'assets/ic/ic_machine.svg';
import icMotorcycle from 'assets/ic/ic_motorcycle.svg';
import icQuickCharge from 'assets/ic/ic_quick_charge.svg';
import icReservedSeat from 'assets/ic/ic_reserved_seat.svg';
import icRV from 'assets/ic/ic_rv.svg';
import icSedan from 'assets/ic/ic_sedan.svg';
import icSelfParking from 'assets/ic/ic_self_parking.svg';
import icSingleParking from 'assets/ic/ic_single_parking.svg';
import icSuvBellow from 'assets/ic/ic_suv_below.svg';
import icVacancy from 'assets/ic/ic_vacancy.svg';
import {
  BlackColorSpan, BottomButtonContainer,
  BottomContentsContainer, ButtonEnableDisable,
  CautionBottomTextContainer,
  CautionContentsContainer,
  CautionDetailContainer, CautionDetailNumberSpan,
  CautionDetailNumberTextContainer, CautionDetailTextContainer, CautionDetailTextSpan, CautionDotTextContainer,
  CautionFirstContainer,
  CautionIcon,
  CautionRemainContainer,
  CautionSirenTitleContainer,
  CautionSirenTitleContentsContainer,
  CautionTextContainer,
  CautionTitleContainer,
  CautionTopTextContainer,
  CenterContentsContainer,
  DepositStatusContainer,
  DiscountAmountSpan, DotIcon,
  ExclamationIcon,
  GrayColorSpan,
  ImageTextContainer,
  RoadViewImageViewContainer,
  MainColorSpan,
  OperatingHourContainer,
  ParkingIcon,
  ParkingInfoIconStyled,
  ParkingInfoSpan,
  ParkingInfoStatusContainer,
  ParkingNumberSpan,
  ParkingTitleSpan,
  PaymentAccountDepositContainer,
  PaymentAccountDepositContentContainer,
  RedColorSpan,
  SeasonTicketTitleContainer, SirenIcon,
  TimeOperatingHoursSpan,
  ToggleStyled,
  TopParkingInfoContainer,
  TopParkingInfoSpan,
  ViewDivide1,
  ViewDivide2,
  WeekContainer,
  WeekInfoContainer,
} from './styles';
import { ViewDivide } from '../../../../../pages/AgreeTerms/styles';
import ToggleButton from '../../../Toggle';
import TwoButtonModal from '../../TwoButtonModal';
import PurpleTwoButtonCheckModal from '../../PurpleTwoButtonCheckModal';
import DateChoiceBottomModal from '../../DateChoiceBottomModal';

export interface MonthParkingInfoDetailTab {
  name: string;
  content: ReactElement;
}

const MonthParkingInfoDetail = (): MonthParkingInfoDetailTab[] => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleToggle = (index: number) => { setSelectedIndex(index); }
  const [isCautionCheckModal, setIsCautionCheckModal] = React.useState(false)
  const cautionCheckModal = () => { setIsCautionCheckModal(true) }

  const [isDateChoiceBottomModal, setIsDateChoiceBottomModal] = useState(false);

  const iconList = [
    icBoxTruck,
    icCarRegi,
    icCircuitBreaker,
    icCompactCarExclusive,
    icDualParking,
    icFullCharge,
    icMachine,
    icMotorcycle,
    icQuickCharge,
    icReservedSeat,
    icRV,
    icSedan,
    icSelfParking,
    icSingleParking,
    icSuvBellow,
    icVacancy
  ];

  const [isMonthParkingInfoBottomModal, setIsMonthParkingInfoBottomModal] = useState(false);

  return [
    {
      name: '주차장',
      content: (
        <div style={{ height: 'auto' }}>
          <TopParkingInfoContainer>
            <ParkingNumberSpan>20394번 주차장</ParkingNumberSpan>
            <ImageTextContainer>
              <img src={icLocation} alt={'로케이션 회색 아이콘'}/>
              <TopParkingInfoSpan>서울 영등포구 당산로 31길 ***</TopParkingInfoSpan>
            </ImageTextContainer>
            <ImageTextContainer>
              <img src={icCallGray} alt={'전화기 회색 아이콘'}/>
              <TopParkingInfoSpan>1666-4369</TopParkingInfoSpan>
            </ImageTextContainer>
          </TopParkingInfoContainer>
          <ViewDivide1 />
          <CenterContentsContainer>
            <ImageTextContainer>
              <ParkingIcon src={icParkingPurple} alt={'주차장 보라색 아이콘'} />
              <ParkingInfoSpan>주차장 정보</ParkingInfoSpan>
              <ExclamationIcon
                onClick={() => {
                  setIsMonthParkingInfoBottomModal(true);
                }}
                src={icExclamationGray}
                alt={'느낌표 회색 아이콘'}
              />
            </ImageTextContainer>
            <ParkingInfoStatusContainer>
              {iconList.map((icon, index) => (
                <ParkingInfoIconStyled key={index} src={icon} alt={`Icon ${index}`} />
              ))}
            </ParkingInfoStatusContainer>
            <ViewDivide />
            <div>
              <OperatingHourContainer>
                <ParkingTitleSpan>운영시간</ParkingTitleSpan>
                <PaymentAccountDepositContentContainer>
                  <WeekContainer>평일</WeekContainer>
                  <TimeOperatingHoursSpan>08:00~17:00</TimeOperatingHoursSpan>
                </PaymentAccountDepositContentContainer>
              </OperatingHourContainer>
              <WeekInfoContainer>
                <WeekContainer>주말·공휴일</WeekContainer>
                <TimeOperatingHoursSpan>운영안함</TimeOperatingHoursSpan>
              </WeekInfoContainer>

              <PaymentAccountDepositContainer>
                <ParkingTitleSpan>결제금액</ParkingTitleSpan>
                <PaymentAccountDepositContentContainer>
                  <DiscountAmountSpan>142.600원</DiscountAmountSpan>
                  <TimeOperatingHoursSpan>140,000원</TimeOperatingHoursSpan>
                </PaymentAccountDepositContentContainer>
              </PaymentAccountDepositContainer>

              <PaymentAccountDepositContainer>
                <ParkingTitleSpan>보증금</ParkingTitleSpan>
                <PaymentAccountDepositContentContainer>
                  <DepositStatusContainer>주자 이용 종료 후 반환</DepositStatusContainer>
                  <TimeOperatingHoursSpan>50.000원</TimeOperatingHoursSpan>
                </PaymentAccountDepositContentContainer>
              </PaymentAccountDepositContainer>
            </div>

            <ToggleStyled>
              <ToggleButton
                options={['사진', '로드뷰']}
                selectedIndex={selectedIndex}
                content={[
                  <div key="사진">
                    <RoadViewImageViewContainer>
                      <img src={icNotImage} alt={'이미지 없는 아이콘'} />
                      <span>등록된 사진이 없습니다.</span>
                    </RoadViewImageViewContainer>
                  </div>,
                  <div key="로드뷰">
                    <RoadViewImageViewContainer>
                      <img src={icNotRoadView} alt={'로드뷰 없는 아이콘'} />
                      <span>로드뷰가 제공 되지 않는 곳입니다.</span>
                    </RoadViewImageViewContainer>
                    {/* 사진을 제대로 불러 왔을때 처리 해야됨 */}
                    {/*<span*/}
                    {/*  style={{*/}
                    {/*    color: '#868686',*/}
                    {/*    fontFamily: 'Pretendard',*/}
                    {/*    fontSize: '11px',*/}
                    {/*    fontStyle: 'normal',*/}
                    {/*    fontWeight: 400,*/}
                    {/*    lineHeight: 'normal',*/}
                    {/*  }}*/}
                    {/*>*/}
                    {/*  로드뷰에서 보여지는 이미지는 실제 주차장과 차이가 있을 수 있습니다.*/}
                    {/*</span>*/}
                  </div>,
                ]}
                onToggle={handleToggle}
              />
            </ToggleStyled>
          </CenterContentsContainer>
        </div>
      ),
    },
    {
      name: '유의사항',
      content:
        <div style={{
          height: 'auto'
        }}>
          <CautionSirenTitleContentsContainer>
            <CautionSirenTitleContainer>
              <SirenIcon src={icSiren} alt={'사이렌 아이콘'} />
              <CautionTitleContainer>
                <div>
                  <span>아래</span>
                  <MainColorSpan>유의사항</MainColorSpan>
                  을
                  <br />
                  꼼꼼하게 확인해 주세요!
                </div>
              </CautionTitleContainer>
            </CautionSirenTitleContainer>

            <CautionContentsContainer>
              <CautionFirstContainer>
                <CautionIcon src={icCaution1} alt={'주의 아이콘1'} />

                <CautionTextContainer>
                  <CautionTopTextContainer>
                    <GrayColorSpan>정기권 결제 완료 후</GrayColorSpan>
                    <RedColorSpan> 즉시 이용 불가</RedColorSpan>
                  </CautionTopTextContainer>
                  <CautionBottomTextContainer>
                    <MainColorSpan>공유자의 최종 승인</MainColorSpan>
                    <BlackColorSpan>이 필요합니다.</BlackColorSpan>
                  </CautionBottomTextContainer>
                </CautionTextContainer>
              </CautionFirstContainer>

              <CautionRemainContainer>
                <CautionIcon src={icCaution2} alt={'주의 아이콘2'} />

                <CautionTextContainer>
                  <CautionTopTextContainer>
                    <GrayColorSpan>공유자</GrayColorSpan>
                    <RedColorSpan> 최종 승인 후</RedColorSpan>
                  </CautionTopTextContainer>
                  <CautionBottomTextContainer>
                    <MainColorSpan>카카오 알림톡 수신 후</MainColorSpan>
                    <BlackColorSpan>이용 가능 합니다.</BlackColorSpan>
                  </CautionBottomTextContainer>
                </CautionTextContainer>
              </CautionRemainContainer>

              <CautionRemainContainer>
                <CautionIcon src={icCaution3} alt={'주의 아이콘3'} />

                <CautionTextContainer>
                  <CautionTopTextContainer>
                    <GrayColorSpan>유의사항 미숙지로 발생된 피해는</GrayColorSpan>
                  </CautionTopTextContainer>
                  <CautionBottomTextContainer>
                    <MainColorSpan>운전자에게 책임</MainColorSpan>
                    <BlackColorSpan>이 있습니다.</BlackColorSpan>
                  </CautionBottomTextContainer>
                </CautionTextContainer>
              </CautionRemainContainer>

              <SeasonTicketTitleContainer>
                <ParkingIcon src={icParkingPurple} alt={'주차장 보라색 아이콘'} />
                <ParkingInfoSpan>정기권 구매 유의사항</ParkingInfoSpan>
              </SeasonTicketTitleContainer>
              <ViewDivide2/>

              <BottomContentsContainer>
                <CautionDetailContainer>
                  <CautionDetailNumberTextContainer>
                    <CautionDetailNumberSpan>01</CautionDetailNumberSpan>
                    <CautionDetailTextContainer>
                      <CautionDetailTextSpan>
                        해당 주차장은{' '}
                        <RedColorSpan>주차비 결제 완료 후 공유자의 승인</RedColorSpan>
                        을 받
                        <br />
                        아야 이용 가능하며,
                        <RedColorSpan>최종 이용 확정</RedColorSpan>{' '}
                        내용의 카카오 알림
                        <br />톡 및 APP Push 수신 시부터 이용 가능합니다.
                      </CautionDetailTextSpan>
                      <CautionDotTextContainer>
                        <DotIcon src={icDotGray} alt={'회색 점 아이콘'} />
                        공유자의 최종승인 전 이용이 불가하며, 발생되는 별도의 주차비 책임은 운전자에게
                        있습니다.
                      </CautionDotTextContainer>
                    </CautionDetailTextContainer>
                  </CautionDetailNumberTextContainer>
                </CautionDetailContainer>

                <CautionDetailContainer>
                  <CautionDetailNumberTextContainer>
                    <CautionDetailNumberSpan>02</CautionDetailNumberSpan>
                    <CautionDetailTextContainer>
                      <CautionDetailTextSpan>
                        <RedColorSpan>
                          미성년자, 대포차, 압류차량, 무면허, 음주운전, 차량번호
                          <br />
                          오기재 등
                        </RedColorSpan>
                        <GrayColorSpan>
                          {' '}
                          주차장 이용 중 운전자의 과실이 명확한 책임은 운전자에게 있으며 강제 출차 및
                          견인 조치 될 수 있습니다.
                        </GrayColorSpan>
                      </CautionDetailTextSpan>
                      <CautionDotTextContainer>
                        <DotIcon src={icDotGray} alt={'회색 점 아이콘'} />
                        불법주차 시 주차비는 환불되지 않습니다.
                      </CautionDotTextContainer>
                    </CautionDetailTextContainer>
                  </CautionDetailNumberTextContainer>
                </CautionDetailContainer>

                <CautionDetailContainer>
                  <CautionDetailNumberTextContainer>
                    <CautionDetailNumberSpan>03</CautionDetailNumberSpan>
                    <CautionDetailTextContainer>
                      <CautionDetailTextSpan>
                    <span>
                      기계식, 카리프트 주차장의 경우 차량 제원에 따라 이용이 제한 될 수 있습니다.
                    </span>
                      </CautionDetailTextSpan>
                    </CautionDetailTextContainer>
                  </CautionDetailNumberTextContainer>
                </CautionDetailContainer>

                <CautionDetailContainer>
                  <CautionDetailNumberTextContainer>
                    <CautionDetailNumberSpan>04</CautionDetailNumberSpan>
                    <CautionDetailTextContainer>
                      <CautionDetailTextSpan>
                        <RedColorSpan>환불은 이용 시작일로부터 7일차 사용 전까지만</RedColorSpan>
                        <GrayColorSpan>가능합니다.</GrayColorSpan>
                      </CautionDetailTextSpan>
                      <CautionDotTextContainer>
                        <DotIcon src={icDotGray} alt={'회색 점 아이콘'} />
                        잔여 기간은 일할 계산 환불되며, 환불 정책에 따라 환불 금액이 없을 수 있습니다.
                      </CautionDotTextContainer>
                    </CautionDetailTextContainer>
                  </CautionDetailNumberTextContainer>
                </CautionDetailContainer>

                <CautionDetailContainer>
                  <CautionDetailNumberTextContainer>
                    <CautionDetailNumberSpan>05</CautionDetailNumberSpan>
                    <CautionDetailTextContainer>
                      <CautionDetailTextSpan>
                      <span>
                        정기주차 이용 시작일 개시 후 실제 주차이용을 하지
                      <br />
                        않았더라도 주차장 대여 기간 초과분에 대해서는 환불이
                      <br />
                        불가합니다.
                      </span>
                      </CautionDetailTextSpan>
                    </CautionDetailTextContainer>
                  </CautionDetailNumberTextContainer>
                </CautionDetailContainer>
              </BottomContentsContainer>
            </CautionContentsContainer>
          </CautionSirenTitleContentsContainer>

          <BottomButtonContainer>
            <ButtonEnableDisable
              onClick={() => setIsCautionCheckModal(true)}>
              정기권 구매하기
            </ButtonEnableDisable>
          </BottomButtonContainer>

          <TwoButtonModal
            isVisible={isCautionCheckModal}
            onClose={() => setIsCautionCheckModal(false)}
            title={'유의사항을 확인하셨나요?'}
            content={'유의사항 미숙지로 발생하는 피해는\n주만사가 책임지지 않습니다.'}
            leftButtonText={'아니오'}
            rightButtonText={'네, 확인했습니다.'}
            rightClick={() => {
              setIsDateChoiceBottomModal(true);
              console.log("DateChoiceBottomModal state set to true");
            }}
          />

          <DateChoiceBottomModal
            isVisible={isDateChoiceBottomModal}
            onClose={() =>
              setIsDateChoiceBottomModal(false)}
          />
        </div>
    },
  ];
};

export default MonthParkingInfoDetail;
