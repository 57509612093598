import BaseService from '../baseService';
import {
  ITerraceTowerUsageHistoryDetail, ITerraceTowerUsageHistoryDetailResponse,

} from 'models/TerraceTower/terraceTowerUsageHistoryDetail';
import BaseError from '../../models/baseError';
import AxiosClient from '../../utils/axiosClient';
import ErrorHandler from '../../utils/error/errorHandler';
import {
  ITerraceTowerUsageHistoryCancel,
  ITerraceTowerUsageHistoryCancelResponse,
} from 'models/TerraceTower/terraceTowerUsageHistoryCancel';
import { ITerraceTowerProduct, ITerraceTowerProductResponse } from 'models/TerraceTower/terraceTowerProduct';
import {
  ITerraceTowerProductOrder,
  ITerraceTowerProductOrderResponse,
} from 'models/TerraceTower/terraceTowerProductOrder';
import {
  ITerraceTowerCompleteProductOrder,
  ITerraceTowerCompleteProductOrderResponse,
} from 'models/TerraceTower/terraceTowerCompleteProductOrder';
import icSuccessCheckGreen from 'assets/ic/ic_success_check_green.svg';
import { response } from 'express';


interface TerraceTowerServiceEndPoint {
  TERRACE_TOWER_USAGE_HISTORY_DETAIL: string;
  TERRACE_TOWER_USAGE_HISTORY_CANCEL: string;
  TERRACE_TOWER_PRODUCT: string;
  TERRACE_TOWER_PRODUCT_ORDER: string;
  TERRACE_TOWER_COMPLETE_PRODUCT_ORDER: string;
}

class TerraceTowerService extends BaseService<TerraceTowerServiceEndPoint> {
  apiEndpoints: TerraceTowerServiceEndPoint = {
    TERRACE_TOWER_USAGE_HISTORY_DETAIL: 'getUsageHistoryDetail',
    TERRACE_TOWER_USAGE_HISTORY_CANCEL: 'getUsageHistoryCancel',
    TERRACE_TOWER_PRODUCT: 'getProduct',
    TERRACE_TOWER_PRODUCT_ORDER: 'createProductOrder',
    TERRACE_TOWER_COMPLETE_PRODUCT_ORDER: 'completeProductOrder'
  }

  postTerraceTowerUsageHistoryDetail = async (
    requestBody: ITerraceTowerUsageHistoryDetail,
  ): Promise<ITerraceTowerUsageHistoryDetailResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .data({ ...requestBody })
        .url(`${this.apiEndpoints.TERRACE_TOWER_USAGE_HISTORY_DETAIL}`)
        .build<ITerraceTowerUsageHistoryDetailResponse>()

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError()
      }
    } catch (error) {
      console.error(error)
      return ErrorHandler.create(error).getError()
    }
  }

  postTerraceTowerUsageHistoryCancel = async (
    requestBody: ITerraceTowerUsageHistoryCancel,
  ): Promise<ITerraceTowerUsageHistoryCancelResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .data({ ...requestBody })
        .url(`${this.apiEndpoints.TERRACE_TOWER_USAGE_HISTORY_CANCEL}`)
        .build<ITerraceTowerUsageHistoryCancelResponse>()

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError()
      }
    } catch (error) {
      console.error(error)
      return ErrorHandler.create(error).getError()
    }
  }

  postTerraceTowerProduct = async (
    requestBody: ITerraceTowerProduct,
  ): Promise<ITerraceTowerProductResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .data({ ...requestBody })
        .url(`${this.apiEndpoints.TERRACE_TOWER_PRODUCT}`)
        .build<ITerraceTowerProductResponse>()

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError()
      }
    } catch (error) {
      console.error(error)
      return ErrorHandler.create(error).getError()
    }
  }

  postTerraceTowerProductOrder = async (
    requestBody: ITerraceTowerProductOrder,
  ): Promise<ITerraceTowerProductOrderResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .data({ ...requestBody })
        .url(`${this.apiEndpoints.TERRACE_TOWER_PRODUCT_ORDER}`)
        .build<ITerraceTowerProductOrderResponse>()

      if (response.data.res_code === 200) {
        return response.data
      } else {
        alert(response.data.err_msg)
        return ErrorHandler.create(new Error(response.data.err_msg)).getError()
      }
    } catch (error: any) {
      console.error(error)
      return ErrorHandler.create(error).getError()
    }
  }

  postTerraceTowerCompleteProductOrder = async (
    requestBody: ITerraceTowerCompleteProductOrder,
  ): Promise<ITerraceTowerCompleteProductOrderResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .data({ ...requestBody })
        .url(`${this.apiEndpoints.TERRACE_TOWER_COMPLETE_PRODUCT_ORDER}`)
        .build<ITerraceTowerCompleteProductOrderResponse>()

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError()
      }
    } catch (error) {
      console.error(error)
      return ErrorHandler.create(error).getError()
    }
  }
}

export default new TerraceTowerService();