import { styled } from '@stitches/react';

export const NotificationModalContainer = styled('div', {
  backgroundColor: '#FFFFFF',
  borderRadius: '8px',
  padding: '16px',
  marginTop: '6px',
  position: 'absolute',
  marginLeft: '20px',
  boxShadow: '1px 2px 16px 0px rgba(0, 0, 0, 0.16)'
});

export const TitleContainer = styled('div', {
  color: '#8B63BB',
  fontFamily: 'Pretendard',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal'
})

